import AXIOS_INSTANCE from "..";
import { GiayPhepRequest } from "./model/MotCuaModel";

export default class LayKetQuaGiayPhepService {
    constructor() { }
    
    layKetQuaGiayPhep = (loaiGiayPhep: string) => {
        return AXIOS_INSTANCE.post('lay-ket-qua/danh-sach-so-giay-phep',  {loaiGiayPhep},{timeout : 600000});
    }

    layKetQuaByMaHoSo = (giayPhep : GiayPhepRequest) => {
        return AXIOS_INSTANCE.post('lay-ket-qua/get-by-ma-ho-so', giayPhep,  {timeout : 600000});
    }


}


