import moment from "moment";
import StringUtil from "./stringUtils";
export default class DateUtil {
    static toDDMMYYYY_HH_MM_SS(date: Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return '';
        }
    }

    static toDDMMYYYY(date: null | Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }
    static timeToDDMMYYYY(dateTime: null | number): string {
        if (dateTime && dateTime != 0) {
            return moment(new Date(dateTime)).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }
    static getEndtDate(dateTime: Date): Date {
        if (dateTime) {
            const startDate = new Date(dateTime);
            startDate.setHours(23, 59, 59, 59);
            return startDate;
        } 
        return new Date();
    }

    static  stringDDMMYYYtoDate(value:string): Date|null{
        let result= null;
        if(!StringUtil.isNullOrEmty(value)){
            result = moment(value, 'DD/MM/YYYY').toDate();
        }
        return result;
    }

    static getFiveYear(dateTime: Date): any {
        if (dateTime) {
            let ngayCap =  moment(dateTime).add({ year: 5 }).subtract(1,'days');
            return ngayCap;
        } 
        return new Date();
    }

    static getThreeYear(dateTime: Date): any {
        if (dateTime) {
            let ngayCap =  moment(dateTime).add({ year: 3 }).subtract(1,'days');
            return ngayCap;
        } 
        return new Date();
    }
    
    static getThreeYearRight(dateTime: Date): any {
        if (dateTime) {
            let ngayCap =  moment(dateTime).add({ year: 3 });
            return ngayCap;
        } 
        return new Date();
    }
}