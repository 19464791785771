import { useState } from "react";
import { useLocation } from "react-router-dom";
import { HoSoCuaToiDTO } from "../apis/hoSoCuaToi/hoSoCuaToiMgtService";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useQuerySoBienNhan() {
  let location = useLocation<HoSoCuaToiDTO>();
  const [soBienNhan] = useState<string>(() => {
    if (location.state) {
      return location.state.maSoHoSo;
    }
    return "";
  });
  return soBienNhan;
}

export function useQueryBussinessPath() {
  let location = useLocation<HoSoCuaToiDTO>();
  const [bussinessPath] = useState<string>(() => {
    if (location.state) {
      return String(location.state.bussinessPath);
    }
    return "";
  });
  return bussinessPath;
}
