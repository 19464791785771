export enum TypeCell {
    BOOLEAN = "0",
    NUMBERIC = "1",
    DATE = "2",
    DATE_TIME = "3",
    CURRENCY = "4",
    FILE = "5",
    ACTION = "6",
    ACTION_LINE = "7",
    LINK = "8",
    ATTACH_FILE_TOOL_TIP = "9",
    ACTION_LINE_VIEW = "10"
}

export interface Action<T> {
    func?: (data: T) => void;
    title?: string;
    icon?: React.ReactNode;
}

export interface Pageable {
    total: number;
    page: number;
    rowsPerPage: number;
}
