
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputBase, InputLabel, NativeSelect } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { OptionMonthsInYear, OptionYears } from "components/commons/options/optionCommons";
import moment from "moment";
import { KienThucVeSinhAttpDanhSachDTO, TraCuuRequestDTO, TraCuuRequestDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { ParamExport } from "apis/baseService";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { createFileType, downLoadFile } from "apis/Common/exportUtil";


 const BaoCaoKienThucVeSinhAttp = () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapGxnTu: Yup.date()
                .nullable()
                .when("ngayCapGxnDen", (ngayCapGxnDen: any, schema: any) => {
                    if (ngayCapGxnDen) {
                        return schema.max(ngayCapGxnDen, "Không hợp lệ");
                    }
                    return schema;
                }),
            ngayCapGxnDen: Yup.date()
                .nullable()
                .when("ngayCapGxnTu", (ngayCapGxnTu: any, schema: any) => {
                    if (ngayCapGxnTu) {
                        return schema.min(ngayCapGxnTu, "Không hợp lệ");
                    }
                    return schema;
                }),
        },
        [["ngayCapGxnTu", "ngayCapGxnDen"]]
    );

    const columns: ColumnCustomTable<KienThucVeSinhAttpDanhSachDTO>[] = [
        { title: "Số giấy xác nhận", field: "soGxn" },
        { title: "Ngày cấp", field: "ngayCapGxn", type: TypeCell.DATE },
        { title: "Ngày hết hạn", field: "ngayHetHanGxn", type: TypeCell.DATE },
        { title: "Tổ chức/ Cá nhân", field: "tenCaNhanToChuc", width: "20%", align: "left" },
        { title: "Địa chỉ", field: "fullDiaChi", width: "30%", align: "left" },
        { title: "Loại đối tượng", field: "loaiDoiTuongStr" }
    ];

    //state
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);
    const [rows, setRows] = useState<KienThucVeSinhAttpDanhSachDTO[]>([]);
    const [mode, setMode] = useState<string>("YEAR");
    // hook
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();


    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT, year: (new Date()).getFullYear() };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
        validateOnChange: false,
    });

    useEffect(() => {
        async function handleChangeMode() {
            if (mode === 'YEAR') {
                formik.setFieldValue('year', (new Date()).getFullYear());
            }
            if (mode === 'MONTH') {
                formik.setFieldValue('month', (new Date()).getMonth());
            }
        }
        handleChangeMode();
    }, [mode]);

    useEffect(() => {
        async function fetchData() {
            await new KienThucVeSinhAttpService().getBaoCao(formik.values, pageParams.page, pageParams.rowsPerPage).then((response) => {
                setRows(response.rows);
                setPageParams((prev) => ({ ...prev, total: response.total }));
            });
        }
        fetchData();
    }, [pageParams.page, pageParams.rowsPerPage]);
    // Control
    async function handleSubmit(request: TraCuuRequestDTO) {
        setPageParams((prev) => ({ ...prev, page: 0 }));
        if (mode === 'YEAR') {
            request.ngayCapGxnTu = null;
            request.ngayCapGxnDen = null;
        } else {
            if (mode === 'MONTH') {
                let mm = moment().year(Number(request.year)).month(Number(request.month));
                request.ngayCapGxnTu = mm.startOf('month').toDate();
                request.ngayCapGxnDen = mm.endOf('month').toDate();
            }
            request.year = undefined;
        }
        await new KienThucVeSinhAttpService().getBaoCao(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
            setRows(response.rows);
            setPageParams((prev) => ({ ...prev, total: response.total }));
        });
    }

    function handleOnChangePage(value: number) {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    }

    function handOnChangeRowsPerPage(value: number) {
        setPageParams((pageParams) => ({
            ...pageParams,
            page: 0,
            rowsPerPage: value,
        }));
    }

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        // let data = { ...formik.values };
        // if (mode === 'YEAR') {
        //     data.ngayCapGxnTu = null;
        //     data.ngayCapGxnDen = null;
        // } else {
        //     if (mode === 'MONTH') {
        //         let mm = moment().year(Number(data.year)).month(Number(data.month));
        //         data.ngayCapGxnTu = mm.startOf('month').toDate();
        //         data.ngayCapGxnDen = mm.endOf('month').toDate();
        //         data.month = String(Number(data.month));
        //     }
        //     data.year = undefined;
        // }
        let newParams: ParamExport = { pageNumber: pageParams.page, pageSize: pageParams.rowsPerPage, downloadType: downloadType };
        await new KienThucVeSinhAttpService().baoCaoExport({ ...formik.values , mode: mode}, newParams).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Bao-cao-kien-thuc-ve-sinh-attp-]${new Date()}`);
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'BÁO CÁO TÌNH HÌNH CẤP CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM'}</div>
                <Grid container>
                <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true}/>
                <Grid item xs={6} className="grid-item"></Grid>
                <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                        <NativeSelect value={mode} onChange={e => setMode(e.target.value)}
                            input={<InputBase fullWidth classes={inputStyles} />} >
                            <option value="YEAR">Năm</option>
                            <option value="MONTH">Tháng</option>
                            <option value="FROM_TO">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    {
                        (mode === 'YEAR' || mode === 'MONTH') &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Năm</InputLabel>
                            <NativeSelect value={formik.values.year} onChange={e => formik.setFieldValue("year", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <option value={undefined}>Chọn năm</option>
                                <OptionYears />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        mode === 'MONTH' &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tháng</InputLabel>
                            <NativeSelect value={formik.values.month} onChange={e => formik.setFieldValue("month", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <OptionMonthsInYear />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        mode === 'FROM_TO' &&
                        <>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGxnTu"
                                    label={"Từ ngày"}
                                    value={formik.values.ngayCapGxnTu}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGxnTu", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGxnTu") && getIn(formik.errors, "ngayCapGxnTu"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGxnTu") && getIn(formik.errors, "ngayCapGxnTu")) && formik.errors?.ngayCapGxnTu}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGxnDen"
                                    label={"Đến ngày"}
                                    value={formik.values.ngayCapGxnDen}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGxnDen", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGxnDen") && getIn(formik.errors, "ngayCapGxnDen"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGxnDen") && getIn(formik.errors, "ngayCapGxnDen")) && formik.errors?.ngayCapGxnDen}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tổng hợp
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handOnChangeRowsPerPage}
                            pageParams={pageParams}
                        />
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>
    )
}
export default BaoCaoKienThucVeSinhAttp;