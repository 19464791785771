import { Grid, GridSize, InputLabel } from "@material-ui/core";
import React from "react";
import { bootstrapTextFieldHook } from "../input/bootstrap";
import { FieldGeneral, FormikType } from "./Interface";

export interface CreateFieldsProps<T, K> extends FieldGeneral<T, K> {
    Component?: React.ElementType;
    xs?: GridSize;
}

export interface ArrField<T, K> {
    fields: CreateFieldsProps<T, K>[];
    formik: FormikType<T>;
}

const CreateFields = <T extends object, K extends object>({ fields, formik }: ArrField<T, K>) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    return (
        <>
            {fields.map(({ Component, label, name, xs, ...rest }, index) => {
                if (rest.diaChiOf && Component) {
                    return <Component key={index} {...rest} formik={formik} label={label} name={name} xs={xs} />;
                }
                if (!Component && !name) {
                    return (
                        <Grid item xs={xs} className="grid-item" key={index}>
                            <InputLabel classes={labelStyles}>{label}</InputLabel>
                        </Grid>
                    );
                }

                return (
                    <Grid item xs={xs} className="grid-item" key={index}>
                        {Component && <Component {...rest} formik={formik} label={label} name={name} />}
                    </Grid>
                );
            })}
        </>
    );
};

export default CreateFields;
