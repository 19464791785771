import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomNativeSelect from "components/commons/fields/CustomNativeSelect";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { useFormik } from "formik";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { optionNam } from "model/thucVat/thuoc";
import moment from "moment";
import React from "react";
import ChartKienThucVeSinhAttp from "./chartBieuDo";
import { KienThucVeSinhAttpThongKeDTO } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";

export interface BieuDoKienThucVeSinhAttpProps {}

const BieuDoKienThucVeSinhAttp: React.FC<BieuDoKienThucVeSinhAttpProps> = ({}) => {
    const title = "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP XÁC NHẬN KIẾN THỨC VỀ AN TOÀN THỰC PHẨM";
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<KienThucVeSinhAttpThongKeDTO[]>([]);

    React.useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
        setLoading(true);
        return () => setLoading(false);
    }, []);

    const initialValues: KienThucVeSinhAttpThongKeDTO = {
        ngayCapGcnTu: null,
        ngayCapGcnDen: null,
        year: new Date().getFullYear(),
        mode: "YEAR",
        diaChi: DiaChiDTO_INT_CAN_THO,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: fetchData,
    });

    React.useEffect(() => {
        async function handleChangeMode() {
            if (formik.values.mode === "YEAR") {
                formik.setFieldValue("year", new Date().getFullYear());
            }
        }
        handleChangeMode();
    }, [formik.values.mode]);

    const fields: CreateFieldsProps<KienThucVeSinhAttpThongKeDTO, OptionDefault>[] = [
        { Component: DiaChiDonViHanhChinh, diaChiOf: "diaChi", disabled: true, hideTinhThanh: true, hidePhuongXa: true, required: false },
        { label: "Năm", name: "year", options: optionNam, Component: CustomNativeSelect, xs: 3, fieldString: "title", fieldValue: "value" },
    ];

    React.useEffect(() => {
        loading && fetchData(formik.values);
    }, [loading]);

    async function fetchData(values: KienThucVeSinhAttpThongKeDTO) {
        if (loading) {
            let data = { ...values, mode: formik.values.mode };
            if (formik.values.mode === "YEAR") {
                data.ngayCapGcnTu = moment([Number(data.year)])
                    .startOf("year")
                    .toDate();
                data.ngayCapGcnDen = moment([Number(data.year)])
                    .endOf("year")
                    .toDate();
            } else {
                if (formik.values.mode === "MONTH") {
                    let mm = moment()
                        .year(Number(data.year))
                        .month(Number(data.month) - 1);
                    data.ngayCapGcnTu = mm.startOf("month").toDate();
                    data.ngayCapGcnDen = mm.endOf("month").toDate();
                    data.month = String(Number(data.month));
                }
                data.year = undefined;
            }
            await new KienThucVeSinhAttpService().thongKe(data).then((response) => {
                setRows(response);
            });
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{title}</span>
                </div>
                <Paper component="div">
                    <Grid container>
                        <CreateFields formik={formik} fields={fields} />
                        <Grid item xs={12} className="grid-item">
                            <Box className="button-group-center">
                                <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                    Tổng hợp
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <ChartKienThucVeSinhAttp rows={rows} />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default BieuDoKienThucVeSinhAttp;
