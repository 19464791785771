import { InputLabel } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { ColumnTable, CommonTableChuyenNganh } from "components/commons/table/commonTableChuyenNganh";
import { ChungNhanAttpHoaChatDTO, ChungNhanAttpHoaChatDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import React, { useState } from "react";
import { HoaChatSuDungDialog } from "./hoaChatSuDungDialog";

export interface DanhSachHoaChatProps {
    onDeleteItem?: (item: ChungNhanAttpHoaChatDTO, index: number) => void;
    onSaveItem?: (item: ChungNhanAttpHoaChatDTO, index: number, isEdit: boolean) => void;
    dataListItem: ChungNhanAttpHoaChatDTO[];
    disableAddButton?: boolean;
    viewOnly?: boolean;
    label?: string;
}



export const HoaChatSuDung = (props: DanhSachHoaChatProps) => {
    const { onSaveItem, onDeleteItem, dataListItem, disableAddButton, viewOnly, label } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();

    const [openDlg, setOpenDlg] = useState(false);
    const [itemEdited, setItemEdited] = useState<ChungNhanAttpHoaChatDTO>({...ChungNhanAttpHoaChatDTO_INT});
    const [isEdit, setIsEdit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);

    const columns: ColumnTable<ChungNhanAttpHoaChatDTO>[] = [
        { title: "Tên hóa chất", field: "tenHoaChat" },
        { title: "Thành phần chính", field: "thanhPhanChinh" },
        { title: "Nước sản xuất", field: "nuocSanXuat" },
        { title: "Mục đích sử dụng", field: "mucDichSuDung" },
        { title: "Nồng độ", field: "nongDo" },
    ];

    function handleClickAdd() {
        setItemEdited({...ChungNhanAttpHoaChatDTO_INT});
        setIsEdit(false);
        setOpenDlg(true);
    }

    function handleSaveDialog(item: ChungNhanAttpHoaChatDTO) {
        setOpenDlg(false);
        onSaveItem && onSaveItem(item, indexEdit, isEdit);

    }

    function handleEditItem(item: ChungNhanAttpHoaChatDTO, index: number) {
        setItemEdited(item);
        setIsEdit(true);
        setIndexEdit(index);
        setOpenDlg(true);
    }
    return (
        <>
            <InputLabel classes={labelStyles}>{label}</InputLabel>
            <CommonTableChuyenNganh columns={columns} rows={dataListItem}
                onDelete={onDeleteItem ? onDeleteItem : undefined}
                onEdit={onSaveItem ? handleEditItem : undefined}
                viewOnly={viewOnly}
                onAdd={disableAddButton ? undefined : handleClickAdd} />
            {
                openDlg && <HoaChatSuDungDialog openDialog={openDlg} onDialogClose={() => setOpenDlg(false)}
                    onSubmitData={handleSaveDialog}
                    itemEdit={itemEdited}
                />
            }
        </>
    )
}