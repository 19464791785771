import { OptionDefault } from "model/danhMuc/danhMucDTO";

export enum LoaiChuyenNganh {
    NHAP_DAU_KY = "0",
    CAP_LAI = "1",
    CAP_MOI = "2",
    THU_HOI = "3"
}

export enum DoiTuong {
    NONE = "",
    TO_CHUC_KD_SP_NLTS = "0"
}

export function getDoiTuongEnum(doiTuong?: DoiTuong) {
    if (doiTuong == DoiTuong.TO_CHUC_KD_SP_NLTS) {
        return "Tổ chức kinh doanh sản phẩm nông lâm thủy sản";
    }
}

export enum GioiTinh {
    NAM = "1",
    NU = "2",
    KHAC = "3"
}

export const GioiTinhOptions : GioiTinh[] = [
    GioiTinh.NAM, GioiTinh.NU, GioiTinh.KHAC
]

export const optionsGioiTinh: OptionDefault[] = [
    { title: "Nam", value: GioiTinh.NAM },
    { title: "Nữ", value: GioiTinh.NU },
    { title: "Không xác định", value: GioiTinh.KHAC },
];

export function getGioiTinhEnum(gioiTinh?: GioiTinh) {
    if (gioiTinh == GioiTinh.NAM) {
        return "Nam";
    } else if (gioiTinh == GioiTinh.NU) {
        return "Nữ";
    } else if (gioiTinh == GioiTinh.KHAC) {
        return "Không xác định";
    } else {
        return "--Chọn giới tính--";
    }
}

export interface DanhMucDTO {
    ten: string;
    ma: string;
}

//export const NGUOI_KY : DanhMucDTO[] = [
export const NGUOI_KY = [
    {
        ten : 'Người ký 01',
        ma : 'NK01'
    },
    {
        ten : 'Người ký 02',
        ma : 'NK02'
    },
    {
        ten : 'Người ký 03',
        ma : 'NK03'
    },
    {
        ten : 'Người ký 04',
        ma : 'NK04'
    },
    {
        ten : 'Người ký 05',
        ma : 'NK05'
    }
]

// export const CHUC_VU : DanhMucDTO[] = [
export const CHUC_VU = [
    {
        ten : 'Chức vụ 01',
        ma : 'CV01'
    },
    {
        ten : 'Chức vụ 02',
        ma : 'CV02'
    },
    {
        ten : 'Chức vụ 03',
        ma : 'CV03'
    }
]