import apis from "apis";
import { BaseService } from "apis/baseService";

export interface DashboardTrongTrotDTO {
    thang: number;
    tenThang: string;
    buongBanPhanBon: number;
    sanXuatPhanBon: number;
    buongBanBVTV: number;
}
export class DashboardTrongTrotService extends BaseService {
    loadDataDashboard = (nam: number) => {
        return apis.post("thuc-vat/dashboard/load-data", { nam: nam });
    };

}
