
import { BaoCaoChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/baoCaoChungNhanDuDieuKienAttp";
import { ViewGiayChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/capChungNhanDuDieuKienAttp/chiTiet";
import { DanhSachChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/danhSachChungNhanDuDieuKienAttp";
import { FormCapLaiChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/capChungNhanDuDieuKienAttp/formCapLai";
import { FormCapMoiChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/capChungNhanDuDieuKienAttp/formCapMoi";
import { FormNhapDauKyChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/capChungNhanDuDieuKienAttp/formNhapDauKy";
import { ThongKeChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/thongKeChungNhanDuDieuKienAttp";
import { BieuDoChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/bieuDoChungNhanDuDieuKienAttp";
import { ThuHoiChungNhanDuDieuKienAttp } from "pages/QLCLNongLamThuySan/capChungNhanDuDieuKienAttp/thuHoiChungNhanDuDieuKienAttp";
import { NhapDauKyKienThucVeSinhAttp } from "pages/QLCLNongLamThuySan/kienThucVeSinhAttp/nhapDauKyKienThucVeSinhAttp";
import { ChiTietKienThucVeSinhAttp } from "pages/QLCLNongLamThuySan/kienThucVeSinhAttp/chiTietKienThucVeSinhAttp";
import { DanhSachKienThucVeSinhAttp } from "pages/QLCLNongLamThuySan/traCuuKienThucVeSinhAttp/danhSachKienThucVeSinhAttp";
import { RoutePropsI } from "routes";
import BaoCaoKienThucVeSinhAttp from "pages/QLCLNongLamThuySan/traCuuKienThucVeSinhAttp/baoCaoKienThucVeSinhAttp";
import {CapMoiKienThucVeSinhAttp} from "pages/QLCLNongLamThuySan/kienThucVeSinhAttp/capMoi";
import ThongKeKienThucVeSinhAttp from "pages/QLCLNongLamThuySan/traCuuKienThucVeSinhAttp/thongKe";
import BieuDoKienThucVeSinhAttp from "pages/QLCLNongLamThuySan/kienThucVeSinhAttp/bieuDo";
import { DanhSachChungNhanDuDieuKienAttpNongLam } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/NongLamThuySan/danhSachChungNhanDuDieuKienAttpNongLam";
import { BaoCaoChungNhanDuDieuKienAttpNongLam } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/NongLamThuySan/baoCaoChungNhanDuDieuKienAttpNongLam";
import { ThongKeChungNhanDuDieuKienAttpNongLam } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/NongLamThuySan/thongKeChungNhanDuDieuKienAttpNongLam";
import { DanhSachChungNhanDuDieuKienAttpThuySan } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/ThuySan/danhSachChungNhanDuDieuKienAttpThuySan";
import { BaoCaoChungNhanDuDieuKienAttpThuySan } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/ThuySan/baoCaoChungNhanDuDieuKienAttpThuySan";
import { ThongKeChungNhanDuDieuKienAttpThuySan } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/ThuySan/thongKeChungNhanDuDieuKienAttpThuySan";
import { DanhSachChungNhanDuDieuKienAttpTrongTrot } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/TrongTrotChanNuoi/danhSachChungNhanDuDieuKienAttpTrongTrot";
import { ThongKeChungNhanDuDieuKienAttpTrongTrot } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/TrongTrotChanNuoi/thongKeChungNhanDuDieuKienAttpTrongTrot";
import { BaoCaoChungNhanDuDieuKienAttpTrongTrot } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/TrongTrotChanNuoi/baoCaoChungNhanDuDieuKienAttpTrongTrot";
import { BieuDoChungNhanDuDieuKienAttpNongLam } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/NongLamThuySan/bieuDoChungNhanDuDieuKienAttpNongLam";
import { BieuDoChungNhanDuDieuKienAttpThuySan } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/ThuySan/bieuDoChungNhanDuDieuKienAttpThuySan";
import { BieuDoChungNhanDuDieuKienAttpTrongTrot } from "pages/QLCLNongLamThuySan/traCuuChungNhanDuDieuKienAttp/TrongTrotChanNuoi/bieuDoChungNhanDuDieuKienAttpTrongTrot";

const ROOT = "/qlcl-nong-lam-thuy-san"

export const ROUTE_PAGE_QLCL_NONG_LAM_THUY_SAN: RoutePropsI[] = [
    {
        ten: "Danh sách chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/danh-sach`,
        giaoDien: <DanhSachChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Báo cáo chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bao-cao`,
        giaoDien: <BaoCaoChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Thống kê chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/thong-ke`,
        giaoDien: <ThongKeChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Biểu đồ chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bieu-do`,
        giaoDien: <BieuDoChungNhanDuDieuKienAttp />,
    },
    // ATTP-Nông Lâm Và Thủy sản
    {
        ten: "Danh sách chứng nhận đủ điều kiện an toàn thực phẩm nông lâm và thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/danh-sach-nong-lam-thuy-san`,
        giaoDien: <DanhSachChungNhanDuDieuKienAttpNongLam />,
    },
    
    {
        ten: "Báo cáo chứng nhận đủ điều kiện an toàn thực phẩm nông lâm và thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bao-cao-nong-lam-thuy-san`,
        giaoDien: <BaoCaoChungNhanDuDieuKienAttpNongLam />,
    },
    
    {
        ten: "Thống kê chứng nhận đủ điều kiện an toàn thực phẩm nông lâm và thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/thong-ke-nong-lam-thuy-san`,
        giaoDien: <ThongKeChungNhanDuDieuKienAttpNongLam />,
    },
    {
        ten: "Biểu đồ chứng nhận đủ điều kiện an toàn thực phẩm nông lâm và thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bieu-do-nong-lam-thuy-san`,
        giaoDien: <BieuDoChungNhanDuDieuKienAttpNongLam />,
    },
    // ATTP-Thủy Sản
    {
        ten: "Danh sách chứng nhận đủ điều kiện an toàn thực phẩm thủy sản ",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/danh-sach-thuy-san`,
        giaoDien: <DanhSachChungNhanDuDieuKienAttpThuySan />,
    },
    {
        ten: "Báo cáo chứng nhận đủ điều kiện an toàn thực phẩm thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bao-cao-thuy-san`,
        giaoDien: <BaoCaoChungNhanDuDieuKienAttpThuySan />,
    },
    {
        ten: "Thống kê chứng nhận đủ điều kiện an toàn thực phẩm thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/thong-ke-thuy-san`,
        giaoDien: <ThongKeChungNhanDuDieuKienAttpThuySan />,
    },
    {
        ten: "Biểu đồ chứng nhận đủ điều kiện an toàn thực phẩm thủy sản",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bieu-do-thuy-san`,
        giaoDien: <BieuDoChungNhanDuDieuKienAttpThuySan />,
    },
    // ATTP-Trồng trọt chăn nuôi
    {
        ten: "Danh sách chứng nhận đủ điều kiện an toàn thực phẩm trồng trọt và chăn nuôi",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/danh-sach-trong-trot-va-chan-nuoi`,
        giaoDien: <DanhSachChungNhanDuDieuKienAttpTrongTrot />,
    },
    {
        ten: "Báo cáo chứng nhận đủ điều kiện an toàn thực phẩm trồng trọt và chăn nuôi",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bao-cao-trong-trot-va-chan-nuoi`,
        giaoDien: <BaoCaoChungNhanDuDieuKienAttpTrongTrot />,
    },
    {
        ten: "Thống kê chứng nhận đủ điều kiện an toàn thực phẩm trồng trọt và chăn nuôi",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/thong-ke-trong-trot-va-chan-nuoi`,
        giaoDien: <ThongKeChungNhanDuDieuKienAttpTrongTrot />,
    },
    {
        ten: "Biểu đồ chứng nhận đủ điều kiện an toàn thực phẩm trồng trọt và chăn nuôi",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/bieu-do-trong-trot-va-chan-nuoi`,
        giaoDien: <BieuDoChungNhanDuDieuKienAttpTrongTrot />,
    },
    // Xác nhận kiến thức vệ sinh an toàn thực phẩm
    {
        ten: "Danh sách xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/danh-sach`,
        giaoDien: <DanhSachKienThucVeSinhAttp />,
    },
    {
        ten: "Báo cáo xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/bao-cao`,
        giaoDien: <BaoCaoKienThucVeSinhAttp />,
    },
    {
        ten: "Thống kê tình hình cấp giấy xác nhận kiến thức về an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/thong-ke`,
        giaoDien: <ThongKeKienThucVeSinhAttp />,
    },
    {
        ten: "Biểu đồ thống kê tình hình cấp xác nhận kiến thức về an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/bieu-do`,
        giaoDien: <BieuDoKienThucVeSinhAttp />,
    },
]

export const ROUTE_CN_QLCL_NONG_LAM_THUY_SAN: RoutePropsI[] = [
    {
        ten: "Cấp mới chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/cap-moi`,
        giaoDien: <FormCapMoiChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Cấp lại chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/cap-lai`,
        giaoDien: <FormCapLaiChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Nhập đầu kỳ chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/nhap-dau-ky`,
        giaoDien: <FormNhapDauKyChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Chi tiết chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/chi-tiet`,
        giaoDien: <ViewGiayChungNhanDuDieuKienAttp />,
    },
    {
        ten: "Thu hồi chứng nhận đủ điều kiện an toàn thực phẩm",
        duongDan: `${ROOT}/chung-nhan-du-dieu-kien-attp/thu-hoi`,
        giaoDien: <ThuHoiChungNhanDuDieuKienAttp />,
    },
    // Xác nhận kiến thức vệ sinh an toàn thực phẩm
    {
        ten: "Nhập đầu kỳ xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/nhap-dau-ky`,
        giaoDien: <NhapDauKyKienThucVeSinhAttp />,
    },
    {
        ten: "Chi tiết xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/chi-tiet`,
        giaoDien: <ChiTietKienThucVeSinhAttp />,
    },
    {
        ten: "Cấp giấy xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT}/kien-thuc-ve-sinh-attp/cap-moi`,
        giaoDien: <CapMoiKienThucVeSinhAttp />,
    },
    
]