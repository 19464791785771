import { Box, Button, Card, CardContent, createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, Paper, Theme } from "@material-ui/core";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { OptionYears } from "components/commons/options/optionCommons";
import { ThongKeChungNhanDuDieuKienAttpDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { getLinhVuc, LinhVuc } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { TraCuuRequestDTO, TraCuuRequestDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/traCuuChungNhanDuDieuKienAttpDTO";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
    })
);

export const BieuDoChungNhanDuDieuKienAttpThuySan = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();

    const title = "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP XÁC NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM";

    const [rows, setRows] = useState<ThongKeChungNhanDuDieuKienAttpDTO[]>([]);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [loading, setLoading] = useState<boolean>(false);
    
    const classes = useStyles();

    const dataCapMoi = [...rows.map((item) => item.totalCapMoi)];
    const dataThuHoi = [...rows.map((item) => item.totalThuHoi)];
    const dataCapLai = [...rows.map((item) => item.totalCapLai)];

    useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }, []);

    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT, year: (new Date()).getFullYear(), linhVuc: LinhVuc.THUY_SAN };

    React.useEffect(() => {
        setLoading(true);
        return () => {
            setLoading(false);
        };
    }, []);

    React.useEffect(() => {
        loading && fetchData();
    }, [loading]);

    async function fetchData() {
        let data = { ...initialValues, year: year};
        if (data.mode === "YEAR") {
            data.ngayCapGcnTu = moment([Number(data.year)])
                .startOf("year")
                .toDate();
            data.ngayCapGcnDen = moment([Number(data.year)])
                .endOf("year")
                .toDate();
        }
        await new ChungNhanDuDieuKienAttpService().getThongKeChungNhan(data).then((response) => {
            setRows(response);
        });
    }

    const data = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Cấp lần đầu",
                backgroundColor: "#51A3A3",
                data: dataCapMoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cấp lại",
                backgroundColor: "#EAD94C",
                data: dataCapLai,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Thu hồi",
                backgroundColor: "#5E5D5C",
                data: dataThuHoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
        ],
    };

    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>{title}</span>
            </div>
            <Paper component="div">
                <Grid container>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Năm</InputLabel>
                        <NativeSelect
                            value={year}
                            onChange={(e) => setYear(Number(e.target.value))}
                            input={<InputBase fullWidth classes={inputStyles} />}
                        >
                            <OptionYears />
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} >Lĩnh vực</InputLabel>
                        <NativeSelect disabled = {true} input={<CustomInput />} fullWidth name="linhVuc">
                            <option value={LinhVuc.THUY_SAN}>{getLinhVuc(LinhVuc.THUY_SAN)}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <Box className="button-group" marginTop="14px" justifyContent="flex-start" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => fetchData()}>
                                Tổng hợp
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Card classes={classes}>
                            <CardContent>
                                <Bar data={data} options={options} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
