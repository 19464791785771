
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputBase, InputLabel, NativeSelect } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParamExport } from "apis/baseService";
import { downLoadFile } from "apis/Common/exportUtil";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { TypeCell } from "components/commons/customTable/customTableInterface";
import DialogChiTietThongKe from "components/commons/dialog/dialogChiTietThongKe";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { OptionMonthsInYear, OptionYears } from "components/commons/options/optionCommons";
import { getIn, useFormik } from "formik";
import useContextTableFooter from "hooks/useContextTableFooter";
import { TinhTrangEnum } from "model/baseModel";
import { ThongKeChungNhanDuDieuKienAttpDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { getLinhVuc, LinhVuc } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { DanhSachTraCuuDTO, TraCuuRequestDTO, TraCuuRequestDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/traCuuChungNhanDuDieuKienAttpDTO";
import moment from "moment";
import { useEffect, useState } from "react";
import StringUtil from "utils/stringUtils";
import * as Yup from "yup";

export const ThongKeChungNhanDuDieuKienAttpTrongTrot= () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapGcnTu: Yup.date()
                .when('mode', {
                    is : 'FROM_TO',
                    then: Yup.date().nullable()
                    .required('Từ ngày không được bỏ trống')
                    .when('toDate', (toDate: null | Date, schema: any) => {
                        if (toDate) {
                            return schema.max(toDate, 'Không hợp lệ');
                        }
                        return schema;
                    }),
                    otherwise: Yup.date().nullable()
                }),
            ngayCapGcnDen: Yup.date()
            .when('mode', {
                is: 'FROM_TO',
                then: Yup.date().nullable()
                    .required('Đến ngày không được bỏ trống')
                    .when('fromDate', (fromDate: null | Date, schema: any) => {
                        if (fromDate) {
                            return schema.min(fromDate, 'Không hợp lệ');
                        }
                        return schema;
                    }),
                otherwise: Yup.date().nullable()
            }),
        },
    );

    const columns: ColumnCustomTable<ThongKeChungNhanDuDieuKienAttpDTO>[] = [
        { title: "Tháng", field: "thang" },
        {
            title: "Cấp mới",
            field: "totalCapMoi",
            actions: [{
                func: (item) => item.totalCapMoi && item.totalCapMoi>0 && chiTietThongKeHandler(item, TinhTrangEnum.CAP_LAN_DAU)
            }]
        },
        {
            title: "Cấp lại",
            field: "totalCapLai",
            actions: [{
                func: (item) => item.totalCapLai && item.totalCapLai>0 &&  chiTietThongKeHandler(item, TinhTrangEnum.CAP_LAI)
            }]
        },
        {
            title: "Thu hồi",
            field: "totalThuHoi",
            actions: [{
                func: (item) => item.totalThuHoi && item.totalThuHoi>0 &&  chiTietThongKeHandler(item, TinhTrangEnum.THU_HOI)
            }]
        },
    ];

    const columns1: ColumnCustomTable<ThongKeChungNhanDuDieuKienAttpDTO>[] = [
        { title: "Từ ngày", field: "ngayCapGcnTu", type: TypeCell.DATE },
        { title: "Đến ngày", field: "ngayCapGcnDen", type: TypeCell.DATE },
        {
            title: "Cấp mới",
            field: "totalCapMoi",
            actions: [{
                func: (item) => item.totalCapMoi && item.totalCapMoi>0 && chiTietThongKeHandler(item, TinhTrangEnum.CAP_LAN_DAU)
            }]
        },
        {
            title: "Cấp lại",
            field: "totalCapLai",
            actions: [{
                func: (item) => item.totalCapLai && item.totalCapLai>0 &&  chiTietThongKeHandler(item, TinhTrangEnum.CAP_LAI)
            }]
        },
        {
            title: "Thu hồi",
            field: "totalThuHoi",
            actions: [{
                func: (item) => item.totalThuHoi && item.totalThuHoi>0 &&  chiTietThongKeHandler(item, TinhTrangEnum.THU_HOI)
            }]
        },
    ];

    const columnsChiTiet: ColumnCustomTable<DanhSachTraCuuDTO>[] = [
        {
            title: "Số giấy chứng nhận",
            field: "soGcn"
        },
        { title: "Ngày cấp", field: "ngayCapGcn", type: TypeCell.DATE },
        { title: "Ngày hết hạn", field: "ngayHetHanGcn", type: TypeCell.DATE },
        { title: "Tên cơ sở", field: "tenCoSo", width: "20%", align: "left" },
        { title: "Địa chỉ", field: "fullDiaChi", width: "25%", align: "left" },
        { title: "Số điện thoại", field: "soDienThoai", align: "left" },
        { title: "Tình trạng", field: "tinhTrangStr" }
    ];

    //state
    const [rows, setRows] = useState<ThongKeChungNhanDuDieuKienAttpDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [rowsChiTiet, setRowsChiTiet] = useState<DanhSachTraCuuDTO[]>([]);
    const [loaiChiTiet, setLoaiChiTiet] = useState<TinhTrangEnum>(TinhTrangEnum.CAP_LAN_DAU);
    const [thangChiTiet, setThangChiTiet] = useState<String>("");
    // hook
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const { pageParams, setPageParams, handOnChangeRowsPerPage, handleOnChangePage } = useContextTableFooter();

    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT, year: (new Date()).getFullYear(),  linhVuc: LinhVuc.TRONG_TROT_CHAN_NUOI, };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: fetchData,
        validateOnChange: false,
    });

    const reduceSum: Number[] = [
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalCapMoi"),
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalCapLai"),
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalThuHoi"),
    ];

    useEffect(() => {
        setLoading(true);
        return () => {
            setLoading(false);
        };
    }, []);

    useEffect(() => {
        handleChangeMode();
    }, [formik.values.mode]);

    useEffect(() => {
        setRows([]);
    }, [formik.values.mode]);

    useEffect(() => {
        loading && fetchData(formik.values);
    }, [loading]);
    // Control
    async function fetchData(request: TraCuuRequestDTO) {
        let data = { ...request };
        if (formik.values.mode === "YEAR") {
            data.ngayCapGcnTu = moment([Number(data.year)]).startOf("year").toDate();
            data.ngayCapGcnDen = moment([Number(data.year)]).endOf("year").toDate();
        } else {
            if (formik.values.mode === "MONTH") {
                let mm = moment().year(Number(data.year)).month(Number(data.month));
                data.ngayCapGcnTu = mm.startOf("month").toDate();
                data.ngayCapGcnDen = mm.endOf("month").toDate();
                data.month = String(Number(data.month) + 1);
            }
            data.year = undefined;
        }
        await new ChungNhanDuDieuKienAttpService().getThongKeChungNhan(data).then((response) => {
            if (response) setRows(response);
        });
    }

    const chiTietThongKeHandler = (item: ThongKeChungNhanDuDieuKienAttpDTO,data: TinhTrangEnum) => {
        if(item.thang){
            if (formik.values.mode === "YEAR" || formik.values.mode === "MONTH") {
                let monthIndex = item.thang.split(" ");
                let thang = parseInt(monthIndex[1]) - 1;
                setThangChiTiet(thang.toString());
            }
        }
        setLoaiChiTiet(data);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }


    useEffect(() => {
        async function loadChiTietThongKeHandler() {
            if (openDialog) {
                let request: TraCuuRequestDTO = { ...formik.values };
                request.tinhTrang = loaiChiTiet;
                if (request.mode === "MONTH" || request.mode === "YEAR") {
                    let mm = moment().year(Number(request.year)).month(Number(thangChiTiet));
                    request.ngayCapGcnTu = mm.startOf("month").toDate();
                    request.ngayCapGcnDen = mm.endOf("month").toDate();
                    request.month = String(Number(thangChiTiet) + 1);
                }
                await new ChungNhanDuDieuKienAttpService().getChiTietThongKe(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
                    setRowsChiTiet(response.rows);
                    setPageParams((prev) => ({ ...prev, total: response.total }));
                });
            }
        }

        loadChiTietThongKeHandler();
    }, [pageParams.page, pageParams.rowsPerPage, openDialog]);

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        let data = { ...formik.values };
        if (formik.values.mode === "YEAR") {
            data.ngayCapGcnTu = moment([Number(data.year)]).startOf("year").toDate();
            data.ngayCapGcnDen = moment([Number(data.year)]).endOf("year").toDate();
        } else {
            if (formik.values.mode === "MONTH") {
                let mm = moment().year(Number(data.year)).month(Number(data.month));
                data.ngayCapGcnTu = mm.startOf("month").toDate();
                data.ngayCapGcnDen = mm.endOf("month").toDate();
                data.month = String(Number(data.month) + 1);
            }
            data.year = undefined;
        }
        let newParams: ParamExport = { pageNumber: 0, pageSize: -1, downloadType: downloadType };
        await new ChungNhanDuDieuKienAttpService().thongKeExport(data, newParams).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Thong-ke-chung-nhan-du-dieu-kien-attp-]${new Date()}`);
        });
    }

    async function handleChangeMode() {
        if (formik.values.mode === 'YEAR') {
            formik.setFieldValue('year', (new Date()).getFullYear());
        }
         if (formik.values.mode === 'MONTH') {
            formik.setFieldValue('month', (new Date()).getMonth());
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'THỐNG KÊ TÌNH HÌNH CẤP CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM'}</div>
                <Grid container>
                    <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true} />
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} >Lĩnh vực</InputLabel>
                        <NativeSelect disabled = {true} input={<CustomInput />} fullWidth name="linhVuc" value={formik.values?.linhVuc} onChange={formik.handleChange} >
                            <option value={LinhVuc.NONE}>Chọn lĩnh vực</option>
                            <option value={LinhVuc.NONG_LAM_THUY_SAN}>{getLinhVuc(LinhVuc.NONG_LAM_THUY_SAN)}</option>
                            <option value={LinhVuc.THUY_SAN}>{getLinhVuc(LinhVuc.THUY_SAN)}</option>
                            <option value={LinhVuc.TRONG_TROT_CHAN_NUOI}>{getLinhVuc(LinhVuc.TRONG_TROT_CHAN_NUOI)}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item"></Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                        <NativeSelect value={formik.values.mode} onChange={e => formik.setFieldValue("mode", e.target.value)}
                            input={<InputBase fullWidth classes={inputStyles} />} >
                            <option value="YEAR">Năm</option>
                            <option value="MONTH">Tháng</option>
                            <option value="FROM_TO">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    {
                        (formik.values.mode === 'YEAR' || formik.values.mode === 'MONTH') &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Năm</InputLabel>
                            <NativeSelect value={formik.values.year} onChange={e => formik.setFieldValue("year", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <OptionYears />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        formik.values.mode === 'MONTH' &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tháng</InputLabel>
                            <NativeSelect value={formik.values.month} onChange={e => formik.setFieldValue("month", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <OptionMonthsInYear />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        formik.values.mode === 'FROM_TO' &&
                        <>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnTu"
                                    label={"Từ ngày"}
                                    value={formik.values.ngayCapGcnTu}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnTu", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu")) && formik.errors?.ngayCapGcnTu}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    required = {true}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnDen"
                                    label={"Đến ngày"}
                                    value={formik.values.ngayCapGcnDen}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnDen", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen")) && formik.errors?.ngayCapGcnDen}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    required = {true}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tổng hợp
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={formik.values.mode === "FROM_TO" ? columns1 : columns}
                            rows={rows}
                            reduceSumField={formik.values.mode === "YEAR" ? reduceSum : undefined}
                        />
                    </Grid>
                </Grid>
            </div>
            {openDialog &&
                <DialogChiTietThongKe
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    rows={rowsChiTiet}
                    columns={columnsChiTiet}
                    pageParams={pageParams}
                    onChangePage={handleOnChangePage}
                    onChangeRowsPerPage={handOnChangeRowsPerPage}
                />}
        </MuiPickersUtilsProvider>
    )
}