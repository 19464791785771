import AXIOS_INSTANCE from "..";
import { BaseResponse, BaseService } from "../baseService";

const path_url: string = "/lgsp";
export default class LgspService extends BaseService {
    layThongTinCongDanByCMND = (soCMND: string) => {
        return AXIOS_INSTANCE.post(`${path_url}/info-cong-dan-by-cmnd`, { soCMND: soCMND });
    }

    layThongTinDNByMaSoDN = (maSoDoanhNghiep: string) => {
        return AXIOS_INSTANCE.post(`${path_url}/info-doanh-nghiep-by-ma-so`, { maSoDoanhNghiep: maSoDoanhNghiep });
    }
}
