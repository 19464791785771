import { Box, InputBase, InputLabel, NativeSelect, Card, CardContent, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { OverlayLoading } from "components/overlayLoading/overlayLoading";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import moment from "moment";
import { DashboardNongLamThuySanDTO, DashboardNongLamThuySanService } from "apis/QLCLNongLamThuySan/dashboard/DashboardNongLamThuySanService";
import { OptionYears } from "components/commons/options/optionCommons";
import { Bar } from "react-chartjs-2";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
    })
);
export const DashboardNongLamThuySanComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState<DashboardNongLamThuySanDTO[]>([]);
    const [year, setYear] = React.useState<number>(Number(moment().year()));
    const service = new DashboardNongLamThuySanService();
    const [dataCaNhan, setDataCaNhan] = React.useState<number[]>([]);
    const [dataToChuc, setDataToChuc] = React.useState<number[]>([]);
    const [dataCoSo, setDataCoSo] = React.useState<number[]>([]);
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    useEffect(() => {
        loadDataTable(year);
    }, []);
    const loadDataTable = async (year: number) => {
        setYear(year);
        dispatchLoading(showLoading());
        await service
            .loadDataDashboard(year)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                        let data: DashboardNongLamThuySanDTO[] = [...response.data.data];
                        setDataCaNhan([...data.map((item) => item.caNhan)]);
                        setDataToChuc([...data.map((item) => item.toChuc)]);
                        setDataCoSo([...data.map((item) => item.coSoDuDieuKien)]);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>QUẢN LÝ CHẤT LƯỢNG NÔNG, LÂM SẢN VÀ THỦY SẢN</span>
            </div>
            <Grid item xs={3} >
                <InputLabel classes={labelStyles}>Năm</InputLabel>
                <NativeSelect
                    name="year"
                    value={year}
                    onChange={(e: any) => loadDataTable(Number(e.target.value))}
                    input={<InputBase fullWidth classes={inputStyles} />}
                >
                    <OptionYears />
                </NativeSelect>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <Paper>
                    <Card classes={classes}>
                        <CardContent>
                            <Bar data={{
                                labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
                                datasets: [
                                    {
                                        label: "Cá nhân",
                                        backgroundColor: "#51A3A3",
                                        data: dataCaNhan,
                                        barThickness: 15, // number (pixels) or 'flex'
                                        maxBarThickness: 20, // number (pixels)
                                    },
                                    {
                                        label: "Tổ chức",
                                        backgroundColor: "#EAD94C",
                                        data: dataToChuc,
                                        barThickness: 15, // number (pixels) or 'flex'
                                        maxBarThickness: 20, // number (pixels)
                                    },
                                    {
                                        label: "Cơ sở",
                                        backgroundColor: "#5E5D5C",
                                        data: dataCoSo,
                                        barThickness: 15, // number (pixels) or 'flex'
                                        maxBarThickness: 20, // number (pixels)
                                    },
                                ],
                            }} options={options} />
                        </CardContent>
                    </Card>
                </Paper>
            </Grid>
            <OverlayLoading />
        </div>
    );
};
