import { TinhTrangEnum } from "model/baseModel";
import { AttachFileDTO } from "model/chuyenNganh/attachFile";
import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "../diaChiDTO";
import { DoiTuong, LinhVuc, LoaiChuyenNganh, LoaiHinhSanXuatKinhDoanh, LoaiMatHangKinhDoanh } from "./chungNhanDuDieuKienAttpEnum";

export interface ChungNhanAttpSanPhamDTO {
    id: number | null;
    chungNhanId: number | null;
    soGcn: string;
    tenSanPham?: string;
    tenNguyenLieu?: string;
    nguonGoc?: string;
    cachDongGoi?: string;
    dienTich?: string;
    soHo?: string;
    sanLuong?: string;
}

export const ChungNhanAttpSanPhamDTO_INT: ChungNhanAttpSanPhamDTO = {
    id: null,
    chungNhanId: null,
    soGcn: "",
    tenSanPham: "",
    tenNguyenLieu: "",
    nguonGoc: "",
    cachDongGoi: "",
    dienTich: "",
    soHo: "",
    sanLuong: ""
}

export interface ChungNhanAttpTrangThietBiDTO {
    id: number | null;
    chungNhanId: number | null;
    soGcn?: string;
    tenThietBi?: string;
    soLuong?: string;
    nuocSanXuat?: string;
    tongCongSuat?: string;
    namBatDauSuDung?: string;
}

export const ChungNhanAttpTrangThietBiDTO_INT: ChungNhanAttpTrangThietBiDTO = {
    id: null,
    chungNhanId: null,
    soGcn: "",
    tenThietBi: "",
    soLuong: "",
    nuocSanXuat: "",
    tongCongSuat: "",
    namBatDauSuDung: ""
}

export interface ChungNhanAttpHoaChatDTO {
    id: number | null;
    chungNhanId: number | null;
    soGcn?: string;
    tenHoaChat?: string;
    thanhPhanChinh?: string;
    nuocSanXuat?: string;
    mucDichSuDung?: string;
    nongDo?: string;
}

export const ChungNhanAttpHoaChatDTO_INT: ChungNhanAttpHoaChatDTO = {
    id: null,
    chungNhanId: null,
    soGcn: "",
    tenHoaChat: "",
    thanhPhanChinh: "",
    nuocSanXuat: "",
    mucDichSuDung: "",
    nongDo: ""
}

export interface ChungNhanAttpHienTrangCoSoDTO {
    id: number | null;
    soGcn: string;
    tongDienTich?: string;
    dtKhuVucTiepNhan?: string;
    dtKhuVucSanXuat?: string;
    dtKhuVucDongGoi?: string;
    dtKhuVucBaoQuan?: string;
    dtKhuVucSanXuatKhac?: string;
    // Trang thiết bị chính
    listTrangThietBi: ChungNhanAttpTrangThietBiDTO[];
    // Hệ thống phụ trợ
    nuocMayCongCong?: boolean;
    nuocGiengKhoan?: boolean;
    nuocSong?: boolean;
    coHeThongXuLy?: boolean;
    khongCoHeThongXuLy?: boolean;
    phuongPhapXuLy?: string;
    nuocDaTuSanXuat?: boolean;
    nuocDaMuaNgoai?: boolean;
    phuongPhapKsclNuocDa?: string;
    // Hệ thống xử lý chất thải
    heThongXuLyChatThai?: string;
    // Người sản xuất, kinh doanh
    tongSoNguoiSxkd?: number;
    laoDongTrucTiep?: number;
    laoDongGianTiep?: number;
    // Vệ sinh nhà xưởng, trang thiết bị
    tanSuatVsnx?: string;
    tongCongNhanVsnx?: number;
    congNhanVsnxCuaCoSo?: number;
    congNhanVsnxThue?: number;
    // Danh mục các loại hóa chất, phụ gia/chất bổ sung, chất tẩy rửa-khử trùng sử dụng
    listHoaChat: ChungNhanAttpHoaChatDTO[];
    // Hệ thống quản lý chất lượng đang áp dụng
    heThongQuanLyChatLuong?: string;
    // Phòng kiểm nghiệm
    phongKiemNghiemCuaCoSo?: boolean;
    phongKiemNghiemThueNgoai?: boolean;
    tenPhongKiemNghiemCuaCoSo?: string;
    tenPhongKiemNghiemThueNgoai?: string;
    // Nội dung khác
    noiDungKhac?: string;
}

export const ChungNhanAttpHienTrangCoSoDTO_INT: ChungNhanAttpHienTrangCoSoDTO = {
    id: null,
    soGcn: "",
    tongDienTich: "",
    dtKhuVucTiepNhan: "",
    dtKhuVucSanXuat: "",
    dtKhuVucDongGoi: "",
    dtKhuVucBaoQuan: "",
    dtKhuVucSanXuatKhac: "",
    // Trang thiết bị chính
    listTrangThietBi: new Array<ChungNhanAttpTrangThietBiDTO>(),
    // Hệ thống phụ trợ
    nuocMayCongCong: false,
    nuocGiengKhoan: false,
    nuocSong: false,
    coHeThongXuLy: false,
    khongCoHeThongXuLy: false,
    phuongPhapXuLy: "",
    nuocDaTuSanXuat: false,
    nuocDaMuaNgoai: false,
    phuongPhapKsclNuocDa: "",
    // Hệ thống xử lý chất thải
    heThongXuLyChatThai: "",
    // Người sản xuất, kinh doanh
    tongSoNguoiSxkd: 0,
    laoDongTrucTiep: 0,
    laoDongGianTiep: 0,
    // Vệ sinh nhà xưởng, trang thiết bị
    tanSuatVsnx: "",
    tongCongNhanVsnx: 0,
    congNhanVsnxCuaCoSo: 0,
    congNhanVsnxThue: 0,
    // Danh mục các loại hóa chất, phụ gia/chất bổ sung, chất tẩy rửa-khử trùng sử dụng
    listHoaChat: new Array<ChungNhanAttpHoaChatDTO>(),
    // Hệ thống quản lý chất lượng đang áp dụng
    heThongQuanLyChatLuong: "",
    // Phòng kiểm nghiệm
    phongKiemNghiemCuaCoSo: false,
    phongKiemNghiemThueNgoai: false,
    tenPhongKiemNghiemCuaCoSo: "",
    tenPhongKiemNghiemThueNgoai: "",
    // Nội dung khác
    noiDungKhac: "",
}

export interface ChungNhanAttpThongTinChungDTO {
    ngayTao?: null | Date;
    nguoiTaoId?: string;
    id: number | null;
    soBienNhan?: string;
    soGcn: string;
    ngayCapGcn?: null | Date;
    ngayHetHanGcn?: null | Date;
    soGcnCu: string;
    ngayCapGcnCu?: null | Date;
    ngayHetHanGcnCu?: null | Date;
    referenceId?: number | null;
    loaiChuyenNganh: LoaiChuyenNganh;
    lyDoCapLai?: string;
    lanCapLai?: string;
    ngayCapLai?: null | Date;

    doiTuong?: DoiTuong;
    linhVuc?: LinhVuc;

    soDkkd?: string;
    ngayCapDkkd?: null | Date;
    noiCapDkkd?: string;
    maSoDkkd?: string;
    tenCoSo?: string;

    loaiHinhSxkd?: LoaiHinhSanXuatKinhDoanh;
    namBatDauHoatDong?: string;
    congSuatThietKe?: string;

    diaChi: DiaChiDTO;
    fullDiaChi?: string;
    chuCoSo?: string;

    loaiMatHangDkkd?: LoaiMatHangKinhDoanh;
    matHangSxkd?: string;

    attachFiles?: AttachFileDTO[];
    temp?: boolean;

    tinhTrang?: TinhTrangEnum;
    tinhTrangStr?: string;

    listSanPham: ChungNhanAttpSanPhamDTO[];
    hienTrangCoSo: ChungNhanAttpHienTrangCoSoDTO;

    lyDoThuHoi?: string;
    ngayThuHoi?: null | Date;
    soQuyetDinhThuHoi?: string;
    ngayQuyetDinhThuHoi?: null | Date;
    ghiChuThuHoi?: string;
    //Trường dữ liệu đổ dữ liệu đầu kỳ  
    canKySo?: string;
    tenFile?: string;
    dauKy?: string;
    oldId: number | null;
    diaChiKinhDo: number;
    diaChiViDo: number;

    layKetQua: boolean;

}

export const ChungNhanAttpThongTinChung_INT: ChungNhanAttpThongTinChungDTO = {
    id: null,
    ngayTao: null,
    nguoiTaoId: "",
    soBienNhan: "",
    soGcn: "",
    ngayCapGcn: null,
    ngayHetHanGcn: null,
    soGcnCu: "",
    ngayCapGcnCu: null,
    ngayHetHanGcnCu: null,
    referenceId: null,
    loaiChuyenNganh: LoaiChuyenNganh.NHAP_DAU_KY,
    lyDoCapLai: "",
    lanCapLai: "",
    ngayCapLai: null,

    doiTuong: DoiTuong.NONE,

    soDkkd: "",
    ngayCapDkkd: null,
    noiCapDkkd: "",
    maSoDkkd: "",
    tenCoSo: "",

    ngayThuHoi: null,
    ngayQuyetDinhThuHoi: null,

    loaiHinhSxkd: LoaiHinhSanXuatKinhDoanh.NONE,
    namBatDauHoatDong: "",
    congSuatThietKe: "",

    diaChi: { ...DiaChiDTO_INT_CAN_THO },
    fullDiaChi: "",
    chuCoSo: "",

    tinhTrangStr: "",

    loaiMatHangDkkd: LoaiMatHangKinhDoanh.NONE,
    matHangSxkd: "",

    attachFiles: new Array<AttachFileDTO>(),
    listSanPham: new Array<ChungNhanAttpSanPhamDTO>(),
    hienTrangCoSo: { ...ChungNhanAttpHienTrangCoSoDTO_INT },
    oldId: 0,
    diaChiKinhDo: 0,
    diaChiViDo: 0,
    
    layKetQua: false,
}

export interface LichSuBienDongDTO {
    id: number | null;
    loaiBienDong?: string;
    ngayBienDong?: null | Date;
    soLan?: string;
    noiDung?: string;
    attachFiles?: AttachFileDTO[];
}

export interface ThongKeChungNhanDuDieuKienAttpDTO {
    mode?: String;
    thang?: String;
    year?: Number;
    diaChi?: DiaChiDTO;
    totalCapMoi?: Number;
    totalCapLai?: Number;
    totalThuHoi?: Number;

    ngayCapGcnTu: Date | null;
    ngayCapGcnDen: Date | null;
}