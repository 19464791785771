import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { ConfirmationDialogRaw } from "../commons/dialog/confirmDialog";

export const ConfirmDialog = () => {
    const { dialogContext } = useSelector((state: RootState) => state.confirmationDialog);

    return (
        <>
            {dialogContext.open && (
                <ConfirmationDialogRaw
                    id={`${dialogContext.id}`}
                    keepMounted
                    open={dialogContext.open}
                    title={`${dialogContext.title}`}
                    content={dialogContext.content}
                    onClose={dialogContext.onClose}
                    value={`${dialogContext.value}`}
                />
            )}
        </>
    );
};
