import { Button, Grid, InputLabel, NativeSelect, InputBase, ThemeProvider, createMuiTheme, createStyles, Theme } from '@material-ui/core';
import { OverlayLoading } from 'components/overlayLoading/overlayLoading';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { LoadingAction } from 'redux/actions/applicationAction';
import React from "react";
import { bootstrapTextFieldHook } from 'components/commons/input/bootstrap';
import { DashboardNongLamThuySanComponent } from 'pages/QLCLNongLamThuySan/dashboard/DashboardNongLamThuySanComponent';
import { DashboardThuYComponent } from 'pages/thuY/dashboard/DashboardThuYComponent';
import { DashboardTrongTrotComponent } from 'pages/thucVat/dashboard/DashboardTrongTrotComponent';
import { DashboardThuySanComponent } from 'pages/thuySan/dashboard/DashboardThuySanComponent';
export interface DashboardPublicProps { }
const DashboardPublic: React.FC<DashboardPublicProps> = ({ }) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [loaiDashboard, setLoaiDashboard] = React.useState<string>('1');
    React.useEffect(() => {
    }, []);
    return (
        <>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel classes={labelStyles}>Dashboard</InputLabel>
                    <NativeSelect input={<InputBase fullWidth classes={inputStyles} />}
                        value={loaiDashboard} onChange={(e) => setLoaiDashboard(e.target.value as string)} >
                        <option value={'1'}>Quản lý chất lượng Nông, Lâm sản và Thủy sản</option>
                        <option value={'2'}>Quản lý lĩnh vực thú y</option>
                        <option value={'3'}>Quản lý lĩnh vực Trồng trọt và lĩnh vực Bảo vệ thực vật</option>
                        <option value={'4'}>Quản lý lĩnh vực thủy sản</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="gridItem">
                    {
                        loaiDashboard === '1' &&
                        <DashboardNongLamThuySanComponent/>
                    }
                    {
                        loaiDashboard === '2' &&
                        <DashboardThuYComponent/>
                    }
                    {
                        loaiDashboard === '3' &&
                        <DashboardTrongTrotComponent/>
                    }
                    {
                        loaiDashboard === '4' &&
                        <DashboardThuySanComponent/>
                    }


                </Grid>
            </Grid>
        </>
    )
}
export default DashboardPublic;