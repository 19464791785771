import { Box, Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Pagination } from "apis/baseService";
import { Pageable } from "components/commons/customTable/customTableInterface";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import { useFormik } from "formik";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { ChungNhanATTPMarkerDTO, MarkerDTO, ThucVatMarkerDTO, ThuYMarkerDTO } from "model/marker";
import { DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { DiaChiDTO } from "model/utils/diaChiDTO";
import React from "react";
import ResultItem from "./resultItem";

export interface FormValuesTraCuuMap {
    lopBanDo: Number | String;
    soGiayPhep: String;
    tenCoSo: String;
    diaChi?: DiaChiDTO;
    diaChiHuyenCode?: String;
    diaChiXaCode?: String;
    diaChiTinhCode?: String;
}

export interface DialogTraCuuProps {
    view: any;
    setOpenDialog: (value: boolean) => void;
    setArrMarker: (value: MarkerDTO[] | ThuYMarkerDTO[] | ThucVatMarkerDTO[] | ChungNhanATTPMarkerDTO[]) => void;
    arrMarker: MarkerDTO[] | ThuYMarkerDTO[] | ThucVatMarkerDTO[] | ChungNhanATTPMarkerDTO[];
    setItemEdit: (value: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO | ChungNhanATTPMarkerDTO | null) => void;
    itemEdit: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO | ChungNhanATTPMarkerDTO | null;
    valueEdit: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO | ChungNhanATTPMarkerDTO | null;
    handleSubmitSearch: (
        values: FormValuesTraCuuMap,
        pageParams: Pageable
    ) => Promise<Pagination<MarkerDTO[] | ThuYMarkerDTO[] | ThucVatMarkerDTO[] | ChungNhanATTPMarkerDTO[]>>;
    handleSaveLatLong: (values: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO) => Promise<MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO | ChungNhanATTPMarkerDTO>;
    fields: CreateFieldsProps<FormValuesTraCuuMap, OptionDefault>[];
    renderDetails: (item: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO | ChungNhanATTPMarkerDTO, showDetail: boolean) => JSX.Element;
}

const DialogTraCuu = ({
    fields,
    setOpenDialog,
    setArrMarker,
    arrMarker,
    setItemEdit,
    itemEdit,
    valueEdit,
    handleSubmitSearch,
    handleSaveLatLong,
    renderDetails,
}: DialogTraCuuProps) => {
    const [pageParams, setPageParams] = React.useState<Pageable>({ page: 0, rowsPerPage: 5, total: 0 });
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const initialValues: any = {
        diaChi: DiaChiDTO_INT_CAN_THO,
        lopBanDo: 5,
        soGiayPhep: "",
        tenCoSo: "",
        soGiayXacNhan: "",
        tenToChuc: "",
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSearch,
    });

    React.useEffect(() => {
        if (arrMarker.length === 0 && !isSubmit) {
            formik.resetForm();
        }
    }, [arrMarker]);

    React.useEffect(() => {
        if (isSubmit) {
            handleSearch(formik.values);
        }
    }, [pageParams.page]);

    async function handleSearch(values: any) {
        try {
            setIsSubmit(true);
            const { rows, total, page } = await handleSubmitSearch({ ...values }, pageParams);
            setArrMarker([...rows] as MarkerDTO[] | ThuYMarkerDTO[] | ThucVatMarkerDTO[]);
            setPageParams((prev) => ({ ...prev, total, page }));
        } catch (error) {
            console.log(error);
        }
    }

    async function handleSubmitEdit(values: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO) {
        const index = arrMarker.findIndex((item) => values.id === item.id);
        const data = valueEdit && (await handleSaveLatLong({ ...values, ...valueEdit }));
        const newValues = { ...arrMarker[index], ...data };
        setArrMarker([...arrMarker.slice(0, index), { ...newValues }, ...arrMarker.slice(index + 1)]);
        setItemEdit(null);
    }

    function handleRemove() {
        setArrMarker([...arrMarker]);
        setItemEdit(null);
    }

    function handleOnChangePage(value: number) {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    }

    function handleReset() {
        setIsSubmit(false);
        setArrMarker([]);
        setItemEdit(null);
        setPageParams({ page: 0, total: 0, rowsPerPage: 5 });
    }

    return (
        <Paper className="dialog_map">
            <Box
                style={{
                    padding: 10,
                    backgroundColor: "#ccc",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    fontWeight: 700,
                    color: "#fff",
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>Tìm kiếm</Box>
                <CancelPresentationIcon style={{ cursor: "pointer" }} color="secondary" onClick={() => setOpenDialog(false)} />
            </Box>
            <Box padding={0}>
                <Grid container style={{ overflow: "scroll", maxHeight: "53vh" }}>
                    {arrMarker.length === 0 && <CreateFields formik={formik} fields={fields} />}
                    {/* {arrMarker.length > 0 &&
                        arrMarker.map((item: any, index: number) => (
                            <ResultItem
                                renderDetails={renderDetails}
                                key={index}
                                setItemEdit={setItemEdit}
                                itemEdit={itemEdit}
                                item={item}
                                handleSubmitEdit={handleSubmitEdit}
                                handleRemove={handleRemove}
                            />
                        ))} */}
                </Grid>
            </Box>
            {arrMarker.length > 0 && (
                <Box style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }} className="grid-item">
                    <Typography variant="subtitle2" gutterBottom style={{ paddingTop: 9 }}>
                        Đang hiển thị kết quả {pageParams.page * pageParams.rowsPerPage + 1} -{" "}
                        {pageParams.total < (pageParams.page + 1) * pageParams.rowsPerPage ? pageParams.total : (pageParams.page + 1) * pageParams.rowsPerPage}
                    </Typography>
                    <Box>
                        <IconButton size="small" disabled={pageParams.page === 0} onClick={() => handleOnChangePage(pageParams.page - 1)}>
                            <KeyboardArrowLeftIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            size="small"
                            disabled={pageParams.page + 1 === Math.ceil(pageParams.total / pageParams.rowsPerPage)}
                            onClick={() => handleOnChangePage(pageParams.page + 1)}
                        >
                            <KeyboardArrowRightIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <Box className="footer_map">
                {arrMarker.length === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => formik.handleSubmit()}
                        style={{ marginLeft: 10, marginRight: 10 }}
                    >
                        Tìm kiếm
                    </Button>
                )}
                <Button
                    style={{ marginLeft: arrMarker.length === 0 ? 0 : 10 }}
                    id="btnHuy"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleReset}
                >
                    Làm mới
                </Button>
            </Box>
        </Paper>
    );
};

export default DialogTraCuu;
