import { Box, Button, Dialog, Grid, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import { HoSoCuaToiDTO, HoSoCuaToiMgtService, VanBanDuThaoDTO, VanBanDuThaoDTO_INIT } from "apis/hoSoCuaToi/hoSoCuaToiMgtService";
import { UserData } from "apis/userMgt/userManagementService";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import { TepDinhKemPopper } from "components/commons/hoso/TepDinhKemPopper";
import { CustomInput } from "components/commons/input/customInput";
import { Cell, Row } from "components/commons/table/tableCommons";
import { getObject } from "helpers/localStorage";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { hideLoading, LoadingAction, showLoading } from "redux/actions/applicationAction";
import DateUtil from "utils/dateUtils";
import DuThaoModal from "./duThaoModal";

interface DanhSachDuThaoModalProps {
    show: boolean;
    onToggle: () => void;
    maHoSo: string;
}
const DanhSachDuThaoModal = (props: DanhSachDuThaoModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [show, setShow] = useState(false);
    const [rows, setRows] = useState<VanBanDuThaoDTO[]>([]);
    const { onToggle, maHoSo } = props;
    let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
    const [hoSoBoSung, setHoSoBoSung] = useState<VanBanDuThaoDTO>({
        ...VanBanDuThaoDTO_INIT,
        maHoSo: maHoSo,
        maNguoiGui: C_USER_DATA_DE.userName,
        tenNguoiYeuCau: C_USER_DATA_DE.fullName,
    });
    
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShow(props.show);
        
        if (props.show) {
            initData();
        }
    }, [props.show]);
    const initData = () => {
        dispatchLoading(showLoading());
        hoSoCuaToiMgtService
            .danhSachVanBanDuThao({ maHoSo: maHoSo, pageNumber: pageNumber, pageSize: pageSize } as VanBanDuThaoDTO)
            .then((p) => {
                if (p.data && p.data.data && p.data.data.data) {
                    setRows(p.data.data.data);
                    setTotal(p.data.data.total);
                }
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    };
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    };
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        dispatchLoading(showLoading());
        hoSoCuaToiMgtService
            .danhSachVanBanDuThao({ maHoSo: maHoSo, pageNumber: pageNumberNew, pageSize: pageSizeNew } as VanBanDuThaoDTO)
            .then((p) => {
                if (p.data && p.data.data && p.data.data.data) {
                    setRows(p.data.data.data);
                    setTotal(p.data.data.total);
                }
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    };
    const reloadData = () => {
        initData();
    };
    return (
        <>
            <DuThaoModal data={hoSoBoSung} hoso={maHoSo} reloadData={reloadData} onToggle={handleToggle} show={showModal} />
            <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show}>
                <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                    DANH SÁCH DỰ THẢO
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <Cell component="th" scope="row" align="center" width="5%">
                                                STT
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="15%">
                                                Trích yếu dự thảo
                                            </Cell>
                                            <Cell component="th" scope="row" align="center">
                                                Loại văn bản
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="15%">
                                                Ngày dự thảo
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="25%">
                                                Tệp đính kèm
                                            </Cell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows && rows.length > 0 ? (
                                            rows.map((row, i) => (
                                                <Row key={row.id}>
                                                    <Cell scope="row" align="center">
                                                        {(pageNumber - 1) * pageSize + i + 1}
                                                    </Cell>
                                                    <Cell scope="row" align="left">
                                                        {row.noiDung}
                                                    </Cell>
                                                    <Cell scope="row" align="left">
                                                        {row.tenLoaiVanBan}
                                                    </Cell>
                                                    <Cell scope="row" align="left">
                                                        {DateUtil.timeToDDMMYYYY(row.createDate)}
                                                    </Cell>
                                                    <Cell scope="row" align="left">
                                                        <TepDinhKemPopper data={row.files} />
                                                    </Cell>
                                                </Row>
                                            ))
                                        ) : (
                                            <Row key={"empty"}>
                                                <Cell colSpan={6} scope="row" align="center">
                                                    Không có dữ liệu
                                                </Cell>
                                            </Row>
                                        )}
                                    </TableBody>
                                </Table>
                                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                                    <Pagination
                                        count={Math.ceil(total / pageSize)}
                                        defaultPage={1}
                                        siblingCount={1}
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        shape="rounded"
                                        showFirstButton
                                        showLastButton
                                        onChange={handleChangePage}
                                    />
                                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                                        <Box component="span" paddingX="5px">
                                            Số mẫu tin trên trang
                                        </Box>
                                        <NativeSelect input={<CustomInput />} onChange={handleChangePageSize}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={25}>20</option>
                                        </NativeSelect>
                                    </Box>
                                    <Box component="span" paddingX="5px">
                                        Mẫu tin {(pageNumber - 1) * pageSize + 1} - {pageNumber * pageSize > total ? total : pageNumber * pageSize} / {total}
                                    </Box>
                                </Box>
                            </TableContainer>

                            <Grid item xs={12} className="grid-item">
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation onClick={handleToggle}>
                                        Thêm mới
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation onClick={onToggle}>
                                        Đóng
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default DanhSachDuThaoModal;
