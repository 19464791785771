export enum ThongTinChuyenNganhEnum {
  NGAY_THANG_HIEN_TAI = "ngaythanghientai",
  CMND = "cmnd",
  NGAY_CAP_CMND = "ngaycapcmnd",
  NOI_CAP_CMND = "noicapcmnd",
  HO_KHAU_THUONG_TRU = "hokhauthuongtru",
  NOI_O_HIEN_NAY = "noiohiennay",
  HO_VA_TEN = "hovaten",
  HO_VA_TEN_VIET_HOA = "hovatenviethoa",
  NGAY_THANG_NAM_SINH = "ngaythangsinh",
  NGAY_HIEN_TAI = "ngayhientai",
  THANG_HIEN_TAI = "thanghientai",
  NAM_HIEN_TAI = "namhientai",
  QUOC_TICH = "quoctich",
  TINH_THANH = "tinhthanh",
  QUAN_HUYEN = "quanhuyen",
  PHUONG_XA = "xaphuong",
  SO_DIEN_THOAI ="sdt",
  FAX ="fax",
  EMAIL ="email",
  GIOI_TINH ="gioitinh",
  DIA_CHI ="diachi",
  KINH_GUI ="kinhgui",
  TEN_DOANH_NGHIEP ="tendoanhnghiep",
  DIA_CHI_DOANH_NGHIEP ="diachidoanhnghiep",
  WEBSITE = "website",
  GIAY_DKDN = "giaydkkd",
  CO_QUAN_CAP_DKDN = "coquancapdkkd",
  NGAY_CAP_DKDN = "ngaycapdkkd",
  NOI_CAP_DKDN = "noicapdkkd",
  HO_VA_TEN_NGUOI_DAI_DIEN = "hotennguoidaidien",
  CHUC_VU_NGUOI_DAI_DIEN = "chucvunguoidaidien",
  DIA_DIEM = "diadiem",
  CHU_CO_SO = "chucoso",
  TO_CHUC_QC_BVTC = "1_004493_000_00_00_h13_tentochuccanhandenghixacnhannoidungquangcao",
  TEN_CHIU_TRACH_NHIEM_BVTC = "1_004493_000_00_00_h13_hotenvasodienthoainguoichiutrachnhiem",
  SO_GIAY_PHEP_HD_BVTC="1_004493_000_00_00_h13_sogiayphephoatdong",
  FAX__BVTC = "1_004493_000_00_00_h13_fax",
  EMAIL_BVTC="1_004493_000_00_00_h13_email",
  SDT_BVTC = "1_004493_000_00_00_h13_sodienthoai",
  SDT_QC_THUOC_THU_Y = "sdt2",
  HO_TEN_QC_THUOC_THU_Y = "hotenvasdt",
  SO_GIAY_PHEP_HD_QC_THUOC_THU_Y = "sogiayphephoatdong",
  DANH_SACH_THUOC_BVTT= "1_004493_000_00_00_h13_danhsachxacnhannoidungquangcaodoivoithuocbaovethucvat",
  PHUONG_TIEN_QUANG_CAO = "1_004493_000_00_00_h13_danhsachxacnhannoidungquangcaodoivoithuocbaovethucvat_phuongtienquangcao",
  TEN_THUOC_QUANG_CAO= "1_004493_000_00_00_h13_danhsachxacnhannoidungquangcaodoivoithuocbaovethucvat_tenthuoc",
  GIAY_QUANG_CAO= "1_004493_000_00_00_h13_danhsachxacnhannoidungquangcaodoivoithuocbaovethucvat_giaychungnhandangky",
  DS_QUANG_CAO_THUOC_THU_Y = "bangnoidungquancaothuoc",
  GIAY_CHUNG_NHAN_THU_Y = "giaychungnhan",
  TEN_THUOC_THU_Y = "tenthuoc",
  PHUONG_TIEN_QUANG_CAO_THU_Y= "phuongtienquangcao"
}

export enum TypeField{
  TYPE_STRING = '0',
  TYPE_NUMBER = '1',
  TYPE_DATE = '2',
}