import { lazy } from "react";
import { Type } from "apis/danhMuc/danhMucDungRiengService";

import { RoutePropsI } from "routes";

const DanhMucDungRiengMgt = lazy(() => import("pages/danhMuc/dungRieng/danhMucDungRiengMgt").then(({ DanhMucDungRiengMgt }) => ({ default: DanhMucDungRiengMgt })));


export const ROUTE_ADMIN_DANH_MUC: RoutePropsI[] = [
	{
		ten: "Danh mục hành nghề",
		giaoDien: <DanhMucDungRiengMgt type={Type.CHUNG_CHI_HANH_NGHE} />,
		duongDan: "/danh-muc-chung-chi-hanh-nghe",
	},
	{
		ten: "Danh mục buôn bán thú y",
		giaoDien: <DanhMucDungRiengMgt type={Type.BUON_BAN_THUOC_THU_Y} />,
		duongDan: "/danh-muc-buon-ban-thu-y",
	},
	{
		ten: "Danh mục loại hình sản xuất kinh doanh",
		giaoDien: <DanhMucDungRiengMgt type={Type.LOAI_HINH_SX_KINH_DOANH} />,
		duongDan: "/danh-muc-loai-hinh-sx-kd",
	},
	{
		ten: "Danh mục loại hình hoạt động",
		giaoDien: <DanhMucDungRiengMgt type={Type.LOAI_HINH_HOAT_DONG} />,
		duongDan: "/danh-muc-loai-hinh-hoat-dong",
	},
	{
		ten: "Danh mục thị trường tiêu thụ",
		giaoDien: <DanhMucDungRiengMgt type={Type.THI_TRUONG_TIEU_THU} />,
		duongDan: "/danh-muc-thi-truong-tieu-thu",
	},
	{
		ten: "Danh mục lý do",
		giaoDien: <DanhMucDungRiengMgt type={Type.LY_DO_CAP_LAI} />,
		duongDan: "/danh-muc-ly-do-cap-lai",
	},
	{
		ten: "Danh mục hình thức nuôi",
		giaoDien: <DanhMucDungRiengMgt type={Type.HINH_THUC_NUOI} />,
		duongDan: "/danh-muc-hinh-thuc-nuoi",
	},
	{
		ten: "Danh mục loại bệnh",
		giaoDien: <DanhMucDungRiengMgt type={Type.LOAI_BENH} />,
		duongDan: "/danh-muc-loai-benh",
	},
	{
		ten: "Danh mục xếp loại",
		giaoDien: <DanhMucDungRiengMgt type={Type.XEP_LOAI} />,
		duongDan: "/danh-muc-xep-loai",
	},
	{
		ten: "Danh mục các sản phẩm kinh doanh",
		giaoDien: <DanhMucDungRiengMgt type={Type.CAC_SAN_PHAM_KINH_DOANH} />,
		duongDan: "/danh-muc-cac-san-pham-kinh-doanh",
	},
]