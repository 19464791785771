import DashboardPublic from "pages/public/DashboardPublic";
import WrapperMapTraCuuQuangCaoPhanBon from "pages/thucVat/quangCaoPhanBon/traCuuBanDo";
import { lazy } from "react";
import { RoutePropsI } from "routes";

//Thú y
const ROOT_THU_Y = "/thu-y";
const BieuDoThongKe = lazy(() => import("pages/thuY/chungChiHanhNgheThuY/bieuDo/index"));
const BieuDoThongKeQuangCaoThuocThuY = lazy(() => import("pages/thuY/quangCao/bieuDo"));
const BieuDoThongKeVeSinhThuY = lazy(() => import("pages/thuY/veSinh/bieuDo"));
//Thủy sản
const ROOT_THUY_SAN = "/thuy-san";
const ThongKeCNDangKyTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/thongKeCNDKTauCa').then(({ ThongKeCNDangKyTauCa }) => ({ default: ThongKeCNDangKyTauCa })));
const BaoCaoCNDangKyTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/baoCaoCNDKTauCa').then(({ BaoCaoCNDangKyTauCa }) => ({ default: BaoCaoCNDangKyTauCa })));
//Thực vật
const ROOT_THUC_VAT = "/thuc-vat";
const BieuDoBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/bieuDo"));
const BieuDoBuonBanThuocBVTC = lazy(() => import("pages/thucVat/buonBanThuocBVTV/bieuDoBBThuocBVTV"));
const ThongKeQuangCaoPhanBon = lazy(() => import("pages/thucVat/quangCaoPhanBon/danhSachTraCuu/thongKeQuangCaoPhanBon"));
//  Xác nhận kiến thức vệ sinh an toàn thực phẩm
const ROOT_QLCL_NONG_LAM_THUY_SAN = "/qlcl-nong-lam-thuy-san"
const BaoCaoKienThucVeSinhAttp = lazy(() => import("pages/QLCLNongLamThuySan/traCuuKienThucVeSinhAttp/baoCaoKienThucVeSinhAttp"));
const WrapperMapTraCuu = lazy(() => import("pages/traCuuBanDo/wrapperTraCuuBanDo"));
//
const ROUTER_CHARTS: RoutePropsI[] = [
    //thú y
    {
        ten: "Biểu đồ giấy chứng nhận đủ đIều kiện vệ sinh thú y",
        duongDan: `${ROOT_THU_Y}/ve-sinh/bieu-do-giay-chung-nhan`,
        giaoDien: <BieuDoThongKeVeSinhThuY />,
    },
    {
        ten: "Biểu đồ thống kê tình hình cấp giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT_THU_Y}/quang-cao/bieu-do-giay-chung-nhan`,
        giaoDien: <BieuDoThongKeQuangCaoThuocThuY />,
    },
    {
        ten: "Biểu đồ chứng chỉ hành nghề thú y",
        duongDan: `${ROOT_THU_Y}/bieu-do-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <BieuDoThongKe />,
    },

    //thực vật
    {
        ten: "Biểu đồ giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT_THUC_VAT}/bieu-do-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <BieuDoBuonBanPhanBon />,
    },
    {
        ten: "Biểu đồ giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT_THUC_VAT}/buon-ban-thuoc-bvtv/bieu-do`,
        giaoDien: <BieuDoBuonBanThuocBVTC />,
    },
    {
        ten: "Tình hình cấp giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT_THUC_VAT}/thong-ke-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <ThongKeQuangCaoPhanBon />,
    },
    {
        ten: "Bản đồ",
        duongDan: "/tra-cuu-ban-do",
        giaoDien: <WrapperMapTraCuu />,
    },
    {
        ten: "Bản đồ quảng cáo phân bón",
        duongDan: "/tra-cuu-ban-do-quang-cao-phan-bon",
        giaoDien: <WrapperMapTraCuuQuangCaoPhanBon />,
    },
];
export const ROUTE_PAGE_PUBLIC: RoutePropsI[] = [
    ...ROUTER_CHARTS,
    {
        ten: "TK Tình hình cấp giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT_THUY_SAN}/thong-ke-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <ThongKeCNDangKyTauCa />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT_THUY_SAN}/bao-cao-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <BaoCaoCNDangKyTauCa />,
    },
    // Xác nhận kiến thức vệ sinh an toàn thực phẩm
    {
        ten: "Báo cáo xác nhận kiến thức vệ sinh an toàn thực phẩm",
        duongDan: `${ROOT_QLCL_NONG_LAM_THUY_SAN}/kien-thuc-ve-sinh-attp/bao-cao`,
        giaoDien: <BaoCaoKienThucVeSinhAttp />,
    },
    {
        ten: "Dashboard",
        duongDan: '/dashboard',
        giaoDien: <DashboardPublic />,
    },
    
];

