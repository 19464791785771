import { Box, Button, Dialog, Grid, InputLabel, TextField } from "@material-ui/core";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import { DiaChiDonViHanhChinh3CapCustom } from "components/commons/fields/dvhc3Cap/donViHanhChinh3CapCustom";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn, useFormik } from "formik";
import { AoNuoiDTO } from "model/thuySan/longBe/dangKyThongTinChungDTO";
import { DiaChiDTO } from "model/utils/diaChiDTO";
import React from "react";
import * as Yup from "yup";
export interface AoNuoiDialogPropsI {
    onSubmitData: (resp: AoNuoiDTO) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: AoNuoiDTO | null;
}
const initialValuesDiaChi: DiaChiDTO = {
    diaChiTinhCode: '',
    diaChiTinhName: '',
    diaChiHuyenCode: '',
    diaChiHuyenName: '',
    diaChiXaCode: '',
    diaChiXaName: '',
    duong: '',
    soNha: '',
    soDienThoai: '',
    fax: '',
    email: ''
};
export const AoNuoiDialog = (props: AoNuoiDialogPropsI) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const { onSubmitData, onDialogClose, openDialog, itemEdit } = props;
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const validationSchema = Yup.object().shape(
        {
            longNuoi: Yup.string()
            .matches(/^[1-9][0-9]*$/, "Ao/bể/lồng nuôi không đúng định dạng"),
            dienTich: Yup.string()
            .matches(/^\d+(\.\d+)*$/, "Diện tích không đúng định dạng"),
            diaChiAoNuoi: Yup.object().shape({
                diaChiTinhCode: Yup.string().required("Tỉnh/Thành không được bỏ trống"),
                diaChiHuyenCode: Yup.string().trim().required("Quận/Huyện không được bỏ trống"),
                diaChiXaCode: Yup.string().trim().required("Phường/Xã không được bỏ trống"),
            })
        },
    );

    const formik = useFormik({
        initialValues: { ...itemEdit },
        onSubmit: (values) => onSubmitData(values),
        validationSchema,
        validateOnChange: false,
    });

    


    function handleResetForm() {
        formik.setValues(prev => ({
            ...prev, maSoNhanDien: '', longNuoi: '',
            dienTich: '', diaChiAoNuoi: initialValuesDiaChi, doiTuongNuoi:''
        }))
    }
    return (
        <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                {itemEdit ? 'Cập nhật' : 'Thêm mới'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid spacing={2} container>
                        <Grid item xs={3} className="gridItem">
                            <TextField
                                fullWidth
                                label={"Mã số nhận diện"}
                                name="maSoNhanDien"
                                value={formik.values?.maSoNhanDien}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={3} className="gridItem">
                            <TextField
                                fullWidth
                                label={"Ao/bể/lồng nuôi"}
                                name="longNuoi"
                                value={formik.values?.longNuoi}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                type="number"
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "longNuoi") && getIn(formik.errors, "longNuoi")) && formik.errors.longNuoi}
                            />
                        </Grid>
                        <Grid item xs={3} className="gridItem">
                            <TextField
                                fullWidth
                                label={"Diện tích"}
                                name="dienTich"
                                value={formik.values?.dienTich}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "dienTich") && getIn(formik.errors, "dienTich")) && formik.errors.dienTich}
                            />
                        </Grid>
                        <Grid item xs={3} className="gridItem">
                            <TextField
                                fullWidth
                                label={"Đối tượng nuôi"}
                                name="doiTuongNuoi"
                                value={formik.values?.doiTuongNuoi}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                              
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel classes={labelStyles} style={{ fontStyle: "italic" }}>
                                địa chỉ vùng nuôi
                            </InputLabel>
                        </Grid>
                        <DiaChiDonViHanhChinh3CapCustom  formik={formik} diaChiOf={"diaChiAoNuoi"} disabled={false} />
                        <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Số nhà, đường"}
                                    name="diaChiAoNuoi.soNha"
                                    value={formik.values?.diaChiAoNuoi?.soNha}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                />
                            </Grid>
                        <Grid item xs={12} className="gridItem">
                            <Box className="button-group" marginTop="5px">
                                <Button variant="contained" color="primary" disableElevation
                                    onClick={() => formik.handleSubmit()}>
                                    Lưu
                                </Button>
                                <Button variant="contained" color="secondary" disableElevation
                                    onClick={handleResetForm}>
                                    Xóa
                                </Button>
                                <Button variant="contained" color="default" disableElevation
                                    onClick={onDialogClose}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}