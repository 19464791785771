import { NoiDungQuangCaoPhanBonDTO, ThucVatPhanBonQuangCaoTraCuuDTO } from "model/thucVat/quangCaoPhanBon";
import { BaseResponse, BaseService, Pagination, ParamExport } from "../../baseService";
import api from "../../index";
const ROOT_PATH = "/thuc-vat/phan-bon-quang-cao";

export interface BussinessTemplateDownloadRequest {
  soBienNhan: string;
  filedId: string;
}

export class PhanBonQuangCaoManagementSevice extends BaseService {
  async search(data: ThucVatPhanBonQuangCaoTraCuuDTO, page: number, size: number): Promise<Pagination<ThucVatPhanBonQuangCaoTraCuuDTO[]>> {
    let result: Pagination<ThucVatPhanBonQuangCaoTraCuuDTO[]> = { page: page, size: size, total: 0, rows: [], };
    await api.post(`${ROOT_PATH}/get-danh-sach`, data, {
      params: { pageSize: size, pageNumber: page, },
      data: {},
    }).then((response) => {
      result = {
        ...result,
        total: response.data.data.total,
        rows: response.data.data.content,
      };
    }).catch();
    return result;
  }

  async traCuuExport(data: ThucVatPhanBonQuangCaoTraCuuDTO | {}, params: ParamExport): Promise<any> {
    return await api.post(`${ROOT_PATH}/get-danh-sach/export`, data, {
      params: params,
      responseType: "blob",
      data: {},
      timeout: 30000,
    });
  }

  async insertOrUpdateThongTinChung(data: NoiDungQuangCaoPhanBonDTO): Promise<BaseResponse> {
    let result = { isSuccess: false } as BaseResponse;
    await api.post(`${ROOT_PATH}/thong-tin-chung/insert-or-update`, data, this.getTokenRequestHeaders()).then((response) => {
      result = {
        isSuccess: response.data.error_code === "SUCCESSFUL",
        message: response.data.error_message,
        data: response.data.data,
      };
    }).catch(() => (result = { ...result, message: "Có lỗi khi kết nối tới server" }));
    return result;
  }

  async getByBienNhan(soBienNhan: string): Promise<NoiDungQuangCaoPhanBonDTO> {
    let result = {} as NoiDungQuangCaoPhanBonDTO;
    await api.get(`${ROOT_PATH}/thong-tin-chung/get-by-so-bien-nhan`, {
      params: {
        soBienNhan: soBienNhan,
      },
    }).then((response) => {
      result = response.data.data;
    }).catch();
    return result;
  }

  async getThongTinChiTiet(id: number): Promise<NoiDungQuangCaoPhanBonDTO> {
    let result = {} as NoiDungQuangCaoPhanBonDTO;
    await api.get(`${ROOT_PATH}/thong-tin-chung/get-thong-tin-chi-tiet?id=${id}`)
      .then((response) => {
        result = response.data.data;
      })
      .catch();
    return result;
  }

  async getBaoCao(data: ThucVatPhanBonQuangCaoTraCuuDTO, page: number, size: number): Promise<Pagination<ThucVatPhanBonQuangCaoTraCuuDTO[]>> {
    let result: Pagination<ThucVatPhanBonQuangCaoTraCuuDTO[]> = { page: page, size: size, total: 0, rows: [] };
    await api.post(`${ROOT_PATH}/get-bao-cao`, data, {
      params: { pageSize: size, pageNumber: page, },
      data: {},
    }).then((response) => {
      result = {
        ...result,
        total: response.data.data.total,
        rows: response.data.data.content,
      };
    }).catch();
    return result;
  }
  async baoCaoExport(data: ThucVatPhanBonQuangCaoTraCuuDTO | {}, params: ParamExport): Promise<any> {
    return await api.post(`${ROOT_PATH}/get-bao-cao/export`, data, {
      params: params,
      responseType: "blob",
      data: {},
      timeout: 30000,
    });
  }

  async thongKe(data: ThucVatPhanBonQuangCaoTraCuuDTO): Promise<ThucVatPhanBonQuangCaoTraCuuDTO[]> {
    let result: ThucVatPhanBonQuangCaoTraCuuDTO[] = []
    await api
      .post(`${ROOT_PATH}/get-thong-ke`, data, this.getTokenRequestHeaders()).then((response) => {
        result = response.data.data;
      }).catch();
    return result;
  }

  thongKeExport = async (request: ThucVatPhanBonQuangCaoTraCuuDTO, params: ParamExport): Promise<any> => {
    return api.post(`${ROOT_PATH}/get-thong-ke/export`, request, { params: params, data: request, responseType: "blob", timeout: 30000 });
  };

  /**
     * @description xử lý download file khi thực hiện in ấn
     * @param attachFile
     * @returns
     */
  handleDownloadTemplateFile = async (request: BussinessTemplateDownloadRequest): Promise<any> => {
    return api.get(`${ROOT_PATH}/download-bussiness-template-file`, {
      responseType: "blob",
      params: request,
    });
  };

}
