import { ThongKeXNNuoiTrongLongBeAoNuoi } from "pages/thuySan/longBe/thongKe/thongKeXNNuoiTrongLongBeAoNuoi";
import { lazy } from "react";
import { RoutePropsI } from "routes";
const DanhSachCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachCNDongMoiCaiHoanTauCa').then(({ DanhSachCNDongMoiCaiHoanTauCa }) => ({ default: DanhSachCNDongMoiCaiHoanTauCa })));
const DanhSachCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachCNDKTauCa').then(({ DanhSachCNDKTauCa }) => ({ default: DanhSachCNDKTauCa })));
const DanhSachCNDKTauCaTamThoi = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachCNDKTauCaTamThoi').then(({ DanhSachCNDKTauCaTamThoi }) => ({ default: DanhSachCNDKTauCaTamThoi })));
const DanhSachVanBanChapThuan = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachVanBanChapThuan').then(({ DanhSachVanBanChapThuan }) => ({ default: DanhSachVanBanChapThuan })));
const DanhSachVBCTChoCapSo = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachVBChapThuanChoCapSo').then(({ DanhSachVBCTChoCapSo }) => ({ default: DanhSachVBCTChoCapSo })));
const DanhSachXoaCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/danhSachXoaCNDKTauCa').then(({ DanhSachXoaCNDKTauCa }) => ({ default: DanhSachXoaCNDKTauCa })));
const NhapDauKyCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/nhapDauKyCNDKTauCa').then(({ NhapDauKyCNDKTauCa }) => ({ default: NhapDauKyCNDKTauCa })));
const ThuHoiCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachTraCuu/thuHoiCNDongMoiCaiHoanTauCa').then(({ ThuHoiCNDongMoiCaiHoanTauCa }) => ({ default: ThuHoiCNDongMoiCaiHoanTauCa })));

const BaoCaoCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/baoCaoCNDongMoiCaiHoanTauCa').then(({ BaoCaoCNDongMoiCaiHoanTauCa }) => ({ default: BaoCaoCNDongMoiCaiHoanTauCa })));
const BaoCaoCNDangKyTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/baoCaoCNDKTauCa').then(({ BaoCaoCNDangKyTauCa }) => ({ default: BaoCaoCNDangKyTauCa })));
const BaoCaoVanBanChapThuan = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/baoCaoVanBanChapThuan').then(({ BaoCaoVanBanChapThuan }) => ({ default: BaoCaoVanBanChapThuan })));

const ThongKeCNDangKyTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/thongKeCNDKTauCa').then(({ ThongKeCNDangKyTauCa }) => ({ default: ThongKeCNDangKyTauCa })));
const ThongKeVanBanChapThuan = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/thongKeVanBanChapThuan').then(({ ThongKeVanBanChapThuan }) => ({ default: ThongKeVanBanChapThuan })));
const ThongKeCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/danhSachBaoCaoThongKe/thongKeCNDongMoiCaiHoanTauCa').then(({ ThongKeCNDongMoiCaiHoanTauCa }) => ({ default: ThongKeCNDongMoiCaiHoanTauCa })));

const CapGCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/capGCNDongMoiCaiHoanTauCa/index').then(({ CapGCNDongMoiCaiHoanTauCa }) => ({ default: CapGCNDongMoiCaiHoanTauCa })));
const CapGiayCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/capGCNDKTauCa/index').then(({ CapGiayCNDKTauCa }) => ({ default: CapGiayCNDKTauCa })));
const CapLaiGiayCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/capLaiGCNDKTauCa/index').then(({ CapLaiGiayCNDKTauCa }) => ({ default: CapLaiGiayCNDKTauCa })));
const XoaGiayCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/xoaGCNDKTauCa/index').then(({ XoaGiayCNDKTauCa }) => ({ default: XoaGiayCNDKTauCa })));
const CapGiayCNDKTamThoiTauCa = lazy(() => import('pages/thuySan/tauCa/capGCNDKTamThoiTauCa/index').then(({ CapGiayCNDKTamThoiTauCa }) => ({ default: CapGiayCNDKTamThoiTauCa })));
const CapVanBanChapThuan = lazy(() => import('pages/thuySan/tauCa/capVanBanChapThuan/index').then(({ CapVanBanChapThuan }) => ({ default: CapVanBanChapThuan })));

const ViewCNDongMoiCaiHoanTauCa = lazy(() => import('pages/thuySan/tauCa/chiTiet/viewCNDongMoiCaiHoanTauCa').then(({ ViewCNDongMoiCaiHoanTauCa }) => ({ default: ViewCNDongMoiCaiHoanTauCa })));
const ViewCNDKTauCaTamThoi = lazy(() => import('pages/thuySan/tauCa/chiTiet/viewCNDKTauCaTamThoi').then(({ ViewCNDKTauCaTamThoi }) => ({ default: ViewCNDKTauCaTamThoi })));
const ViewCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/chiTiet/viewCNDKTauCa').then(({ ViewCNDKTauCa }) => ({ default: ViewCNDKTauCa })));
const ViewVanBanChapThuan = lazy(() => import('pages/thuySan/tauCa/chiTiet/viewVBChapThuan').then(({ ViewVanBanChapThuan }) => ({ default: ViewVanBanChapThuan })));
const ViewXoaCNDKTauCa = lazy(() => import('pages/thuySan/tauCa/chiTiet/viewXoaCNDKTauCa').then(({ ViewXoaCNDKTauCa }) => ({ default: ViewXoaCNDKTauCa })));
const CapXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/capXNNuoiTrongLongBe/index').then(({ CapXNNuoiTrongLongBe }) => ({ default: CapXNNuoiTrongLongBe })));
const NhapDauKyXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/danhSachTraCuu/nhapDauKyXNNuoiTrongLongBe').then(({ NhapDauKyXNNuoiTrongLongBe }) => ({ default: NhapDauKyXNNuoiTrongLongBe })));
const DanhSachXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/danhSachTraCuu/danhSachXNNuoiTrongLongBe').then(({ DanhSachXNNuoiTrongLongBe }) => ({ default: DanhSachXNNuoiTrongLongBe })));
const ViewXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/chiTiet/viewXNNuoiTrongLongBe').then(({ ViewXNNuoiTrongLongBe }) => ({ default: ViewXNNuoiTrongLongBe })));
const BaoCaoXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/baoCaoThongKe/baoCaoXNNuoiTrongLongBe').then(({ BaoCaoXNNuoiTrongLongBe }) => ({ default: BaoCaoXNNuoiTrongLongBe })));
const ThongKeXNNuoiTrongLongBe = lazy(() => import('pages/thuySan/longBe/baoCaoThongKe/thongKeXNNuoiTrongLongBe').then(({ ThongKeXNNuoiTrongLongBe }) => ({ default: ThongKeXNNuoiTrongLongBe })));

const ROOT = "/thuy-san"
export const ROUTE_PAGE_THUY_SAN: RoutePropsI[] = [
    {
        ten: "Danh sách chứng nhận đóng mới, cải hoán tàu cá",
        duongDan: `${ROOT}/danh-sach-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <DanhSachCNDongMoiCaiHoanTauCa />,
    },
    {
        ten: "Danh sách chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/danh-sach-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <DanhSachCNDKTauCa />,
    },
    {
        ten: "Danh sách chứng nhận đăng ký tàu cá tạm thời",
        duongDan: `${ROOT}/danh-sach-chung-nhan-dang-ky-tau-ca-tam-thoi`,
        giaoDien: <DanhSachCNDKTauCaTamThoi />,
    },
    {
        ten: "Danh sách Văn bản chấp thuận",
        duongDan: `${ROOT}/danh-sach-van-ban-chap-thuan`,
        giaoDien: <DanhSachVanBanChapThuan />,
    },
    {
        ten: "Danh sách Văn bản chấp thuận chờ cấp số",
        duongDan: `${ROOT}/danh-sach-van-ban-chap-thuan-cho-cap-so`,
        giaoDien: <DanhSachVBCTChoCapSo />,
    },
    {
        ten: "Danh sách xóa chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/danh-sach-xoa-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <DanhSachXoaCNDKTauCa />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy chứng nhận cơ sở đủ đIều kiện đóng mới, cải hoán tàu cá",
        duongDan: `${ROOT}/bao-cao-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <BaoCaoCNDongMoiCaiHoanTauCa />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/bao-cao-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <BaoCaoCNDangKyTauCa />,
    },
    {
        ten: "Báo cáo tình hình cấp văn bản chấp thuận đóng mới, cải hoán, thuê, mua tàu cá",
        duongDan: `${ROOT}/bao-cao-van-ban-chap-thuan`,
        giaoDien: <BaoCaoVanBanChapThuan />,
    },
    {
        ten: "TK Tình hình cấp giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/thong-ke-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <ThongKeCNDangKyTauCa />,
    },
    {
        ten: "TK Tình hình cấp văn bản chấp thuận đóng mới, cải hoán, thuê, mua tàu cá",
        duongDan: `${ROOT}/thong-ke-van-ban-chap-thuan`,
        giaoDien: <ThongKeVanBanChapThuan />,
    },
    {
        ten: "TK Tình hình cấp giấy chứng nhận cơ sở đủ điều kiện đóng mới, cải hoán tàu cá",
        duongDan: `${ROOT}/thong-ke-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <ThongKeCNDongMoiCaiHoanTauCa />,
    },
    //longBe
    {
        ten: "Danh sách giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/danh-sach-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <DanhSachXNNuoiTrongLongBe />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/view/bao-cao-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <BaoCaoXNNuoiTrongLongBe />,
    },
    {
        ten: "Thống kê tình hình cấp giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/view/thong-ke-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <ThongKeXNNuoiTrongLongBe />,
    },
    {
        ten: "Thống kê danh sách Ao/bể/lồng nuôi",
        duongDan: `${ROOT}/view/thong-ke-xac-nhan-dang-ky-nuoi-trong-long-be-ao-nuoi`,
        giaoDien: <ThongKeXNNuoiTrongLongBeAoNuoi />,
    },

]

export const ROUTE_CN_TAU_CA: RoutePropsI[] = [
    {
        ten: "Cấp, cấp lại giấy chứng nhận cơ sở đủ đIều kiện đóng mới, cải hoán tàu cá",
        duongDan: `${ROOT}/cap-giay-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <CapGCNDongMoiCaiHoanTauCa />,
    },
    {
        ten: "Cấp giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/cap-giay-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <CapGiayCNDKTauCa />,
    },
    {
        ten: "Cấp lại giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/cap-lai-giay-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <CapLaiGiayCNDKTauCa />,
    },
    {
        ten: "Cấp giấy chứng nhận đăng ký tạm thời tàu cá",
        duongDan: `${ROOT}/cap-giay-chung-nhan-dang-ky-tam-thoi-tau-ca`,
        giaoDien: <CapGiayCNDKTamThoiTauCa />,
    },
    {
        ten: "Cấp văn bản chấp thuận đóng mớI, cải hoán, thuê, mua tàu cá trên biển",
        duongDan: `${ROOT}/cap-van-ban-chap-thuan`,
        giaoDien: <CapVanBanChapThuan />,
    },
    {
        ten: "Xóa đăng ký tàu cá",
        duongDan: `${ROOT}/xoa-dang-ky-tau-ca`,
        giaoDien: <XoaGiayCNDKTauCa />,
    },
    //longBe
    {
        ten: "Cấp, cấp lại giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/cap-giay-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <CapXNNuoiTrongLongBe />,
    },
    {
        ten: "Nhập đầu kỳ giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/nhap-dau-ky-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <NhapDauKyXNNuoiTrongLongBe />,
    },
    
    {
        ten: "Nhập đầu kỳ giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/nhap-dau-ky-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <NhapDauKyCNDKTauCa />,
    },
    {
        ten: "Thu hồi giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/thu-hoi-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <ThuHoiCNDongMoiCaiHoanTauCa />,
    },
    {
        ten: "Thông tin chi tiết giấy chứng nhận cơ sở đủ đIều kiện đóng mớI, cải hoán tàu cá",
        duongDan: `${ROOT}/view/chi-tiet-giay-chung-nhan-dong-moi-cai-hoan-tau-ca`,
        giaoDien: <ViewCNDongMoiCaiHoanTauCa />,
    },
    {
        ten: "Thông tin chi tiết giấy chứng nhận đăng ký tàu cá tạm thời",
        duongDan: `${ROOT}/view/chi-tiet-giay-chung-nhan-dang-ky-tau-ca-tam-thoi`,
        giaoDien: <ViewCNDKTauCaTamThoi />,
    },
    {
        ten: "Thông tin chi tiết giấy chứng nhận đăng ký tàu cá",
        duongDan: `${ROOT}/view/chi-tiet-giay-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <ViewCNDKTauCa />,
    },
    {
        ten: "Thông tin chi tiết văn bản chấp thuận đóng mới, cải hoán, thuê, mua tàu cá trên biển",
        duongDan: `${ROOT}/view/chi-tiet-van-ban-chap-thuan`,
        giaoDien: <ViewVanBanChapThuan />,
    },
    {
        ten: "Thông tin chi tiết văn bản chứng nhận xóa đăng ký tàu cá",
        duongDan: `${ROOT}/view/chi-tiet-van-ban-xoa-chung-nhan-dang-ky-tau-ca`,
        giaoDien: <ViewXoaCNDKTauCa />,
    },
    {
        ten: "Thông tin chi tiết giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
        duongDan: `${ROOT}/view/chi-tiet-giay-xac-nhan-dang-ky-nuoi-trong-long-be`,
        giaoDien: <ViewXNNuoiTrongLongBe />,
    },
]

// export const ROUTE_CN_LONG_BE: RoutePropsI[] = [
//     {
//         ten: "Cấp, cấp lại giấy xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực",
//         duongDan: `${ROOT}/cap-giay-xac-nhan-dang-ky-nuoi-trong-long-be`,
//         giaoDien: CapXNNuoiTrongLongBe,
//     },
// ]