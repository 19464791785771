import { Box } from "@material-ui/core";
import React, { useEffect } from "react"

interface TableFooterPropsI {
    count: number;
    page: number;
    rowsPerPage: number;
}

export const useTableFooter = (props: TableFooterPropsI) => {
    const { count, page, rowsPerPage } = props;
    const [totalPage, setTotalPage] = React.useState(0);

    useEffect(() => {
        setTotalPage(Math.ceil(count / rowsPerPage));
    }, [count, rowsPerPage]);
    return (
        <Box component="span" paddingX="5px">
            Mẫu tin {(page * rowsPerPage) + 1} - {
                totalPage - page === 1 ? count : (page * rowsPerPage) + rowsPerPage
            } / {count}
        </Box>
    )
}