export const API_KEY_MAP = "AAPK33324f5f968a45d5a0f447b8ccc4dfc48RQTWUPnGZauFUBxgEl0_1FlFxegBPn-iPeYn2DPikYUqlJUxY8H1EmktLnhF1W7";

export enum MapEnum {
    DIEU_KIEN_VE_SINH_THU_Y_NHAP_DAU_KY = 0,
    BUON_BAN_PHAN_BON_CAP_MOI = 1,
    BUON_BAN_PHAN_BON_CAP_LAI = 2,

    CAP_MOI_BUON_BAN_THUOC_BVTV = 3,
    CAP_MOI_AN_TOAN_DICH_BENH_THUY_SAN = 4,

    QUANG_CAO_PHAN_BON = 5,

    TRA_CUU_BAN_DO_QUANG_CAO_PHAN_BON = 98,
    TRA_CUU = 99,
}
