import { BaseResponse, BaseService, PageRequest, Pagination } from "../baseService";
import axios from "../index";

export interface DanhMucDungRiengDTO {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    type: Type;
}
export enum Type {
    CHUNG_CHI_HANH_NGHE = 0,
    BUON_BAN_THUOC_THU_Y = 1,
    LOAI_HINH_SX_KINH_DOANH = 2,
    LOAI_HINH_HOAT_DONG = 3,
    THI_TRUONG_TIEU_THU = 4,
    LY_DO_CAP_LAI = 5,
    HINH_THUC_NUOI = 6,
    LOAI_BENH = 7,
    XEP_LOAI = 8,
    CAC_SAN_PHAM_KINH_DOANH = 9,
}
export interface DanhMucDungRiengRequest extends PageRequest {
    type: Type;
    isActive?: Boolean;
}
export class DanhMucDungRiengService extends BaseService {

    getDMDungRieng = async (request: DanhMucDungRiengRequest): Promise<Pagination<DanhMucDungRiengDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        } as Pagination<DanhMucDungRiengDTO[]>;
        await axios.get('/danh-muc/' + request.type + '/search', { params: request }).then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content
                }
            }
        }).catch();
        return result;
    }


    getAllDanhMuc = async (type: Type): Promise<DanhMucDungRiengDTO[]> => {
        let result: DanhMucDungRiengDTO[] = [];
        await axios.get('/danh-muc/' + type + '/get').then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = response.data.data;
            }
        }).catch();
        return result;
    }

    insertOrUpdate = async (request: DanhMucDungRiengDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc/' + request.type + '/insertOrUpdate', JSON.stringify(request), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }

    deleteById = async (request: DanhMucDungRiengDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc/' + request.type + '/delete', JSON.stringify({ id: request.id }), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }


}
