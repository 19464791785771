import { RoutePropsI } from "routes";
import { ROUTE_CN_QLCL_NONG_LAM_THUY_SAN } from "./routersQLCLNongLamThuySan";
import { ROUTE_CN_THUC_VAT } from "./routersThucVat";
import { ROUTE_CN_THU_Y } from "./routersThuY";
import { ROUTE_CN_TAU_CA } from "./routersThuySan";
import { ROUTE_ADMIN_USER_MGT } from "./routesUserMgt";

export const ROUTE_ROOT_CN: RoutePropsI[] = [
    ...ROUTE_CN_TAU_CA, 
    ...ROUTE_CN_THUC_VAT, 
    ...ROUTE_CN_THU_Y,
    ...ROUTE_CN_QLCL_NONG_LAM_THUY_SAN
];

export const ROUTE_ROOT_ADMIN: RoutePropsI[] = [
    ...ROUTE_ADMIN_USER_MGT
];
