
import { DongBoCanBoCongChucComponent } from "pages/quanTriHeThong/DongBoCanBo/DongBoCanBoCongChucComponent";
import { UserManagement } from "pages/quanTriHeThong/user/mgt/userManagement";
import React, { lazy } from "react";
import { ROUTE_ROOT_ADMIN, ROUTE_ROOT_CN } from "./rootRouter";
import { ROUTE_PAGE_QLCL_NONG_LAM_THUY_SAN } from "./routersQLCLNongLamThuySan";
import { ROUTE_PAGE_THUC_VAT } from "./routersThucVat";
import { ROUTE_PAGE_THU_Y } from "./routersThuY";

import { ROUTE_PAGE_THUY_SAN } from "./routersThuySan";
import { ROUTE_ADMIN_DANH_MUC } from "./routesDanhMuc";
const HoSoCuaToiMgt = lazy(() => import("../pages/hoSoCuaToi/hoSoCuaToiMgt").then(({ HoSoCuaToiMgt }) => ({ default: HoSoCuaToiMgt })));
const DanhMucBieuMauMgt = lazy(() =>
    import("../pages/quanTriHeThong/danhMucBieuMau").then(({ DanhMucBieuMau }) => ({ default: DanhMucBieuMau }))
);
const DongBoMotCuaComponent = lazy(() =>
    import("../pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent").then(({ DongBoMotCuaComponent }) => ({ default: DongBoMotCuaComponent }))
);
const DongBoDuLieuComponent = lazy(() =>
    import("../pages/quanTriHeThong/dongBoDuLieu/DongBoDuLieuComponent").then(({ DongBoDuLieuComponent }) => ({ default: DongBoDuLieuComponent }))
);
const WrapperMapTraCuu = lazy(() => import("pages/traCuuBanDo/wrapperTraCuuBanDo"));

const HuongDanSuDungComponent = lazy(() =>
import('pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));

export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes?: RoutePropsI[];
}
export const ROUTE_CN: RoutePropsI[] = ROUTE_ROOT_CN;

export const ROUTE_ADMIN: RoutePropsI[] = ROUTE_ROOT_ADMIN;

export const ROUTE_PAGE: RoutePropsI[] = [
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: <HuongDanSuDungComponent />,
        duongDan: '/huong-dan-su-dung',
    },
    {
        ten: "HỒ SƠ CỦA TÔI",
        duongDan: "/ho-so-cua-toi",
        giaoDien: <HoSoCuaToiMgt /> 
    },
    {
        ten: "Đồng bộ một cửa",
        duongDan: "/dong-bo-mot-cua",
        giaoDien: <DongBoMotCuaComponent />,
    },
    {
        ten: "Đồng bộ dữ liệu",
        duongDan: "/dong-bo-du-lieu",
        giaoDien: <DongBoDuLieuComponent />,
    },
    ...ROUTE_ROOT_ADMIN,
    {
        ten: "Danh mục biểu mẫu",
        duongDan: "/danh-muc-bieu-mau",
        giaoDien: <DanhMucBieuMauMgt />,
    },
    {
        ten: "Bản đồ",
        duongDan: "/tra-cuu-ban-do",
        giaoDien: <WrapperMapTraCuu />,
    },
    {
        ten: 'Đồng bộ người dùng',
        duongDan: '/dong-bo-nguoi-dung',
        giaoDien: <DongBoCanBoCongChucComponent/>,
    },
    {
        ten: 'Quản trị người dùng',
        duongDan: '/quan-tri-nguoi-dung',
        giaoDien: <UserManagement/>,
    },
    ...ROUTE_ADMIN_DANH_MUC,
    ...ROUTE_PAGE_THUY_SAN,
    ...ROUTE_PAGE_QLCL_NONG_LAM_THUY_SAN,
    ...ROUTE_PAGE_THUC_VAT,
    ...ROUTE_PAGE_THU_Y,
];

