import {Box, createStyles, IconButton, makeStyles, Theme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {blue} from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AttachIcon } from "components/commons/attachFileTooltip/attachFileTooltip";
import { FileEntry } from "apis/Common/fileEntry";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        nameFile: {
            textDecoration: "none",
            color: blue[500],
            marginRight: "auto",
        },
        button: {
            margin: "0px 5px",
        },
        container: {
            marginTop: "5px",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
        },
        buttonFile: {
            justifyContent: "flex-start",
            color: blue[500],
        },
    }),
);

interface PreviewDropzoneI {
    files: FileEntry[];
    onDelete: (f: FileEntry, index: number) => void;
    onDownload: (f: FileEntry) => void;
}

const PreviewDropzoneFromServer: React.FC<PreviewDropzoneI> = ({ files, onDelete, onDownload }) => {
    const classes = useStyles();
    return (
        <>
            {files.map(
                (item, i) =>
                    (
                        <Box display="flex">
                            <Box display="flex" flexGrow="1" flexDirection="column" key={i}>
                                <Button
                                    size="small"
                                    className={classes.buttonFile}
                                    disableRipple
                                    startIcon={<AttachIcon fileName={`${item.name}`} />}
                                    style={{
                                        display: "flex",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "410px",
                                        whiteSpace: "nowrap",
                                    }}
                                    endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                    onClick={(e) => onDownload(item)}
                                >
                                   {item.name.length > 30 ? item.name.slice(0, 33) + "..." + item.name.slice(item.name.length - 10) : item.name}
                                </Button>
                            </Box>

                            <IconButton onClick={() => onDelete(item, i)} size="small" disableRipple color="secondary" title="Xóa">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    ),
            )}
        </>
    );
};

export default PreviewDropzoneFromServer;
