import { DiaChiDTO } from "model/utils/diaChiDTO";

export default class StringUtil {
    static isNullOrEmty(str: any): boolean {
        if (
            typeof str == "undefined" ||
            !str ||
            str.length === 0 ||
            str === "" ||
            !/[^\s]/.test(str) ||
            /^\s*$/.test(str) ||
            str.toString().replace(/\s/g, "") === ""
        )
            return true;
        else return false;
    }
    
    static formatMoney(str: string): string {
        if (str.match(/,/g)) {
            const newString = `${str.toString().replace(/,/g, "")}`;
            return `${newString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    static checkInputNumber(str: string) {
        if (/^[1-9]?[\d]+$/g.test(str)) {
            return true;
        }
        return false;
    }

    static diaChiToString(values: DiaChiDTO): string {
        return Object.entries(values)
            .map(([key, value], index) => {
                if (key.match("soNha")) {
                    return value;
                }
                if (key.match("diaChiXaName")) {
                    return value;
                }
                if (key.match("diaChiHuyenName")) {
                    return value;
                }
                if (key.match("diaChiTinhName")) {
                    return value;
                }
            })
            .filter((item) => item !== null && item)
            .join(", ");
    }

    static sumNumber(rows: any[], field: string) {
        return rows.reduce((acc, init) => {
            return acc + Number(init[field]);
        }, 0);
    }

    static  replaceDots(value:string): string{
        let result= "";
        if(!StringUtil.isNullOrEmty(value)){
            result = value.replace(/\./g,"")
        }
        return result;
    }

}
