export enum BussinessType {
    CHUNG_NHAN_DU_DK_ATTP = "0"
}

export enum LoaiChuyenNganh {
    NHAP_DAU_KY = "0",
    CAP_LAI = "1",
    CAP_MOI = "2",
    THU_HOI = "3"
}

export enum DoiTuong {
    NONE = "",
    CS_GIET_MO = "0",
    SP_NONG_LAM_THUY_SAN = "1"
}

export function getDoiTuongEnum(doiTuong?: DoiTuong) {
    if (doiTuong == DoiTuong.CS_GIET_MO) {
        return "Cá nhân sản xuất sản phẩm nông lâm sản, thủy sản";
    } else if (doiTuong == DoiTuong.SP_NONG_LAM_THUY_SAN) {
        return "Tổ chức kinh doanh sản phẩm nông lâm sản, thủy sản";
    } else {
        return "";
    }
}

export enum LoaiMatHangKinhDoanh {
    NONE = "",
    NONG_SAN = "0",
    THUY_SAN = "1",
    CHUYEN_DOANH = "2",
    DONG_VAT = "3",
    TRONG_TROT = "4"
}

export function getLoaiMatHangKinhDoanhEnum(loaiMatHangKinhDoanh?: LoaiMatHangKinhDoanh) {
    if (loaiMatHangKinhDoanh == LoaiMatHangKinhDoanh.NONG_SAN) {
        return "Nông sản thực vật";
    } else if (loaiMatHangKinhDoanh == LoaiMatHangKinhDoanh.THUY_SAN) {
        return "Thủy sản";
    } else if (loaiMatHangKinhDoanh == LoaiMatHangKinhDoanh.DONG_VAT) {
        return "Nông sản động vật";
    } else if (loaiMatHangKinhDoanh == LoaiMatHangKinhDoanh.TRONG_TROT){
        return "Trồng trọt";
    }
    else {
        return "Chuyên doanh";
    }
}

export enum LoaiHinhSanXuatKinhDoanh {
    NONE = "",
    NHA_NUOC = "0",
    LIEN_DOANH_NUOC_NGOAI = "1",
    TU_NHAN = "2",
    VON_NUOC_NGOAI = "3",
    CO_PHAN = "4",
    KHAC = "5"
}
export function getLoaiHinhSanXuatKinhDoanhEnum(loaiHinhSanXuatKinhDoanh?: LoaiHinhSanXuatKinhDoanh) {
    if (loaiHinhSanXuatKinhDoanh == LoaiHinhSanXuatKinhDoanh.NHA_NUOC) {
        return "Nhà nước";
    } else if (loaiHinhSanXuatKinhDoanh == LoaiHinhSanXuatKinhDoanh.LIEN_DOANH_NUOC_NGOAI) {
        return "Liên doanh nước ngoài";
    } else if (loaiHinhSanXuatKinhDoanh == LoaiHinhSanXuatKinhDoanh.TU_NHAN) {
        return "Tư nhân";
    } else if (loaiHinhSanXuatKinhDoanh == LoaiHinhSanXuatKinhDoanh.VON_NUOC_NGOAI) {
        return "Vốn nước ngoài";
    } else if (loaiHinhSanXuatKinhDoanh == LoaiHinhSanXuatKinhDoanh.CO_PHAN) {
        return "Cổ phần";
    } else {
        return "Khác";
    }
}

export enum LinhVuc {
    NONE = "",
    NONG_LAM_THUY_SAN = "0",
    THUY_SAN = "1",
    TRONG_TROT_CHAN_NUOI = "2"

}

export function getLinhVuc(linhVuc?: LinhVuc) {
    if (linhVuc == LinhVuc.NONG_LAM_THUY_SAN) {
        return "Nông lâm, thủy sản";
    } else if (linhVuc == LinhVuc.THUY_SAN) {
        return "Thủy sản";
    } else if (linhVuc == LinhVuc.TRONG_TROT_CHAN_NUOI) {
        return "Trồng trọt và chăn nuôi";
    } else {
        return "";
    }
}