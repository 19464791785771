import { InputLabel } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { ColumnTable, CommonTableChuyenNganh, TypeCell } from "components/commons/table/commonTableChuyenNganh";
import { KienThucVeSinhAttpCaNhanDTO, KienThucVeSinhAttpCaNhanDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import React, { useState } from "react";
import { CaNhanDialog } from "./caNhanDialog";

export interface DanhSachCaNhanPropsI {
    onDeleteItem?: (item: KienThucVeSinhAttpCaNhanDTO, index: number) => void;
    onSaveItem?: (item: KienThucVeSinhAttpCaNhanDTO, index: number, isEdit: boolean) => void;
    dataListItem: KienThucVeSinhAttpCaNhanDTO[];
    disableAddButton?: boolean;
    viewOnly?: boolean;
    label?: string;
}

export const CaNhan = (props: DanhSachCaNhanPropsI) => {
    const { onSaveItem, onDeleteItem, dataListItem, disableAddButton, viewOnly, label } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();

    const [openDlg, setOpenDlg] = useState(false);
    const [itemEdited, setItemEdited] = useState<KienThucVeSinhAttpCaNhanDTO>({...KienThucVeSinhAttpCaNhanDTO_INT});
    const [isEdit, setIsEdit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);

    const columns: ColumnTable<KienThucVeSinhAttpCaNhanDTO>[] = [
        { title: "Họ tên", field: "hoTen" },
        { title: "Giới tính", field: "gioiTinh" },
        { title: "Số căn cước/CMND", field: "soCmnd" },
        { title: "Ngày cấp", field: "ngayCapCmnd", type: TypeCell.DATE },
        { title: "Nơi cấp", field: "noiCapCmnd" },
    ];

    function handleClickAdd() {
        setItemEdited({...KienThucVeSinhAttpCaNhanDTO_INT});
        setIsEdit(false);
        setOpenDlg(true);
    }

    function handleSaveDialog(item: KienThucVeSinhAttpCaNhanDTO) {
        setOpenDlg(false);
        onSaveItem && onSaveItem(item, indexEdit, isEdit);
    }

    function handleEditItem(item: KienThucVeSinhAttpCaNhanDTO, index: number) {
        setItemEdited(item);
        setIsEdit(true);
        setIndexEdit(index);
        setOpenDlg(true);
    }
    return (
        <>
            <InputLabel classes={labelStyles}>{label}</InputLabel>
            <CommonTableChuyenNganh columns={columns} rows={dataListItem}
                onDelete={onDeleteItem ? onDeleteItem : undefined}
                onEdit={onSaveItem ? handleEditItem : undefined}
                viewOnly={viewOnly}
                onAdd={disableAddButton ? undefined : handleClickAdd} />
            {
                openDlg && <CaNhanDialog openDialog={openDlg} onDialogClose={() => setOpenDlg(false)}
                    onSubmitData={handleSaveDialog}
                    itemEdit={itemEdited}
                />
            }
        </>
    )
}