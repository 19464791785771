import React from "react";
import Dropzone from "react-dropzone-uploader";
import { Box, Grid, InputLabel } from "@material-ui/core";
import PreviewDropzoneFromServer from "../previewFileFromServer/previewFileFromServer";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import LayoutDrop from "../layoutDropZone/layoutDrop";
import { PreviewDropZone } from "../layoutDropZone/previewDropZone";
import { AttachFileDTO } from "../../../model/chuyenNganh/attachFile";
import { bootstrapTextFieldHook } from "../input/bootstrap";
import Button from "@material-ui/core/Button";

interface IDropZoneCustom {
    acceptFiles: String;
    attachFileServer: AttachFileDTO[];
    url: String;
    handleChangeStatus: (file: IFileWithMeta) => void;
    handleDeleteFileServer: (file: AttachFileDTO) => void;
    handleDownloadFile: (file: AttachFileDTO) => void;
}

const DropZoneCustom: React.FC<IDropZoneCustom> = ({ acceptFiles, attachFileServer, url, handleDeleteFileServer, handleChangeStatus, handleDownloadFile }) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();

    return (
        <>
            <Grid item xs={2} className="grid-item">
                <InputLabel classes={labelStyles}>Đính kèm</InputLabel>
            </Grid>
            <Grid item xs={10}>
                {attachFileServer && (
                    <Box width="456px">
                        <PreviewDropzoneFromServer files={attachFileServer} onDelete={handleDeleteFileServer} onDownload={handleDownloadFile} />
                    </Box>
                )}
                <Box width="456px">
                    <Dropzone
                        accept={`${acceptFiles}`}
                        inputContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        multiple={true}
                        //getUploadParams={() => ({ url: `${url}` })}
                        getUploadParams={(file: IFileWithMeta) => {
                            const formData = new FormData();
                            formData.append("file", file.file);
                            let headers: any = {
                                'csrf-token': localStorage.getItem("csrf-token")
                            }
                            return {
                                url: `${url}`,
                                headers: headers,
                                body: formData,
                            };
                        }}
                        onChangeStatus={handleChangeStatus}
                        
                        // InputComponent={(props: any) => <InputCustom acceptFiles={`${acceptFiles}`} {...props} />}
                        LayoutComponent={(props: any) => <LayoutDrop {...props} acceptFiles={acceptFiles} />}
                        PreviewComponent={(props: any) => <PreviewDropZone {...props} />}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default DropZoneCustom;
