
import AttachFileService from "apis/attachFileService/attachFileService";
import { AttachFileDTO } from "model/chuyenNganh/attachFile";
import { useState } from "react";
import StringUtil from "utils/stringUtils";
import { IFileWithMeta } from "react-dropzone-uploader";

export const useContextDropZone = () => {
    // State
    const [attachFileServer, setAttachFileServer] = useState<AttachFileDTO[]>([]);

    // Control
    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: AttachFileDTO) {
        let newValues;
        if (item?.motCua && !StringUtil.isNullOrEmty(item?.linkFile)) {
            newValues = attachFileServer.map((file: AttachFileDTO) => (file.id === item.id ? { ...file, isDeleted: true } : { ...file }));
        } else {
            newValues = attachFileServer.map((file: AttachFileDTO) => (file.fileId === item.fileId ? { ...file, isDeleted: true } : { ...file }));
        }
        setAttachFileServer([...newValues]);
    }

    async function handleDownloadFile(file: AttachFileDTO) {
        if (file?.motCua && !StringUtil.isNullOrEmty(file?.linkFile)) {
            let link = document.createElement("a");
            link.href = file?.linkFile ? file?.linkFile : "";
            link.setAttribute("download", `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.linkFile ? file?.linkFile : "");
        } else {
            await new AttachFileService().downloadFile(file).then((res) => {
                let blob = new Blob([res.data], { type: res.data.type });
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${file.fileName}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            });
        }
    }

    function handleSetAttachFile(files: AttachFileDTO[]) {
        setAttachFileServer(files);
    }

    return { attachFileServer, handleSetAttachFile, handleChangeStatus, handleDownloadFile, handleDeleteFileServer };
};
