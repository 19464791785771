import { AttachFileDTO } from "model/chuyenNganh/attachFile";
import { DanhMucXBPDTO, OptionDefault } from "model/danhMuc/danhMucDTO";
import { DiaChiDTO_INT, DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { DiaChiDTO } from "model/utils/diaChiDTO";

export enum LoaiDongVatEnum {
    THUY_SAN = 0,
    TREN_CAN = 1,
}

export enum LoaiDangKyEnum {
    CAP_LAN_DAU = 0,
    CAP_LAI = 1,
    CAP_DOI = 2,
    CAP_BO_SUNG = 3,
    DANH_GIA_LAI = 4,
}

export const dongVatOptions: OptionDefault[] = [
    { title: "Thủy sản", value: LoaiDongVatEnum.THUY_SAN },
    { title: "Trên cạn", value: LoaiDongVatEnum.TREN_CAN },
];

export const dangKyOptions: OptionDefault[] = [
    { title: "Cấp lần đầu", value: LoaiDangKyEnum.CAP_LAN_DAU },
    { title: "Cấp lại", value: LoaiDangKyEnum.CAP_LAI },
    { title: "Cấp đổi", value: LoaiDangKyEnum.CAP_DOI },
    { title: "Cấp bổ sung", value: LoaiDangKyEnum.CAP_BO_SUNG },
    { title: "Đánh giá lại", value: LoaiDangKyEnum.DANH_GIA_LAI },
];

export interface ThuYAnToanDichBenhTraCuuDTO {
    id?: Number | null;
    referenceId?: String;
    loaiDongVat?: LoaiDongVatEnum;
    loaiDangKy?: LoaiDangKyEnum;
    soBienNhan?: String;

    soGCNMoi?: String;
    ngayCapMoi?: Date | null;
    ngayHetHanMoi?: Date | null;
    tenCoSo?: String;
    diaChiCoSo?: DiaChiDTO;
    fullDiaChiCoSo?: String;
    loaiDongVatStr?: String;
    tenDongVat?: String;
    loaiBenhId?: Number | null;
    loaiBenhStr?: String;
    loaiDangKyStr?: String;

    ngayCapFrom: Date | null;
    ngayCapTo: Date | null;
    year?: Number;
    thang?: string;
    mode?: String;

    totalCapLanDau?: number;
    totalCapLai?: number;
    totalCapBoSung?: number;
    totalCapDoi?: number;
    totalDanhGiaLai?: number;
}

export interface ThuYAnToanDichBenhThongTinChungDTO {
    id: Number | null;
    referenceId: Number | null;
    loaiDongVat?: LoaiDongVatEnum;
    loaiDangKy?: LoaiDangKyEnum;
    soBienNhan?: String;
    soGCNCu?: String;
    ngayCapCu: Date | null;
    ngayHetHanCu: Date | null;

    soGCNMoi: String;
    ngayCapMoi: Date | null;
    ngayHetHanMoi: Date | null;
    lanCapLai?: String;
    lyDoCapLai?: string;

    tenCoSo: String;
    diaChiCoSo: DiaChiDTO;
    fullDiaChiCoSo?: String;

    chuCoSo: String;
    diaChiChuCoSo: DiaChiDTO;
    fullDiaChiChuCoSo?: String;

    lstLoaiHinhHoatDong?: DanhMucXBPDTO[];
    thiTruongTieuThuId?: Number | null;
    thiTruongTieuThuStr?: String;
    loaiBenhId?: Number | null;
    loaiBenhStr?: String;

    dangKyAnToanVoiLoaiBenh?: String;
    trenDoiTuong?: String;

    attachFiles?: AttachFileDTO[];
    diaChiKinhDo?: Number;
    diaChiViDo?: Number;
    ngayBienDong?: Date | null;
}

export const initAnToanDichBenhTTC: ThuYAnToanDichBenhThongTinChungDTO = {
    id: null,
    loaiBenhId: NaN,
    referenceId: null,
    thiTruongTieuThuId: NaN,
    chuCoSo: "",
    diaChiCoSo: DiaChiDTO_INT_CAN_THO,
    diaChiChuCoSo: DiaChiDTO_INT,
    lstLoaiHinhHoatDong: [],
    ngayCapMoi: null,
    ngayHetHanMoi: null,
    soGCNMoi: "",
    tenCoSo: "",
    ngayCapCu: null,
    ngayHetHanCu: null,
    ngayBienDong: null,
};

export interface DieuKienCoSoDongVatTrenCanDTO {
    id: Number | null;
    loaiDangKy: LoaiDangKyEnum;
    referenceId: Number | null;
    //1
    tongDienTich?: String;
    vungTiepGiapXungQuanh?: String;
    //2.Cơ sở vật chất
    hangRaoNganCach?: boolean;
    khuHanhChinh?: boolean;
    phongGiaoDich?: boolean;
    khuChanNuoi?: String;
    khuNhaKho?: String;
    khuXuLyChatThai?: boolean;
    khuXuLyChatThaiStr?: String;
    khuCachLyGiaSucMoi?: boolean;
    khuCachLyGiaSucCoBenh?: boolean;
    khuCachLyStr?: String;
    khuTieuHuyGiaSucBenh?: boolean;
    quanAoTrongKhuChanNuoi?: boolean;
    phongThayQuanAo?: boolean;
    phongTamSatTrung?: boolean;
    phongTamSatTrungStr?: String;
    hoSatTrung?: boolean;
    hoSatTrungStr?: String;
    //3. Quy mô, cơ cấu...
    quyMo?: String;
    coCauDan?: String;
    sanPhamBanRa?: String;
    sanLuongHangNam?: String;
    //4.Nguồn nhân lực
    nguoiQuanLy?: String;
    soCongNhanChanNuoi?: String;
    soDuocDaoTao?: String;
    soChuaDuocDaoTao?: String;
    canBoChuyenTrach?: String;
    //5. Hệ thống quản lý chăn nuôi
    giaSuc?: String;
    nguonThucAn?: String;
    nguonNuocUong?: String;
    chamSocQuanLy?: String;
    heThongGhiChep?: String;
    cheDoViSinhChuongTrai?: String;
    quyTrinhChanNuoi?: boolean;
    noiQuyRaVaoTrai?: boolean;
    cheDoTiemPhong?: String;
    noiTieuThuSanPham?: String;
    tinhHinhChanNuoi?: String;
    //6.Tình hình dịch bệnh
    tinhHinhDichBenh?: String;
    congTacTiemPhong?: String;
    ketQuaThucHienGiamSat?: String;
    tuThuocThuY?: String;
}

export const DieuKienCoSoDongVatTrenCanDTO_INT: DieuKienCoSoDongVatTrenCanDTO = {
    id: null,
    loaiDangKy: LoaiDangKyEnum.CAP_LAN_DAU,
    referenceId: null,
    //1
    //tongDienTich: String,
    //vungTiepGiapXungQuanh: String,
    //2.Cơ sở vật chất
    hangRaoNganCach: false,
    khuHanhChinh: false,
    phongGiaoDich: false,
    //khuChanNuoi: String,
    //khuNhaKho: String,
    khuXuLyChatThai: false,
    //khuXuLyChatThaiStr: String,
    khuCachLyGiaSucMoi: false,
    khuCachLyGiaSucCoBenh: false,
    //khuCachLyStr: String,
    khuTieuHuyGiaSucBenh: false,
    quanAoTrongKhuChanNuoi: false,
    phongThayQuanAo: false,
    phongTamSatTrung: false,
    //phongTamSatTrungStr: String,
    hoSatTrung: false,
    //hoSatTrungStr: String,
    //3. Quy mô, cơ cấu...
    //quyMo: String,
    //coCauDan: String,
    //sanPhamBanRa: String,
    //sanLuongHangNam: String,
    //4.Nguồn nhân lực
    //nguoiQuanLy: String,
    //soCongNhanChanNuoi: String,
    //soDuocDaoTao: String,
    //soChuaDuocDaoTao: String,
    //canBoChuyenTrach: String,
    //5. Hệ thống quản lý chăn nuôi
    //giaSuc: String,
    //nguonThucAn: String,
    //nguonNuocUong: String,
    //chamSocQuanLy: String,
    //heThongGhiChep: String,
    //cheDoViSinhChuongTrai: String,
    quyTrinhChanNuoi: false,
    noiQuyRaVaoTrai: false,
    //cheDoTiemPhong: String,
    //noiTieuThuSanPham: String,
    //tinhHinhChanNuoi: String,
    //6.Tình hình dịch bệnh
    //tinhHinhDichBenh: String,
    //congTacTiemPhong: String,
    //ketQuaThucHienGiamSat: String,
    //tuThuocThuY: String,
}

export interface DieuKienCoSoThuySanDTO {
    id: Number | null;
    loaiDangKy?: LoaiDangKyEnum;
    referenceId: Number | null;
    lstKhuVucXungQuanh?: String[];
    heThongBaoQuanhCoSo?: boolean;
    heThongBaoQuanhCoSoStr?: String;
    nguonNuoc?: String;
    viTriGiaoThong?: String;
    heThongDien?: String;

    dienTichCoSo?: String;
    //2.2 Điều kiện cơ sở hạ tầng
    haTangCoMaiChe?: boolean;
    haTangHeThongAoBe?: boolean;
    haTangHeThongcapThoatNuoc?: boolean;
    haTangKhuVucXuLy?: boolean;
    //2.3
    trangThietBi?: String;
    //2.4 Thực trạng sản xuất
    //2.4.1
    thucTrangHeThongAoBe?: boolean;
    thucTrangHeThongAoBeStr?: String;
    thucTrangThietBiDungCu?: boolean;
    thucTrangThietBiDungCuStr?: String;
    thucTrangNguonNuoc?: boolean;
    thucTrangNguonNuocStr?: String;
    thucTrangXuLyThuySanChet?: boolean;
    thucTrangXuLyThuySanChetStr?: String;
    thucTrangVeSinhCaNhan?: boolean;
    //2.4.2
    thucTrangXuLyCaiTaoBe?: boolean;
    thucTrangXuLyCaiTaoBeStr?: String;
    thucTrangThayNuocDinhKy?: boolean;
    thucTrangDinhDuong?: boolean;
    thucTrangDinhDuongStr?: String;
    thucTrangVeSinhAoBe?: boolean;
    thucTrangVeSinhAoBeStr?: String;
    thucTrangKhangSinh?: boolean;
    thucTrangKhangSinhStr?: String;
    thucTrangDietKhuanDinhKy?: boolean;
    thucTrangDietKhuanDinhKyStr?: String;
    thucTrangBonViSinhDinhKy?: boolean;
    thucTrangBonViSinhDinhKyStr?: String;
    //2.5
    ghiChepTheoDoi?: String;
    ghiChepTinhHinh?: boolean;
    ghiChepTinhHinhStr?: String;
}

export const initDieuKienCoSoThuySan: DieuKienCoSoThuySanDTO = {
    id: null,
    referenceId: null,
};
