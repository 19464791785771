
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputBase, InputLabel, NativeSelect } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParamExport } from "apis/baseService";
import { downLoadFile } from "apis/Common/exportUtil";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { OptionMonthsInYear, OptionYears } from "components/commons/options/optionCommons";
import { getIn, useFormik } from "formik";
import { getLinhVuc, LinhVuc } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { DanhSachTraCuuDTO, TraCuuRequestDTO, TraCuuRequestDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/traCuuChungNhanDuDieuKienAttpDTO";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

export const BaoCaoChungNhanDuDieuKienAttpTrongTrot= () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapGcnTu: Yup.date()
                .nullable()
                .when("ngayCapGcnDen", (ngayCapGcnDen: any, schema: any) => {
                    if (ngayCapGcnDen) {
                        return schema.max(ngayCapGcnDen, "Không hợp lệ");
                    }
                    return schema;
                }),
            ngayCapGcnDen: Yup.date()
                .nullable()
                .when("ngayCapGcnTu", (ngayCapGcnTu: any, schema: any) => {
                    if (ngayCapGcnTu) {
                        return schema.min(ngayCapGcnTu, "Không hợp lệ");
                    }
                    return schema;
                }),
        },
        [["ngayCapGcnTu", "ngayCapGcnDen"]]
    );

    const columns: ColumnCustomTable<DanhSachTraCuuDTO>[] = [
        {
            title: "Số giấy chứng nhận",
            field: "soGcn",
        },
        { title: "Ngày cấp", field: "ngayCapGcn", type: TypeCell.DATE },
        { title: "Ngày hết hạn", field: "ngayHetHanGcn", type: TypeCell.DATE },
        { title: "Tên cơ sở", field: "tenCoSo", width: "15%", align: "left" },
        { title: "Địa chỉ", field: "fullDiaChi", width: "20%", align: "left" },
        { title: "Mặt hàng SX,KD", field: "matHangSxkd", width: "15%" ,align: "left" },
        { title: "Tình trạng", field: "tinhTrangStr" },
    ];

    //state
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);
    const [rows, setRows] = useState<DanhSachTraCuuDTO[]>([]);
    const [mode, setMode] = useState<string>("YEAR");
    // hook
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();


    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT,  linhVuc: LinhVuc.TRONG_TROT_CHAN_NUOI,year: (new Date()).getFullYear() };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
        validateOnChange: false,
    });

    useEffect(() => {
        async function handleChangeMode() {
            if (mode === 'YEAR') {
                formik.setFieldValue('year', (new Date()).getFullYear());
            }
            if (mode === 'MONTH') {
                formik.setFieldValue('month', (new Date()).getMonth());
            }
        }
        handleChangeMode();
    }, [mode]);

    useEffect(() => {
        async function fetchData() {
            await new ChungNhanDuDieuKienAttpService().getBaoCaoChungNhan(formik.values, pageParams.page, pageParams.rowsPerPage).then((response) => {
                setRows(response.rows);
                setPageParams((prev) => ({ ...prev, total: response.total }));
            });
        }
        fetchData();
    }, [pageParams.page, pageParams.rowsPerPage]);
    // Control
    async function handleSubmit(request: TraCuuRequestDTO) {
        setPageParams((prev) => ({ ...prev, page: 0 }));
        if (mode === 'YEAR') {
            request.ngayCapGcnTu = null;
            request.ngayCapGcnDen = null;
        } else {
            if (mode === 'MONTH') {
                let mm = moment().year(Number(request.year)).month(Number(request.month));
                request.ngayCapGcnTu = mm.startOf('month').toDate();
                request.ngayCapGcnDen = mm.endOf('month').toDate();
            }
            request.year = undefined;
        }
        await new ChungNhanDuDieuKienAttpService().getBaoCaoChungNhan(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
            setRows(response.rows);
            setPageParams((prev) => ({ ...prev, total: response.total }));
        });
    }

    function handleOnChangePage(value: number) {
        let pageParamsNew ={ ...pageParams, page: value };
        setPageParams(pageParamsNew);
    }

    function handOnChangeRowsPerPage(value: number) {
        let pageParamsNew = {...pageParams, page: 0, rowsPerPage: value,};
        setPageParams(pageParamsNew);
    }

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        let newParams: ParamExport = { pageNumber: pageParams.page, pageSize: pageParams.rowsPerPage, downloadType: downloadType };
        await new ChungNhanDuDieuKienAttpService().baoCaoExport({ ...formik.values, mode : mode  }, newParams).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Bao-cao-chung-nhan-du-dieu-kien-attp-]${new Date()}`);
        });
    }
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'BÁO CÁO TÌNH HÌNH CẤP CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM'}</div>
                <Grid container>
                    <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true}/>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} >Lĩnh vực</InputLabel>
                        <NativeSelect disabled = {true} input={<CustomInput />} fullWidth name="linhVuc" value={formik.values?.linhVuc} onChange={formik.handleChange} >
                            <option value={LinhVuc.NONE}>Chọn lĩnh vực</option>
                            <option value={LinhVuc.NONG_LAM_THUY_SAN}>{getLinhVuc(LinhVuc.NONG_LAM_THUY_SAN)}</option>
                            <option value={LinhVuc.THUY_SAN}>{getLinhVuc(LinhVuc.THUY_SAN)}</option>
                            <option value={LinhVuc.TRONG_TROT_CHAN_NUOI}>{getLinhVuc(LinhVuc.TRONG_TROT_CHAN_NUOI)}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item"></Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                        <NativeSelect value={mode} onChange={e => setMode(e.target.value)}
                            input={<InputBase fullWidth classes={inputStyles} />} >
                            <option value="YEAR">Năm</option>
                            <option value="MONTH">Tháng</option>
                            <option value="FROM_TO">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    {
                        (mode === 'YEAR' || mode === 'MONTH') &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Năm</InputLabel>
                            <NativeSelect value={formik.values.year} onChange={e => formik.setFieldValue("year", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <OptionYears />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        mode === 'MONTH' &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tháng</InputLabel>
                            <NativeSelect value={formik.values.month} onChange={e => formik.setFieldValue("month", Number(e.target.value))}
                                input={<InputBase fullWidth classes={inputStyles} />} >
                                <OptionMonthsInYear />
                            </NativeSelect>
                        </Grid>
                    }
                    {
                        mode === 'FROM_TO' &&
                        <>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnTu"
                                    label={"Từ ngày"}
                                    value={formik.values.ngayCapGcnTu}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnTu", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu")) && formik.errors?.ngayCapGcnTu}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnDen"
                                    label={"Đến ngày"}
                                    value={formik.values.ngayCapGcnDen}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnDen", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen"))}
                                    FormHelperTextProps={{
                                        className: "-error",
                                        classes: helperTextStyles,
                                    }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen")) && formik.errors?.ngayCapGcnDen}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tổng hợp
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handOnChangeRowsPerPage}
                            pageParams={pageParams}
                        />
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>
    )
}