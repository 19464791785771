export enum LoaiGiayPhepEnum {
    BUON_BAN_PHAN_BON = "1",
    BUON_BAN_THUOC_BAO_VE_THUC_VAT = "2",
    THUOC_BAO_VE_THUC_VAT_QUANG_CAO = "3",
    SAN_XUAT_PHAN_BON = "4",
    VAN_CHUYEN_THUOC_BAO_VE_THUC_VAT = "5",
    THU_Y_BUON_BAN_THUOC = "6",
    THU_Y_CHUNG_CHI_HANH_NGHE = "7",
    QUANG_CAO_THUOC_THU_Y = "8",
    THU_Y_VE_SINH = "9",
    THUY_SAN_LONG_BE="10",
    THUY_SAN_TAU_CA="11",
    CHUNG_NHAN_ATTP="12",
    PHAN_BON_QUANG_CAO="13",
}