import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn } from "formik";
import { FieldGeneral } from "../Interface/index";
import StringUtil from "utils/stringUtils";

const CustomTextField = <T extends { [key: string]: any }, K extends object>({
    formik,
    label,
    name,
    required = false,
    disabled = false,
    nameObject,
    InputProps,
    type = "text",
}: FieldGeneral<T, K>) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const [value, setValue] = React.useState<String>("");

    function rendervalue() {
        if ((type === "text" || type === "number") && name && formik) {
            setValue(String(formik.values[`${name}`] ? formik.values[`${name}`] : ""));
        }
        if (type === "currency" && name && formik) {
            setValue(StringUtil.formatMoney(formik.values[`${name}`]));
        }

        if ((type === "text" || type === "number") && nameObject && formik) {
            setValue(
                String(
                    formik.values[nameObject.split(".")[0]][nameObject.split(".")[1]]
                        ? formik.values[nameObject.split(".")[0]][nameObject.split(".")[1]]
                        : ""
                )
            );
        }
        if (type === "currency" && nameObject && formik) {
            setValue(StringUtil.formatMoney(formik.values[nameObject.split(".")[0]][nameObject.split(".")[1]]));
        }
    }

    React.useEffect(() => {
        rendervalue();
    }, [formik && formik.values]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (type === "number") {
            formik &&
                formik.setFieldValue &&
                formik.setFieldValue(
                    nameObject ? nameObject : `${name}`,
                    StringUtil.checkInputNumber(e.target.value) || e.target.value === "" ? e.target.value : value + ""
                );
            setValue(StringUtil.checkInputNumber(e.target.value) || e.target.value === "" ? e.target.value : value + "");
            return;
        }
        formik && formik.setFieldValue && formik.setFieldValue(nameObject ? nameObject : `${name}`, e.target.value);
        setValue(e.target.value);
    }

    return (
        <>
            <TextField
                fullWidth
                required={required}
                label={label}
                disabled={disabled}
                type="text"
                name={nameObject ? nameObject : `${name}`}
                value={value}
                onChange={handleChange}
                InputLabelProps={{ classes: labelStyles }}
                InputProps={{
                    disableUnderline: true,
                    classes: inputStyles,
                    ...InputProps,
                }}
                error={Boolean(getIn(formik && formik.errors, nameObject ? nameObject : `${name}`))}
                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                helperText={
                    Boolean(getIn(formik && formik.errors, nameObject ? nameObject : `${name}`)) && formik && name && formik.errors[name]
                }
            />
        </>
    );
};

export default CustomTextField;
