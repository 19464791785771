import { loadModules } from "esri-loader";
import React, { useEffect } from "react";
import locatorImg from "assets/images/locator_1.png";

export interface MarkerProps {
    view: any;
    formik: any;
    fullDiaChi?: String;
}

const Marker: React.FC<MarkerProps> = ({ formik, view, fullDiaChi }) => {
    useEffect(() => {
        if (formik.values["diaChiKinhDo"] && formik.values["diaChiViDo"]) {
            loadModules(["esri/geometry/Point", "esri/Graphic"]).then(([Point, Graphic]) => {
                if (view) {
                    let pt = new Point({
                        latitude: formik.values["diaChiViDo"],
                        longitude: formik.values["diaChiKinhDo"],
                    });
                    view.goTo(pt);
                    const locationGraphic = createGraphic(pt);
                    view.graphics.add(locationGraphic, 0);
                    view.popup.set("dockOptions", {
                        breakpoint: false,
                        buttonEnabled: false,
                        position: "bottom-right",
                    });
                    view.popup.open({
                        title: +Math.round(pt.longitude * 100000) / 100000 + ", " + Math.round(pt.latitude * 100000) / 100000,
                        content: fullDiaChi ? fullDiaChi : "Vị trí hiện tại",
                        location: pt,
                    });
                }
                function createGraphic(point: any) {
                    let symbol = {
                        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
                        url: locatorImg,
                        width: "24px",
                        height: "35px",
                    };
                    const graphic = new Graphic({
                        geometry: point,
                        symbol: symbol,
                    });

                    view.graphics.add(graphic);
                    return graphic;
                }
            });
        }
    }, [formik.values["diaChiViDo"], formik.values["diaChiKinhDo"]]);
    return null;
};

export default Marker;
