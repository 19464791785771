import { Box, Button, Dialog, Grid } from "@material-ui/core";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomKeyboardDatePicker from "components/commons/fields/CustomKeyboardDatePicker";
import CustomTextField from "components/commons/fields/CustomTextField";
import { useFormik } from "formik";
import { ChungNhanAttpSanPhamDTO, ChungNhanAttpSanPhamDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import * as Yup from "yup";

export interface SanPhamDialogPropsI {
    onSubmitData: (resp: ChungNhanAttpSanPhamDTO) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: ChungNhanAttpSanPhamDTO;
}

export const SanPhamDialog = (props: SanPhamDialogPropsI) => {
    const { onSubmitData, onDialogClose, openDialog, itemEdit } = props;
    
    const validationSchema = Yup.object().shape(
        {
            // hoTen: Yup.string().required("Họ tên không được bỏ trống"),
            // ngayCapGxn: Yup.date()
            //     .nullable()
            //     .required("Ngày cấp không được bỏ trống")
            //     .when("ngayHetHanGxn", (ngayHetHanGxn: any, schema: any): any => {
            //         if (ngayHetHanGxn) {
            //             return schema.max(ngayHetHanGxn, "Không hợp lệ");
            //         }
            //         return schema;
            //     }),
            // soCmnd: Yup.string().required("Số căn cước/CMND không được bỏ trống")
        },
        []
    );

    const fields: CreateFieldsProps<ChungNhanAttpSanPhamDTO, {}>[] = [
        { label: "Tên sản phầm, sản xuất kinh doanh", name: "tenSanPham", Component: CustomTextField, xs: 6 },
        { label: "Tên nguyên liệu/ sản phẩm", name: "tenNguyenLieu", Component: CustomTextField, xs: 6, required: true },
        { label: "Nguồn gốc/ xuất xứ", name: "nguonGoc", Component: CustomTextField, xs: 6, required: true },
        { label: "Cách thức đóng gói và thông tin ghi trên bao bì", name: "cachDongGoi", Component: CustomTextField, xs: 6 },
        { label: "Diện tích (m2/ha)", name: "dienTich", Component: CustomTextField, xs: 3 },
        { label: "Số hộ", name: "soHo", Component: CustomTextField, xs: 3 },
        { label: "Sản lượng (Tấn/ kg)", name: "sanLuong", Component: CustomTextField, xs: 6 }
    ];

    const formik = useFormik({
        initialValues: { ...itemEdit },
        onSubmit: (values) => onSubmitData(values),
        validationSchema,
        validateOnChange: false,
    });

    function handleResetForm() {
        formik.setValues(prev => ({...ChungNhanAttpSanPhamDTO_INT}))
    }

    return (
        <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                {itemEdit ? 'Cập nhật' : 'Thêm mới'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid spacing={2} container>
                        <CreateFields formik={formik} fields={fields} />
                        <Grid item xs={12} className="gridItem">
                            <Box className="button-group" marginTop="5px">
                                <Button variant="contained" color="primary" disableElevation
                                    onClick={() => formik.handleSubmit()}>
                                    Lưu
                                </Button>
                                <Button variant="contained" color="secondary" disableElevation
                                    onClick={handleResetForm}>
                                    Xóa
                                </Button>
                                <Button variant="contained" color="default" disableElevation
                                    onClick={onDialogClose}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}