import { loadModules } from "esri-loader";
import React, { useEffect, useState } from "react";
import locatorImg from "assets/images/locator_1.png";
export interface LocatorARCMAPProps {
    view: any;
    setStringDiaChi: (value: String) => void;
    layer?: any;
}

const LocatorARCMAP: React.FC<LocatorARCMAPProps> = ({ view, setStringDiaChi, layer }) => {
    const [locator, setLocator] = useState<any>(null);

    useEffect(() => {
        loadModules(["esri/tasks/Locator"])
            .then(([Locator]) => {
                const locatorTask = new Locator({
                    url: "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                });
                setLocator(locatorTask);
            })

            .catch((err) => console.error(err));

        return function cleanup() {};
    }, []);

    useEffect(() => {
        loadModules(["esri/Graphic"]).then(([Graphic]) => {
            view.on("click", function (evt: { mapPoint: { longitude: number; latitude: number } }) {
                const locationGraphic = createGraphic(evt.mapPoint);
                view.graphics.add(locationGraphic, 0);
                clickPoint(evt);
            });
            function createGraphic(point: any) {
                view.graphics.removeAll();
                let symbol = {
                    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
                    url: locatorImg,
                    width: "24px",
                    height: "35px",
                };
                const graphic = new Graphic({
                    geometry: point,
                    symbol: symbol,
                });

                view.graphics.add(graphic);
                return graphic;
            }
        });
    }, [locator]);

    function clickPoint(evt: { mapPoint: { longitude: number; latitude: number } }) {
        const params = {
            location: evt.mapPoint,
        };

        if (locator) {
            locator.locationToAddress(params).then(
                function (response: { address: any }) {
                    // Show the address found
                    const address = response.address;
                    showAddress(address, evt.mapPoint);
                    setStringDiaChi(address);
                },
                function (err: any) {
                    // Show no address found
                    showAddress("Địa chỉ không rõ", evt.mapPoint);
                    setStringDiaChi("Địa chỉ không rõ");
                }
            );
        }
    }

    function showAddress(address: string, pt: { longitude: number; latitude: number }) {
        if (view) {
            view.popup.open({
                title: +Math.round(pt.longitude * 100000) / 100000 + ", " + Math.round(pt.latitude * 100000) / 100000,
                content: address,
                location: pt,
            });
        }
    }

    return null;
};

export default LocatorARCMAP;
