import { ChungChiHanhNgheThuYDTO } from "./../model/thuY/chungChi/index";
import { DanhSachTraCuuDTO } from "model/thuySan/tauCa/danhSachTraCuuDTO";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoaiDangKyEnum, LoaiDongVatEnum } from "model/thuY/anToanDichBenh";

export function useGetSoGiayPhepThuySan() {
    let location = useLocation<{ item: DanhSachTraCuuDTO }>();
    const [item, setItem] = useState<DanhSachTraCuuDTO | null>(null);
    useEffect(() => {
        if (location.state) {
            setItem(location.state.item);
        }
    }, []);
    return { item };
}

export function useGetSoGiayPhepThuyY() {
    let location = useLocation<{ item: ChungChiHanhNgheThuYDTO }>();
    const [item, setItem] = useState<ChungChiHanhNgheThuYDTO | null>(null);
    useEffect(() => {
        if (location.state) {
            setItem(location.state.item);
        }
    }, []);
    return { item };
}

export function useGetReferItem<T>() {
    let location = useLocation<{ item: T }>();
    const [item, setItem] = useState<T | null>(null);
    useEffect(() => {
        if (location.state) {
            setItem(location.state.item);
        }
    }, []);
    return { item };
}
export function useGetLoaiDangKy() {
    let location = useLocation<{ loaiDangKy: LoaiDangKyEnum; loaiDongVat: LoaiDongVatEnum }>();
    const [loaiDangKy, setLoaiDangKy] = useState<LoaiDangKyEnum>(LoaiDangKyEnum.CAP_LAN_DAU);
    const [loaiDongVat, setLoaiDongVat] = useState<LoaiDongVatEnum>(LoaiDongVatEnum.TREN_CAN);

    useEffect(() => {
        if (location.state) {
            setLoaiDangKy(location.state.loaiDangKy);
            setLoaiDongVat(location.state.loaiDongVat);
        }
    }, []);
    return { loaiDangKy, loaiDongVat };
}
