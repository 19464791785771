import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "../diaChiDTO";
import { TinhTrangEnum } from "model/baseModel";
import { LinhVuc, LoaiMatHangKinhDoanh } from "./chungNhanDuDieuKienAttpEnum";

export interface DanhSachTraCuuDTO {
    id: number | null;
    soBienNhan?: string;
    soGcn?: string;
    ngayCapGcn?: null | Date;
    ngayHetHanGcn?: null | Date;
    tenCoSo?: string;
    fullDiaChi?: string;
    tinhTrang?: TinhTrangEnum;
    tinhTrangStr?: string;
    soDienThoai?: string;
    matHangSxkd?: string;

}

export const DanhSachTraCuuDTO_INT: DanhSachTraCuuDTO = {
    id: null,
    soBienNhan: "",
    soGcn: "",
    ngayCapGcn: null,
    ngayHetHanGcn: null,
    tenCoSo: "",
    fullDiaChi: "",
    tinhTrang: TinhTrangEnum.CAP_LAN_DAU,
    tinhTrangStr: ""
}

export interface TraCuuRequestDTO {
    soBienNhan?: string;
    soGcn?: string;
    tenCoSo?: string;
    diaChi?: DiaChiDTO | null;
    tinhTrang?: TinhTrangEnum;
    loaiMatHangDkkd?: LoaiMatHangKinhDoanh;
    linhVuc?: LinhVuc;

    ngayCapGcnTu?: null | Date;
    ngayCapGcnDen?: null | Date;
    year?: number;
    month?: string;
    mode?: string;
}

export const TraCuuRequestDTO_INT: TraCuuRequestDTO = {
    soBienNhan: "",
    soGcn: "",
    tenCoSo: "",
    diaChi: { ...DiaChiDTO_INT_CAN_THO },
    tinhTrang: TinhTrangEnum.NONE,

    ngayCapGcnTu: null,
    ngayCapGcnDen: null,
    year: undefined,
    month: undefined,
    mode: "YEAR",
}
// export const TraCuuChungNhanATTP_NongLamThuySan_INT: TraCuuRequestDTO = {
//     soBienNhan: "",
//     soGcn: "",
//     tenCoSo: "",
//     diaChi: { ...DiaChiDTO_INT_CAN_THO },
//     tinhTrang: TinhTrangEnum.NONE,
//     linhVuc: LinhVuc.NONG_LAM_THUY_SAN,
//     ngayCapGcnTu: null,
//     ngayCapGcnDen: null,
//     year: undefined,
//     month: undefined,
//     mode: "YEAR",
// }