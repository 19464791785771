import StringUtil from "utils/stringUtils";


export function getString(key: string) : string {
    let result : string = "";
    let valueAny : any = localStorage.getItem(key);
    if(!StringUtil.isNullOrEmty(valueAny)){
        result = valueAny as string;
    }
    return result;
}
export function getObject(key: string) : any {
    let result : any = {};
    let valueAny : any = localStorage.getItem(key);
    if(valueAny != null){
        result = valueAny as any;
    }
    return result;
}
