import apis from "apis";
import { BaseService } from "apis/baseService";

export interface DashboardNongLamThuySanDTO {
    thang: number;
    tenThang: string;
    caNhan: number;
    toChuc: number;
    coSoDuDieuKien: number;
}
export class DashboardNongLamThuySanService extends BaseService {
    loadDataDashboard = (nam: number) => {
        return apis.post("qlcl-nong-lam-thuy-san/dashboard/load-data", { nam: nam });
    };

}
