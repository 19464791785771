
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParamExport } from "apis/baseService";
import { downLoadFile } from "apis/Common/exportUtil";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { getIn, useFormik } from "formik";
import { TinhTrangEnum } from "model/baseModel";
import { getLinhVuc, getLoaiMatHangKinhDoanhEnum, LinhVuc, LoaiMatHangKinhDoanh } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { DanhSachTraCuuDTO, TraCuuRequestDTO_INT, TraCuuRequestDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/traCuuChungNhanDuDieuKienAttpDTO";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";


export const DanhSachChungNhanDuDieuKienAttpThuySan = () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapGcnTu: Yup.date()
                .nullable()
                .when("ngayCapGcnDen", (ngayCapGcnDen: any, schema: any) => {
                    if (ngayCapGcnDen) {
                        return schema.max(ngayCapGcnDen, "Không hợp lệ");
                    }
                    return schema;
                }),
            ngayCapGcnDen: Yup.date()
                .nullable()
                .when("ngayCapGcnTu", (ngayCapGcnTu: any, schema: any) => {
                    if (ngayCapGcnTu) {
                        return schema.min(ngayCapGcnTu, "Không hợp lệ");
                    }
                    return schema;
                }),
        },
        [["ngayCapGcnTu", "ngayCapGcnDen"]]
    );

    const columns: ColumnCustomTable<DanhSachTraCuuDTO>[] = [
        {
            title: "Số giấy chứng nhận", 
            field: "soGcn", 
            type: TypeCell.LINK,
            actions: [{ title: "/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp/chi-tiet" }],
        },
        { title: "Ngày cấp", field: "ngayCapGcn", type: TypeCell.DATE },
        { title: "Ngày hết hạn", field: "ngayHetHanGcn", type: TypeCell.DATE },
        { title: "Tên cơ sở", field: "tenCoSo", width: "20%", align: "left" },
        { title: "Địa chỉ", field: "fullDiaChi", width: "25%", align: "left" },
        { title: "Số điện thoại", field: "soDienThoai", align: "left" },
        { title: "Tình trạng", field: "tinhTrangStr" },
        {
            title: "Thao tác",
            actions: [
                {
                    title: "Chỉnh sửa" ,
                    icon: <EditIcon fontSize="small" color="primary" />,
                    func: (data: DanhSachTraCuuDTO) => {
                        if (data.soBienNhan) {
                            history.push({
                                pathname: "/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp/cap-moi",
                                state: { maSoHoSo: data.soBienNhan, item: data }
                            });
                        } else {
                            history.push({
                                pathname: "/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp/nhap-dau-ky",
                                state: { item: data }
                            });
                        }
                    }
                },
                {
                    title: "Thu hồi",
                    icon: <CloseIcon fontSize="small" color="secondary" />,
                    func: (data: DanhSachTraCuuDTO) => history.push({
                        pathname: "/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp/thu-hoi",
                        state: { item: data }
                    })
                },
            ],
            type: TypeCell.ACTION,
            width: "5%",
        },
    ];

    //state
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);
    const [rows, setRows] = useState<DanhSachTraCuuDTO[]>([]);

    // hook
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();


    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT, linhVuc: LinhVuc.THUY_SAN };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
        validateOnChange: false,
    });

    useEffect(() => {
        async function fetchData() {
            await new ChungNhanDuDieuKienAttpService().getDanhSachChungNhan(formik.values, pageParams.page, pageParams.rowsPerPage).then((response) => {
                setRows(response.rows);
                setPageParams((prev) => ({ ...prev, total: response.total }));
            });
        }
        fetchData();
    }, [pageParams.page, pageParams.rowsPerPage]);
    // Control
    async function handleSubmit(request: TraCuuRequestDTO) {
        setPageParams((prev) => ({ ...prev, page: 0 }));
        await new ChungNhanDuDieuKienAttpService().getDanhSachChungNhan(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
            setRows(response.rows);
            setPageParams((prev) => ({ ...prev, total: response.total }));
        });
    }

    function handleOnChangePage(value: number) {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    }

    function handOnChangeRowsPerPage(value: number) {
        setPageParams((pageParams) => ({
            ...pageParams,
            page: 0,
            rowsPerPage: value,
        }));
    }

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        // let data = { ...formik.values };
        // if (formik.values.mode === "YEAR") {
        //     data.ngayCapGcnTu = moment([Number(data.year)])
        //         .startOf("year")
        //         .toDate();
        //     data.ngayCapGcnDen = moment([Number(data.year)])
        //         .endOf("year")
        //         .toDate();
        // } else {
        //     if (formik.values.mode === "MONTH") {
        //         let mm = moment()
        //             .year(Number(data.year))
        //             .month(Number(data.month) - 1);
        //         data.ngayCapGcnTu = mm.startOf("month").toDate();
        //         data.ngayCapGcnDen = mm.endOf("month").toDate();
        //         data.month = String(Number(data.month));
        //     }
        //     data.year = undefined;
        // }
        // console.log('data', data);
        let newParams: ParamExport = { pageNumber: pageParams.page, pageSize: pageParams.rowsPerPage, downloadType: downloadType };
        await new ChungNhanDuDieuKienAttpService().traCuuExport( { ...formik.values }, newParams).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Danh-sach-chung-nhan-du-dieu-kien-attp-]${new Date()}`);
        });
    }
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'DANH SÁCH GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM'}</div>
                <Grid container>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số giấy chứng nhận"}
                            name="soGcn"
                            value={formik.values.soGcn}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapGcnTu"
                            label={"Ngày cấp từ"}
                            value={formik.values.ngayCapGcnTu}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapGcnTu", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapGcnTu") && getIn(formik.errors, "ngayCapGcnTu")) && formik.errors?.ngayCapGcnTu}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapGcnDen"
                            label={"Ngày cấp đến"}
                            value={formik.values.ngayCapGcnDen}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapGcnDen", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapGcnDen") && getIn(formik.errors, "ngayCapGcnDen")) && formik.errors?.ngayCapGcnDen}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                        <NativeSelect input={<CustomInput />} fullWidth name="tinhTrang" value={formik.values?.tinhTrang} onChange={formik.handleChange} >
                            <option value={TinhTrangEnum.NONE}>--Chọn--</option>
                            <option value={TinhTrangEnum.CAP_LAN_DAU}>Cấp lần đầu</option>
                            <option value={TinhTrangEnum.CAP_LAI}>Cấp lại</option>
                            <option value={TinhTrangEnum.THU_HOI}>Thu hồi</option>
                        </NativeSelect>
                    </Grid>
                    <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true}/>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Tên cơ sở"}
                            name="tenCoSo"
                            value={formik.values.tenCoSo}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Loại mặt hàng đăng ký kinh doanh</InputLabel>
                        <NativeSelect input={<CustomInput />} fullWidth name="loaiMatHangDkkd" value={formik.values?.loaiMatHangDkkd} onChange={formik.handleChange} >
                            <option value={LoaiMatHangKinhDoanh.NONE}>--Chọn--</option>
                            <option value={LoaiMatHangKinhDoanh.NONG_SAN}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.NONG_SAN)}</option>
                            <option value={LoaiMatHangKinhDoanh.THUY_SAN}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.THUY_SAN)}</option>
                            <option value={LoaiMatHangKinhDoanh.CHUYEN_DOANH}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.CHUYEN_DOANH)}</option>
                            <option value={LoaiMatHangKinhDoanh.DONG_VAT}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.DONG_VAT)}</option>
                            <option value={LoaiMatHangKinhDoanh.TRONG_TROT}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.TRONG_TROT)}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} >Lĩnh vực</InputLabel>
                        <NativeSelect disabled = {true} input={<CustomInput />} fullWidth name="linhVuc" value={formik.values?.linhVuc} onChange={formik.handleChange} >
                            <option value={LinhVuc.NONE}>Chọn lĩnh vực</option>
                            <option value={LinhVuc.NONG_LAM_THUY_SAN}>{getLinhVuc(LinhVuc.NONG_LAM_THUY_SAN)}</option>
                            <option value={LinhVuc.THUY_SAN}>{getLinhVuc(LinhVuc.THUY_SAN)}</option>
                            <option value={LinhVuc.TRONG_TROT_CHAN_NUOI}>{getLinhVuc(LinhVuc.TRONG_TROT_CHAN_NUOI)}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tìm kiếm
                            </Button>
                            <Button variant="contained" color="primary" disableElevation
                                onClick={() => history.push("/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp/nhap-dau-ky")}>
                                Nhập đầu kỳ
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handOnChangeRowsPerPage}
                            pageParams={pageParams}
                        />
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>
    )
}