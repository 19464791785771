import { FileEntry } from 'apis/Common/fileEntry';
import { SearchRequest } from 'apis/Common/model/CommonModel';
import { BaseService, PageRequest, Pagination } from '../baseService';
import AXIOS_INSTANCE from '../index';

export interface HoSoCuaToiRequest extends PageRequest {
    maCoQuan: string;
    tenCoQuan: string;
    ngayNhanTu: number;
    ngayNhanDen: number;
    ngayHenTraTu: number;
    ngayHenTraDen: number;
    maLinhVuc: string;
    tenLinhVuc: string;
    maBuocXuLy: string;
    maThuTuc: string;
    tenThuTuc: string;
    maHoSo: string;
    nguoiNop: string;
    maNguoiXuLy: string;
}

export interface HoSoCuaToiDTO {
    maLinhVuc: string;
    trangThai: number;
    maBuocXuLy: string;
    maThuTuc: string;
    ngayHenTra: string;
    loaiHoSo: number;
    tenTrangThai: string;
    tenHoSo: string;
    nguoiNop: string;
    maSoHoSo: string;
    ngayNhan: string;
    bussinessPath?: string;
}

export interface ThongTinBuocDTO {
    soThuTu: number;
    nguoiNhan: NguoiNhanDTO[];
    maBuocXuLy: string;
    tenBuocXuLy: string;
    idBuocXuLy: number;
    hanXuLyTheoGio : number;
}
export interface ChuyenXuLyRequest {
    maHoSo: string;
    idBuocXuLyTiepTheo: number;
    maNguoiGui: string;
    nguoiNhanId: number;
    tenNguoiNhan: string;
    noiDung: string;
    trichYeuDuThao: string;
    idLoaiVanBan: number;
    hanXuLy: number;
    danhSachFiles: TepTinChuyenXuLy[];
}
export interface NguoiNhanDTO {
    id: number;
    tenNguoiNhan: string;
}
export interface NguoiNhanDTO {
    id: number;
    tenNguoiNhan: string;
}
export interface TepTinChuyenXuLy {
    linkFile: string;
    tenFile: string;
    kieuFile: string;
}
export interface HoSoYeuCauBoSungDTO extends SearchRequest {
    id: number;

    maHoSo: string;

    modifyDate: number;

    createDate: number;

    maNguoiYeuCau: string;

    tenNguoiYeuCau: string;

    noiDung: string;

    daBoSung: boolean;

    ngayBoSung: number;

    files: FileEntry[];
}
export const HoSoYeuCauBoSungDTO_INIT: HoSoYeuCauBoSungDTO = {
    id: 0,

    maHoSo: '',

    modifyDate: 0,

    createDate: 0,

    maNguoiYeuCau: '',

    tenNguoiYeuCau: '',

    noiDung: '',

    daBoSung: false,

    ngayBoSung: 0,

    files: [],

    pageSize: 1,

    pageNumber: 5,

    keywords: '',
    
    hoatDong: 0
}

export interface HoSoKhongGiaiQuyetDTO extends SearchRequest {
    id: number;

    maHoSo: string;

    modifyDate: number;

    createDate: number;

    maNguoiYeuCau: string;

    tenNguoiYeuCau: string;

    noiDung: string;

    files: FileEntry[];
}
export const HoSoKhongGiaiQuyetDTO_INIT: HoSoKhongGiaiQuyetDTO = {
    id: 0,

    maHoSo: '',

    modifyDate: 0,

    createDate: 0,

    maNguoiYeuCau: '',

    tenNguoiYeuCau: '',

    noiDung: '',

    files: [],

    pageSize: 1,

    pageNumber: 5,

    keywords: '',
    
    hoatDong: 0
}

export interface VanBanDuThaoDTO extends SearchRequest {
    id: number;

    maHoSo: string;

    modifyDate: number;

    createDate: number;

    maNguoiGui: string;

    tenNguoiYeuCau: string;

    noiDung: string;

    loaiVanBan : number;

    tenLoaiVanBan : string;

    files: FileEntry[];
}
export const VanBanDuThaoDTO_INIT: VanBanDuThaoDTO = {
    id: 0,

    maHoSo: '',

    modifyDate: 0,

    createDate: 0,

    maNguoiGui: '',

    tenNguoiYeuCau: '',

    noiDung: '',

    loaiVanBan : 0,

    tenLoaiVanBan : '',

    files: [],

    pageSize: 1,

    pageNumber: 5,

    keywords: '',
    
    hoatDong: 0
}



export class HoSoCuaToiMgtService extends BaseService {

    timKiem = async (request: HoSoCuaToiRequest): Promise<Pagination<HoSoCuaToiDTO[]>> => {
        let result: Pagination<HoSoCuaToiDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await AXIOS_INSTANCE.post('/mot-cua/danh-sach-ho-so', request, this.getTokenRequestHeaders()).then(response => {
            if (response &&  response.data && response.data.data && response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }
    getThongTinXuLy = async (maHoSo: string): Promise<ThongTinBuocDTO[]> => {
        let result: ThongTinBuocDTO[] = [];
        await AXIOS_INSTANCE.post('/mot-cua/thong-tin-chuyen-xu-ly', { maHoSo: maHoSo }, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data) {
                result = [
                    ...response.data.data
                ]
            }
        }).catch();
        return result;
    }
    chuyenXuLy = (request: ChuyenXuLyRequest) => {
        return AXIOS_INSTANCE.post('/mot-cua/chuyen-xu-ly', request, this.getTokenRequestHeaders());
    }
    addOrUpdateBoSungHoSo = (request: HoSoYeuCauBoSungDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/add-or-update-ho-so-bo-sung', request, this.getTokenRequestHeaders());
    }
    danhSachHoSoYeuCauBoSung = (request: HoSoYeuCauBoSungDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/danh-sach-ho-so-yeu-cau-bo-sung', request, this.getTokenRequestHeaders());
    }

    addOrUpdateKhongGiaiQuyet = (request: HoSoKhongGiaiQuyetDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/add-or-update-ho-so-khong-giai-quyet', request, this.getTokenRequestHeaders());
    }
    danhSachHoSoKhongGiaiQuyet = (request: HoSoKhongGiaiQuyetDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/danh-sach-ho-so-khong-giai-quyet', request, this.getTokenRequestHeaders());
    }

    addOrUpdateVanBanDuThao = (request: VanBanDuThaoDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/add-or-update-van-ban-du-thao', request, this.getTokenRequestHeaders());
    }
    danhSachVanBanDuThao = (request: VanBanDuThaoDTO) => {
        return AXIOS_INSTANCE.post('/mot-cua/danh-sach-van-ban-du-thao', request, this.getTokenRequestHeaders());
    }
    getThongTinLuanChuyen = (maHoSo: string) => {
        return AXIOS_INSTANCE.post('/mot-cua/get-thong-tin-luan-chuyen', { maHoSo: maHoSo }, this.getTokenRequestHeaders());
    }
    getThanhPhanHoSo = (maHoSo: string) => {
        return AXIOS_INSTANCE.post('/mot-cua/get-thanh-phan-ho-so', { maHoSo: maHoSo }, this.getTokenRequestHeaders());
    }
    chiTiet = (maHoSo: string) => {
        return AXIOS_INSTANCE.post('/mot-cua/chi-tiet', { maHoSo: maHoSo }, this.getTokenRequestHeaders());
    }
    downloadFileThanhPhan = (tepTinChuyenXuLy: TepTinChuyenXuLy) => {
        return AXIOS_INSTANCE.post('/mot-cua/download', tepTinChuyenXuLy, { responseType: 'blob' });
    }

}


