import { LoaiDongVatEnum } from "model/thuY/anToanDichBenh";

import { lazy } from "react";
import { RoutePropsI } from "routes";

//Chứng chỉ hành nghề
const BieuDoThongKe = lazy(() => import("pages/thuY/chungChiHanhNgheThuY/bieuDo/index"));
const ThongKeChungChinhHangNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/thongKe/thongKeChungChiHanhNgheThuY").then(({ ThongKeChungChinhHangNgheThuY }) => ({
        default: ThongKeChungChinhHangNgheThuY,
    }))
);
const BaoCaoChungChinhHangNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/baoCao/baoCaoChungChiHanhNgheThuY").then(({ BaoCaoChungChinhHangNgheThuY }) => ({
        default: BaoCaoChungChinhHangNgheThuY,
    }))
);
const DanhSachChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/giayPhep/danhSachChungChiHangNgheThuY").then(({ DanhSachChungChiHanhNgheThuY }) => ({
        default: DanhSachChungChiHanhNgheThuY,
    }))
);
const ThongTinChiTietChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/giayPhep/chiTiet/viewChungChiHanhNgheThuY").then(({ ThongTinChiTietChungChiHanhNgheThuY }) => ({
        default: ThongTinChiTietChungChiHanhNgheThuY,
    }))
);
const NhapDauKyHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/giayPhep/nhapDauKy/nhapDauKyChungChiHanhNgheThuY").then(({ NhapDauKyHanhNgheThuY }) => ({
        default: NhapDauKyHanhNgheThuY,
    }))
);
const ThuHoiChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/giayPhep/thuHoi/thuHoiChungChiHanhNgheThuY").then(({ ThuHoiChungChiHanhNgheThuY }) => ({
        default: ThuHoiChungChiHanhNgheThuY,
    }))
);
const GiaHanChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/giaHan/giaHanChungChiHanhNgheThuY").then(({ GiaHanChungChiHanhNgheThuY }) => ({
        default: GiaHanChungChiHanhNgheThuY,
    }))
);
const CapLaiChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/capLai/capLaiChungChiHanhNgheThuY").then(({ CapLaiChungChiHanhNgheThuY }) => ({
        default: CapLaiChungChiHanhNgheThuY,
    }))
);
const CapChungChiHanhNgheThuY = lazy(() =>
    import("pages/thuY/chungChiHanhNgheThuY/capMoi/capChungChiHanhNgheThuY").then(({ CapChungChiHanhNgheThuY }) => ({
        default: CapChungChiHanhNgheThuY,
    }))
);
//Quảng cáo
const CapMoiQCThuY = lazy(() => import("pages/thuY/quangCao/capMoiGCN/CapMoiQCThuY").then(({ CapMoiQCThuY }) => ({ default: CapMoiQCThuY })));
const DanhSachQCThuocThuY = lazy(() =>
    import("pages/thuY/quangCao/traCuu/DanhSachQCThuocThuY").then(({ DanhSachQCThuocThuY }) => ({ default: DanhSachQCThuocThuY }))
);
const ChiTietQuangCaoThuocThuY = lazy(() =>
    import("pages/thuY/quangCao/traCuu/ChiTietQuangCaoThuocThuY").then(({ ChiTietQuangCaoThuocThuY }) => ({
        default: ChiTietQuangCaoThuocThuY,
    }))
);
const BaoCaoQCThuY = lazy(() => import("pages/thuY/quangCao/baoCao/BaoCaoQCThuY").then(({ BaoCaoQCThuY }) => ({ default: BaoCaoQCThuY })));
const ThongKeQCThuY = lazy(() => import("pages/thuY/quangCao/thongKe/ThongKeQCThuY").then(({ ThongKeQCThuY }) => ({ default: ThongKeQCThuY })));
//Buôn bán thuốc thú y
const CapMoiBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/capMoi"));
const CapLaiBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/capLai"));
const NhapDauKyBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/nhapDauKy"));
const ThuHoiBuonBanThuocThuY = lazy(() =>
    import("pages/thuY/BuonBanThuoc/thuHoiBuonBanThuoc").then(({ ThuHoiBuonBanThuocThuY }) => ({
        default: ThuHoiBuonBanThuocThuY,
    }))
);
const DSBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc"));
const BaoCaoBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/baoCao"));
const ThongKeBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/thongKe"));
const BieuDoThongKeBuonBanThuocThuY = lazy(() => import("pages/thuY/BuonBanThuoc/bieuDo"));
const ViewBuonBanThuoc = lazy(() => import("pages/thuY/BuonBanThuoc/viewBuonBanThuoc"));
// const ChiTietQuangCaoThuocThuY = lazy(() =>
//     import("pages/thuY/quangCao/traCuu/ChiTietQuangCaoThuocThuY").then(({ ChiTietQuangCaoThuocThuY }) => ({
//         default: ChiTietQuangCaoThuocThuY,
//     }))
// );
// const BaoCaoQCThuY = lazy(() => import("pages/thuY/quangCao/baoCao/BaoCaoQCThuY").then(({ BaoCaoQCThuY }) => ({ default: BaoCaoQCThuY })));
// const ThongKeQCThuY = lazy(() => import("pages/thuY/quangCao/thongKe/ThongKeQCThuY").then(({ ThongKeQCThuY }) => ({ default: ThongKeQCThuY })));
//Vệ sinh thú y
const DanhSachGiayChungNhanDuDKVS = lazy(() =>
    import("pages/thuY/veSinh/danhSachTraCuu/danhSachGiayChungNhan").then(({ DanhSachGiayChungNhanDuDKVS }) => ({
        default: DanhSachGiayChungNhanDuDKVS,
    }))
);
const BaoCaoGiayChungNhanDuDKVS = lazy(() =>
    import("pages/thuY/veSinh/danhSachTraCuu/baoCaoGiayChungNhan").then(({ BaoCaoGiayChungNhanDuDKVS }) => ({
        default: BaoCaoGiayChungNhanDuDKVS,
    }))
);
const NhapDauKy = lazy(() => import("pages/thuY/veSinh/danhSachTraCuu/nhapDauKy/index").then(({ NhapDauKy }) => ({ default: NhapDauKy })));
const ThuHoiGiayChungNhan = lazy(() =>
    import("pages/thuY/veSinh/danhSachTraCuu/thuHoi/index").then(({ ThuHoiGiayChungNhan }) => ({ default: ThuHoiGiayChungNhan }))
);
const CapMoiGCN = lazy(() => import("pages/thuY/veSinh/capMoiGCN/index").then(({ CapMoiGCN }) => ({ default: CapMoiGCN })));
const CapLaiGCN = lazy(() => import("pages/thuY/veSinh/capLaiGCN/index").then(({ CapLaiGCN }) => ({ default: CapLaiGCN })));
const ViewGiayChungNhan = lazy(() => import("pages/thuY/veSinh/chiTiet/index").then(({ ViewGiayChungNhan }) => ({ default: ViewGiayChungNhan })));
const ThongKeXacNhanVeSinhThuY = lazy(() => import("pages/thuY/veSinh/thongKe"));
const BieuDoThongKeVeSinhThuY = lazy(() => import("pages/thuY/veSinh/bieuDo"));
const BieuDoThongKeQuangCaoThuocThuY = lazy(() => import("pages/thuY/quangCao/bieuDo"));

const NhapDauKyAnToanDichBenhTrenCan = lazy(() => import("pages/thuY/anToanDichBenh/nhapDauKy/trenCan"));
const NhapDauKyAnToanDichBenhThuySan = lazy(() => import("pages/thuY/anToanDichBenh/nhapDauKy/thuySan"));
const DSAnToanDichBenh = lazy(() => import("pages/thuY/anToanDichBenh"));
const CapMoiBaoVeDongVatThuySan = lazy(() => import("pages/thuY/anToanDichBenh/capMoiThuySan"));
const CapMoiBaoVeDongVatTrenCan = lazy(() => import("pages/thuY/anToanDichBenh/capMoiTrenCan"));
const CapLaiAnToanDichBenhDongVat = lazy(() => import("pages/thuY/anToanDichBenh/capLai"));
const CapBoSungAnToanDichBenhDongVat = lazy(() => import("pages/thuY/anToanDichBenh/capBoSung"));
const CapDanhGiaLaiAnToanThuY = lazy(() => import("pages/thuY/anToanDichBenh/capDanhGiaLai"));
const BaoCaoAnToanDichBenh = lazy(() => import("pages/thuY/anToanDichBenh/baoCao"));
const ViewAnToanDichBenh = lazy(() => import("pages/thuY/anToanDichBenh/viewAnToanDichBenh"));
const CapDoiAnToanDB = lazy(() => import("pages/thuY/anToanDichBenh/capDoi"));
const CapLaiCaHai = lazy(() => import("pages/thuY/anToanDichBenh/capLaiCaHai"));
const ThongKeAnToanDichBenh = lazy(() => import("pages/thuY/anToanDichBenh/thongKe"));
const BieuDoAnToanDichBenh = lazy(() => import("pages/thuY/anToanDichBenh/bieuDo"));

const ROOT = "/thu-y";
const ROUTE_PAGE_CHUNG_CHI_HANH_NGHE: RoutePropsI[] = [
    {
        ten: "Danh sách chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/danh-sach-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <DanhSachChungChiHanhNgheThuY />,
    },
    {
        ten: "Báo cáo chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/bao-cao-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <BaoCaoChungChinhHangNgheThuY />,
    },

    {
        ten: "Thống kê chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/thong-ke-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <ThongKeChungChinhHangNgheThuY />,
    },
    {
        ten: "Biểu đồ chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/bieu-do-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <BieuDoThongKe />,
    },
];
const ROUTE_CN_CHUNG_CHI_HANH_NGHE: RoutePropsI[] = [
    {
        ten: "Cấp chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/cap-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <CapChungChiHanhNgheThuY />,
    },
    {
        ten: "Cấp lại chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/cap-lai-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <CapLaiChungChiHanhNgheThuY />,
    },
    {
        ten: "Gia hạn chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/gia-han-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <GiaHanChungChiHanhNgheThuY />,
    },
    {
        ten: "Nhập đầu kỳ chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/nhap-dau-ky-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <NhapDauKyHanhNgheThuY />,
    },
    {
        ten: "Thông tin chi tiết chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/view-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <ThongTinChiTietChungChiHanhNgheThuY />,
    },
    {
        ten: "Thu hồi chứng chỉ hành nghề thú y",
        duongDan: `${ROOT}/thu-hoi-chung-chi-hanh-nghe-thu-y`,
        giaoDien: <ThuHoiChungChiHanhNgheThuY />,
    },
];

const ROUTE_PAGE_QC_THU_Y: RoutePropsI[] = [
    {
        ten: "Danh sách giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/danh-sach-qc-thuoc-thu-y`,
        giaoDien: <DanhSachQCThuocThuY />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/bao-cao`,
        giaoDien: <BaoCaoQCThuY />,
    },
    {
        ten: "Thống kê tình hình cấp giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/thong-ke`,
        giaoDien: <ThongKeQCThuY />,
    },
    {
        ten: "Biểu đồ thống kê tình hình cấp giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/bieu-do-giay-chung-nhan`,
        giaoDien: <BieuDoThongKeQuangCaoThuocThuY />,
    },
];

const ROUTE_CN_QC_THU_Y: RoutePropsI[] = [
    {
        ten: "Cấp giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/cap-moi`,
        giaoDien: <CapMoiQCThuY />,
    },
    // {
    //     ten: "Nhập đầu kỳ chứng nhận đủ điều kiện vệ sinh thú y",
    //     duongDan: `${ROOT}/ve-sinh/nhap-du-lieu-dau-ky`,
    //     giaoDien: NhapDauKy,
    // },
    {
        ten: "Thông tin chi tiết giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/quang-cao/chi-tiet-giay-xac-nhan`,
        giaoDien: <ChiTietQuangCaoThuocThuY />,
    },
];

const ROUTE_CN_BUON_BAN_THUOC_THU_Y: RoutePropsI[] = [
    {
        ten: "Cấp giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/cap-moi`,
        giaoDien: <CapMoiBuonBanThuocThuY />,
    },
    {
        ten: "Cấp lại giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/cap-lai`,
        giaoDien: <CapLaiBuonBanThuocThuY />,
    },
   
    {
        ten: "Nhập đầu kỳ giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/nhap-dau-ky`,
        giaoDien: <NhapDauKyBuonBanThuocThuY/>,
    },
    {
        ten: "Thông tin chi tiết giấy xác nhận nội dung quảng cáo thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/chi-tiet-giay-chung-nhan`,
        giaoDien: <ViewBuonBanThuoc />,
    },
    {
        ten: "Thu hồi giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/thu-hoi-giay-chung-nhan`,
        giaoDien: <ThuHoiBuonBanThuocThuY />,
    },
];


const ROUTE_PAGE_BUON_BAN_THUOC_THU_Y: RoutePropsI[] = [
    {
        ten: "Tra cứu giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/danh-sach`,
        giaoDien: <DSBuonBanThuocThuY />,
    },
    {
        ten: "Báo cáo danh sách giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/bao-cao`,
        giaoDien: <BaoCaoBuonBanThuocThuY />,
    },
    {
        ten: "Thống kê tình hình cấp giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/thong-ke`,
        giaoDien: <ThongKeBuonBanThuocThuY />,
    },
    {
        ten: "Biểu đồ thống kê tình hình cấp giấy chứng nhận đủ điều kiện buôn bán thuốc thú y",
        duongDan: `${ROOT}/buon-ban-thuoc/bieu-do-giay-chung-nhan`,
        giaoDien: <BieuDoThongKeBuonBanThuocThuY />,
    },
];

const ROUTE_PAGE_VE_SINH_THU_Y: RoutePropsI[] = [
    {
        ten: "Danh sách giấy chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/danh-sach-gcn-du-dieu-kien-vs`,
        giaoDien: <DanhSachGiayChungNhanDuDKVS />,
    },
    {
        ten: "Báo cáo tình hình cấp chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/bao-cao-gcn-du-dieu-kien-vs`,
        giaoDien: <BaoCaoGiayChungNhanDuDKVS />,
    },
    {
        ten: "Thống kê giấy chứng nhận đủ đIều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/thong-ke-giay-chung-nhan`,
        giaoDien: <ThongKeXacNhanVeSinhThuY />,
    },
    {
        ten: "Biểu đồ giấy chứng nhận đủ đIều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/bieu-do-giay-chung-nhan`,
        giaoDien: <BieuDoThongKeVeSinhThuY />,
    },
];
const ROUTE_CN_VE_SINH_THU_Y: RoutePropsI[] = [
    {
        ten: "Cấp chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/cap-moi-gcn-du-dieu-kien-vs`,
        giaoDien: <CapMoiGCN />,
    },
    {
        ten: "Cấp lại chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/cap-lai-gcn-du-dieu-kien-vs`,
        giaoDien: <CapLaiGCN />,
    },
    {
        ten: "Nhập đầu kỳ chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/nhap-du-lieu-dau-ky`,
        giaoDien: <NhapDauKy />,
    },
    {
        ten: "Thông tin chi tiết chứng nhận đủ điều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/chi-tiet-giay-chung-nhan`,
        giaoDien: <ViewGiayChungNhan />,
    },
    {
        ten: "Thu hồi giấy chứng nhận đủ đIều kiện vệ sinh thú y",
        duongDan: `${ROOT}/ve-sinh/thu-hoi-giay-chung-nhan`,
        giaoDien: <ThuHoiGiayChungNhan />,
    },
];

const ROUTE_PAGE_AN_TOAN_DICH_BENH: RoutePropsI[] = [
    {
        ten: "Danh sách an toàn dịch bệnh",
        duongDan: `${ROOT}/an-toan-db/ds-an-toan-dich-benh`,
        giaoDien: <DSAnToanDichBenh />,
    },
    {
        ten: "Báo cáo an toàn dịch bệnh",
        duongDan: `${ROOT}/an-toan-db/bao-cao`,
        giaoDien: <BaoCaoAnToanDichBenh />,
    },
    {
        ten: "Thống kê an toàn dịch bệnh động vật thủy sản",
        duongDan: `${ROOT}/an-toan-db/thong-ke`,
        giaoDien: <ThongKeAnToanDichBenh />,
    },
    {
        ten: "Biều đồ an toàn dịch bệnh động vật thủy sản",
        duongDan: `${ROOT}/an-toan-db/bieu-do`,
        giaoDien: <BieuDoAnToanDichBenh />,
    },
];

const ROUTE_PAGE_CN_AN_TOAN_DICH_BENH: RoutePropsI[] = [
    {
        ten: "Cấp mới an toàn dịch bệnh thủy sản",
        duongDan: `${ROOT}/an-toan-db/cap-moi-thuy-san`,
        giaoDien: <CapMoiBaoVeDongVatThuySan />,
    },
    {
        ten: "Cấp mới an toàn dịch bệnh trên cạn",
        duongDan: `${ROOT}/an-toan-db/cap-moi-tren-can`,
        giaoDien: <CapMoiBaoVeDongVatTrenCan />,
    },
    {
        ten: "Cấp đánh giá lại an toàn dịch bệnh",
        duongDan: `${ROOT}/an-toan-db/cap-danh-gia-lai`,
        giaoDien: <CapDanhGiaLaiAnToanThuY />,
    },
    {
        ten: "Cấp lại chứng nhận cơ sở an toàn dịch bệnh động vật thủy sản",
        duongDan: `${ROOT}/an-toan-db/cap-lai-thuy-san`,
        giaoDien: <CapLaiAnToanDichBenhDongVat loaiDongVat={LoaiDongVatEnum.THUY_SAN} />,
    },
    {
        ten: "Cấp lại chứng nhận cơ sở an toàn dịch bệnh động vật trên cạn",
        duongDan: `${ROOT}/an-toan-db/cap-lai-tren-can`,
        giaoDien: <CapLaiAnToanDichBenhDongVat loaiDongVat={LoaiDongVatEnum.TREN_CAN} />,
    },
    {
        ten: "Cấp bổ sung giấy chứng nhận cơ sở an toàn dịch bệnh động vật thủy sản",
        duongDan: `${ROOT}/an-toan-db/cap-bo-sung-thuy-san`,
        giaoDien: <CapBoSungAnToanDichBenhDongVat loaiDongVat={LoaiDongVatEnum.THUY_SAN} />,
    },
    {
        ten: "Cấp bổ sung giấy chứng nhận cơ sở an toàn dịch bệnh động vật trên cạn",
        duongDan: `${ROOT}/an-toan-db/cap-bo-sung-tren-can`,
        giaoDien: <CapBoSungAnToanDichBenhDongVat loaiDongVat={LoaiDongVatEnum.TREN_CAN} />,
    },
    {
        ten: "Cấp lại chứng nhận cơ sở an toàn dịch bệnh động vật trên cạn và thủy sản",
        duongDan: `${ROOT}/an-toan-db/cap-lai-dong-vat-tren-can-va-thuy-san`,
        giaoDien: <CapLaiCaHai />,
    },
    {
        ten: "Cấp đổi chứng nhận cơ sở an toàn dịch bệnh động vật trên cạn và thủy sản",
        duongDan: `${ROOT}/an-toan-db/cap-doi-dong-vat-tren-can-va-thuy-san`,
        giaoDien: <CapDoiAnToanDB />,
    },
    {
        ten: "Nhập đầu kỳ an toàn dịch bệnh",
        duongDan: `${ROOT}/an-toan-db/nhap-dau-ky-tren-can`,
        giaoDien: <NhapDauKyAnToanDichBenhTrenCan />,
    },
    {
        ten: "Nhập đầu kỳ an toàn dịch bệnh",
        duongDan: `${ROOT}/an-toan-db/nhap-dau-ky-thuy-san`,
        giaoDien: <NhapDauKyAnToanDichBenhThuySan />,
    },
    {
        ten: "Thống tin chi tiết an toàn dịch bệnh động vật thủy sản",
        duongDan: `${ROOT}/an-toan-db/thong-tin-chi-tiet-an-toan-dich-benh`,
        giaoDien: <ViewAnToanDichBenh />,
    },
];

export const ROUTE_PAGE_THU_Y: RoutePropsI[] = [
    ...ROUTE_PAGE_CHUNG_CHI_HANH_NGHE,
    ...ROUTE_PAGE_VE_SINH_THU_Y,
    ...ROUTE_PAGE_QC_THU_Y,
    ...ROUTE_PAGE_AN_TOAN_DICH_BENH,
    ...ROUTE_PAGE_BUON_BAN_THUOC_THU_Y,
];

export const ROUTE_CN_THU_Y: RoutePropsI[] = [
    ...ROUTE_CN_CHUNG_CHI_HANH_NGHE,
    ...ROUTE_CN_VE_SINH_THU_Y,
    ...ROUTE_CN_QC_THU_Y,
    ...ROUTE_PAGE_CN_AN_TOAN_DICH_BENH,
    ...ROUTE_CN_BUON_BAN_THUOC_THU_Y,
];
