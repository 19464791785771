import { Card, CardContent, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { KienThucVeSinhAttpThongKeDTO } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";

export interface ChartKienThucVeSinhAttpProps {
    rows: KienThucVeSinhAttpThongKeDTO[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
    })
);

const ChartKienThucVeSinhAttp: React.FC<ChartKienThucVeSinhAttpProps> = ({ rows }) => {
    const classes = useStyles();

    const giayXacNhan = [...rows.map((item) => Number(Number(item.totalCaNhan?item.totalCaNhan:0) + Number(item.totalToChuc?item.totalToChuc:0)))];

    // const capLai = [...rows.map((item) => Number(item.totalCapLai))];
    // const thuHoi = [...rows.map((item) => Number(item.totalThuHoi))];
    const data = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Số chứng nhận đã cấp",
                backgroundColor: "#51A3A3",
                data: giayXacNhan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
        ],
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    return (
        <Grid item xs={12} className="grid-item">
            <Card classes={classes}>
                <CardContent>
                    <Bar data={data} options={options} />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default memo(ChartKienThucVeSinhAttp);
