export interface Pagination<T extends Iterable<any>> {
    page: number;
    size: number;
    total: number;
    rows: T;
}

export interface PageRequest {
    pageSize: number;
    pageNumber: number;
    keywords?: string;
    active?: number;
}

export interface ParamExport extends PageRequest {
    downloadType?: String;
}

export interface BaseResponse {
    isSuccess: boolean;
    message: string;
    data?: any;
}

export class BaseService {
    protected getTokenRequestHeaders = () => {
        return {
            headers: {
                "Content-Type": "application/json"
            }
        };
    };
}