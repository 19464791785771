import { DanhMucService } from 'apis/danhMuc/danhMucService';
import { ThongTinChuyenNganhEnum, TypeField } from 'model/eForm/thongTinChuyenNganheForm';
import { ThuocBaoVeThucVatDTO, ThuocBaoVeThucVatDTO_INT, ThuocBaoVeThucVatQuangCaoDTO } from 'model/thucVat/thuoc';
import DateUtil from './dateUtils';
import StringUtils from './stringUtils';

export default class ThongTinChuyenNganhUtil {

    static getValueByKey(key: string, thongTinChuyenNganh: any[], typeField: TypeField): any {
        let result;
        if (typeField === TypeField.TYPE_STRING) {
            if (!StringUtils.isNullOrEmty(key)) {
                for (let i = 0; i < thongTinChuyenNganh.length; i++) {
                    if (thongTinChuyenNganh[i].tenTruong === key) { result = thongTinChuyenNganh[i].giaTri; break }
                }
            }

        }
        if (typeField === TypeField.TYPE_DATE) {
            if (!StringUtils.isNullOrEmty(key)) {
                for (let i = 0; i < thongTinChuyenNganh.length; i++) {
                    if (thongTinChuyenNganh[i].tenTruong === key) { result = DateUtil.stringDDMMYYYtoDate(thongTinChuyenNganh[i].giaTri); break }
                }
            }
        }
        return result;
    }
    static async getNoiCapCMNDByKey(code: string): Promise<any> {
        let result;
        if (!StringUtils.isNullOrEmty(code)) {
            await new DanhMucService().getDanhMucDiaChiByCodeAndParentCode(code, "").then((resp) => {
                if (resp) {
                    result = resp.name;
                }
            });
        }
        return result;
    }

    static  getDanhSachBVTV(thongTinChuyenNganh: any[]): ThuocBaoVeThucVatDTO[] {
        let thuocBaoVeThucVat : ThuocBaoVeThucVatDTO[] = new Array<ThuocBaoVeThucVatDTO>();
        try{
            if (thongTinChuyenNganh && thongTinChuyenNganh.length > 0) {
                for (let i = 0; i < thongTinChuyenNganh.length; i++) {
                    if(thongTinChuyenNganh[i].tenTruong == ThongTinChuyenNganhEnum.DANH_SACH_THUOC_BVTT && thongTinChuyenNganh[i].giaTri && thongTinChuyenNganh[i].giaTri.length > 0) {
                        const thuocs = thongTinChuyenNganh[i].giaTri;
                        for(let i = 0; i < thuocs.length; i++){
                            if(thuocs[i]?.tenTruong == ThongTinChuyenNganhEnum.PHUONG_TIEN_QUANG_CAO){
                                if(thuocs[i]?.giaTri && thuocs[i]?.giaTri.length > 0 ){
                                    const phuongTienQuangCaos= thuocs[i]?.giaTri;
                                    if(phuongTienQuangCaos && phuongTienQuangCaos.length > 0){                                    
                                        for(let i = 0; i < phuongTienQuangCaos.length; i++){
                                            thuocBaoVeThucVat[i] = {
                                                ...ThuocBaoVeThucVatDTO_INT,
                                                phuongTienQuangCao : phuongTienQuangCaos[i]
                                            };
                                        }
                                    }
                                }
                            }else if(thuocs[i]?.tenTruong == ThongTinChuyenNganhEnum.TEN_THUOC_QUANG_CAO){
                                if(thuocs[i]?.giaTri && thuocs[i]?.giaTri.length > 0 ){
                                    const tenThuocQuangCaos= thuocs[i]?.giaTri;
                                    if(tenThuocQuangCaos && tenThuocQuangCaos.length > 0){                                    
                                        for(let i = 0; i < tenThuocQuangCaos.length; i++){
                                            thuocBaoVeThucVat[i] = {
                                                ...thuocBaoVeThucVat[i] ? thuocBaoVeThucVat[i] : ThuocBaoVeThucVatDTO_INT,
                                                tenThuocBaoVe : tenThuocQuangCaos[i]
                                            };
                                        }
                                    }
                                }
                            }else if(thuocs[i]?.tenTruong == ThongTinChuyenNganhEnum.GIAY_QUANG_CAO){
                                if(thuocs[i]?.giaTri && thuocs[i]?.giaTri.length > 0 ){
                                    const giayTos= thuocs[i]?.giaTri;
                                    if(giayTos && giayTos.length > 0){                                    
                                        for(let i = 0; i < giayTos.length; i++){
                                            thuocBaoVeThucVat[i] = {
                                                ...thuocBaoVeThucVat[i] ? thuocBaoVeThucVat[i] : ThuocBaoVeThucVatDTO_INT,
                                                giayChungNhan : giayTos[i]
                                            };
                                        }
                                    }
                                }
                            }
                           
                        }
                    }
                }
            }    
        }catch(e){
        }
        return thuocBaoVeThucVat;
    }
}