import { Collapse, Typography } from "@material-ui/core";
import { Pagination } from "apis/baseService";
import { PhanBonQuangCaoManagementSevice } from "apis/thucVat/quangCaophanBon";
import TraCuuBanDo from "components/commons/componentMap";
import { FormValuesTraCuuMap } from "components/commons/componentMap/dialogTraCuu";
import { Pageable } from "components/commons/customTable/customTableInterface";
import { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomTextField from "components/commons/fields/CustomTextField";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { MapEnum } from "constants/constantKey";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/actions/notificationAction";
export interface WrapperMapTraCuuQuangCaoPhanBonProps {}

const WrapperMapTraCuuQuangCaoPhanBon: React.FC<WrapperMapTraCuuQuangCaoPhanBonProps> = ({}) => {
    const title = "TRA CỨU BẢN ĐỒ";

    React.useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }, []);

    const dispatch = useDispatch();

    const fields: CreateFieldsProps<any, OptionDefault>[] = [
        { name: "soGiayXacNhan", label: "Số giấy xác nhận", Component: CustomTextField, xs: 12 },
        { name: "tenToChuc", label: "Tên tổ chức cá nhân", Component: CustomTextField, xs: 12 },
    ];

    async function handleSubmitSearch(values: any, pageParams: Pageable) {
        const newValues = { ...values };
        delete newValues.soGiayPhep;
        delete newValues.tenCoSo;
        delete newValues.lopBanDo;
        delete newValues.diaChi;
        const { rows, page, size, total } = await new PhanBonQuangCaoManagementSevice().search(
            {
                id: null,
                soGiayXacNhan: "",
                tenToChuc: "",
                ngayCapFrom: null,
                ngayCapTo: null,
                ...newValues,
            },
            pageParams.page,
            pageParams.rowsPerPage
        );
        if (rows.length > 0 && total) {
            return Promise.resolve({ rows, page, size, total } as unknown as Pagination<any[]>);
        }
        return Promise.resolve({
            rows,
            page,
            size,
            total,
        } as unknown as Pagination<any[]>);
    }

    function reanderDetails(item: any, showDetail: boolean) {
        return (
            <>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Số giấy chứng nhận:</strong> <span>{item.soGiayXacNhan}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Ngày cấp:</strong> <span>{moment(item.ngayCap).format("DD/MM/YYYY")}</span>
                </Typography>
                <Collapse in={showDetail}>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>Tên tổ chức:</strong> <span>{item.tenToChuc}</span>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>Số điện thoại:</strong> <span>{item.diaChi?.soDienThoai}</span>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>Fax:</strong> <span>{item.diaChi?.fax}</span>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>Email: </strong> <span>{item.diaChi?.email}</span>
                    </Typography>
                </Collapse>
            </>
        );
    }

    async function handleSaveLatLong(values: any) {
        dispatch(openNotification({ severity: "success", content: "Cập nhật tọa độ thành công", open: true }));
        return Promise.resolve({ ...values });
    }

    return (
        <TraCuuBanDo
            id={MapEnum.TRA_CUU_BAN_DO_QUANG_CAO_PHAN_BON}
            handleSubmitSearch={handleSubmitSearch}
            handleSaveLatLong={handleSaveLatLong}
            fields={fields}
            renderDetails={reanderDetails}
        />
    );
};

export default WrapperMapTraCuuQuangCaoPhanBon;
