
import { PublicPage } from 'pages/public';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import StringUtil from 'utils/stringUtils';
import { decodeIdToken, getAllSessionParameters, invalidLocalSessionAdmin, isValidSession } from './actions/session';
import { sendTokenRequest } from './actions/signIn';
import './App.css';
import { PrivateRoute } from './components/routers/privateRoute';
import { Layout } from './containers/layout';
import { Login } from './pages/login/login';

export const App = () => {
  const [checkingSession, setCheckingSession] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    validateSessionStore();
  }, []);
  async function validateSessionStore() {
    const maHoSo = new URL(window.location.href).searchParams.get("maHoSo");
    if (!StringUtil.isNullOrEmty(maHoSo)) {
        localStorage.setItem("MA_HO_SO", maHoSo != null ?  maHoSo : "");
    }
    const code = new URL(window.location.href).searchParams.get("code");
    if (code) {
      await sendTokenRequest(code)
        .then(response => {
          setIsLoggedIn(true);
          window.location.href = '/ho-so-cua-toi';
        })
        .catch((error => {
          setIsLoggedIn(false);
        }));
    }
    if (isValidSession()) {
      setIsLoggedIn(true);
      setCheckingSession(false);
      return;
    }
    if (localStorage.getItem("C_USER")) {
      setIsLoggedIn(true);
      setCheckingSession(false);
      return;
    }
    setCheckingSession(false);
  }

  return (
    <>
      {
        !checkingSession &&
        < Router >
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/public/chart" component={PublicPage} />
            <Route exact path="/" component={() => <Redirect to="/ho-so-cua-toi" />} />
            <PrivateRoute redirectToSSO={true} path="/" isSignedIn={isLoggedIn} component={Layout} />
          </Switch>
        </Router>
      }
    </>

  )
}
