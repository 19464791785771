import { Box, createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { blue } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { AttachFileDTO } from "../../../model/chuyenNganh/attachFile";
import { AttachIcon } from "../attachFileTooltip/attachFileTooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        nameFile: {
            textDecoration: "none",
            color: blue[500],
            marginRight: "auto",
        },
        button: {
            margin: "0px 5px",
        },
        container: {
            marginTop: "5px",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
        },
        buttonFile: {
            justifyContent: "flex-start",
            color: blue[500],
        },
    })
);

interface PreviewDropzoneI {
    files: AttachFileDTO[];
    onDelete: (f: AttachFileDTO) => void;
    onDownload: (f: AttachFileDTO) => void;
}

const PreviewDropzoneFromServer: React.FC<PreviewDropzoneI> = ({ files, onDelete, onDownload }) => {
    const classes = useStyles();
    return (
        <>
            {files.map(
                (item, i) =>
                    !Boolean(item.isDeleted) && (
                        <Box display="flex" key={`file-${i}`}>
                            <Box display="flex" flexGrow="1" flexDirection="column" key={i}>
                                <Button
                                    size="small"
                                    className={classes.buttonFile}
                                    disableRipple
                                    startIcon={<AttachIcon fileName={`${item.fileName}`} />}
                                    style={{
                                        display: "flex",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "400px",
                                        whiteSpace: "nowrap",
                                    }}
                                    endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                    onClick={() => onDownload(item)}
                                >
                                    {item?.fileName.length > 30 ? item?.fileName.slice(0, 33) + "..." + item?.fileName.slice(item?.fileName.length - 10) : item?.fileName}
                                </Button>
                            </Box>

                            <IconButton onClick={() => onDelete(item)} size="small" disableRipple color="secondary">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )
            )}
        </>
    );
};

export default PreviewDropzoneFromServer;
