import { BaseResponse, BaseService, Pagination, ParamExport } from "apis/baseService";
import { ChungNhanAttpHienTrangCoSoDTO, ChungNhanAttpSanPhamDTO, ChungNhanAttpThongTinChungDTO, LichSuBienDongDTO, ThongKeChungNhanDuDieuKienAttpDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { LoaiChuyenNganh } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { TraCuuRequestDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/traCuuChungNhanDuDieuKienAttpDTO";
import api from "../../index";
const ROOT_PATH = "/qlcl-nong-lam-thuy-san/chung-nhan-du-dieu-kien-attp";

export interface BussinessTemplateDownloadRequest {
    soBienNhan: string;
    filedId: string;
}

export class ChungNhanDuDieuKienAttpService extends BaseService {
    async addOrUpdateThongTinChung(data: ChungNhanAttpThongTinChungDTO, loaiChuyenNganh: LoaiChuyenNganh): Promise<BaseResponse> {
        let result = {isSuccess: false, } as BaseResponse;
        await api.post(`${ROOT_PATH}/thong-tin-chung/${loaiChuyenNganh}/insert-or-update`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }

    async addOrUpdateSanPham(data: ChungNhanAttpSanPhamDTO): Promise<BaseResponse> {
        let result = {isSuccess: false, } as BaseResponse;
        await api.post(`${ROOT_PATH}/san-pham/insert-or-update`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }    
    async deleteSanPham(data: ChungNhanAttpSanPhamDTO): Promise<BaseResponse> {
        let result = {isSuccess: false, } as BaseResponse;
        await api.post(`${ROOT_PATH}/san-pham/delete`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }

    async getThongTinChungById(id: number): Promise<ChungNhanAttpThongTinChungDTO> {
        let result = {} as ChungNhanAttpThongTinChungDTO;
        await api.get(`${ROOT_PATH}/thong-tin-chung/get-by-id`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getThongTinChungBySoBienNhan(soBienNhan: String, loaiChuyenNganh: LoaiChuyenNganh): Promise<ChungNhanAttpThongTinChungDTO> {
        let result = {} as ChungNhanAttpThongTinChungDTO;
        await api.get(`${ROOT_PATH}/thong-tin-chung/get-by-so-bien-nhan`, {
            params: {
                soBienNhan: soBienNhan,
                loaiChuyenNganh: loaiChuyenNganh
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getThongTinChungThuHoi(id: number): Promise<ChungNhanAttpThongTinChungDTO> {
        let result = {} as ChungNhanAttpThongTinChungDTO;
        await api.get(`${ROOT_PATH}/get-thong-tin-thu-hoi`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getThongTinChungBySoGcn(soGcn: string): Promise<ChungNhanAttpThongTinChungDTO> {
        let result = {} as ChungNhanAttpThongTinChungDTO;
        await api.get(`${ROOT_PATH}/thong-tin-chung/get-by-so-gcn`, {
            params: {
                soGcn: soGcn
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getHienTrangCoSoByChungNhanId(chungNhanId: number): Promise<ChungNhanAttpHienTrangCoSoDTO> {
        let result = {} as ChungNhanAttpHienTrangCoSoDTO;
        await api.get(`${ROOT_PATH}/hien-trang-co-so/get-by-chung-nhan-id`, {
            params: {
                chungNhanId: chungNhanId
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    
    async getDanhSachChungNhan(request: TraCuuRequestDTO, page: number, size: number): Promise<Pagination<ChungNhanAttpSanPhamDTO[]>> {
        let result: Pagination<ChungNhanAttpSanPhamDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api.post(`${ROOT_PATH}/tra-cuu`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async getChiTietThongKe(request: TraCuuRequestDTO, page: number, size: number): Promise<Pagination<ChungNhanAttpSanPhamDTO[]>> {
        let result: Pagination<ChungNhanAttpSanPhamDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api.post(`${ROOT_PATH}/chi-tiet-thong-ke`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async traCuuExport(data: TraCuuRequestDTO | {}, params: ParamExport): Promise<any> {
        return await api.post(`${ROOT_PATH}/tra-cuu/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    
    async getBaoCaoChungNhan(request: TraCuuRequestDTO, page: number, size: number): Promise<Pagination<ChungNhanAttpSanPhamDTO[]>> {
        let result: Pagination<ChungNhanAttpSanPhamDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api.post(`${ROOT_PATH}/bao-cao`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async baoCaoExport(data: TraCuuRequestDTO | {}, params: ParamExport): Promise<any> {
        return await api.post(`${ROOT_PATH}/bao-cao/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    
    async getThongKeChungNhan(request: TraCuuRequestDTO): Promise<ThongKeChungNhanDuDieuKienAttpDTO[]> {
        let result: ThongKeChungNhanDuDieuKienAttpDTO[] = [];
        await api.post(`${ROOT_PATH}/thong-ke`, request).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    
    async thongKeExport(data: TraCuuRequestDTO | {}, params: ParamExport): Promise<any> {
        return await api.post(`${ROOT_PATH}/thong-ke/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    
    async getDanhSachSanPham(request: ChungNhanAttpSanPhamDTO, page: number, size: number): Promise<Pagination<ChungNhanAttpSanPhamDTO[]>> {
        let result: Pagination<ChungNhanAttpSanPhamDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api.post(`${ROOT_PATH}/san-pham/get-danh-sach`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page,
                }
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async getLichSuBienDong(id: number): Promise<LichSuBienDongDTO[]> {
        let result: LichSuBienDongDTO[] = [];
        await api.get(`${ROOT_PATH}/lich-su-bien-dong/get-by-id`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    /**
     * @description xử lý download file khi thực hiện in ấn
     * @param attachFile
     * @returns
     */
     handleDownloadTemplateFile = async (request: BussinessTemplateDownloadRequest, loaiChuyenNganh: LoaiChuyenNganh): Promise<any> => {
        return api.get(`${ROOT_PATH}/${loaiChuyenNganh}/download-bussiness-template-file`, {
            responseType: "blob",
            params: request,
        });
    };

    getAutocompleteBySoGiayPhep_MaTrangThai_PageSize = (soGiayCN: string, limit: number)=>  {
        let params = {
            soGiayCN: soGiayCN,
            limit: limit,
        }
        return api.post(`${ROOT_PATH}/get-autocomplete-get-by-so-bien-nhan`, params);
    
    }
    
    async autocomplete(keywords: String): Promise<BaseResponse> {
        let result = { isSuccess: false } as BaseResponse;
        await api.post(`${ROOT_PATH}/get-autocomplete-get-by-so-bien-nhan`, { "soGiayCN": keywords, limit:100 }).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result = {
                    ...result,
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    data: response.data.data,
                };
            }
        });

        return result;
    }

    getLanThayDoi = async (referenceId: Number, loaiChuyenNganh: Number, soBienNhan: String): Promise<any> => {
        let result = {} as any;
        await api.post(`${ROOT_PATH}/get-lan-thay-doi`, { referenceId, loaiChuyenNganh, soBienNhan }).then((response) => {
            result = response.data.data;
        });
        return result;
    };
}