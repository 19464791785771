import { Map } from "@esri/react-arcgis";
import { Grid } from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
import { Pagination } from "apis/baseService";
import { Pageable } from "components/commons/customTable/customTableInterface";
import { API_KEY_MAP } from "constants/constantKey";
import { loadModules } from "esri-loader";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { MarkerDTO, ThucVatMarkerDTO, ThuYMarkerDTO } from "model/marker";
import { ThucVatPhanBonQuangCaoTraCuuDTO } from "model/thucVat/quangCaoPhanBon";
import React, { memo, useEffect, useState } from "react";
import { CreateFieldsProps } from "../fields/createFields";
import DialogTraCuu, { FormValuesTraCuuMap } from "./dialogTraCuu";
import JsonLayer from "./jsonLayer";
import LocatorTraCuu from "./locaTorTraCuu";
import MarkerPoint from "./markerPoint";

export interface TraCuuBanDoProps {
    id: Number;
    handleSubmitSearch: (values: FormValuesTraCuuMap, pageParams: Pageable) => Promise<Pagination<MarkerDTO[]>>;
    handleSaveLatLong: (values: MarkerDTO) => Promise<MarkerDTO>;
    fields: CreateFieldsProps<FormValuesTraCuuMap, OptionDefault>[];
    renderDetails: (item: MarkerDTO | ThuYMarkerDTO | ThucVatMarkerDTO, showDetail: boolean) => JSX.Element;
}

const TraCuuBanDo: React.FC<TraCuuBanDoProps> = ({ id, fields, handleSubmitSearch, handleSaveLatLong, renderDetails }) => {
    const [view, setView] = useState<any | null>(null);
    const [map, setMap] = useState<any | null>(null);
    const [point, setPoint] = useState<any>(null);
    const [status, setStatus] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [layer, setLayer] = React.useState<any | null>(null);
    const [itemEdit, setItemEdit] = React.useState<MarkerDTO | null>(null);
    const [valueEdit, setValueEdit] = React.useState<MarkerDTO | null>(null);

    const [arrMarker, setArrMarker] = useState<MarkerDTO[]>([]);

    // Hook
    useEffect(() => {
        loadModules(["esri/config", "esri/Map", "esri/views/MapView"]).then(([esriConfig, Map, MapView]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const map = new Map({
                id,
                basemap: "streets-navigation-vector",
            });

            const pt = {
                center: [105.78712, 10.03572],
            };

            setPoint(pt);

            const view = new MapView({
                container: "viewDiv",
                map,
                center: [105.78712, 10.03572],
                zoom: 12,
                popup: {
                    dockEnabled: true,
                    dockOptions: {
                        buttonEnabled: false,
                        breakpoint: false,
                    },
                },
            });

            setView(view);
            setMap(map);
            view.when(() => {
                setStatus(true);
            });
        });
    }, []);

    // Control
    return (
        <Grid container>
            <Grid item xs={12} className="search_map">
                {openDialog && (
                    <DialogTraCuu
                        renderDetails={renderDetails}
                        fields={fields}
                        view={view}
                        handleSubmitSearch={handleSubmitSearch}
                        setOpenDialog={setOpenDialog}
                        setArrMarker={setArrMarker}
                        arrMarker={arrMarker}
                        setItemEdit={setItemEdit}
                        itemEdit={itemEdit}
                        valueEdit={valueEdit}
                        handleSaveLatLong={handleSaveLatLong}
                    />
                )}
            </Grid>
            <AppsIcon
                color="primary"
                fontSize="large"
                onClick={() => setOpenDialog(!openDialog)}
                style={{ zIndex: 3, position: "fixed", right: 0, cursor: "pointer" }}
            />
            <Grid item xs={12} style={{ height: "100vh", zIndex: 0 }}>
                <Map viewProperties={{ center: [105.78712, 10.03572], zoom: 13 }} mapProperties={{ basemap: "streets-navigation-vector" }}>
                    <JsonLayer view={view} map={map} arrMarker={arrMarker} setLayer={setLayer} />
                    {/* <LocatorTraCuu view={view} itemEdit={itemEdit} setValueEdit={setValueEdit} /> */}
                    <MarkerPoint view={view} itemEdit={itemEdit} />
                </Map>
            </Grid>
        </Grid>
    );
};

export default memo(TraCuuBanDo);
