import { bootstrapHelperTextStyles, bootstrapInputStyles, bootstrapLabelStyles, bootstrapInputAdornmentStyles } from './bootstrapInputStyles';

export const bootstrapTextFieldHook = {
    useLabel: bootstrapLabelStyles,
    useInput: bootstrapInputStyles,
    useHelperText: bootstrapHelperTextStyles,
    inputAdornment: bootstrapInputAdornmentStyles,
};
// export const filledTextFieldHook = {
//     useInput: makeStyles(filledInputStyles),
//     useHelperText: makeStyles(filledHelperTextStyles),
// };