import { InputLabel } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { ColumnTable, CommonTableChuyenNganh } from "components/commons/table/commonTableChuyenNganh";
import { ChungNhanAttpTrangThietBiDTO, ChungNhanAttpTrangThietBiDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import React, { useState } from "react";
import { TrangThietBiDialog } from "./trangThietBiDialog";

export interface DanhSachTrangThietBiPropsI {
    onDeleteItem?: (item: ChungNhanAttpTrangThietBiDTO, index: number) => void;
    onSaveItem?: (item: ChungNhanAttpTrangThietBiDTO, index: number, isEdit: boolean) => void;
    dataListItem: ChungNhanAttpTrangThietBiDTO[];
    disableAddButton?: boolean;
    viewOnly?: boolean;
    label?: string;
}

export const TrangThietBi = (props: DanhSachTrangThietBiPropsI) => {
    const { onSaveItem, onDeleteItem, dataListItem, disableAddButton, viewOnly, label } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();

    const [openDlg, setOpenDlg] = useState(false);
    const [itemEdited, setItemEdited] = useState<ChungNhanAttpTrangThietBiDTO>({...ChungNhanAttpTrangThietBiDTO_INT});
    const [isEdit, setIsEdit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);

    const columns: ColumnTable<ChungNhanAttpTrangThietBiDTO>[] = [
        { title: "Tên thiết bị", field: "tenThietBi" },
        { title: "Số lượng", field: "soLuong" },
        { title: "Nước sản xuất", field: "nuocSanXuat" },
        { title: "Tổng công suất", field: "tongCongSuat" },
        { title: "Năm bắt đầu sử dụng", field: "namBatDauSuDung" },
    ];

    function handleClickAdd() {
        setItemEdited({...ChungNhanAttpTrangThietBiDTO_INT});
        setIsEdit(false);
        setOpenDlg(true);
    }

    function handleSaveDialog(item: ChungNhanAttpTrangThietBiDTO) {
        setOpenDlg(false);
        onSaveItem && onSaveItem(item, indexEdit, isEdit);

    }

    function handleEditItem(item: ChungNhanAttpTrangThietBiDTO, index: number) {
        setItemEdited(item);
        setIsEdit(true);
        setIndexEdit(index);
        setOpenDlg(true);
    }
    return (
        <>
            <InputLabel classes={labelStyles}>{label}</InputLabel>
            <CommonTableChuyenNganh columns={columns} rows={dataListItem}
                onDelete={onDeleteItem ? onDeleteItem : undefined}
                onEdit={onSaveItem ? handleEditItem : undefined}
                viewOnly={viewOnly}
                onAdd={disableAddButton ? undefined : handleClickAdd} />
            {
                openDlg && <TrangThietBiDialog openDialog={openDlg} onDialogClose={() => setOpenDlg(false)}
                    onSubmitData={handleSaveDialog}
                    itemEdit={itemEdited}
                />
            }
        </>
    )
}