import MomentUtils from "@date-io/moment";
import { Box, Button, createStyles, FormControlLabel, Grid, InputLabel, makeStyles, NativeSelect, Paper, Radio, RadioGroup, TextareaAutosize, TextField, Theme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { getIn, useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { NotificationAction, openNotification } from "redux/actions/notificationAction";
import * as Yup from "yup";
import { KienThucVeSinhAttpCaNhanDTO, KienThucVeSinhAttpDTO, KienThucVeSinhAttpDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { useGetReferItem } from "hooks/useGetSoGiayPhep";
import { CHUC_VU, DoiTuong, getDoiTuongEnum, LoaiChuyenNganh, NGUOI_KY } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpEnum";
import { Autocomplete } from "@material-ui/lab";
import { CaNhan } from "../caNhan/caNhan";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import moment from "moment";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";


const fontFamily = [
    'Arial',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
        textarea: {
            // boxSizing: 'inherit',
            width: '98%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ced4da',
            fontSize: 14,
            padding: '0.5rem .75rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            fontFamily: fontFamily,
            '&:focus': {
                outline: 'none',
                borderRadius: 4,
                borderColor: '#ced4da',
            },
        }
    }),
);

export const NhapDauKyKienThucVeSinhAttp = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const title = "NHẬP ĐẦU KỲ GIẤY XÁC NHẬN KIẾN THỨC VỀ AN TOÀN THỰC PHẨM";
    const [indexTab, setIndexTab] = useState(0);
    const classes = useStyles();
    const [openResetFormDlg, setOpenResetFormDlg] = useState(false);

    const { item } = useGetReferItem<KienThucVeSinhAttpDTO>();

    const initialValues: KienThucVeSinhAttpDTO = { ...KienThucVeSinhAttpDTO_INT };

    const validationSchema = Yup.object().shape(
        {
            soGxn: Yup.string().required("Số giấy xác nhận không được bỏ trống"),
            hoTen: Yup.string()
                .when("type", {
                    is: 0,
                    then: Yup.string().required("Tên không được bỏ trống"),
                }),
            soCmnd: Yup.string().matches(/^([0-9]{9}|[0-9]{12})$/, 'CMND phải có định dạng là 9 hoặc 12 ký tự')
                .when("type", {
                    is: 0,
                    then: Yup.string().required("Số căn cước/CMND không được bỏ trống"),
                }),
            tenToChuc: Yup.string().when("type", {
                is: 1,
                then: Yup.string().required("Tên tổ chức không được bỏ trống"),
            }),
            ngayCapGxn: Yup.date()
                .nullable()
                .required("Ngày cấp không được bỏ trống")
                .when("ngayHetHanGxn", (ngayHetHanGxn: any, schema: any): any => {
                    if (ngayHetHanGxn) {
                        return schema.max(ngayHetHanGxn, "Không hợp lệ");
                    }
                    return schema;
                }).max(new Date(), "Ngày cấp không được phép lớn hơn ngày hiện tại"),
            ngayHetHanGxn: Yup.date()
                .nullable()
                .required("Ngày hết hạn không được bỏ trống")
                .when("ngayCapGxn", (ngayCapGxn: any, schema: any): any => {
                    if (ngayCapGxn) {
                        return schema.min(ngayCapGxn, "Không hợp lệ");
                    }
                    return schema;
                }),
            diaChi: Yup.object().shape({
                diaChiTinhCode: Yup.string().required("Tỉnh/Thành không được bỏ trống"),
                diaChiHuyenCode: Yup.string().trim().required("Quận/Huyện không được bỏ trống"),
                diaChiXaCode: Yup.string().trim().required("Phường/Xã không được bỏ trống"),
            })
        },
        [["ngayCapGxn", "ngayHetHanGxn"]]
    );
    //hook
    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    const history = useHistory();

    async function initialData() {
        if (item) {
            await new KienThucVeSinhAttpService().getById(Number(item.id)).then(resp => {
                if (resp)
                    formik.setValues(resp);
            })
        }
    }

    useEffect(() => {
        initialData();
    }, [item])

    useEffect(() => {
        if (formik.values.ngayCapGxn != null && formik.values.ngayHetHanGxn == null)
            formik.setFieldValue("ngayHetHanGxn", moment(formik.values.ngayCapGxn).add({ year: 5 }));
    }, [formik.values.ngayCapGxn]);

    function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
        setIndexTab(newValue);
    }

    async function handleSubmit(data: KienThucVeSinhAttpDTO) {
        await new KienThucVeSinhAttpService().addOrUpdate(data, LoaiChuyenNganh.NHAP_DAU_KY).then((response) => {
            if (response.isSuccess == true)
                formik.setFieldValue("id", response.data.id);
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
    }

    const handleChangeSelect = (value: any, ma: string, ten: string) => {
        formik.setFieldValue(ma, value?.ma);
        formik.setFieldValue(ten, value?.ten);
    }

    function handleSaveItemCaNhan(item: KienThucVeSinhAttpCaNhanDTO, index: number, isEdit: boolean) {
        let data = [...formik.values.listCaNhan];
        if (isEdit) {
            data[index] = item;
        } else {
            data.splice(0, 0, item);
        }
        formik.setFieldValue("listCaNhan", data);
    }

    function handleDeleteItemCaNhan(_item: KienThucVeSinhAttpCaNhanDTO, index: number) {
        let data = [...formik.values.listCaNhan];
        data.splice(index, 1);
        formik.setFieldValue("listCaNhan", data);
    }

    const handleOpenResetForm = () => {
        setOpenResetFormDlg(true);
    }

    const handleCloseResetForm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetForm();
        }
        setOpenResetFormDlg(false);
    };

    function handleResetForm() {
        formik.setValues({
            ...initialValues,
            id: formik.values.id,
            type: formik.values.type,
            ngayTao: formik.values.ngayTao,
            nguoiTaoId: formik.values.nguoiTaoId
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{title}</span>
                </div>
                <Paper component="div">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={indexTab} onChange={handleChangeTab}>
                            <CustomTab label="Thông tin chung" />
                        </CustomTabs>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} className="grid-item">
                            <RadioGroup row value={formik.values.type}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    formik.setFieldValue("type", Number((event.target as HTMLInputElement).value));
                                }}>
                                <FormControlLabel value={0}
                                    control={<Radio size="small" color="default" />} label="Cá nhân" />
                                <FormControlLabel value={1}
                                    control={<Radio size="small" color="default" />} label="Tổ chức" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Đối tượng</InputLabel>
                            <NativeSelect input={<CustomInput />} fullWidth name="doiTuong" value={formik.values?.doiTuong} onChange={formik.handleChange} >
                                <option value={DoiTuong.NONE}>Chọn đối tượng</option>
                                <option value={DoiTuong.TO_CHUC_KD_SP_NLTS}>{getDoiTuongEnum(DoiTuong.TO_CHUC_KD_SP_NLTS)}</option>
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                required
                                label={"Số giấy xác nhận"}
                                name="soGxn"
                                value={formik.values.soGxn}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "soGxn") && getIn(formik.errors, "soGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "soGxn") && getIn(formik.errors, "soGxn")) && formik.errors?.soGxn}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <KeyboardDatePicker
                                name="ngayCapGxn"
                                label={"Ngày cấp"}
                                required
                                value={formik.values.ngayCapGxn}
                                onChange={(date, _val) => {
                                    formik.setFieldValue("ngayCapGxn", date);
                                }}
                                autoOk={true}
                                fullWidth
                                disableFuture
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngayCapGxn") && getIn(formik.errors, "ngayCapGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngayCapGxn") && getIn(formik.errors, "ngayCapGxn")) && formik.errors.ngayCapGxn}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <KeyboardDatePicker
                                name="ngayHetHanGxn"
                                label={"Ngày hết hạn"}
                                value={formik.values.ngayHetHanGxn}
                                onChange={(date, _val) => {
                                    formik.setFieldValue("ngayHetHanGxn", date);
                                }}
                                autoOk={true}
                                fullWidth
                                required
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngayHetHanGxn") && getIn(formik.errors, "ngayHetHanGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngayHetHanGxn") && getIn(formik.errors, "ngayHetHanGxn")) && formik.errors.ngayHetHanGxn}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />
                        </Grid>
                        {formik.values.type == 0 && (<Grid container>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Họ tên"}
                                    name="hoTen"
                                    value={formik.values.hoTen}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "hoTen"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Số căn cước/CMND"}
                                    name="soCmnd"
                                    value={formik.values.soCmnd}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "soCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "soCmnd")) && formik.errors?.soCmnd}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapCmnd"
                                    label={"Ngày cấp"}
                                    value={formik.values.ngayCapCmnd}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapCmnd", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "ngayCapCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "ngayCapCmnd")) && formik.errors.ngayCapCmnd}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapCmnd"
                                    value={formik.values.noiCapCmnd}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "noiCapCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "noiCapCmnd")) && formik.errors?.noiCapCmnd}
                                />
                            </Grid>
                        </Grid>)}
                        {formik.values.type == 1 && (<Grid container>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Số giấy CNĐKDN"}
                                    name="soGcnDkdn"
                                    value={formik.values.soGcnDkdn}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "soGcnDkdn") && getIn(formik.errors, "soGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "soGcnDkdn") && getIn(formik.errors, "soGcnDkdn")) && formik.errors?.soGcnDkdn}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnDkdn"
                                    label={"Ngày cấp"}
                                    value={formik.values.ngayCapGcnDkdn}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnDkdn", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnDkdn") && getIn(formik.errors, "ngayCapGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnDkdn") && getIn(formik.errors, "ngayCapGcnDkdn")) && formik.errors.ngayCapGcnDkdn}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapGcnDkdn"
                                    value={formik.values.noiCapGcnDkdn}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "noiCapGcnDkdn") && getIn(formik.errors, "noiCapGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "noiCapGcnDkdn") && getIn(formik.errors, "noiCapGcnDkdn")) && formik.errors?.noiCapGcnDkdn}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Tên tổ chức"}
                                    name="tenToChuc"
                                    value={formik.values.tenToChuc}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "tenToChuc") && getIn(formik.errors, "tenToChuc"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "tenToChuc") && getIn(formik.errors, "tenToChuc")) && formik.errors?.tenToChuc}
                                />
                            </Grid>
                        </Grid>)}
                        <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} disabled={false} />
                        <Grid item xs={12} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Số nhà, đường"}
                                name="diaChi.soNha"
                                value={formik.values?.diaChi?.soNha}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Số điện thoại"}
                                name="diaChi.soDienThoai"
                                value={formik.values?.diaChi?.soDienThoai}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Fax"}
                                name="diaChi.fax"
                                value={formik.values?.diaChi?.fax}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Email"}
                                name="diaChi.email"
                                value={formik.values?.diaChi?.email}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel classes={labelStyles} > Căn cứ </InputLabel>
                            <TextareaAutosize name="canCu" rows="2" value={formik.values.canCu} className={classes.textarea} onChange={formik.handleChange} />
                        </Grid>
                        {formik.values.type == 1 && (<Grid container>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles} style={{ fontStyle: "italic" }}>
                                    Danh sách đối tượng tham gia xác nhận kiến thức
                                </InputLabel>
                            </Grid>
                            {/* <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Số căn cước/CMND"}
                                    name="soCmnd"
                                    value={caNhan.soCmnd}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    //error={Boolean(getIn(formik.errors, "soCmnd"))}
                                    //FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    //helperText={Boolean(getIn(formik.errors, "soCmnd")) && formik.errors?.soCmnd}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapCmnd"
                                    label={"Ngày cấp"}
                                    value={caNhan.ngayCapCmnd}
                                    onChange={(date, _val) => {
                                        handleChangeDateCaNhan(date, _val, "ngayCapCmnd")
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "ngayCapCmnd"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "ngayCapCmnd")) && formik.errors.ngayCapCmnd}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapCmnd"
                                    value={caNhan.noiCapCmnd}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "noiCapCmnd"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "noiCapCmnd")) && formik.errors?.noiCapCmnd}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Họ tên"}
                                    name="hoTen"
                                    value={caNhan.hoTen}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "hoTen"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Giới tính"}
                                    name="gioiTinh"
                                    value={caNhan.gioiTinh}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "hoTen"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Box className="button-group" marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={handleSaveItemCaNhan1}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={handleResetFormCaNhan}>
                                        Làm mới
                                    </Button>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} className="grid-item">
                                <CaNhan
                                    dataListItem={formik.values.listCaNhan}
                                    onSaveItem={handleSaveItemCaNhan} onDeleteItem={handleDeleteItemCaNhan} />
                            </Grid>
                        </Grid>)}
                        <Grid item xs={3} className="grid-item" >
                            <TextField
                                fullWidth
                                label={"Người ký"}
                                name="tenNguoiKy"
                                value={formik.values?.tenNguoiKy}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}/>
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <TextField
                                fullWidth
                                label={"Chức vụ"}
                                name="tenChucVu"
                                value={formik.values?.tenChucVu}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px">
                            <Button variant="contained" color="primary" disableElevation
                                onClick={() => formik.handleSubmit()}>
                                Lưu
                            </Button>
                            <Button variant="contained" color="secondary" disableElevation
                                onClick={handleOpenResetForm}>
                                Xóa
                            </Button>
                            <ConfirmationDialogRaw id="confirm-dialog-reset-form" keepMounted open={openResetFormDlg}
                                title="Xóa thông tin"
                                content="Bạn có đồng ý xóa thông tin đã nhập?"
                                onClose={handleCloseResetForm}
                            />
                            <Button variant="contained" color="default" disableElevation onClick={() => history.go(-1)}>
                                Trở về
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </div>
        </MuiPickersUtilsProvider>
    )
}