import { useState, useEffect } from "react";
import { HoSoCuaToiMgtService } from '../apis/hoSoCuaToi/hoSoCuaToiMgtService';

export function useGetThongTinChuyenNganh(maHoSo: string) {
    const [chiTiet, setChiTiet] = useState<any>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();

    useEffect(() => {
        (async function getDuLieu() {
            if (maHoSo) {
               await hoSoCuaToiMgtService.chiTiet(maHoSo).then((p) => {
                    if (p.data) {
                        if(p.data.data.thongTinChuyenNganh && p.data.data.thongTinChuyenNganh.length>0){
                            let result: any[] = [];
                            for(let i = 0; i< p.data.data.thongTinChuyenNganh.length; i++){
                                result = [...result,...p.data.data.thongTinChuyenNganh[i].data]
                            }
                            setChiTiet(result);
                        }
        
                    }
                });
            }
        })();
    }, [maHoSo]);
    return chiTiet;
}
