import MomentUtils from "@date-io/moment";
import { Box, Button, ClickAwayListener, createStyles, FormControlLabel, Grid, Grow, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, MenuList, NativeSelect, Paper, Popper, Radio, RadioGroup, TextareaAutosize, TextField, Theme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { getIn, useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuerySoBienNhan } from "hooks/useQuery";
import { NotificationAction, openNotification } from "redux/actions/notificationAction";
import * as Yup from "yup";
import { KienThucVeSinhAttpCaNhanDTO, KienThucVeSinhAttpDTO, KienThucVeSinhAttpDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { useGetReferItem } from "hooks/useGetSoGiayPhep";
import { CHUC_VU, DoiTuong, getDoiTuongEnum, GioiTinh, LoaiChuyenNganh, NGUOI_KY } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpEnum";
import { Autocomplete } from "@material-ui/lab";
import { CaNhan } from "pages/QLCLNongLamThuySan/caNhan/caNhan";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import moment from "moment";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { useGetThongTinChuyenNganh } from "hooks/useGetThongTinChuyenNganh";
import LgspService from "apis/quanTri/LgspService";
import StringUtil from "utils/stringUtils";
import DateUtil from "utils/dateUtils";
import ThongTinChuyenNganhUtil from "utils/thongTinChuyenNganhUtils";
import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { ThongTinChuyenNganhEnum, TypeField } from "model/eForm/thongTinChuyenNganheForm";
import SearchIcon from "@material-ui/icons/Search";
import EndAdornmentSearch from "components/commons/endAdornmentSearch";
import { InBieuMauButton } from "components/commons/button/inBieuMauButton";
import { ChuyenXuLyRequest, HoSoCuaToiDTO, HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/hoSoCuaToiMgtService";
import { UserData } from "apis/userMgt/userManagementService";
import { getObject } from "helpers/localStorage";
import ChuyenXuLyModal from "pages/hoSoCuaToi/chuyenXuLyModal";
import DanhSachDuThaoModal from "pages/commons/duThao/danhSachDuThaoModal";
import { CustomButton } from "components/commons/hoso/TepDinhKemPopper";
import ReplyIcon from "@material-ui/icons/Reply";

const fontFamily = [
    'Arial',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
        textarea: {
            // boxSizing: 'inherit',
            width: '98%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ced4da',
            fontSize: 14,
            padding: '0.5rem .75rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            fontFamily: fontFamily,
            '&:focus': {
                outline: 'none',
                borderRadius: 4,
                borderColor: '#ced4da',
            },
        }
    }),
);

export const CapMoiKienThucVeSinhAttp = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const soBienNhan = useQuerySoBienNhan();
    const thongTinChuyenNganh = useGetThongTinChuyenNganh(soBienNhan);
    const title = `Cấp giấy xác nhận kiến thức về an toàn thực phẩm - Mã hồ sơ: ${soBienNhan}`;
    const [indexTab, setIndexTab] = useState(0);
    const classes = useStyles();
    const [openResetFormDlg, setOpenResetFormDlg] = useState(false);

    const { item } = useGetReferItem<KienThucVeSinhAttpDTO>();

    const initialValues: KienThucVeSinhAttpDTO = { ...KienThucVeSinhAttpDTO_INT };
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [openBuocChuyen, setOpenBuocChuyen] = React.useState(false);
    const [buocChuyens, setBuocChuyens] = React.useState<ThongTinBuocDTO[]>([]);
    const [buocChuyen, setBuocChuyen] = React.useState<ThongTinBuocDTO>({} as ThongTinBuocDTO);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showModal, setShowModal] = React.useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = React.useState(false);
    const [chuyenXuLyRequest, setChuyenXuLyRequest] = React.useState<ChuyenXuLyRequest>({} as ChuyenXuLyRequest);
    let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(String(formik.values.soBienNhan)).then((data) => {
            const buocChuyensNew = [...data];
            setBuocChuyens(buocChuyensNew);
        });
        setOpenBuocChuyen(!openBuocChuyen);
    };
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenBuocChuyen(false);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    };
    const clickBuocChuyen = (buocChuyenData: ThongTinBuocDTO) => {
        setBuocChuyen(buocChuyenData);
        const chuyenXuLyRequestNew: ChuyenXuLyRequest = {
            ...chuyenXuLyRequest,
            maHoSo: String(formik.values.soBienNhan),
            idBuocXuLyTiepTheo: buocChuyenData.idBuocXuLy,
            maNguoiGui: C_USER_DATA_DE.userName,
            nguoiNhanId: 0,
            noiDung: "",
            trichYeuDuThao: "",
            idLoaiVanBan: 0,
            hanXuLy: buocChuyenData.hanXuLyTheoGio,
        };
        setChuyenXuLyRequest(chuyenXuLyRequestNew);
        setShowModal(!showModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const validationSchema = Yup.object().shape(
        {
            soGxn: Yup.string().required("Số giấy xác nhận không được bỏ trống"),
            hoTen: Yup.string()
                .when("type", {
                    is: 0,
                    then: Yup.string().required("Tên không được bỏ trống"),
                }),
            soCmnd: Yup.string().matches(/^([0-9]{9}|[0-9]{12})$/, 'CMND phải có định dạng là 9 hoặc 12 ký tự')
                .when("type", {
                    is: 0,
                    then: Yup.string().required("Số căn cước/CMND không được bỏ trống"),
                }),
            tenToChuc: Yup.string().when("type", {
                is: 1,
                then: Yup.string().required("Tên tổ chức không được bỏ trống"),
            }),
            ngayCapGxn: Yup.date()
                .nullable()
                .required("Ngày cấp không được bỏ trống")
                .when("ngayHetHanGxn", (ngayHetHanGxn: any, schema: any): any => {
                    if (ngayHetHanGxn) {
                        return schema.max(ngayHetHanGxn, "Không hợp lệ");
                    }
                    return schema;
                }).max(new Date(), "Ngày cấp không được phép lớn hơn ngày hiện tại"),
            ngayHetHanGxn: Yup.date()
                .nullable()
                .required("Ngày hết hạn không được bỏ trống")
                .when("ngayCapGxn", (ngayCapGxn: any, schema: any): any => {
                    if (ngayCapGxn) {
                        return schema.min(ngayCapGxn, "Không hợp lệ");
                    }
                    return schema;
                }),
            diaChi: Yup.object().shape({
                diaChiTinhCode: Yup.string().required("Tỉnh/Thành không được bỏ trống"),
                diaChiHuyenCode: Yup.string().trim().required("Quận/Huyện không được bỏ trống"),
                diaChiXaCode: Yup.string().trim().required("Phường/Xã không được bỏ trống"),
            })
        },
        [["ngayCapGxn", "ngayHetHanGxn"]]
    );
    //hook
    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    const history = useHistory();
    useEffect(() => {
        function initialData() {
            if (soBienNhan) {
                new KienThucVeSinhAttpService().getByBienNhan(soBienNhan).then(resp => {
                    if (resp) {
                        formik.setValues({ ...initialValues, ...resp });
                    } else {
                        if (thongTinChuyenNganh && thongTinChuyenNganh.length > 0) {
                            formik.setValues(prev => {
                                const updatedTTC = {
                                    ...prev,
                                    soBienNhan: soBienNhan,
                                    hoTen: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.HO_VA_TEN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                                    soCmnd: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.CMND, thongTinChuyenNganh, TypeField.TYPE_STRING),
                                    ngayCapCmnd: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NGAY_CAP_CMND, thongTinChuyenNganh, TypeField.TYPE_DATE),
                                    ngaySinh: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NGAY_THANG_NAM_SINH, thongTinChuyenNganh, TypeField.TYPE_DATE),
                                    tenToChuc: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.TEN_DOANH_NGHIEP, thongTinChuyenNganh, TypeField.TYPE_STRING),
                                    soGcnDkdn: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.GIAY_DKDN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                                    ngayCapGcnDkdn: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NGAY_CAP_DKDN, thongTinChuyenNganh, TypeField.TYPE_DATE),
                                    noiCapGcnDkdn: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NOI_CAP_DKDN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                                }
                                let diaChiEform: DiaChiDTO = DiaChiDTO_INT_CAN_THO;
                                if (updatedTTC.diaChi) {
                                    diaChiEform = { ...updatedTTC.diaChi }
                                }
                                diaChiEform.diaChiTinhCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.TINH_THANH, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.diaChiHuyenCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.QUAN_HUYEN, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.diaChiXaCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.PHUONG_XA, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.soDienThoai = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.SO_DIEN_THOAI, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.fax = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.FAX, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.email = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.EMAIL, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                diaChiEform.duong = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.DIA_DIEM, thongTinChuyenNganh, TypeField.TYPE_STRING);
                                updatedTTC.diaChi = diaChiEform;
                                ThongTinChuyenNganhUtil.getNoiCapCMNDByKey(ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NOI_CAP_CMND, thongTinChuyenNganh, TypeField.TYPE_STRING))
                                    .then(res => { updatedTTC.noiCapCmnd = res })
                                return updatedTTC;
                            });

                        } else {
                            formik.setValues({ ...initialValues, soBienNhan: soBienNhan });
                        }

                    }
                })
            }
        }
        initialData();
    }, [soBienNhan, thongTinChuyenNganh])

    async function initialData() {
        if (item) {
            await new KienThucVeSinhAttpService().getById(Number(item.id)).then(resp => {
                if (resp)
                    formik.setValues(resp);
            })
        }
    }

    useEffect(() => {
        initialData();
    }, [item])

    useEffect(() => {
        if (formik.values.ngayCapGxn != null && formik.values.ngayHetHanGxn == null)
            formik.setFieldValue("ngayHetHanGxn", moment(formik.values.ngayCapGxn).add({ year: 5 }));
    }, [formik.values.ngayCapGxn]);

    function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
        setIndexTab(newValue);
    }

    async function handleSubmit(data: KienThucVeSinhAttpDTO) {
        const submitData = { ...data };
        if (submitData.type === 0) {
            submitData.tenToChuc = "";
            submitData.soGcnDkdn = "";
            submitData.ngayCapGcnDkdn = null;
            submitData.noiCapGcnDkdn = ""
        } else {
            submitData.hoTen = "";
            submitData.soCmnd = "";
            submitData.ngayCapCmnd = null;
            submitData.noiCapCmnd = "";
        }
        await new KienThucVeSinhAttpService().addOrUpdate(submitData, LoaiChuyenNganh.CAP_MOI).then((response) => {
            if (response.isSuccess == true)
                formik.setFieldValue("id", response.data.id);
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
    }

    const handleChangeSelect = (value: any, ma: string, ten: string) => {
        formik.setFieldValue(ma, value?.ma);
        formik.setFieldValue(ten, value?.ten);
    }

    function handleSaveItemCaNhan(item: KienThucVeSinhAttpCaNhanDTO, index: number, isEdit: boolean) {
        let data = [...formik.values.listCaNhan];
        if (isEdit) {
            data[index] = item;
        } else {
            data.splice(0, 0, item);
        }
        formik.setFieldValue("listCaNhan", data);
    }

    function handleDeleteItemCaNhan(_item: KienThucVeSinhAttpCaNhanDTO, index: number) {
        let data = [...formik.values.listCaNhan];
        data.splice(index, 1);
        formik.setFieldValue("listCaNhan", data);
    }

    const handleOpenResetForm = () => {
        setOpenResetFormDlg(true);
    }

    const handleCloseResetForm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetForm();
        }
        setOpenResetFormDlg(false);
    };

    function handleResetForm() {
        formik.setValues({
            ...initialValues,
            id: formik.values.id,
            type: formik.values.type,
            ngayTao: formik.values.ngayTao,
            nguoiTaoId: formik.values.nguoiTaoId
        });
    }

    async function handleGetThongTinCongDanByCMND(): Promise<void> {
        let soCMND = formik.values.soCmnd;
        if (soCMND && !StringUtil.isNullOrEmty(soCMND)) {
            await new LgspService().layThongTinCongDanByCMND(soCMND.toString()).then((res) => {
                if (res.status === 200) {
                    if (res.data.data.data) {
                        const result = res.data.data.data;
                        if (!StringUtil.isNullOrEmty(result.cmt_NgayCap)) {
                            formik.setFieldValue("ngayCapCmnd", DateUtil.stringDDMMYYYtoDate(result.cmt_NgayCap))
                        }
                        if (!StringUtil.isNullOrEmty(result.cmt_NoiCap)) {
                            formik.setFieldValue("noiCapCmnd", result.cmt_NoiCap)
                        }
                        if (!StringUtil.isNullOrEmty(result.tenCongDan)) {
                            formik.setFieldValue("hoTen", result.tenCongDan)
                        }
                        // let updatedDiaChi: DiaChiDTO = formik.values.diaChi? { ...formik.values.diaChi }:{...DiaChiDTO_INT_CAN_THO};
                        // let isValidDiaChi = false;
                        // if (!StringUtil.isNullOrEmty(result.tinhID)) {
                        //     isValidDiaChi = true;
                        //     updatedDiaChi.diaChiTinhCode = result.tinhID;
                        //     if (!StringUtil.isNullOrEmty(result.quanID)) {
                        //         updatedDiaChi.diaChiHuyenCode = result.quanID;
                        //         if (!StringUtil.isNullOrEmty(result.phuongID)) {
                        //             updatedDiaChi.diaChiXaCode = result.phuongID;
                        //         }
                        //     }
                        // }
                        // if(isValidDiaChi){
                        //     formik.setFieldValue("diaChi", updatedDiaChi)
                        // }
                        dispatchNotifiAction(openNotification({ severity: "success", open: true, content: `Tra cứu số ${soCMND} thành công` }));
                    } else {
                        dispatchNotifiAction(openNotification({ severity: "error", open: true, content: `Không tìm thấy số ${soCMND}` }));
                        formik.setFieldValue("ngayCapCMT", null);
                        formik.setFieldValue("noiCapCMT", "");

                    }

                }
            })
        } else {
            dispatchNotifiAction(openNotification({ severity: "error", open: true, content: "Vui lòng nhập số CMND cần tra cứu" }));
        }
    }

    async function handleDownloadTemplate(fileId: String, fileName: String) {
        let request = {
            id: formik.values.id,
            filedId: fileId,

        } as any;
        await new KienThucVeSinhAttpService().handleDownloadTemplateFile(request).then((res) => {
            let blob = new Blob([res.data], { type: res.data.type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", String(fileName));
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{title}</span>
                </div>
                <Paper component="div">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={indexTab} onChange={handleChangeTab}>
                            <CustomTab label="Thông tin chung" />
                        </CustomTabs>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} className="grid-item">
                            <RadioGroup row value={formik.values.type}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    formik.setFieldValue("type", Number((event.target as HTMLInputElement).value));
                                }}>
                                <FormControlLabel value={0}
                                    control={<Radio size="small" color="default" />} label="Cá nhân" />
                                <FormControlLabel value={1}
                                    control={<Radio size="small" color="default" />} label="Tổ chức" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Đối tượng</InputLabel>
                            <NativeSelect input={<CustomInput />} fullWidth name="doiTuong" value={formik.values?.doiTuong} onChange={formik.handleChange} >
                                <option value={DoiTuong.NONE}>Chọn đối tượng</option>
                                <option value={DoiTuong.TO_CHUC_KD_SP_NLTS}>{getDoiTuongEnum(DoiTuong.TO_CHUC_KD_SP_NLTS)}</option>
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                required
                                label={"Số giấy xác nhận"}
                                name="soGxn"
                                value={formik.values.soGxn}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "soGxn") && getIn(formik.errors, "soGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "soGxn") && getIn(formik.errors, "soGxn")) && formik.errors?.soGxn}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <KeyboardDatePicker
                                name="ngayCapGxn"
                                label={"Ngày cấp"}
                                required
                                value={formik.values.ngayCapGxn}
                                onChange={(date, _val) => {
                                    formik.setFieldValue("ngayCapGxn", date);
                                }}
                                autoOk={true}
                                fullWidth
                                disableFuture
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngayCapGxn") && getIn(formik.errors, "ngayCapGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngayCapGxn") && getIn(formik.errors, "ngayCapGxn")) && formik.errors.ngayCapGxn}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <KeyboardDatePicker
                                name="ngayHetHanGxn"
                                label={"Ngày hết hạn"}
                                value={formik.values.ngayHetHanGxn}
                                onChange={(date, _val) => {
                                    formik.setFieldValue("ngayHetHanGxn", date);
                                }}
                                autoOk={true}
                                fullWidth
                                required
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngayHetHanGxn") && getIn(formik.errors, "ngayHetHanGxn"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngayHetHanGxn") && getIn(formik.errors, "ngayHetHanGxn")) && formik.errors.ngayHetHanGxn}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />
                        </Grid>
                        {formik.values.type == 0 && (<Grid container>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Họ tên"}
                                    name="hoTen"
                                    value={formik.values.hoTen}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "hoTen"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Số căn cước/CMND"}
                                    name="soCmnd"
                                    value={formik.values.soCmnd}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                        endAdornment: <EndAdornmentSearch onClick={handleGetThongTinCongDanByCMND} />,
                                    }}
                                    error={Boolean(getIn(formik.errors, "soCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "soCmnd")) && formik.errors?.soCmnd}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapCmnd"
                                    label={"Ngày cấp"}
                                    value={formik.values.ngayCapCmnd}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapCmnd", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "ngayCapCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "ngayCapCmnd")) && formik.errors.ngayCapCmnd}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapCmnd"
                                    value={formik.values.noiCapCmnd}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.errors, "noiCapCmnd"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.errors, "noiCapCmnd")) && formik.errors?.noiCapCmnd}
                                />
                            </Grid>
                        </Grid>)}
                        {formik.values.type == 1 && (<Grid container>
                            <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Số giấy CNĐKDN"}
                                    name="soGcnDkdn"
                                    value={formik.values.soGcnDkdn}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "soGcnDkdn") && getIn(formik.errors, "soGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "soGcnDkdn") && getIn(formik.errors, "soGcnDkdn")) && formik.errors?.soGcnDkdn}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapGcnDkdn"
                                    label={"Ngày cấp"}
                                    value={formik.values.ngayCapGcnDkdn}
                                    onChange={(date, _val) => {
                                        formik.setFieldValue("ngayCapGcnDkdn", date);
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "ngayCapGcnDkdn") && getIn(formik.errors, "ngayCapGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "ngayCapGcnDkdn") && getIn(formik.errors, "ngayCapGcnDkdn")) && formik.errors.ngayCapGcnDkdn}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapGcnDkdn"
                                    value={formik.values.noiCapGcnDkdn}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "noiCapGcnDkdn") && getIn(formik.errors, "noiCapGcnDkdn"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "noiCapGcnDkdn") && getIn(formik.errors, "noiCapGcnDkdn")) && formik.errors?.noiCapGcnDkdn}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Tên tổ chức"}
                                    name="tenToChuc"
                                    value={formik.values.tenToChuc}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    error={Boolean(getIn(formik.touched, "tenToChuc") && getIn(formik.errors, "tenToChuc"))}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(getIn(formik.touched, "tenToChuc") && getIn(formik.errors, "tenToChuc")) && formik.errors?.tenToChuc}
                                />
                            </Grid>
                        </Grid>)}
                        <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} disabled={false} />
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Đường"}
                                name="diaChi.duong"
                                value={formik.values?.diaChi?.duong}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Số nhà"}
                                name="diaChi.soNha"
                                value={formik.values?.diaChi?.soNha}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Số điện thoại"}
                                name="diaChi.soDienThoai"
                                value={formik.values?.diaChi?.soDienThoai}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Fax"}
                                name="diaChi.fax"
                                value={formik.values?.diaChi?.fax}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Email"}
                                name="diaChi.email"
                                value={formik.values?.diaChi?.email}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel classes={labelStyles} > Căn cứ </InputLabel>
                            <TextareaAutosize name="canCu" rows="2" value={formik.values.canCu} className={classes.textarea} onChange={formik.handleChange} />
                        </Grid>
                        {formik.values.type == 1 && (<Grid container>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles} style={{ fontStyle: "italic" }}>
                                    Danh sách đối tượng tham gia xác nhận kiến thức
                                </InputLabel>
                            </Grid>
                            {/* <Grid item xs={3} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Số căn cước/CMND"}
                                    name="soCmnd"
                                    value={caNhan.soCmnd}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    //error={Boolean(getIn(formik.errors, "soCmnd"))}
                                    //FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    //helperText={Boolean(getIn(formik.errors, "soCmnd")) && formik.errors?.soCmnd}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <KeyboardDatePicker
                                    name="ngayCapCmnd"
                                    label={"Ngày cấp"}
                                    value={caNhan.ngayCapCmnd}
                                    onChange={(date, _val) => {
                                        handleChangeDateCaNhan(date, _val, "ngayCapCmnd")
                                    }}
                                    autoOk={true}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "ngayCapCmnd"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "ngayCapCmnd")) && formik.errors.ngayCapCmnd}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Nơi cấp"}
                                    name="noiCapCmnd"
                                    value={caNhan.noiCapCmnd}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "noiCapCmnd"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "noiCapCmnd")) && formik.errors?.noiCapCmnd}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Họ tên"}
                                    name="hoTen"
                                    value={caNhan.hoTen}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "hoTen"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <TextField
                                    fullWidth
                                    required
                                    label={"Giới tính"}
                                    name="gioiTinh"
                                    value={caNhan.gioiTinh}
                                    onChange={handleChangeCaNhan}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    // error={Boolean(getIn(formik.errors, "hoTen"))}
                                    // FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    // helperText={Boolean(getIn(formik.errors, "hoTen")) && formik.errors?.hoTen}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Box className="button-group" marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={handleSaveItemCaNhan1}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={handleResetFormCaNhan}>
                                        Làm mới
                                    </Button>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} className="grid-item">
                                <CaNhan
                                    dataListItem={formik.values.listCaNhan}
                                    onSaveItem={handleSaveItemCaNhan} onDeleteItem={handleDeleteItemCaNhan} />
                            </Grid>
                        </Grid>)}
                        <Grid item xs={3} className="grid-item" >
                            <TextField
                                fullWidth
                                label={"Người ký"}
                                name="tenNguoiKy"
                                value={formik.values?.tenNguoiKy}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }} />
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <TextField
                                fullWidth
                                label={"Chức vụ"}
                                name="tenChucVu"
                                value={formik.values?.tenChucVu}
                                onChange={formik.handleChange}
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px">
                            <Button variant="contained" color="primary" disableElevation
                                onClick={() => formik.handleSubmit()}>
                                Lưu
                            </Button>
                            {formik.values.id && <InBieuMauButton onDownloadTemplate={handleDownloadTemplate} />}
                            <Button variant="contained" color="secondary" disableElevation
                                onClick={handleOpenResetForm}>
                                Xóa
                            </Button>
                            <ConfirmationDialogRaw id="confirm-dialog-reset-form" keepMounted open={openResetFormDlg}
                                title="Xóa thông tin"
                                content="Bạn có đồng ý xóa thông tin đã nhập?"
                                onClose={handleCloseResetForm}
                            />
                            <Button variant="contained" color="default" disableElevation onClick={() => history.go(-1)}>
                                Trở về
                            </Button>
                            {formik.values.id && formik.values.id > 0 &&
                                <>
                                    <ChuyenXuLyModal thongTinBuocDTO={buocChuyen} reloadData={() => history.go(-1)} onToggle={handleToggle} hoso={history.location.state as HoSoCuaToiDTO} show={showModal} />
                                    <DanhSachDuThaoModal maHoSo={String(formik.values.soBienNhan)} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
                                    <Button variant="contained" color="primary" disableElevation onClick={(e) => {
                                        showHidePopperThaoTac()
                                    }} ref={anchorRef} aria-haspopup="true"
                                        aria-controls={openBuocChuyen ? "menu-list-grow" : undefined}>
                                        Chuyển
                                    </Button>
                                    <Button variant="contained" color="primary" disableElevation onClick={handleToggleDuThao}>
                                        Dự thảo
                                    </Button>
                                    <Popper open={openBuocChuyen} anchorEl={anchorRef.current} role={undefined} transition>
                                        {({ TransitionProps, placement }) => (
                                            <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                                        <MenuList autoFocusItem={openBuocChuyen} id="menu-list-grow">
                                                            {buocChuyens.map((buocChuyen, k) => {
                                                                return (
                                                                    <MenuItem key={k}>
                                                                        <CustomButton
                                                                            startIcon={<ReplyIcon />}
                                                                            size="small"
                                                                            color="primary"
                                                                            disableRipple
                                                                            onClick={() => clickBuocChuyen(buocChuyen)}
                                                                        >
                                                                            {buocChuyen.tenBuocXuLy}
                                                                        </CustomButton>
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </>
                            }
                        </Box>
                    </Grid>
                </Paper>
            </div>
        </MuiPickersUtilsProvider>
    )
}