export interface DiaChiDTO {
    id?: Number | null;
    diaChiTinhCode?: String;
    diaChiTinhName?: String;

    diaChiHuyenCode?: String;
    diaChiHuyenName?: String;

    diaChiXaCode?: String;
    diaChiXaName?: String;

    duong?: String;
    tenDuong?: String;
    soNha?: String;

    soDienThoai?: String;
    fax?: String;
    email?: String;


}

export const DiaChiDTO_INT: DiaChiDTO = {
    diaChiTinhCode: "",
    diaChiTinhName: "",
    diaChiHuyenCode: "",
    diaChiHuyenName: "",
    diaChiXaCode: "",
    diaChiXaName: "",
    duong: "",
    tenDuong: "",
    soNha: "",
    soDienThoai: "",
    fax: "",
    email: "",
};

export const DiaChiDTO_INT_CAN_THO: DiaChiDTO = {
    diaChiTinhCode: "92",
    diaChiTinhName: "Thành phố Cần Thơ",
    diaChiHuyenCode: "",
    diaChiHuyenName: "",
    diaChiXaCode: "",
    diaChiXaName: "",
    duong: "",
    tenDuong: "",
    soNha: "",
    soDienThoai: "",
    fax: "",
    email: "",
};
export const DiaChiDTO_INT_CAN_THO_THONG_KE: DiaChiDTO = {
    diaChiTinhCode: "92",
    diaChiTinhName: "Thành phố Cần Thơ",
    diaChiHuyenCode: "916",
    diaChiHuyenName: "Quận Ninh Kiều",
    diaChiXaCode: "",
    diaChiXaName: "",
    duong: "",
    tenDuong: "",
    soNha: "",
    soDienThoai: "",
    fax: "",
    email: "",
};
