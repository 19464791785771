import { InputLabel } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { ColumnTable, CommonTableChuyenNganh, TypeCell } from "components/commons/table/commonTableChuyenNganh";
import { ChungNhanAttpSanPhamDTO, ChungNhanAttpSanPhamDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import React, { useState } from "react";
import { SanPhamDialog } from "./sanPhamDialog";

export interface DanhSachSanPhamPropsI {
    onDeleteItem?: (item: ChungNhanAttpSanPhamDTO, index: number) => void;
    onSaveItem?: (item: ChungNhanAttpSanPhamDTO, index: number, isEdit: boolean) => void;
    dataListItem: ChungNhanAttpSanPhamDTO[];
    disableAddButton?: boolean;
    viewOnly?: boolean;
    label?: string;
}

export const SanPham = (props: DanhSachSanPhamPropsI) => {
    const { onSaveItem, onDeleteItem, dataListItem, disableAddButton, viewOnly, label } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [openDlg, setOpenDlg] = useState(false);
    const [itemEdited, setItemEdited] = useState<ChungNhanAttpSanPhamDTO>({...ChungNhanAttpSanPhamDTO_INT});
    const [isEdit, setIsEdit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);

    const columns: ColumnTable<ChungNhanAttpSanPhamDTO>[] = [
        { title: "Tên sản phầm, sản xuất kinh doanh", field: "tenSanPham" },
        { title: "Tên nguyên liệu/ sản phẩm", field: "tenNguyenLieu" },
        { title: "Nguồn gốc/ xuất xứ", field: "nguonGoc" },
        { title: "Cách thức đóng gói và thông tin ghi trên bao bì", field: "cachDongGoi" },
        { title: "Diện tích (m2/ha)", field: "dienTich" },
        { title: "Số hộ", field: "soHo" },
        { title: "Sản lượng (Tấn/ kg)", field: "sanLuong" },
    ];

    function handleClickAdd() {
        setItemEdited({...ChungNhanAttpSanPhamDTO_INT});
        setIsEdit(false);
        setOpenDlg(true);
    }

    function handleSaveDialog(item: ChungNhanAttpSanPhamDTO) {
        setOpenDlg(false);
        onSaveItem && onSaveItem(item, indexEdit, isEdit);
    }

    function handleEditItem(item: ChungNhanAttpSanPhamDTO, index: number) {
        setItemEdited(item);
        setIsEdit(true);
        setIndexEdit(index);
        setOpenDlg(true);
    }
    return (
        <>
            <InputLabel classes={labelStyles}>{label}</InputLabel>
            <CommonTableChuyenNganh columns={columns} rows={dataListItem}
                onDelete={onDeleteItem ? onDeleteItem : undefined}
                onEdit={onSaveItem ? handleEditItem : undefined}
                viewOnly={viewOnly}
                onAdd={disableAddButton ? undefined : handleClickAdd} />
            {
                openDlg && <SanPhamDialog openDialog={openDlg} onDialogClose={() => setOpenDlg(false)}
                    onSubmitData={handleSaveDialog}
                    itemEdit={itemEdited}
                />
            }
        </>
    )
}