import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import { MSWordIcon, MSExcelIcon, PDFIcon } from "../icons/icons";


export interface ExportGroupPropsI {
    onClickWordButton?: () => void;
    onClickExcelButton?: () => void;
    onClickPDFButton?: () => void;
    onExportFile?: (downloadType: "MSWORD" | "EXCEL" | "PDF") => void;
}

export const ExportGroup = (props: ExportGroupPropsI) => {
    const { onClickWordButton, onClickExcelButton, onClickPDFButton, onExportFile } = props;
    return (
        <>
            <IconButton onClick={() => onExportFile && onExportFile("MSWORD")} disableRipple aria-label="export-ms-word">
                <MSWordIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => onExportFile && onExportFile("EXCEL")} disableRipple aria-label="export-excel">
                <MSExcelIcon style={{ color: green[500] }} />
            </IconButton>
            <IconButton onClick={() => onExportFile && onExportFile("PDF")} disableRipple aria-label="export-pdf">
                <PDFIcon color="secondary" />
            </IconButton>
        </>
    );
};
