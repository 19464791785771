
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParamExport } from "apis/baseService";
import { downLoadFile } from "apis/Common/exportUtil";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import { getIn, useFormik } from "formik";
import { KienThucVeSinhAttpDanhSachDTO, TraCuuRequestDTO, TraCuuRequestDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";


export const DanhSachKienThucVeSinhAttp = () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapGxnTu: Yup.date()
                .nullable()
                .when("ngayCapGxnDen", (ngayCapGxnDen: any, schema: any) => {
                    if (ngayCapGxnDen) {
                        return schema.max(ngayCapGxnDen, "Không hợp lệ");
                    }
                    return schema;
                }),
            ngayCapGxnDen: Yup.date()
                .nullable()
                .when("ngayCapGxnTu", (ngayCapGxnTu: any, schema: any) => {
                    if (ngayCapGxnTu) {
                        return schema.min(ngayCapGxnTu, "Không hợp lệ");
                    }
                    return schema;
                }),
        },
        [["ngayCapGxnTu", "ngayCapGxnDen"]]
    );

    const columns: ColumnCustomTable<KienThucVeSinhAttpDanhSachDTO>[] = [
        {
            title: "Số giấy xác nhận",
            field: "soGxn",
            type: TypeCell.LINK,
            actions: [{ title: "/qlcl-nong-lam-thuy-san/kien-thuc-ve-sinh-attp/chi-tiet" }],
        },
        { title: "Ngày cấp", field: "ngayCapGxn", type: TypeCell.DATE },
        { title: "Ngày hết hạn", field: "ngayHetHanGxn", type: TypeCell.DATE },
        { title: "Tổ chức/ Cá nhân", field: "tenCaNhanToChuc", width: "20%", align: "left" },
        { title: "Địa chỉ", field: "fullDiaChi", width: "30%", align: "left" },
        { title: "Loại đối tượng", field: "loaiDoiTuongStr" },
        {
            title: "Thao tác",
            actions: [
                {
                    title: "Chỉnh sửa",
                    icon: <EditIcon fontSize="small" color="primary" />,
                    func: (data: KienThucVeSinhAttpDanhSachDTO) => {
                        console.log("data")
                        if (data.soBienNhan) {
                            history.push({
                                pathname: "/qlcl-nong-lam-thuy-san/kien-thuc-ve-sinh-attp/cap-moi",
                                state: { maSoHoSo: data.soBienNhan }
                            });
                        } else {
                            history.push({
                                pathname: "/qlcl-nong-lam-thuy-san/kien-thuc-ve-sinh-attp/nhap-dau-ky",
                                state: { item: data }
                            });
                        }
                    }
                }
            ],
            type: TypeCell.ACTION,
            width: "5%",
        },
    ];

    //state
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);
    const [rows, setRows] = useState<KienThucVeSinhAttpDanhSachDTO[]>([]);

    // hook
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();


    const initialValues: TraCuuRequestDTO = { ...TraCuuRequestDTO_INT };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
        validateOnChange: false,
    });

    useEffect(() => {
        async function fetchData() {
            await new KienThucVeSinhAttpService().getDanhSach(formik.values, pageParams.page, pageParams.rowsPerPage).then((response) => {
                setRows(response.rows);
                setPageParams((prev) => ({ ...prev, total: response.total }));
            });
        }
        fetchData();
    }, [pageParams.page, pageParams.rowsPerPage]);
    // Control
    async function handleSubmit(request: TraCuuRequestDTO) {
        setPageParams((prev) => ({ ...prev, page: 0 }));
        await new KienThucVeSinhAttpService().getDanhSach(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
            setRows(response.rows);
            setPageParams((prev) => ({ ...prev, total: response.total }));
        });
    }

    function handleOnChangePage(value: number) {
        let pageParamsNew = { ...pageParams, page: value };
        setPageParams(pageParamsNew);
    }

    function handOnChangeRowsPerPage(value: number) {
        let pageParamsNew = { ...pageParams, page: 0, rowsPerPage: value };
        setPageParams(pageParamsNew);
    }

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        let data = { ...formik.values };
        let newParams: ParamExport = { pageNumber: pageParams.page, pageSize: pageParams.rowsPerPage, downloadType: downloadType };
        await new KienThucVeSinhAttpService().danhSachExport(data, newParams).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Tra-cuu-kien-thuc-ve-sinh-attp-]${new Date()}`);
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'DANH SÁCH GIẤY XÁC NHẬN KIẾN THỰC VỆ SINH AN TOÀN THỰC PHẨM'}</div>
                <Grid container>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số giấy xác nhận"}
                            name="soGxn"
                            value={formik.values.soGxn}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapGxnTu"
                            label={"Ngày cấp từ"}
                            value={formik.values.ngayCapGxnTu}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapGxnTu", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapGxnTu") && getIn(formik.errors, "ngayCapGxnTu"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapGxnTu") && getIn(formik.errors, "ngayCapGxnTu")) && formik.errors?.ngayCapGxnTu}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapGxnDen"
                            label={"Ngày cấp đến"}
                            value={formik.values.ngayCapGxnDen}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapGxnDen", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapGxnDen") && getIn(formik.errors, "ngayCapGxnDen"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapGxnDen") && getIn(formik.errors, "ngayCapGxnDen")) && formik.errors?.ngayCapGxnDen}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Loại đối tượng</InputLabel>
                        <NativeSelect input={<CustomInput />} fullWidth name="type" value={formik.values?.type} onChange={formik.handleChange} >
                            <option value={-1}>--Chọn--</option>
                            <option value={1}>Tổ chức</option>
                            <option value={0}>Cá nhân</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Tổ chức/ Cá nhân"}
                            name="tenCaNhanToChuc"
                            value={formik.values.tenCaNhanToChuc}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                        />
                    </Grid>
                    <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true}/>
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tìm kiếm
                            </Button>
                            <Button variant="contained" color="primary" disableElevation
                                onClick={() => history.push("/qlcl-nong-lam-thuy-san/kien-thuc-ve-sinh-attp/nhap-dau-ky")}>
                                Nhập đầu kỳ
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handOnChangeRowsPerPage}
                            pageParams={pageParams}
                        />
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>
    )
}