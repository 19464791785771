import apis from "apis";
import { BaseService } from "apis/baseService";

export interface DashboardThuySanDTO {
    thang: number;
    tenThang: string;
    tauCa: number;
    beCa: number;
}
export class DashboarThuySanService extends BaseService {
    loadDataDashboard = (nam: number) => {
        return apis.post("thuy-san/dashboard/load-data", { nam: nam });
    };

}
