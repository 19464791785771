import { Avatar, Button, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import StringUtil from 'utils/stringUtils';
import { dispatchLogout, dispatchLogoutLocalAdmin } from '../../actions/signOut';
import { UserManagementService } from '../../apis/userMgt/userManagementService';
import { AuthenticateAction, doLogin, doLogout } from '../../redux/actions/AuthenticateAction';
import { UserInfo } from '../../redux/reducers/authenticateReducer';
import { RootState } from '../../redux/reducers/rootReducer';
type UserData = {
    sessionValue: string;
    userId: string;
    superAdmin: boolean;
    userName: string;
    fullName: string;
};
const useStyles = makeStyles({
    root: {
        backgroundColor: "#367FA9",
        padding: "5px 15px",
        fontSize: "14px",
        color: "#fff",
        display: "flex",
        alignItems: "center",
    },
    headerLeft: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        '& span': {
            marginLeft: "15px",
        },
    },
    headerRight: {
        display: "flex",
        alignItems: "center",
        '& span': {
            marginLeft: "10px",
        }
    },
    button: {
        backgroundColor: "#367FA9",
        color: "#fff",
        textTransform: "none",
        '&:hover': {
            backgroundColor: "#367FA9",
        },
        padding: "8px 2px",
    }
});

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop: '12px',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export const HeaderBar = () => {
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const authenticate = useSelector((state: RootState) => state.authenticate);
    const dispatch = useDispatch<Dispatch<AuthenticateAction>>();

    const getUserInfo = useCallback(async () => {
        let userId = localStorage.getItem("C_USER");
        let C_USER_DATA_DE = {} as UserData;
        if (localStorage.getItem("C_USER_DATA") != null) {
            C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
        }
        let accessToken = localStorage.getItem("ACCESS_TOKEN");
        if (C_USER_DATA_DE && C_USER_DATA_DE.userName && !StringUtil.isNullOrEmty(C_USER_DATA_DE.userName)) {
            let user: UserInfo = {
                type: userId ? "LOCAL_ADMIN" : "SSO_USER",
                userName: C_USER_DATA_DE.userName,
                avatar: "",
                fullName: C_USER_DATA_DE.fullName
            }
            setUserInfo(user);
            dispatch(doLogin(user));
        } else if (accessToken) {
            await new UserManagementService().getUserInfoByAccessToken(accessToken).then(p => {
                if (p.isSuccess && p.data && p.data.sub) {
                    let user = { type: "SSO_USER", userName: p.data.sub } as UserInfo;
                    setUserInfo(user);
                    dispatch(doLogin(user));
                } else {
                    dispatchLogout();
                }
            }).catch(e => {
                console.log(e);
                dispatchLogout();
            });
        } else {
            dispatchLogout();
        }
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        if (authenticate.userInfo.type === 'LOCAL_ADMIN') {
            dispatch(doLogout());
            dispatchLogoutLocalAdmin();
            window.location.href = "/login";
            return;
        } else {
            dispatchLogout();
        }
    }


    return (
        <div className={classes.root}>
            <div className={classes.headerLeft}>
                <NavLink exact to="/">
                    <img src={process.env.PUBLIC_URL + "/images/quochuy.svg.png"} alt="logo" width="50" />
                </NavLink>
                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>CHÍNH QUYỀN ĐIỆN TỬ THÀNH PHỐ CẦN THƠ </span>

                <span>|</span>
                <span>Sở Nông nghiệp và Phát triển nông thôn</span>
            </div>
            <div className={classes.headerRight}>
                <Avatar />
                <Button aria-controls="customized-menu" aria-haspopup="true" className={classes.button}
                    endIcon={Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    variant="contained" disableElevation disableRipple onClick={handleClick} >
                    {userInfo.fullName ? userInfo.fullName : userInfo.userName}
                </Button>
                <StyledMenu
                    id="customized-menu" anchorEl={anchorEl}
                    keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                    <NavLink className="sub-menu-header-bar" exact to="/user-info" onClick={() => handleClose()}>
                        <MenuItem>
                            <ListItemIcon style={{ minWidth: "30px" }}>
                                <PermIdentityOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Thông tin cá nhân" />
                        </MenuItem>
                    </NavLink>
                    <NavLink className="sub-menu-header-bar" exact to="/password-mgt" onClick={() => handleClose()}>
                        <MenuItem>
                            <ListItemIcon style={{ minWidth: "30px" }}>
                                <LockOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Đổi mật khẩu" />
                        </MenuItem>
                    </NavLink>
                    <MenuItem onClick={() => handleLogout()}>
                        <ListItemIcon style={{ minWidth: "30px" }}>
                            <PowerSettingsNewOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Thoát" />
                    </MenuItem>
                </StyledMenu>
            </div>
        </div>
    )
}