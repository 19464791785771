const CONFIG_LIVE = {
    "AUTHORIZE_ENDPOINT": "https://login.cantho.gov.vn/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "USER_ROOT": "ZAD",
    "SCOPE": "openid cccc",
    "REDIRECT_URI": "https%3A%2F%2Fnongnghiep.congchuc.cantho.gov.vn",
    "CLIENT_ID": "M5uVFvQ5SOzyMjMuW4OB_oGia0ga",
    "CLIENT_SECRET": "b5FDd2FQcSLR8efV4pOgJh0CczIa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "*",
    "LOGOUT_URL": "https://login.cantho.gov.vn/oidc/logout",
    "COOKIE_PATH": "/"
};
const CONFIG_TEST = {
    "AUTHORIZE_ENDPOINT": "https://login.cantho.gov.vn/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "USER_ROOT": "ZAD",
    "SCOPE": "openid cccc",
    "REDIRECT_URI": "https%3A%2F%2Fnongnghiep.congchuc.cantho.gov.vn",
    "CLIENT_ID": "K3OvNndCOOZOq_Brj8SDhfJxlRca",
    "CLIENT_SECRET": "NNWaZx4OXh3rU2qJw7YBtPzxwswa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "*",
    "LOGOUT_URL": "https://login.cantho.gov.vn/oidc/logout",
    "COOKIE_PATH": "/"
};

export const CONFIG = CONFIG_LIVE;
export const AUTHORIZE_REQUEST = `${CONFIG.AUTHORIZE_ENDPOINT}?response_type=${CONFIG.RESPONSE_TYPE}&user_root=${CONFIG.USER_ROOT}&scope=${CONFIG.SCOPE}&redirect_uri=${CONFIG.REDIRECT_URI}&client_id=${CONFIG.CLIENT_ID}`;
export const ENDPOINT = {
    "USER_INFO_ENDPOINT": "/user-mgt/sso/userInfo",
    "TOKEN_ENDPOINT": "/user-mgt/sso/accessToken",
}