import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    withStyles,
} from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { Pagination } from "@material-ui/lab";
import * as React from "react";
import { CustomInput } from "../input/customInput";
import { Cell, Row } from "../table/tableCommons";
import { Action, TypeCell, Pageable } from "./customTableInterface";
import CustomCellTableCustom from "./customCellTableCustom";

export interface ColumnCustomTable<T> {
    width?: number | string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    field?: keyof T;
    title: string;
    type?: TypeCell;
    actions?: Action<T>[];
    rowSpan?: number;
    colSpan?: number;
}

export interface CustomTableProps<T> {
    columns: ColumnCustomTable<T>[];
    rows: T[];
    tableHeader?: ColumnCustomTable<T>[][] | null;
    // Option End Table Show Button
    onAdd?: () => void;

    reduceSumField?: Number[];

    pageParams?: Pageable;
    onChangePage?: (value: number) => void;
    onChangeRowsPerPage?: (rowsPerPage: number) => void;
}

const StyledTablePagination = withStyles({
    root: {
        borderBottom: 0,
    },
    toolbar: {
        padding: "0",
        "& .MuiPagination-root": {
            order: "-1",
            marginRight: "10px",
        },
        "& .MuiTablePagination-caption:nth-last-child(2)": {
            flexGrow: 1,
            textAlign: "right",
        },
    },
    spacer: {
        flex: 0,
    },
})(TablePagination);

export const CustomTable = <T extends object>({
    columns,
    onChangePage,
    onChangeRowsPerPage,
    pageParams,
    rows,
    tableHeader,
    onAdd,
    reduceSumField,
}: CustomTableProps<T>) => {
    const handleChangePage = (newPage: number) => {
        onChangePage !== undefined && onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChangeRowsPerPage !== undefined && onChangeRowsPerPage(parseInt(event.target.value));
    };

    React.useEffect(() => {
        if (pageParams) {
            if (rows.length === 0 && pageParams?.page !== 0) {
                handleChangePage(pageParams?.page - 1);
            }
        }
    }, [rows.length]);

    function counterSTT(): number {
        if (pageParams) {
            return pageParams.page * pageParams.rowsPerPage;
        }
        return 0;
    }

    return (
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    {!tableHeader && (
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            {columns.map((col, i) => (
                                <Cell key={i} component="th" scope="row" align={"center"} width={col.width}>
                                    {col.title}
                                </Cell>
                            ))}
                        </TableRow>
                    )}
                    {tableHeader &&
                        tableHeader.map((header) => (
                            <TableRow>
                                {header.map((col, i) => (
                                    <Cell
                                        key={`table-header-${i}`}
                                        component="th"
                                        scope="row"
                                        align={col.align ? col.align : "center"}
                                        width={col.width}
                                        colSpan={col.colSpan}
                                        rowSpan={col.rowSpan}
                                    >
                                        {col.title}
                                    </Cell>
                                ))}
                            </TableRow>
                        ))}
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((item: T, i) => (
                            <Row key={i}>
                                <Cell scope="row" align="center">
                                    {i + 1 + counterSTT()}
                                </Cell>
                                {columns.map(({ field, type, actions, align }, j) => {
                                    return (
                                        <CustomCellTableCustom
                                            key={`table-body-${i + j}`}
                                            item={item}
                                            field={field}
                                            type={type}
                                            actions={actions}
                                            align={align && align}
                                        />
                                    );
                                })}
                            </Row>
                        ))}
                    <TableRow>
                        {rows.length > 0 && reduceSumField && (
                            <Cell colSpan={2} scope="row" align="center" style={{ fontWeight: "bold" }}>
                                Tổng số
                            </Cell>
                        )}
                        {rows.length > 0 &&
                            reduceSumField &&
                            reduceSumField.map((value, index) => {
                                return (
                                    <Cell key={index} scope="row" align="center" style={{ fontWeight: "bold" }}>
                                        {value}
                                    </Cell>
                                );
                            })}
                    </TableRow>
                    {rows.length === 0 && (
                        <TableRow>
                            <Cell colSpan={columns.length + 2} style={{ padding: "8px", textAlign: "center" }}>
                                Không có dữ liệu
                            </Cell>
                        </TableRow>
                    )}
                </TableBody>
                {pageParams !== undefined && (
                    <TableFooter>
                        <TableRow>
                            <StyledTablePagination
                                labelRowsPerPage={"Số mẫu tin trên trang"}
                                labelDisplayedRows={({ from, to, count }) =>
                                    onAdd ? (
                                        <Button variant="contained" color="primary" disableElevation onClick={onAdd}>
                                            Thêm mới
                                        </Button>
                                    ) : (
                                        `Mẫu tin ${from} - ${to}/${count}`
                                    )
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={pageParams.total}
                                rowsPerPage={pageParams.rowsPerPage}
                                page={pageParams.page}
                                SelectProps={{
                                    input: <CustomInput fullWidth={false} />,
                                    inputProps: { "aria-label": "Số mẫu tin trên trang" },
                                    native: true,
                                }}
                                onPageChange={(_e, newPage) => handleChangePage(newPage)}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const {  onPageChange, count, rowsPerPage } = props;

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        onPageChange(null, value - 1);
    };

    return (
        <Pagination
            count={Math.ceil(count / rowsPerPage)}
            defaultPage={1}
            siblingCount={1}
            variant="outlined"
            size="medium"
            color="primary"
            shape="rounded"
            showFirstButton
            showLastButton
            disabled={Math.ceil(count / rowsPerPage) === 1}
            onChange={handleOnChangePage}
        />
    );
};
