import React from "react";
import Dropzone from "react-dropzone-uploader";
import { Box, Grid, InputLabel } from "@material-ui/core";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import Button from "@material-ui/core/Button";
import PreviewDropzoneFromServer from "./PreviewFileFromServer";
import { FileEntry } from "apis/Common/fileEntry";
import LayoutDrop from "components/commons/layoutDropZone/layoutDrop";
import { PreviewDropZone } from "components/commons/layoutDropZone/previewDropZone";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";

interface IDropZoneCustom {
    acceptFiles: String;
    attachFileServer: FileEntry[];
    url: String;
    handleChangeStatus: (file: IFileWithMeta) => void;
    handleDeleteFileServer: (file: FileEntry, index: number) => void;
    handleDownloadFile: (file: FileEntry) => void;
}

const DropZoneCustom: React.FC<IDropZoneCustom> = ({ acceptFiles, attachFileServer, url, handleDeleteFileServer, handleChangeStatus, handleDownloadFile }) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    return (
        <>
            <Grid item xs={2}>
                <InputLabel classes={labelStyles} >Đính kèm</InputLabel>
            </Grid>
            <Grid item xs={10}>
                {attachFileServer && (
                    <label htmlFor="dzu-input">
                        <Box width="456px">
                            <PreviewDropzoneFromServer files={attachFileServer} onDelete={handleDeleteFileServer} onDownload={handleDownloadFile} />
                        </Box>
                    </label>
                )}
                <Box width="456px">
                    <Dropzone
                        accept={`${acceptFiles}`}
                        inputContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        inputWithFilesContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        multiple={true}
                      //  getUploadParams={() => ({ url: `${url}` })}
                    getUploadParams={(file: IFileWithMeta) => {
                        const formData = new FormData();
                        formData.append("file", file.file);
                        let headers: any = {
                            'csrf-token': localStorage.getItem("csrf-token")
                        }
                        return {
                            url: `${url}`,
                            headers: headers,
                            body: formData,
                        };
                    }}  
                      onChangeStatus={handleChangeStatus}
                        //InputComponent={(props: any) => <InputCustom acceptFiles={`${acceptFiles}`} {...props} />}
                        LayoutComponent={(props: any) => <LayoutDrop {...props} acceptFiles={acceptFiles} />}
                        PreviewComponent={(props: any) => <PreviewDropZone {...props} />}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default DropZoneCustom;
