import { DiaChiDTO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { AttachFileDTO } from "model/chuyenNganh/attachFile";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { LoaiKetQuaEnum } from "apis/quanTri/model/loaiGiayPhep/loaiKetQuaEnum";

// export const loaiHinhQCOPtions: OptionDefault[] = [
//     { title: "Chọn loại quảng cáo", value: "" },
//     { title: "Băng rôn", value: "0" },
//     { title: "Treo xe", value: "1" },
//     { title: "Hội thảo nông dân", value: "2" },
//     { title: "Trình diễn sản phẩm", value: "3" },
//     { title: "Treo barner quảng cáo", value: "4" },
// ];

export interface ThuocBaoVeThucVatDTO {
    id?: Number | null;
    tenThuocBaoVe?: String;
    giayChungNhan?: String;
    phuongTienQuangCao?: String;
}

export interface ThoiGianDiaDiemDTO {
    id?: Number | null;
    thoiGian?: String;
    diaDiem?: String;
}

export interface ThuocBaoVeThucVatQuangCaoDTO {
    id: Number | null;
    soBienNhan: String;
    tenToChuc: String;
    nguoiChiuTrachNhiemDangKy?: String;
    sdtNguoiChiuTrachNhiemDangKy?: String;
    diaChi?: DiaChiDTO;
    fullDiaChi?: String;
    soGiayPhepHoatDong?: String;
    loaiHinhQuangCao?: String;
    soLuong?: String;
    lstThuocBVTV: ThuocBaoVeThucVatDTO[];
    lstThoiGianDiaDiem: ThoiGianDiaDiemDTO[];
    soGiayXacNhan: String;
    ngayCap: Date | null;
    ghiChu?: String;
    attachFiles?: AttachFileDTO[];

    layKetQua?: boolean;
    loaiKetQua?: LoaiKetQuaEnum;
}

export interface ThuocBaoVeThucVatQuangCaoTraCuuDTO {
    id?: Number | null;
    soBienNhan?: String;
    soGiayXacNhan?: String;
    ngayCap?: Date | null;
    tenToChuc?: String;
    diaChi?: DiaChiDTO;
    fullDiaChi?: String;
    soDienThoai?: String;
    loaiHinhQuangCao?: String;
    tenLoaiHinhQuangCao?: String;

    ngayCapFrom: Date | null;
    ngayCapTo: Date | null;
    mode?: String;
    thang?: String;
    soLuong?: Number;
    year?: Number;

    tenThuocBaoVeTV?: String;
    tenDiaDiem?: String;
    tenThoiGian?: String;
}

export const ThuocBaoVeThucVatDTO_INT: ThuocBaoVeThucVatDTO = {
    tenThuocBaoVe: "",
    giayChungNhan: "",
    phuongTienQuangCao: ""
}

export const optionsThongKe: OptionDefault[] = [
    { title: "Năm", value: "YEAR" },
    { title: "Tháng", value: "MONTH" },
    { title: "Khoảng thời gian", value: "FROM_TO" },
];
const year = new Date().getFullYear();

const years = Array.from(new Array(5), (val, index) => year - index);

export const optionNam: OptionDefault[] = [];

years.forEach((year) => {
    optionNam.push({ title: `Năm ${year}`, value: `${year}` });
});

const months = Array.from(new Array(12), (val, index) => index + 1);

export const optionThang: OptionDefault[] = [];

months.forEach((i) => {
    optionThang.push({ title: `Tháng ${i}`, value: `${i}` });
});
