import { Grid, GridSize, InputBaseProps, InputClassKey, StandardProps, TextField } from "@material-ui/core";
import { DanhMucService } from "apis/danhMuc/danhMucService";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn } from "formik";
import { DanhMucDiaChiDTO } from "model/danhMuc/danhMucDTO";
import React, { useEffect } from "react";
import { useState } from "react";
import StringUtil from "utils/stringUtils";
import Asynchronous from "./asynchronousAutoComplete";

export interface DiaChiDonViHanhChinhPropsI {
    formik: any;
    diaChiOf: String;
    required?: boolean;
    allTT?: boolean;
    InputProps?: Partial<StandardProps<InputBaseProps, InputClassKey>>;
    disabled?: boolean;
    hideTinhThanh?: boolean;
    hidePhuongXa?: boolean;
    xs?: GridSize;
}

export const DiaChiDonViHanhChinh = (props: DiaChiDonViHanhChinhPropsI) => {
    const { formik, diaChiOf, required = true, allTT, disabled = false, hideTinhThanh = false, hidePhuongXa = false, xs = 3 } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [tinhSelected, setTinhSelected] = useState<DanhMucDiaChiDTO | null | undefined>({ code: "", name: "" } as DanhMucDiaChiDTO);
    const [huyenSelected, setHuyenSelected] = useState<DanhMucDiaChiDTO | null | undefined>({ code: "", name: "" } as DanhMucDiaChiDTO);
    const [xaSelected, setXaSelected] = useState<DanhMucDiaChiDTO | null | undefined>({ code: "", name: "" } as DanhMucDiaChiDTO);
    // const [duongSelected, setDuongSelected] = useState<DanhMucDiaChiDTO | null | undefined>({ code: "", name: "" } as DanhMucDiaChiDTO);

    const [dataTinhThanh] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    // const [dataDuong, setDataDuong] = useState<DanhMucDiaChiDTO[]>([]);

    useEffect(() => {
        // if (disabled) {
        //     formik.setFieldValue(`${diaChiOf}.diaChiTinhName`, "Thành phố Cần Thơ");
        //     formik.setFieldValue(`${diaChiOf}.diaChiTinhCode`, "92");
        // }
        setLoading(true);

        return () => {
            setLoading(false);
        };
    }, []);

    React.useEffect(() => {
        loading && getDm();
    }, [loading]);

    async function getDm() {
        // if (disabled) {
        //     await new DanhMucService().getDanhMucDiaChi("92").then((resp) => {
        //         setDataQuanHuyen(resp);
        //         setDataXaPhuong([]);
        //     });
        // }
    }

    useEffect(() => {
        formik.values[`${diaChiOf}`]["diaChiTinhCode"]
            ? setTinhSelected({ name: formik.values[`${diaChiOf}`]["diaChiTinhName"], code: formik.values[`${diaChiOf}`]["diaChiTinhCode"] })
            : setTinhSelected({ name: "", code: "" });
        formik.values[`${diaChiOf}`]["diaChiHuyenCode"]
            ? setHuyenSelected({ name: formik.values[`${diaChiOf}`]["diaChiHuyenName"], code: formik.values[`${diaChiOf}`]["diaChiHuyenCode"] })
            : setHuyenSelected({ name: "", code: "" });
        formik.values[`${diaChiOf}`]["diaChiXaCode"]
            ? setXaSelected({ name: formik.values[`${diaChiOf}`]["diaChiXaName"], code: formik.values[`${diaChiOf}`]["diaChiXaCode"] })
            : setXaSelected({ name: "", code: "" });
    }, [formik.values[`${diaChiOf}`]]);

    useEffect(() => {
        let diaChiTinhCode = "", diaChiTinhName = "", diaChiHuyenCode = "", diaChiHuyenName = "", diaChiXaCode = "", diaChiXaName = "";

        if (formik.values[`${diaChiOf}`]) {
            diaChiTinhCode = formik.values[`${diaChiOf}`]["diaChiTinhCode"];
            diaChiTinhName = formik.values[`${diaChiOf}`]["diaChiTinhName"];

            diaChiHuyenCode = formik.values[`${diaChiOf}`]["diaChiHuyenCode"];
            diaChiHuyenName = formik.values[`${diaChiOf}`]["diaChiHuyenName"];

            diaChiXaCode = formik.values[`${diaChiOf}`]["diaChiXaCode"];
            diaChiXaName = formik.values[`${diaChiOf}`]["diaChiXaName"];
        }

        if (!StringUtil.isNullOrEmty(diaChiTinhCode)) {
            if (StringUtil.isNullOrEmty(diaChiTinhName)) {
                new DanhMucService().getDanhMucDiaChiByCodeAndParentCode(diaChiTinhCode, "").then((resp) => {
                    if (resp) {
                        formik.setFieldValue(`${diaChiOf}.diaChiTinhName`, resp.name);
                        formik.setFieldValue(`${diaChiOf}.diaChiTinhCode`, resp.code);
                        setTinhSelected({ name: resp.name, code: resp.code });
                    }
                });
            } else {
                setTinhSelected({ name: diaChiTinhName, code: diaChiTinhCode });
            }

            if (!StringUtil.isNullOrEmty(diaChiHuyenCode)) {
                if (StringUtil.isNullOrEmty(diaChiHuyenName)) {
                    new DanhMucService().getDanhMucDiaChiByCodeAndParentCode(diaChiHuyenCode, diaChiTinhCode).then((resp) => {
                        if (resp) {
                            formik.setFieldValue(`${diaChiOf}.diaChiHuyenName`, resp.name);
                            formik.setFieldValue(`${diaChiOf}.diaChiHuyenCode`, resp.code);
                            setHuyenSelected({ name: resp.name, code: resp.code });
                        }
                    });
                } else {
                    setHuyenSelected({ name: diaChiHuyenName, code: diaChiHuyenCode });
                }
                if (!StringUtil.isNullOrEmty(diaChiXaCode)) {
                    if (StringUtil.isNullOrEmty(diaChiXaName)) {
                        new DanhMucService().getDanhMucDiaChiByCodeAndParentCode(diaChiXaCode, diaChiHuyenCode).then((resp) => {
                            if (resp) {
                                formik.setFieldValue(`${diaChiOf}.diaChiXaName`, resp.name);
                                formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, resp.code);
                                setXaSelected({ name: resp.name, code: resp.code });
                            }
                        });
                    } else {
                        setXaSelected({ name: diaChiXaName, code: diaChiXaCode });
                    }
                }
            }
        } else {
            setTinhSelected({ name: diaChiTinhName, code: diaChiTinhCode });
            setHuyenSelected({ name: diaChiHuyenName, code: diaChiHuyenCode });
            setXaSelected({ name: diaChiXaName, code: diaChiXaCode });
        }
        // }, [formik.values[`${diaChiOf}`]["diaChiTinhCode"], formik.values[`${diaChiOf}`]["diaChiHuyenCode"], formik.values[`${diaChiOf}`]["diaChiXaCode"]]);
    }, [formik.values[`${diaChiOf}`]]);

    async function handleChangeTinhThanh(item: DanhMucDiaChiDTO) {
        formik.setFieldValue(`${diaChiOf}.diaChiTinhName`, item.name);
        formik.setFieldValue(`${diaChiOf}.diaChiTinhCode`, item.code);
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenName`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, "");
        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");

        await new DanhMucService().getDanhMucDiaChi(String(item.code)).then((resp) => {
            setDataQuanHuyen(resp);
            setDataXaPhuong([]);
        });
    }

    async function handleChangeQuanHuyen(item: DanhMucDiaChiDTO) {
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenName`, item.name);
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenCode`, item.code);
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, "");
        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
        await new DanhMucService().getDanhMucDiaChi(String(item.code)).then((resp) => {
            setDataXaPhuong(resp);
        });
    }

    async function handleChangeXaPhuong(item: DanhMucDiaChiDTO) {
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, item.name);
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, item.code);
        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
        // await new DanhMucService().getDanhMucDiaChi(String(item.code)).then((resp) => {
        //     setDataDuong(resp);
        // });
    }

    // async function handleChangeDuong(item: DanhMucDiaChiDTO) {
    //     formik.setFieldValue(`${diaChiOf}.maDuong`, item.code);
    //     formik.setFieldValue(`${diaChiOf}.tenDuong`, item.code);
    // }

    function clearTinh() {
        formik.setFieldValue(`${diaChiOf}.diaChiTinhName`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiTinhCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenName`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, "");
        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
        setTinhSelected({ name: "", code: "" });
        setHuyenSelected({ name: "", code: "" });
        setXaSelected({ name: "", code: "" });
        setDataQuanHuyen([]);
        setDataXaPhuong([]);
        // setDataDuong([]);
    }

    function clearHuyen() {
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenName`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiHuyenCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, "");
        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
        setHuyenSelected({ name: "", code: "" });
        setXaSelected({ name: "", code: "" });
        setDataXaPhuong([]);
        // setDataDuong([]);
    }

    function clearXa() {
        formik.setFieldValue(`${diaChiOf}.diaChiXaCode`, "");
        formik.setFieldValue(`${diaChiOf}.diaChiXaName`, "");
        setXaSelected({ name: "", code: "" });

        // formik.setFieldValue(`${diaChiOf}.maDuong`, "");
        // formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
        // setDataDuong([]);
    }

    // function clearDuong() {
    //     formik.setFieldValue(`${diaChiOf}.maDuong`, "");
    //     formik.setFieldValue(`${diaChiOf}.tenDuong`, "");
    // }

    return (
        <>
            {!hideTinhThanh && (
                <Grid item xs={xs} className="grid-item">
                    <Asynchronous
                        id={`${diaChiOf}-tinh-comc`}
                        label="Tỉnh/Thành phố"
                        placeholder="-- Chọn tỉnh thành --"
                        // disabled={disabled}
                        name="diaChiTinhCode"
                        value={tinhSelected}
                        dataOption={dataTinhThanh}
                        required={required}
                        level={1}
                        onChange={(item) => handleChangeTinhThanh(item)}
                        formik={formik}
                        diaChiOf={diaChiOf}
                        clearFunc={clearTinh}
                    />
                </Grid>
            )}
            <Grid item xs={xs} className="grid-item">
                <Asynchronous
                    id={`${diaChiOf}-huyen-comc`}
                    label="Quận/Huyện"
                    placeholder="-- Chọn Quận/Huyện --"
                    name="diaChiHuyenCode"
                    value={huyenSelected}
                    dataOption={dataQuanHuyen}
                    required={required}
                    level={2}
                    onChange={(item) => handleChangeQuanHuyen(item)}
                    dependency={tinhSelected}
                    formik={formik}
                    diaChiOf={diaChiOf}
                    clearFunc={clearHuyen}
                />
            </Grid>
            {!hidePhuongXa && (
                <Grid item xs={xs} className="grid-item">
                    <Asynchronous
                        id={`${diaChiOf}-xa-comc`}
                        name="diaChiXaCode"
                        label="Phường/Xã/TT"
                        placeholder="-- Chọn Phường/Xã/TT --"
                        value={xaSelected}
                        required={required}
                        level={3}
                        dataOption={dataXaPhuong}
                        onChange={(item) => handleChangeXaPhuong(item)}
                        dependency={huyenSelected}
                        formik={formik}
                        diaChiOf={diaChiOf}
                        clearFunc={clearXa}
                    />
                </Grid>
            )}
            {allTT && (
                <>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Đường"}
                            name={`${diaChiOf}.duong`}
                            value={formik.values[`${diaChiOf}`] && formik.values[`${diaChiOf}`]["duong"]}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                        />
                    </Grid>

                    {/* <Grid item xs={3} className="grid-item">
                        <Asynchronous
                            id={`${diaChiOf}-xa-comc`}
                            name="maDuong"
                            label="Đường"
                            value={duongSelected}
                            level={4}
                            dataOption={dataDuong}
                            onChange={(item) => handleChangeDuong(item)}
                            dependency={xaSelected}
                            formik={formik}
                            diaChiOf={diaChiOf}
                            clearFunc={clearDuong}
                        />
                    </Grid> */}

                    <Grid item xs={props.InputProps ? 12 : 3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số nhà"}
                            name={`${diaChiOf}.soNha`}
                            value={formik.values[`${diaChiOf}`] && formik.values[`${diaChiOf}`]["soNha"]}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles, ...props.InputProps }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số điện thoại"}
                            name={`${diaChiOf}.soDienThoai`}
                            value={formik.values[`${diaChiOf}`] && formik.values[`${diaChiOf}`]["soDienThoai"]}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(
                                getIn(formik.touched, `${diaChiOf}.soDienThoai`) && getIn(formik.errors, `${diaChiOf}.soDienThoai`)
                            )}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={
                                Boolean(
                                    getIn(formik.touched, `${diaChiOf}.soDienThoai`) && getIn(formik.errors, `${diaChiOf}.soDienThoai`)
                                ) && formik.errors[`${diaChiOf}`]["soDienThoai"]
                            }
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Fax"}
                            name={`${diaChiOf}.fax`}
                            value={formik.values[`${diaChiOf}`] && formik.values[`${diaChiOf}`]["fax"]}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, `${diaChiOf}.fax`) && getIn(formik.errors, `${diaChiOf}.fax`))}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={
                                Boolean(getIn(formik.touched, `${diaChiOf}.fax`) && getIn(formik.errors, `${diaChiOf}.fax`)) &&
                                formik.errors[`${diaChiOf}`]["fax"]
                            }
                        />
                    </Grid>
                    <Grid item xs={props.InputProps ? 6 : 3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Email"}
                            name={`${diaChiOf}.email`}
                            value={formik.values[`${diaChiOf}`] && formik.values[`${diaChiOf}`]["email"]}
                            onChange={formik.handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, `${diaChiOf}.email`) && getIn(formik.errors, `${diaChiOf}.email`))}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={
                                Boolean(getIn(formik.touched, `${diaChiOf}.email`) && getIn(formik.errors, `${diaChiOf}.email`)) &&
                                formik.errors[`${diaChiOf}`]["email"]
                            }
                        />
                    </Grid>
                </>
            )}
        </>
    );
};
