import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { FieldGeneral } from "../Interface";
import { getIn } from "formik";
import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";

export type typeK<T> = keyof T;

const CustomKeyboardDatePicker = <T extends { [key: string]: any }, K extends object>({
    formik,
    name,
    label,
    required = false,
    names,
    disabled = false,
}: FieldGeneral<T, K>) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    function handleChange(date: any, _val: any): void {
        if (names) {
            names.forEach((item) => {
                if (/Str/.test(`${item}`)) {
                    formik && formik.setFieldValue && formik.setFieldValue(`${String(item)}`, _val);
                }
                formik && formik.setFieldValue && formik.setFieldValue(`${String(item)}`, date);
            });
        } else {
            formik && formik.setFieldValue && formik.setFieldValue(`${name}`, date);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                name={`${name}`}
                label={label}
                required={required}
                value={formik && name && formik.values[name]}
                onChange={(date, _val) => handleChange(date, _val)}
                autoOk={true}
                disabled={disabled}
                fullWidth
                format="DD/MM/YYYY"
                InputAdornmentProps={{ className: "button-date-picker" }}
                variant="inline"
                InputLabelProps={{ classes: labelStyles }}
                InputProps={{ disableUnderline: true, classes: inputStyles }}
                error={Boolean(getIn(formik && formik.errors, `${name}`))}
                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                helperText={Boolean(getIn(formik && formik.errors, `${name}`)) && formik && name && formik.errors[name]}
                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomKeyboardDatePicker;
