import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "../diaChiDTO";


export interface TraCuuRequestDTO {
    soBienNhan?: string;
    soGxn?: string;
    tenCaNhanToChuc?: string;
    hoten?: string;
    tenToChuc?: string;
    diaChi?: DiaChiDTO | null;
    type: number | null;
    ngayCapGxnTu?: null | Date;
    ngayCapGxnDen?: null | Date;
    year?: number;
    month?: string;
    mode?: string;
}

export const TraCuuRequestDTO_INT : TraCuuRequestDTO = {
    soBienNhan: "",
    soGxn: "",
    tenCaNhanToChuc: "",
    hoten: "",
    tenToChuc: "",
    diaChi: {...DiaChiDTO_INT_CAN_THO},
    type: -1,
    ngayCapGxnTu: null,
    ngayCapGxnDen: null,
    year: undefined,
    month: undefined,
    mode: "YEAR",
}
export interface KienThucVeSinhAttpDanhSachDTO {
    id: number | null;
    soBienNhan?: string;
    soGxn?: string;
    ngayCapGxn?: null | Date;
    ngayHetHanGxn?: null | Date;
    tenCaNhanToChuc?: string;
    fullDiaChi?: string;
    tinhTrangStr?: string;
    type?: number | null;
    loaiDoiTuongStr: string;
}

export const KienThucVeSinhAttpDanhSachDTO_INT : KienThucVeSinhAttpDanhSachDTO = {
    id: null,
    soBienNhan: "",
    soGxn: "",
    ngayCapGxn: null,
    ngayHetHanGxn: null,
    tenCaNhanToChuc: "",
    fullDiaChi: "",
    tinhTrangStr: "",
    type: null,
    loaiDoiTuongStr: ""
}

export interface KienThucVeSinhAttpThongKeDTO {
    mode?: string;
    thang?:string;
    month?: string;
    year?: number;
    diaChi?: DiaChiDTO;
    totalCaNhan?: number;
    totalToChuc?: number;
    totalSoNguoi?: number;
    ngayCapGcnTu?: null | Date;
    ngayCapGcnDen?: null | Date;
    downloadType?: string;
}

export const ThongKeRequestDTO_INT : KienThucVeSinhAttpThongKeDTO = {
    diaChi: {...DiaChiDTO_INT_CAN_THO},
    ngayCapGcnTu: null,
    ngayCapGcnDen: null,
    year: undefined,
    month: undefined,
    thang: undefined,
    mode: "YEAR",
}
