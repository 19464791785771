import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParamExport } from "apis/baseService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { TypeCell } from "components/commons/customTable/customTableInterface";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomKeyboardDatePicker from "components/commons/fields/CustomKeyboardDatePicker";
import CustomNativeSelect from "components/commons/fields/CustomNativeSelect";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { useFormik } from "formik";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import { optionNam, optionsThongKe, optionThang } from "model/thucVat/thuoc";
import { ThuYBuonBanThuocManagementSevice } from "apis/thuY/buonBanThuoc";
import moment from "moment";
import React from "react";
import StringUtil from "utils/stringUtils";
import { KienThucVeSinhAttpThongKeDTO,  ThongKeRequestDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { downLoadFile } from "apis/Common/exportUtil";

export interface ThongKeKienThucVeSinhAttpYProps { }

const ThongKeKienThucVeSinhAttp: React.FC<ThongKeKienThucVeSinhAttpYProps> = ({ }) => {
    const title = "Thống kê tình hình cấp giấy xác nhận kiến thức về an toàn thực phẩm";

    const [rows, setRows] = React.useState<KienThucVeSinhAttpThongKeDTO[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
        setLoading(true);
        return () => setLoading(false);
    }, []);

    const initialValues: KienThucVeSinhAttpThongKeDTO = {
        ngayCapGcnTu: null,
        ngayCapGcnDen: null,
        year: new Date().getFullYear(),
        mode: "YEAR",
        diaChi: DiaChiDTO_INT_CAN_THO,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: fetchData,
    });

    React.useEffect(() => {
        async function handleChangeMode() {
            if (formik.values.mode === "YEAR") {
                formik.setFieldValue("year", new Date().getFullYear());
            }
            if (formik.values.mode === "MONTH") {
                formik.setFieldValue("thang", new Date().getMonth() + 1);
            }
        }
        handleChangeMode();
    }, [formik.values.mode]);

    const fieldsKTG: CreateFieldsProps<KienThucVeSinhAttpThongKeDTO, OptionDefault>[] = [
        { Component: DiaChiDonViHanhChinh, diaChiOf: "diaChi", disabled: true, hideTinhThanh: true, hidePhuongXa: true, required: false },
        {
            label: "Kỳ thống kê",
            name: "mode",
            options: optionsThongKe,
            Component: CustomNativeSelect,
            xs: 3,
            fieldString: "title",
            fieldValue: "value",
        },
        { label: "Từ ngày", name: "ngayCapGcnTu", Component: CustomKeyboardDatePicker, xs: 3 },
        { label: "Đến đến", name: "ngayCapGcnDen", Component: CustomKeyboardDatePicker, xs: 3 },
    ];

    const fieldsYear: CreateFieldsProps<KienThucVeSinhAttpThongKeDTO, OptionDefault>[] = [
        { Component: DiaChiDonViHanhChinh, diaChiOf: "diaChi", disabled: true, hideTinhThanh: true, hidePhuongXa: true, required: false },
        {
            label: "Kỳ thống kê",
            name: "mode",
            options: optionsThongKe,
            Component: CustomNativeSelect,
            xs: 3,
            fieldString: "title",
            fieldValue: "value",
        },
        { label: "Năm", name: "year", options: optionNam, Component: CustomNativeSelect, xs: 3, fieldString: "title", fieldValue: "value" },
        formik.values.mode === "MONTH"
            ? {
                label: "Tháng",
                name: "thang",
                options: optionThang,
                Component: CustomNativeSelect,
                xs: 3,
                fieldString: "title",
                fieldValue: "value",
            }
            : {},
    ];

    const columnsNam: ColumnCustomTable<KienThucVeSinhAttpThongKeDTO>[] = [
        { title: "Tháng", field: "month" },
        { title: "Cá nhân", field: "totalCaNhan" },
        { title: "Tổ chức", field: "totalToChuc" },
        { title: "Tổng số người", field: "totalSoNguoi" },
    ];

    const columnsKTG: ColumnCustomTable<KienThucVeSinhAttpThongKeDTO>[] = [
        { title: "Từ ngày", field: "ngayCapGcnTu", type: TypeCell.DATE },
        { title: "Đến ngày", field: "ngayCapGcnDen", type: TypeCell.DATE },
        { title: "Cá nhân", field: "totalCaNhan" },
        { title: "Tổ chức", field: "totalToChuc" },
        { title: "Tổng số người", field: "totalSoNguoi" },
    ];

    async function fetchData(values: KienThucVeSinhAttpThongKeDTO) {
        if (loading) {
            let data = { ...values, mode: formik.values.mode };
            if (formik.values.mode === "YEAR") {
                data.ngayCapGcnTu = moment([Number(data.year)])
                    .startOf("year")
                    .toDate();
                data.ngayCapGcnDen = moment([Number(data.year)])
                    .endOf("year")
                    .toDate();
            } else {
                if (formik.values.mode === "MONTH") {
                    let mm = moment()
                        .year(Number(data.year))
                        .month(Number(data.month) - 1);
                    data.ngayCapGcnTu = mm.startOf("month").toDate();
                    data.ngayCapGcnDen = mm.endOf("month").toDate();
                    data.month = String(Number(data.month));
                }
                // data.year = data;
            }
            await new KienThucVeSinhAttpService().thongKe(data).then((response) => {
                setRows(response);
            });
        }
    }

    React.useEffect(() => {
        loading && fetchData(formik.values);
    }, [loading]);

    function renderColumn() {
        if (formik.values.mode === "YEAR" || formik.values.mode === "MONTH") return columnsNam;
        return columnsKTG;
    }

    const reduceSum: Number[] = [
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalCaNhan"),
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalToChuc"),
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "totalSoNguoi"),
    ];

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        let data = { ...formik.values };
        if (formik.values.mode === "YEAR") {
            data.ngayCapGcnTu = moment([Number(data.year)])
                .startOf("year")
                .toDate();
            data.ngayCapGcnDen = moment([Number(data.year)])
                .endOf("year")
                .toDate();
        } else {
            if (formik.values.mode === "MONTH") {
                let mm = moment()
                    .year(Number(data.year))
                    .month(Number(data.month) - 1);
                data.ngayCapGcnTu = mm.startOf("month").toDate();
                data.ngayCapGcnDen = mm.endOf("month").toDate();
                data.month = String(Number(data.month));
            }
            // data.year = undefined;
        }
        // let newParams: ParamExport = { pageNumber: 0, pageSize: -1, downloadType: downloadType };
        await new KienThucVeSinhAttpService().exportFileThongKe(data, downloadType).then((resp) => {
                // let blob = new Blob([resp.data], { type: resp.data.type });
                // let url = window.URL.createObjectURL(blob);
                // let link = document.createElement("a");
                // link.href = url;
                // link.setAttribute("download", `[DS_Thong_Ke]${new Date()}`);
                // document.body.appendChild(link);
                // link.click();
                // link.remove();
                // window.URL.revokeObjectURL(url);
                downLoadFile(resp.data, resp.data.type, `[DS_Thong_Ke]${new Date()}`);
            });
    }
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{title}</span>
                </div>
                <Paper component="div">
                    <Grid container>
                        <CreateFields fields={formik.values.mode === "FROM_TO" ? fieldsKTG : fieldsYear} formik={formik} />
                        <Grid item xs={12} className="grid-item">
                            <Box className="button-group-center">
                                <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                    Tổng hợp
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                <ExportGroup onExportFile={handleExportFile} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <CustomTable columns={renderColumn()} rows={rows}
                                reduceSumField={formik.values.mode === "YEAR" ? reduceSum : undefined} />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default ThongKeKienThucVeSinhAttp;
