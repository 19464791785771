import MomentUtils from "@date-io/moment";
import { Box, Button, Grid, InputBase, InputLabel, Paper, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { getIn, useFormik } from "formik";
import { useGetSoGiayPhepThuySan } from "hooks/useGetSoGiayPhep";
import { TinhTrangEnum } from "model/baseModel";
import { ChungNhanAttpThongTinChungDTO, ChungNhanAttpThongTinChung_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { getDoiTuongEnum, getLoaiHinhSanXuatKinhDoanhEnum, getLoaiMatHangKinhDoanhEnum, LoaiChuyenNganh } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { NotificationAction, openNotification } from "redux/actions/notificationAction";
import * as Yup from "yup";
import { HienTrangCoSo, ThongTinSanPham } from "./chiTiet";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

export const ThuHoiChungNhanDuDieuKienAttp = () => {
    const title = "Thu hồi giấy chứng nhận đủ điều kiện an toàn thực phẩm";
    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = title;
    }, []);

    // Control
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>{title}</span>
            </div>
            <Paper component="div">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={value} onChange={handleChange}>
                        <CustomTab label="Thông tin chung" />
                        <CustomTab label="Thông tin về sản phẩm" />
                        <CustomTab label="Hiện trạng cơ sở" />
                    </CustomTabs>
                </Box>
                {value === 0 && (
                    <TabPanel value={value} index={0}>
                        <ThongTinChung />
                    </TabPanel>
                )}
                {value === 1 && (
                    <TabPanel value={value} index={1}>
                        <ThongTinSanPham />
                    </TabPanel>
                )}
                {value === 2 && (
                    <TabPanel value={value} index={2}>
                        <HienTrangCoSo />
                    </TabPanel>
                )}
            </Paper>
        </div>
    )
}

const ThongTinChung = () => {
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const { item } = useGetSoGiayPhepThuySan();
    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();
    const [openResetFormDlg, setOpenResetFormDlg] = useState(false);

    const initialValues: ChungNhanAttpThongTinChungDTO = { ...ChungNhanAttpThongTinChung_INT };

    const validationSchema = Yup.object().shape(
        {
            lyDoThuHoi: Yup.string().required("Lý do thu hồi không được bỏ trống"),
            ngayThuHoi: Yup.date().nullable().required("Ngày thu hồi không được bỏ trống")
        }
    );

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    useEffect(() => {
        function initialData() {
            if (item && item?.id) {
                new ChungNhanDuDieuKienAttpService().getThongTinChungThuHoi(Number(item.id)).then(resp => {
                    if (resp) {
                        formik.setValues(prev => ({...prev, ...resp, referenceId: Number(item.id) }));
                        if (resp.tinhTrang != TinhTrangEnum.THU_HOI) {
                            formik.setFieldValue("id", null);
                            formik.setFieldValue("oldId", item?.id);
                            formik.setFieldValue("referenceId", getReferenceId(resp));
                            
                        }
                    }
                })
            }
        }
        initialData();
    }, [item?.id])

    const getReferenceId = (data : ChungNhanAttpThongTinChungDTO) => {
        if(data?.referenceId && data?.referenceId > 0){
            return data?.referenceId;
        }else{
            return data?.id;
        }
    }  

    async function handleSubmit(data: ChungNhanAttpThongTinChungDTO) {
        await new ChungNhanDuDieuKienAttpService().addOrUpdateThongTinChung(data, LoaiChuyenNganh.THU_HOI).then((response) => {
            if (response.isSuccess == true)
                formik.setFieldValue("id", response.data.id);
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
    }

    function handleResetForm() {
        formik.setFieldValue("lyDoThuHoi", '');
        formik.setFieldValue("ngayThuHoi", null);
        formik.setFieldValue("soQuyetDinhThuHoi", '');
        formik.setFieldValue("ngayQuyetDinhThuHoi", null);
        formik.setFieldValue("ghiChuThuHoi", '');
    }

    const handleOpenResetForm = () => {
        setOpenResetFormDlg(true);
    }

    const handleCloseResetForm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetForm();
        }
        setOpenResetFormDlg(false);
    };

    return (<Grid container>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Đối tượng</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{getDoiTuongEnum(formik.values.doiTuong)}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Số giấy chứng nhận cũ</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.soGcnCu}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Ngày cấp cũ</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.ngayCapGcnCu && moment(formik.values.ngayCapGcnCu).format("DD/MM/YYYY")}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Số giấy chứng nhận mới</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.soGcn}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Ngày cấp mới</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.ngayCapGcn && moment(formik.values.ngayCapGcn).format("DD/MM/YYYY")}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Tên cơ sở</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.tenCoSo}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Mã số</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.maSoDkkd}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Số giấy CNĐKKD</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.soDkkd}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Ngày cấp</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.ngayCapDkkd && moment(formik.values.ngayCapDkkd).format("DD/MM/YYYY")}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Nơi cấp</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.noiCapDkkd}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Loại hình sản xuất, KD</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{getLoaiHinhSanXuatKinhDoanhEnum(formik.values.loaiHinhSxkd)}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Năm hoạt động</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.namBatDauHoatDong}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Công suất thiết kế</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.congSuatThietKe}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Địa chỉ</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.fullDiaChi}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Số điện thoại</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.diaChi?.soDienThoai}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Fax</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.diaChi?.fax}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Email</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.diaChi?.email}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Chủ cơ sở</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.chuCoSo}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Loại mặt hàng sản xuất, KD</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{getLoaiMatHangKinhDoanhEnum(formik.values.loaiMatHangDkkd)}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Mặt hàng sản xuất, KD</InputLabel>
            </Grid>
            <Grid item xs={10} className="grid-item">
                <label className="text-info">{formik.values.matHangSxkd}</label>
            </Grid>
        </Grid>
        <Grid container item xs={12} className="grid-item">
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Tình trạng hoạt động</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.tinhTrangStr}</label>
            </Grid>
            <Grid item xs={2} className="grid-item">
                <InputLabel className="label-info">Lần cấp lại</InputLabel>
            </Grid>
            <Grid item xs={4} className="grid-item">
                <label className="text-info">{formik.values.lanCapLai}</label>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container item xs={6} className="grid-item">
                <TextField
                    fullWidth
                    required
                    label={"Lý do thu hồi"}
                    name="lyDoThuHoi"
                    value={formik.values.lyDoThuHoi}
                    onChange={formik.handleChange}
                    InputLabelProps={{ classes: labelStyles }}
                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                    error={Boolean(getIn(formik.errors, "lyDoThuHoi"))}
                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                    helperText={Boolean(getIn(formik.errors, "lyDoThuHoi")) && formik.errors?.lyDoThuHoi}
                />
            </Grid>
            <Grid item xs={3} className="grid-item">
                <KeyboardDatePicker
                    name="ngayCap"
                    label={"Ngày thu hồi"}
                    required
                    value={formik.values.ngayThuHoi}
                    onChange={(date, _val) => {
                        formik.setFieldValue("ngayThuHoi", date);
                    }}
                    autoOk={true}
                    fullWidth
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{ className: "button-date-picker" }}
                    variant="inline"
                    InputLabelProps={{ classes: labelStyles }}
                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                    error={Boolean(getIn(formik.errors, "ngayThuHoi"))}
                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                    helperText={Boolean(getIn(formik.errors, "ngayThuHoi")) && formik.errors.ngayThuHoi}
                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                />
            </Grid>
            <Grid container item xs={6} className="grid-item">
                <TextField
                    fullWidth
                    label={"Số quyết định thu hồi"}
                    name="soQuyetDinhThuHoi"
                    value={formik.values.soQuyetDinhThuHoi}
                    onChange={formik.handleChange}
                    InputLabelProps={{ classes: labelStyles }}
                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                />
            </Grid>
            <Grid item xs={3} className="grid-item">
                <KeyboardDatePicker
                    name="ngayCap"
                    label={"Ngày quyết định"}
                    value={formik.values.ngayQuyetDinhThuHoi}
                    onChange={(date, _val) => {
                        formik.setFieldValue("ngayQuyetDinhThuHoi", date);
                    }}
                    autoOk={true}
                    fullWidth
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{ className: "button-date-picker" }}
                    variant="inline"
                    InputLabelProps={{ classes: labelStyles }}
                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                />
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>Ghi chú</InputLabel>
                <InputBase fullWidth rows={3} multiline classes={inputStyles}
                    value={formik.values?.ghiChuThuHoi} name="ghiChuThuHoi" onChange={formik.handleChange} />
            </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12} className="gridItem">
            <Box className="button-group" marginTop="5px">
                <Button variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                    Lưu
                </Button>
                <Button variant="contained" color="secondary" disableElevation onClick={handleOpenResetForm}>
                    Xóa
                </Button>
                <ConfirmationDialogRaw id="confirm-dialog-reset-form" keepMounted open={openResetFormDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseResetForm}
                />
                <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                    Trở về
                </Button>
            </Box>
        </Grid>
    </Grid >
    )
}

// const LichSu = () => {
//     // Constant
//     const { item } = useGetSoGiayPhepThuySan();
//     const columns: ColumnCustomTable<LichSuBienDongDTO>[] = [
//         { title: "Loại biến động", field: "loaiBienDong", align: "left" },
//         { title: "Ngày biến động", field: "ngayBienDong", type: TypeCell.DATE },
//         { title: "Số lần", field: "soLan" },
//         { title: "Nội dung", field: "noiDung", width: "40%", align: "left" },
//         { title: "Đính kèm", field: "attachFiles", type: TypeCell.ATTACH_FILE_TOOL_TIP },
//     ];
//     // State
//     const [rows, setRows] = useState<LichSuBienDongDTO[]>([]);
//     // Hook
//     useEffect(() => {
//         function initialData() {
//             if (item && item.soBienNhan) {
//                 new TauCaManagementSevice().getLichSuBienDong(Number(item.id)).then(resp => {
//                     setRows(resp);
//                 })
//             }
//         }
//         initialData();
//     }, [item?.id]);
//     return (
//         <>
//             <CustomTable columns={columns} rows={rows} />
//         </>
//     );
// }