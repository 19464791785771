import React, { useEffect, useState } from "react";
import { loadModules } from "esri-loader";
import locatorImg from "assets/images/locator_1.png";
import StringUtil from "utils/stringUtils";

export interface SearchMapProps {
    view: any;
    formik: any;
    setStringDiaChi: (value: String) => void;
    setKinhDo: (values: number) => void;
    setViDo: (values: number) => void;
    diaChiOf: string;
}

const SearchMap: React.FC<SearchMapProps> = ({ view, formik, setStringDiaChi, diaChiOf, setKinhDo, setViDo }) => {
    const [search, setSearch] = useState<any>(null);

    useEffect(() => {
        loadModules(["esri/widgets/Search"])
            .then(([Search]) => {
                const search = new Search({
                    view: view,
                    popupOpenOnSelect: false,
                    searchTerm: "",
                });
                setSearch(search);
                view.ui.add(search, "top-right"); //Add to the map
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (
            search &&
            formik.values[diaChiOf]["diaChiTinhName"] &&
            formik.values[diaChiOf]["diaChiHuyenName"] &&
            formik.values[diaChiOf]["diaChiXaName"] &&
            !formik.values["diaChiKinhDo"] &&
            !formik.values["diaChiViDo"]
        ) {
            search.search(StringUtil.diaChiToString(formik.values[diaChiOf]));
        }
    }, [search, formik.values[diaChiOf]]);

    useEffect(() => {
        loadModules(["esri/Graphic"]).then(([Graphic]) => {
            if (search) {
                search.on("select-result", function (evt: any) {
                    const { latitude, longitude } = evt.result.feature.geometry;
                    setStringDiaChi(evt.result.name);
                    setKinhDo(longitude);
                    setViDo(latitude);
                    view.popup.open({
                        location: evt.result.feature.geometry, // location of the click on the view
                        title: `${Math.round(longitude * 100000) / 100000}, ${Math.round(latitude * 100000) / 100000}`, // title displayed in the popup
                        content: evt.result.name, // content displayed in the popup
                    });
                    view.graphics.removeAll();

                    const locationGraphic = createGraphic(evt.result.feature.geometry);
                    view.graphics.add(locationGraphic, 0);
                });
            }
            function createGraphic(point: any) {
                let symbol = {
                    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
                    url: locatorImg,
                    width: "15px",
                    height: "25px",
                };
                const graphic = new Graphic({
                    geometry: point,
                    symbol: symbol,
                });

                view.graphics.add(graphic);
                return graphic;
            }
        });
    }, [search]);

    return null;
};

export default SearchMap;
