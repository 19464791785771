import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useRef, useState } from "react";
import { DanhMucBieuMauService } from "apis/danhMucBieuMau/danhMucBieuMauService";
import { useQueryBussinessPath } from "hooks/useQuery";
import { BieuMauAttachFileDTO } from "model/danhMucBieuMau";
import { AttachIcon } from "../attachFileTooltip/attachFileTooltip";
import { useHistory } from "react-router-dom";

export interface InBieuMauButtonPropsI {
  onDownloadTemplate: (fileId: String, fileName: String) => void;
}

export const InBieuMauButton = (props: InBieuMauButtonPropsI) => {
  const bussinessPath = useQueryBussinessPath();
  const history = useHistory();
  const [openInAn, setOpenInAn] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { onDownloadTemplate } = props;
  const [bieuMauAttachFileDTOs, setBieuMauAttachFileDTOs] = useState<
    BieuMauAttachFileDTO[]
  >([]);
  const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenInAn(false);
  };
  async function handleOpenInAn() {
    let path = bussinessPath;
    if (!!path) {
      path = history.location.pathname;
    }
    await new DanhMucBieuMauService()
      .findDanhSachBieuMauByPath(path)
      .then((res) => {
        setBieuMauAttachFileDTOs(res);
        setOpenInAn(true);
      });
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        ref={anchorRef}
        aria-haspopup="true"
        aria-controls={openInAn ? "danh-sach-file-template" : undefined}
        onClick={handleOpenInAn}
      >
        In ấn
      </Button>
      <Popper
        open={openInAn}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseProper}>
                <MenuList id="danh-sach-file-template">
                  {bieuMauAttachFileDTOs &&
                    bieuMauAttachFileDTOs.map((item, k) => (
                      <MenuItem style={{ padding: "unset" }} key={k}>
                        <Button
                          size="small"
                          disableRipple
                          fullWidth
                          startIcon={
                            <AttachIcon fileName={String(item.fileName)} />
                          }
                          endIcon={
                            <GetAppIcon color="primary" fontSize="small" />
                          }
                          onClick={() =>
                            onDownloadTemplate(item.fileId, item.fileName)
                          }
                        >
                          {item?.screenName ? (
                            <Box component="span" flexGrow={1} textAlign="left">
                              {item?.screenName.length > 30
                                ? item.screenName.slice(0, 33) +
                                "..." +
                                item.screenName.slice(
                                  item.screenName.length - 10
                                )
                                : item.screenName}
                            </Box>
                          ) : (
                            <Box component="span" flexGrow={1} textAlign="left">
                              {item.fileName.length > 30
                                ? item.fileName.slice(0, 33) +
                                "..." +
                                item.fileName.slice(item.fileName.length - 10)
                                : item.fileName}
                            </Box>
                          )}
                        </Button>
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
