import { RoutePropsI } from "routes";
import { lazy } from "react";

const CauHinhNghiepVuMgt = lazy(() => import("pages/quanTriHeThong/cauHinhNghiepVu/cauHinhNghiepVuMgt").then(({ CauHinhNghiepVuMgt }) => ({ default: CauHinhNghiepVuMgt })));
const MenuRoleManagement = lazy(() => import("pages/quanTriHeThong/menu/menuRoleManagement").then(({ MenuRoleManagement }) => ({ default: MenuRoleManagement })));
const GroupRoleManagementComponent = lazy(() =>
    import("pages/quanTriHeThong/nhomVaiTro/groupRoleManagementComponent").then(({ GroupRoleManagementComponent }) => ({ default: GroupRoleManagementComponent }))
);
const PhanQuyenNguoiDungComponent = lazy(() =>
    import("pages/quanTriHeThong/phanQuyenNguoiDung/phanQuyenNguoiDungComponent").then(({ PhanQuyenNguoiDungComponent }) => ({ default: PhanQuyenNguoiDungComponent }))
);
const RoleManagementComponent = lazy(() => import("pages/quanTriHeThong/vaiTro/roleManagementComponent").then(({ RoleManagementComponent }) => ({ default: RoleManagementComponent })));

export const ROUTE_ADMIN_USER_MGT: RoutePropsI[] = [
    {
        ten: "Cấu hình nghiệp vụ",
        giaoDien: <CauHinhNghiepVuMgt />,
        duongDan: "/cau-hinh-nghiep-vu",
    },
    {
        ten: "Phân quyền người dùng",
        giaoDien: <PhanQuyenNguoiDungComponent />,
        duongDan: "/phan-quyen-nguoi-dung",
    },
    {
        ten: "Quản lý vai trò",
        giaoDien: <RoleManagementComponent />,
        duongDan: "/quan-ly-vai-tro",
    },
    {
        ten: "Quản lý nhóm vai trò",
        giaoDien: <GroupRoleManagementComponent />,
        duongDan: "/quan-ly-nhom-vai-tro",
    },
    {
        ten: "Quản lý menu",
        giaoDien: <MenuRoleManagement />,
        duongDan: "/quan-ly-menu",
    },
];
