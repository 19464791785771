import { Box, createStyles, Dialog, Grid, makeStyles, Theme } from "@material-ui/core";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import React from "react";
// import { Pageable } from "pages/quanTriHeThong/importDauKy/table/commonTableChuyenNganhLazy";
import {ViewXNNuoiTrongLongBe} from "pages/thuySan/longBe/chiTiet/viewXNNuoiTrongLongBe";

export interface DialogDiaDiemProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    // rows: any[];
    // columns: any[];
    // pageParams: Pageable,
    // onChangePage:(value:number)=> void,
    // onChangeRowsPerPage: (value:number)=> void,
    // search : FormValuesTK
    itemChiTiet: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);
const getModalStyle =() => {
    const top = 25;
    const left = 25;
  
    return {
      top: `${top}%`,
      margin:'auto'
      // left: `${left}%`,
      // transform: `translate(-${top}%, -${left}%)`,
    };
  }
const DialogChiTietXNNuoiTrong: React.FC<DialogDiaDiemProps> = ({  openDialog, handleCloseDialog, itemChiTiet}) => {
    const classes = useStyles();
    // const {  handOnChangeRowsPerPage, handleOnChangePage } = useContextTableFooter();
    const closeDialog = () => {
        handleCloseDialog();
    }

    return (
        <>
            <Dialog fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog} onClose={closeDialog}  >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                    CHI TIẾT
                </DialogTitle>
                <DialogContent dividers>
                    <ViewXNNuoiTrongLongBe item={itemChiTiet} />
                </DialogContent>
            </Dialog>   
        </>
    );
};

export default DialogChiTietXNNuoiTrong;
