import React from "react";
import { FormHelperText, InputBase, InputLabel, NativeSelect } from "@material-ui/core";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn } from "formik";
import { FieldGeneral } from "../Interface";

const CustomNativeSelect = <T extends { [key: string]: any }, K extends object>({
    formik,
    name,
    label,
    required,
    disabled = false,
    options,
    fieldString,
    fieldValue,
    type,
}: FieldGeneral<T, K>) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    return (
        <>
            <InputLabel
                required={required}
                error={Boolean(getIn(formik && formik.touched, `${name}`) && getIn(formik && formik.errors, `${name}`))}
                classes={labelStyles}
            >
                {label}
            </InputLabel>
            <NativeSelect
                name={`${name}`}
                value={formik && name && formik.values[name]}
                onChange={(e) => {
                    formik && formik.setFieldValue && formik.setFieldValue(`${name}`, type === "number" ? Number(e.target.value) : e.target.value);
                }}
                error={Boolean(getIn(formik && formik.touched, `${name}`) && getIn(formik && formik.errors, `${name}`))}
                input={<InputBase fullWidth classes={inputStyles} />}
                disabled={disabled}
                style={{ lineHeight: "15px" }}
            >
                {type === "number" && <option value={NaN}>-- Chọn --</option>}
                {options &&
                    options.map((item, idx) => (
                        <option key={idx} value={String(fieldValue && item[fieldValue])}>
                            {fieldString && item[fieldString]}
                        </option>
                    ))}
            </NativeSelect>
            {Boolean(getIn(formik && formik.touched, `${name}`) && getIn(formik && formik.errors, `${name}`)) && (
                <FormHelperText className="-error" classes={helperTextStyles}>
                    {formik && name && formik.errors[name]}
                </FormHelperText>
            )}
        </>
    );
};

export default CustomNativeSelect;
