import { Box, Button, Dialog, Grid } from "@material-ui/core";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomKeyboardDatePicker from "components/commons/fields/CustomKeyboardDatePicker";
import CustomTextField from "components/commons/fields/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { KienThucVeSinhAttpCaNhanDTO, KienThucVeSinhAttpCaNhanDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import CustomNativeSelect from "components/commons/fields/CustomNativeSelect";
import { optionsGioiTinh } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpEnum";
import { OptionDefault } from "model/danhMuc/danhMucDTO";
import { TypeCell } from "components/commons/customTable/customTableInterface";

export interface CaNhanDialogPropsI {
    onSubmitData: (resp: KienThucVeSinhAttpCaNhanDTO) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: KienThucVeSinhAttpCaNhanDTO;
}

export const CaNhanDialog = (props: CaNhanDialogPropsI) => {
    const { onSubmitData, onDialogClose, openDialog, itemEdit } = props;
    
    const validationSchema = Yup.object().shape(
        {
            hoTen: Yup.string().required("Họ tên không được bỏ trống"),
            // ngayCapGxn: Yup.date()
            //     .nullable()
            //     .required("Ngày cấp không được bỏ trống")
            //     .when("ngayHetHanGxn", (ngayHetHanGxn: any, schema: any): any => {
            //         if (ngayHetHanGxn) {
            //             return schema.max(ngayHetHanGxn, "Không hợp lệ");
            //         }
            //         return schema;
            //     }),
            soCmnd: Yup.string().required("Số căn cước/CMND không được bỏ trống")
        },
        []
    );

    const fields: CreateFieldsProps<KienThucVeSinhAttpCaNhanDTO, OptionDefault>[] = [
        { label: "Họ tên", name: "hoTen", Component: CustomTextField, xs: 6, required: true },
        { label: "Giới tính", name: "gioiTinh", options: optionsGioiTinh, Component: CustomNativeSelect, xs: 6, fieldString: "title", fieldValue: "value" },
        { label: "Số căn cước/CMND", name: "soCmnd", Component: CustomTextField, xs: 3, required: true },
        { label: "Ngày cấp", name: "ngayCapCmnd", Component: CustomKeyboardDatePicker, xs: 3 },
        { label: "Nơi cấp", name: "noiCapCmnd", Component: CustomTextField, xs: 6 }
    ];

    const formik = useFormik({
        initialValues: { ...itemEdit },
        onSubmit: (values) => onSubmitData(values),
        validationSchema,
        validateOnChange: false,
    });

    function handleResetForm() {
        formik.setValues(prev => ({...KienThucVeSinhAttpCaNhanDTO_INT}))
    }

    return (
        <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                {itemEdit ? 'Cập nhật' : 'Thêm mới'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid spacing={2} container>
                        <CreateFields formik={formik} fields={fields} />
                        <Grid item xs={12} className="gridItem">
                            <Box className="button-group" marginTop="5px">
                                <Button variant="contained" color="primary" disableElevation
                                    onClick={() => formik.handleSubmit()}>
                                    Lưu
                                </Button>
                                <Button variant="contained" color="secondary" disableElevation
                                    onClick={handleResetForm}>
                                    Xóa
                                </Button>
                                <Button variant="contained" color="default" disableElevation
                                    onClick={onDialogClose}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}