
import { BussinessType, LoaiChuyenNganh } from "model/thuySan/tauCa";
import { DangKyLongBeThongTinChungDTO, AoNuoiDTO, LichSuBienDongDTO } from "model/thuySan/longBe/dangKyThongTinChungDTO";
import { DanhSachTraCuuLongBeDTO } from "model/thuySan/longBe/danhSachTraCuuLongBeDTO";
import { LongBeThongKeDTO } from "model/thuySan/longBe/longBeThongKeDTO";
import { BaseResponse, BaseService, Pagination } from "../../baseService";
import api from "../../index";
import { PageRequest } from "apis/baseService";
import AXIOS_INSTANCE from "../../index";

const ROOT_PATH = "/thuy-san";

export interface BussinessTemplateDownloadRequest {
    soBienNhan: string;
    filedId: string;
}

export class LongBeManagementSevice extends BaseService {

    async search(data: DanhSachTraCuuLongBeDTO, page: number, size: number): Promise<Pagination<DanhSachTraCuuLongBeDTO[]>> {
        let result: Pagination<DanhSachTraCuuLongBeDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await AXIOS_INSTANCE
            .post(`${ROOT_PATH}/long-be-tra-cuu/get-danh-sach`, data, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
                data: {},
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async chiTietThongKeAoBeLongNuoi(data: DanhSachTraCuuLongBeDTO, page: number, size: number): Promise<Pagination<DanhSachTraCuuLongBeDTO[]>> {
        let result: Pagination<DanhSachTraCuuLongBeDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api
            .post(`${ROOT_PATH}/long-be-tra-cuu/chi-tiet-thong-ke-ao-be-long-nuoi`, data, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
                data: {},
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async insertOrUpdateThongTinChung(data: DangKyLongBeThongTinChungDTO, loaiChuyenNganh: LoaiChuyenNganh): Promise<BaseResponse> {
        let result = { isSuccess: false, } as BaseResponse;
        await AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be/thong-tin-chung/${loaiChuyenNganh}/insert-or-update`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }

    async getBySoGiayPhep(soGiayPhep: string, loaiChuyenNganh: LoaiChuyenNganh): Promise<DangKyLongBeThongTinChungDTO> {
        let result = {} as DangKyLongBeThongTinChungDTO;
        await AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be/thong-tin-chung/get/${loaiChuyenNganh}/by-so-giay-phep`, {
            params: {
                soGiayPhep: soGiayPhep
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    //to-do
    async getForFillFormCapLai(soGiayPhep: string): Promise<DangKyLongBeThongTinChungDTO> {
        let result = {} as DangKyLongBeThongTinChungDTO;
        await AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be/thong-tin-chung/get-fill-form-cap-lai/by-so-giay-phep`, {
            params: {
                soGiayPhep: soGiayPhep
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getByBienNhan(soBienNhan: string): Promise<DangKyLongBeThongTinChungDTO> {
        let result = {} as DangKyLongBeThongTinChungDTO;
        await AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be/thong-tin-chung/get-by-so-bien-nhan`, {
            params: {
                soBienNhan: soBienNhan,
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    async getLichSuBienDong(id: number): Promise<LichSuBienDongDTO[]> {
        let result: LichSuBienDongDTO[] = [];
        await AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be-tra-cuu/get/history`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async getThongTinChung(id: number): Promise<DangKyLongBeThongTinChungDTO> {
        let result = {} as DangKyLongBeThongTinChungDTO;
        await AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be-tra-cuu/get/thong-tin-chung`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    async baoCao(data: DanhSachTraCuuLongBeDTO, page: number, size: number): Promise<Pagination<DanhSachTraCuuLongBeDTO[]>> {
        let result: Pagination<DanhSachTraCuuLongBeDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await AXIOS_INSTANCE
            .post(`${ROOT_PATH}/long-be-tra-cuu/get-bao-cao`, data, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
                data: {},
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }
    async thongKe(data: LongBeThongKeDTO): Promise<LongBeThongKeDTO[]> {
        let result: DanhSachTraCuuLongBeDTO[] = []
        await AXIOS_INSTANCE
            .post(`${ROOT_PATH}/long-be-tra-cuu/get-thong-ke`, data, this.getTokenRequestHeaders()).then((response) => {
                result = response.data.data;
            }).catch();
        return result;
    }
    async thongKeDiaChiAoNuoi(data: LongBeThongKeDTO): Promise<LongBeThongKeDTO[]> {
        let result: DanhSachTraCuuLongBeDTO[] = []
        await AXIOS_INSTANCE
            .post(`${ROOT_PATH}/long-be-tra-cuu/get-thong-ke-dia-chi-ao-be`, data, this.getTokenRequestHeaders()).then((response) => {
                result = response.data.data;
            }).catch();
        return result;
    }
    async traCuuExport(data: DanhSachTraCuuLongBeDTO | {}, params: PageRequest): Promise<any> {
        return await AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be-tra-cuu/search/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    async baoCaoExport(data: DanhSachTraCuuLongBeDTO | {}, params: PageRequest): Promise<any> {
        return await AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be-tra-cuu/bao-cao/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    async thongKeExport(data: LongBeThongKeDTO | {}): Promise<any> {
        return await AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be-tra-cuu/thong-ke/export`, data, {
            params: "",
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }

    async thongKeDiaChiAoNuoiExport(data: LongBeThongKeDTO | {}): Promise<any> {
        return await AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be-tra-cuu/thong-ke-dia-chi-ao-be/export`, data, {
            params: "",
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }

    /**
     * @description xử lý download file khi thực hiện in ấn
     * @param attachFile
     * @returns
     */
    handleDownloadTemplateFile = async (request: BussinessTemplateDownloadRequest, loaiChuyenNganh: LoaiChuyenNganh): Promise<any> => {
        return AXIOS_INSTANCE.get(`${ROOT_PATH}/long-be/${loaiChuyenNganh}/download-bussiness-template-file`, {
            responseType: "blob",
            params: request,
        });
    };

    getAutocompleteBySoGiayPhep_MaTrangThai_PageSize = (soGiayCN: string, limit: number) => {
        let params = {
            soGiayCN: soGiayCN,
            limit: limit,
        }
        return AXIOS_INSTANCE.post(`${ROOT_PATH}/long-be/get-autocomplete-get-by-so-bien-nhan`, params);
    }
}