import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "../diaChiDTO";
import { DoiTuong, GioiTinh } from "./kienThucVeSinhAttpEnum";

export interface KienThucVeSinhAttpDTO {
    id: number | null;
    ngayTao?: null | Date;
    nguoiTaoId?: string;
    type: number;
    soGxn?: string;
    ngayCapGxn?: null | Date;
    ngayHetHanGxn?: null | Date;
    referenceId?: number | null;
    doiTuong?: DoiTuong;
    loaiDoiTuongStr?: string;

    soCmnd?: string;
    ngayCapCmnd?: null | Date;
    noiCapCmnd?: string;
    hoTen?: string;
    soGcnDkdn?: string;
    ngayCapGcnDkdn?: null | Date;
    noiCapGcnDkdn?: string;
    tenToChuc?: string;
    diaChi?: DiaChiDTO;
    fullDiaChi?: string;
    canCu?: string;
    maNguoiKy: string;
    tenNguoiKy: string;
    maChucVu: string;
    tenChucVu: string;
    soBienNhan : string;
    listCaNhan: KienThucVeSinhAttpCaNhanDTO[];
}

export const KienThucVeSinhAttpDTO_INT : KienThucVeSinhAttpDTO = {
    id: null,
    ngayTao: null,
    nguoiTaoId: "",
    type: 0,
    soGxn: "",
    ngayCapGxn: null,
    ngayHetHanGxn: null,
    referenceId: null,
    loaiDoiTuongStr: "",

    soCmnd: "",
    ngayCapCmnd: null,
    noiCapCmnd: "",
    hoTen: "",
    soGcnDkdn: "",
    ngayCapGcnDkdn: null,
    noiCapGcnDkdn: "",
    tenToChuc: "",
    diaChi: DiaChiDTO_INT_CAN_THO,
    fullDiaChi: "",
    canCu: "",
    maNguoiKy: "",
    tenNguoiKy: "",
    maChucVu: "",
    tenChucVu: "",
    soBienNhan : "", 
    listCaNhan: new Array<KienThucVeSinhAttpCaNhanDTO>()
}

export interface KienThucVeSinhAttpCaNhanDTO {
    id: number | null;
    soCmnd?: string;
    ngayCapCmnd?: null | Date;
    noiCapCmnd?: string;
    hoTen?: string;
    gioiTinh?: GioiTinh;
}

export const KienThucVeSinhAttpCaNhanDTO_INT : KienThucVeSinhAttpCaNhanDTO = {
    id: null,
    soCmnd: "",
    ngayCapCmnd: null,
    noiCapCmnd: "",
    hoTen: "",
    gioiTinh: GioiTinh.NAM
}