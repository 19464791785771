import { Box, createStyles, Dialog, Grid, makeStyles, Theme } from "@material-ui/core";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import useContextTableFooter from "hooks/useContextTableFooter";
import React from "react";
import { Pageable } from "pages/quanTriHeThong/importDauKy/table/commonTableChuyenNganhLazy";

export interface DialogDiaDiemProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    rows: any[];
    columns: any[];
    pageParams: Pageable,
    onChangePage:(value:number)=> void,
    onChangeRowsPerPage: (value:number)=> void,
    // search : FormValuesTK
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);
const getModalStyle =() => {
    const top = 25;
    const left = 25;
  
    return {
      top: `${top}%`,
      margin:'auto'
      // left: `${left}%`,
      // transform: `translate(-${top}%, -${left}%)`,
    };
  }
const DialogChiTietThongKe: React.FC<DialogDiaDiemProps> = ({  openDialog, handleCloseDialog, rows, columns, pageParams, onChangePage, onChangeRowsPerPage }) => {
    const classes = useStyles();
    // const {  handOnChangeRowsPerPage, handleOnChangePage } = useContextTableFooter();
    const closeDialog = () => {
        handleCloseDialog();
    }

    return (
        <>
            <Dialog fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog} onClose={closeDialog}  >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                    CHI TIẾT
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}   >
                        <Grid container>
                            <Grid item xs={12} className="grid-item" >
                                <CustomTable
                                    rows={rows}
                                    columns={columns}
                                    pageParams={pageParams}
                                    onChangePage={onChangePage}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>   
        </>
    );
};

export default DialogChiTietThongKe;
