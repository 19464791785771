import { Box, Button, Dialog, Grid } from "@material-ui/core";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import CreateFields, { CreateFieldsProps } from "components/commons/fields/createFields";
import CustomTextField from "components/commons/fields/CustomTextField";
import { useFormik } from "formik";
import { ChungNhanAttpTrangThietBiDTO, ChungNhanAttpTrangThietBiDTO_INT } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";

export interface TrangThietBiDialogPropsI {
    onSubmitData: (resp: ChungNhanAttpTrangThietBiDTO) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: ChungNhanAttpTrangThietBiDTO;
}

export const TrangThietBiDialog = (props: TrangThietBiDialogPropsI) => {
    const { onSubmitData, onDialogClose, openDialog, itemEdit } = props;

    const fields: CreateFieldsProps<ChungNhanAttpTrangThietBiDTO, {}>[] = [
        { label: "Tên thiết bị", name: "tenThietBi", Component: CustomTextField, xs: 6 },
        { label: "Số lượng", name: "soLuong", Component: CustomTextField, xs: 6 },
        { label: "Nước sản xuất", name: "nuocSanXuat", Component: CustomTextField, xs: 6 },
        { label: "Tổng công suất", name: "tongCongSuat", Component: CustomTextField, xs: 6 },
        { label: "Năm bắt đầu sử dụng", name: "namBatDauSuDung", Component: CustomTextField, xs: 6 }
    ];

    const formik = useFormik({
        initialValues: { ...itemEdit },
        onSubmit: (values) => onSubmitData(values),
        validateOnChange: false,
    });

    function handleResetForm() {
        formik.setValues(prev => ({...ChungNhanAttpTrangThietBiDTO_INT}))
    }

    return (
        <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                {itemEdit ? 'Cập nhật' : 'Thêm mới'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid spacing={2} container>
                        <CreateFields formik={formik} fields={fields} />
                        <Grid item xs={12} className="gridItem">
                            <Box className="button-group" marginTop="5px">
                                <Button variant="contained" color="primary" disableElevation
                                    onClick={() => formik.handleSubmit()}>
                                    Lưu
                                </Button>
                                <Button variant="contained" color="secondary" disableElevation
                                    onClick={handleResetForm}>
                                    Xóa
                                </Button>
                                <Button variant="contained" color="default" disableElevation
                                    onClick={onDialogClose}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}