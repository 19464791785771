import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { KienThucVeSinhAttpService } from "apis/QLCLNongLamThuySan/xacNhanKienThucAttp/kienThucVeSinhAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { useGetReferItem } from "hooks/useGetSoGiayPhep";
import { KienThucVeSinhAttpDTO, KienThucVeSinhAttpDTO_INT } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CaNhan } from "../caNhan/caNhan";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

export const ChiTietKienThucVeSinhAttp = () => {
    const title = "THÔNG TIN CHI TIẾT GIẤY XÁC NHẬN KIẾN THỨC VỀ AN TOÀN THỰC PHẨM";
    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = title;
    }, []);

    // Control
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>{title}</span>
            </div>
            <Paper component="div">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={value} onChange={handleChange}>
                        <CustomTab label="Thông tin chung" />
                        {/* <CustomTab label="Lịch sử" /> */}
                    </CustomTabs>
                </Box>
                {value === 0 && (
                    <TabPanel value={value} index={0}>
                        <ThongTinChung />
                    </TabPanel>
                )}
            </Paper>
        </div>
    )
}

const ThongTinChung = () => {
    const history = useHistory();
    const { item } = useGetReferItem<KienThucVeSinhAttpDTO>();
    const [data, setData] = useState<KienThucVeSinhAttpDTO>({ ...KienThucVeSinhAttpDTO_INT });

    useEffect(() => {
        initialData();
    }, [item])


    function initialData() {
        if (item) {
            new KienThucVeSinhAttpService().getById(Number(item.id)).then(resp => {
                if (resp)
                    setData(resp);
            })
        }
    }
    return (
        <Grid container>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Đối tượng</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.loaiDoiTuongStr}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số giấy xác nhận</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soGxn}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapGxn && moment(data.ngayCapGxn).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            {data.type == 0 && (<Grid container><Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Họ tên</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.hoTen}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số căn cước/CMND</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soCmnd}</label>
                </Grid>
            </Grid>
                <Grid container item xs={12} className="grid-item">
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <label className="text-info">{data.ngayCapCmnd && moment(data.ngayCapCmnd).format("DD/MM/YYYY")}</label>
                    </Grid>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <label className="text-info">{data.noiCapCmnd}</label>
                    </Grid>
                </Grid>
            </Grid>)}
            {data.type == 1 && (<Grid container>
                <Grid container item xs={12} className="grid-item">
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Tên tổ chức</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <label className="text-info">{data.tenToChuc}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className="grid-item">
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Số giấy CNĐKKD</InputLabel>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <label className="text-info">{data.soGcnDkdn}</label>
                    </Grid>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <label className="text-info">{data.ngayCapGcnDkdn && moment(data.ngayCapGcnDkdn).format("DD/MM/YYYY")}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className="grid-item">
                    <Grid item xs={2} className="grid-item">
                        <InputLabel className="label-info">Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <label className="text-info">{data.noiCapGcnDkdn}</label>
                    </Grid>
                </Grid>
            </Grid>)}

            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.fullDiaChi}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số điện thoại</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.soDienThoai}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Fax</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.fax}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Email</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.diaChi?.email}</label>
                </Grid>
            </Grid>
            {data.type == 1 && (<Grid item xs={12} className="grid-item">
                <CaNhan label={'Danh sách đối tượng'} viewOnly dataListItem={data.listCaNhan ? data.listCaNhan : []} disableAddButton />
            </Grid>)}
            <Grid item xs={12} className="gridItem">
                <Box className="button-group" marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

const LichSu = () => {
    return (
        <>
            <div></div>
        </>
    );
}