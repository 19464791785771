import { BaseResponse, BaseService, Pagination, ParamExport } from "apis/baseService";
import { LoaiChuyenNganh } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpEnum";
import { KienThucVeSinhAttpDTO } from "model/QLCLNongLamThuySan/xacNhanKienThuc/kienThucVeSinhAttpDTO";
import api from "../../index";
import { KienThucVeSinhAttpDanhSachDTO, KienThucVeSinhAttpThongKeDTO, TraCuuRequestDTO } from "model/QLCLNongLamThuySan/xacNhanKienThuc/traCuuKienThucVeSinhAttpDTO";
const ROOT_PATH = "/qlcl-nong-lam-thuy-san/kien-thuc-ve-sinh-attp";

export class KienThucVeSinhAttpService extends BaseService {
    async addOrUpdate(data: KienThucVeSinhAttpDTO, loaiChuyenNganh: LoaiChuyenNganh): Promise<BaseResponse> {
        let result = {isSuccess: false, } as BaseResponse;
        await api.post(`${ROOT_PATH}/${loaiChuyenNganh}/insert-or-update`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }

    async getById(id: number): Promise<KienThucVeSinhAttpDTO> {
        let result = {} as KienThucVeSinhAttpDTO;
        await api.get(`${ROOT_PATH}/get-by-id`, {
            params: {
                id: id
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
    
    async getDanhSach(request: TraCuuRequestDTO, page: number, size: number): Promise<Pagination<KienThucVeSinhAttpDanhSachDTO[]>> {
        let result: Pagination<KienThucVeSinhAttpDanhSachDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api
            .post(`${ROOT_PATH}/danh-sach`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async danhSachExport(data: TraCuuRequestDTO | {}, params: ParamExport): Promise<any> {
        return await api.post(`${ROOT_PATH}/tra-cuu/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    
    async getBaoCao(request: TraCuuRequestDTO, page: number, size: number): Promise<Pagination<KienThucVeSinhAttpDanhSachDTO[]>> {
        let result: Pagination<KienThucVeSinhAttpDanhSachDTO[]> = {
            page: page,
            size: size,
            total: 0,
            rows: [],
        };
        await api
            .post(`${ROOT_PATH}/bao-cao`, request, {
                params: {
                    pageSize: size,
                    pageNumber: page
                },
            }).then((response) => {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }).catch();
        return result;
    }

    async baoCaoExport(data: TraCuuRequestDTO | {}, params: ParamExport): Promise<any> {
        return await api.post(`${ROOT_PATH}/bao-cao/export`, data, {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
    async getByBienNhan(soBienNhan: string): Promise<KienThucVeSinhAttpDTO> {
        let result = {} as KienThucVeSinhAttpDTO;
        await api.get(`${ROOT_PATH}/thong-tin-chung/get-by-so-bien-nhan`, {
            params: {
                soBienNhan: soBienNhan
            }
        }).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }

    async thongKe(data: KienThucVeSinhAttpThongKeDTO): Promise<KienThucVeSinhAttpThongKeDTO[]> {
        let result: KienThucVeSinhAttpThongKeDTO[] = []
        await api
            .post(`${ROOT_PATH}/thong-ke`, data, this.getTokenRequestHeaders()).then((response) => {
                result = response.data.data;
            }).catch();
        return result;
    }

    exportFileThongKe = async (request: KienThucVeSinhAttpThongKeDTO, downloadType: String): Promise<any> => {
        let data = {...request};
        data.downloadType = downloadType.toString();
        return api.post(`${ROOT_PATH}/thong-ke/export`, data,
            {
                params: {},
                data: {},
                responseType: "blob",
                timeout: 30000
            });
    };


    handleDownloadTemplateFile = async (request: any): Promise<any> => {
        return api.get(`${ROOT_PATH}/download-bussiness-template-file`, {
            responseType: "blob",
            params: request,
        });
    };
}