import { InputLabel } from "@material-ui/core"
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { ColumnTable, CommonTableChuyenNganh } from "components/commons/table/commonTableChuyenNganh";
import { AoNuoiDTO } from "model/thuySan/longBe/dangKyThongTinChungDTO";
import React, { useEffect, useState } from "react";
import { AoNuoiDialog } from "./aoNuoiDialog";

export interface DanhSachAoNuoiPropsI {
    onDeleteItem?: (item: AoNuoiDTO, index: number) => void;
    onSaveItem?: (item: AoNuoiDTO, index: number, isEdit: boolean) => void;
    dataListItem: AoNuoiDTO[];
    disableAddButton?: boolean;
    viewOnly?: boolean;
}



export const DanhSachAoNuoi = (props: DanhSachAoNuoiPropsI) => {
    const { onSaveItem, onDeleteItem, dataListItem, disableAddButton, viewOnly } = props;
    const labelStyles = bootstrapTextFieldHook.useLabel();

    const [openDlg, setOpenDlg] = useState(false);
    const [itemEdited, setItemEdited] = useState<AoNuoiDTO | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);

    const columns: ColumnTable<AoNuoiDTO>[] = [
        { title: "Mã số nhận diện", field: "maSoNhanDien" },
        { title: "Ao/bể/lồng nuôi", field: "longNuoi" },
        { title: "Diện tích (m2/m3)", field: "dienTich" },
        { title: "Đối tượng nuôi", field: "doiTuongNuoi" },
        { title: "Địa chỉ", field: "fullDiaChi" },
    ];

    function handleClickAdd() {
        setItemEdited(null);
        setIsEdit(false);
        setOpenDlg(true);
    }

    function handleSaveDialog(item: AoNuoiDTO) {
        setOpenDlg(false);
        onSaveItem && onSaveItem(item, indexEdit, isEdit);

    }

    function handleEditItem(item: AoNuoiDTO, index: number) {
        setItemEdited(item);
        setIsEdit(true);
        setIndexEdit(index);
        setOpenDlg(true);
    }
    return (
        <>
            <InputLabel classes={labelStyles}>{'2. Danh sách Ao/bể/lồng nuôi'}</InputLabel>
            <CommonTableChuyenNganh columns={columns} rows={dataListItem}
                onDelete={onDeleteItem ? onDeleteItem : undefined}
                onEdit={onSaveItem ? handleEditItem : undefined}
                viewOnly={viewOnly}
                onAdd={disableAddButton ? undefined : handleClickAdd} />
            {
                openDlg && <AoNuoiDialog openDialog={openDlg} onDialogClose={() => setOpenDlg(false)}
                    onSubmitData={handleSaveDialog}
                    itemEdit={itemEdited}
                />
            }
        </>
    )
}