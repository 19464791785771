import { Box, Button, Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { LongBeManagementSevice } from "apis/thuySan/longBe";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { TypeCell } from "components/commons/customTable/customTableInterface";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { useGetSoGiayPhepThuySan } from "hooks/useGetSoGiayPhep";
import { DangKyLongBeThongTinChungDTO, LichSuBienDongDTO } from "model/thuySan/longBe/dangKyThongTinChungDTO";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DanhSachAoNuoi } from "../danhSachAoNuoi/danhSachAoNuoi";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

export const ViewXNNuoiTrongLongBe = ({item}:any) => {
    const title = "Thông tin chi tiết xác nhận đăng ký nuôi trồng thủy sản lồng bè, đối tượng thủy sản nuôi chủ lực";
    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = title;
    }, []);

    // Control
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>{title}</span>
            </div>
            <Paper component="div">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={value} onChange={handleChange}>
                        <CustomTab label="Thông tin chung" />
                        <CustomTab label="Lịch sử" />
                    </CustomTabs>
                </Box>
                {value === 0 && (
                    <TabPanel value={value} index={0}>
                        <ThongTinChung itemChiTiet={item} />
                    </TabPanel>
                )}
                {value === 1 && (
                    <TabPanel value={value} index={1}>
                        <LichSu itemChiTiet={item} />
                    </TabPanel>
                )}
            </Paper>
        </div>
    )
}

const ThongTinChung = ({itemChiTiet}:any) => {
    const { item } = useGetSoGiayPhepThuySan();
    const [data, setData] = useState<DangKyLongBeThongTinChungDTO>({} as DangKyLongBeThongTinChungDTO);
    const history = useHistory();

    useEffect(() => {
        function initialData() {
            if (item && item.id) {
                new LongBeManagementSevice().getThongTinChung(item.id).then(resp => {
                    if (resp) {
                        setData(resp);
                    }
                })
            }
            if (itemChiTiet && itemChiTiet.id) {
                new LongBeManagementSevice().getThongTinChung(itemChiTiet.id).then(resp => {
                    if (resp) {
                        setData(resp);
                    }
                })
            }
        }
        initialData();
    }, [item?.id])
    return (
        <Grid container>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số giấy xác nhận</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soGiayPhep}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCap && moment(data.ngayCap).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Họ tên chủ cơ sở</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.tenCoSo}</label>
                </Grid>
                
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số căn cước/CMND</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soCMT}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapCMT && moment(data.ngayCapCMT).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.noiCapCMT}</label>
                </Grid>
            </Grid>


            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số đăng ký doanh nghiệp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soDKDN}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapDKDN}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.noiCapDKDN}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Địa chỉ cơ sở</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.fullDiaChi}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số điện thoại</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.soDienThoai}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Fax</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.fax}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Email</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.diaChi?.email}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Tổng diện tích của cơ sở (ha)</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.tongDienTichCoSo}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">DT/TT nuôi trồng thủy sản (m2/m3)</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.dienTichNuoiTrong}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Hình thức nuôi</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.hinhThucNuoiStr}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Lần cấp lại</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.lanCapLai}</label>
                </Grid>
    
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <DanhSachAoNuoi viewOnly dataListItem={data.lstAoNuoi ? data.lstAoNuoi : []} disableAddButton />
            </Grid>
            <Grid item xs={12} className="gridItem">
                <Box className="button-group" marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

const LichSu = ({itemChiTiet}:any) => {
    // Constant
    const { item } = useGetSoGiayPhepThuySan();
    const history = useHistory();
    const columns: ColumnCustomTable<LichSuBienDongDTO>[] = [
        { title: "Loại biến động", field: "loaiBienDong", align: "left" },
        { title: "Ngày biến động", field: "ngayBienDong", type: TypeCell.DATE },
        { title: "Số lần", field: "soLan" },
        { title: "Nội dung", field: "noiDung", width: "40%", align: "left" },
        { title: "Đính kèm", field: "attachFiles", type: TypeCell.ATTACH_FILE_TOOL_TIP },
    ];
    // State
    const [rows, setRows] = useState<LichSuBienDongDTO[]>([]);
    // Hook
    useEffect(() => {
        function initialData() {
            if (item?.id) {
                new LongBeManagementSevice().getLichSuBienDong(Number(item.id)).then(resp => {
                    setRows(resp);
                })
            }
            if (itemChiTiet&&itemChiTiet?.id) {
                new LongBeManagementSevice().getLichSuBienDong(Number(itemChiTiet.id)).then(resp => {
                    setRows(resp);
                })
            }
        }
        initialData();
    }, [item?.id]);
    return (
        <>
            <CustomTable columns={columns} rows={rows} />
            <Grid item xs={12} className="gridItem">
                <Box className="button-group" marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </>
    );
}