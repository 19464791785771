export enum TinhTrangEnum {
    NONE = "",
    DANG_HOAT_DONG = "0",
    THU_HOI = "1",
    CAP_LAI = "2",
    GIA_HAN = "3",
    XOA_DANG_KY = "4",
    CAP_LAN_DAU = "5",
    HET_HAN = "6"
}

export function getTinhTrangEnum(tinhTrang?: TinhTrangEnum) {
    if (tinhTrang == TinhTrangEnum.DANG_HOAT_DONG) {
        return "Đang hoạt động";
    } else if (tinhTrang == TinhTrangEnum.THU_HOI) {
        return "Thu hồi";
    } else if (tinhTrang == TinhTrangEnum.CAP_LAI) {
        return "Cấp lại";
    } else if (tinhTrang == TinhTrangEnum.GIA_HAN) {
        return "Gia hạn";
    } else if (tinhTrang == TinhTrangEnum.XOA_DANG_KY) {
        return "Xóa đăng ký";
    } else if (tinhTrang == TinhTrangEnum.CAP_LAN_DAU) {
        return "Cấp lần đầu";
    } else if (tinhTrang == TinhTrangEnum.HET_HAN) {
        return "Hết hạn";
    }
    return "";
}