import MomentUtils from "@date-io/moment";
import { Box, Button, Checkbox, ClickAwayListener, createStyles, FormControlLabel, FormHelperText, Grid, Grow, InputAdornment, InputLabel, makeStyles, MenuItem, MenuList, NativeSelect, Paper, Popper, TextareaAutosize, TextField, Theme, Typography } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ChuyenXuLyRequest, HoSoCuaToiDTO, HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/hoSoCuaToiMgtService";
import { BussinessTemplateDownloadRequest, ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import LgspService from "apis/quanTri/LgspService";
import { UserData } from "apis/userMgt/userManagementService";
import { InBieuMauButton } from "components/commons/button/inBieuMauButton";
import DropZoneCustom from "components/commons/customDropzone/dropzoneCustome";
import { Pageable } from "components/commons/customTable/customTableInterface";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import EndAdornmentSearch from "components/commons/endAdornmentSearch";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { CustomButton } from "components/commons/hoso/TepDinhKemPopper";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomInput } from "components/commons/input/customInput";
import DialogMap from "components/commons/map/dialogMap";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { API_KEY_MAP } from "constants/constantKey";
import { ACCEPT_FILES, URL_ATTACHFILES_QLCL_NONG_LAM_THUY_SAN } from "constants/constantsQLCLNongLamThuySan";
import { loadModules } from "esri-loader";
import { getIn, useFormik } from "formik";
import { getObject } from "helpers/localStorage";
import { useContextDropZone } from "hooks/useContextDropZone";
import { useGetReferItem } from "hooks/useGetSoGiayPhep";
import { useGetThongTinChuyenNganh } from "hooks/useGetThongTinChuyenNganh";
import { useQuerySoBienNhan } from "hooks/useQuery";
import { ThongTinChuyenNganhEnum, TypeField } from "model/eForm/thongTinChuyenNganheForm";
import { LopBanDoEnum } from "model/marker";
import { ChungNhanAttpHienTrangCoSoDTO_INT, ChungNhanAttpHoaChatDTO, ChungNhanAttpSanPhamDTO, ChungNhanAttpSanPhamDTO_INT, ChungNhanAttpThongTinChungDTO, ChungNhanAttpThongTinChung_INT, ChungNhanAttpTrangThietBiDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { BussinessType, DoiTuong, getDoiTuongEnum, getLinhVuc, getLoaiHinhSanXuatKinhDoanhEnum, getLoaiMatHangKinhDoanhEnum, LinhVuc, LoaiChuyenNganh, LoaiHinhSanXuatKinhDoanh, LoaiMatHangKinhDoanh } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import { DiaChiDTO, DiaChiDTO_INT_CAN_THO } from "model/QLCLNongLamThuySan/diaChiDTO";
import DanhSachDuThaoModal from "pages/commons/duThao/danhSachDuThaoModal";
import ChuyenXuLyModal from "pages/hoSoCuaToi/chuyenXuLyModal";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NotificationAction, openNotification } from "redux/actions/notificationAction";
import DateUtil from "utils/dateUtils";
import StringUtil from "utils/stringUtils";
import ThongTinChuyenNganhUtil from "utils/thongTinChuyenNganhUtils";
import * as Yup from "yup";
import { HoaChatSuDung } from "../hoaChat/hoaChatSuDung";
import { SanPham } from "../sanPham/sanPham";
import { TrangThietBi } from "../trangThietBi/trangThietBi";
import { GiayPhepRequest } from "apis/quanTri/model/MotCuaModel";
import LayKetQuaGiayPhepService from "apis/quanTri/LayKetQuaGiayPhepService";
import { LoaiGiayPhepEnum } from "apis/quanTri/model/loaiGiayPhep/loaiGiayPhepEnum";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {

    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

const fontFamily = [
    'Arial',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
        textarea: {
            // boxSizing: 'inherit',
            width: '98%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ced4da',
            fontSize: 14,
            padding: '0.5rem .75rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            fontFamily: fontFamily,
            '&:focus': {
                outline: 'none',
                borderRadius: 4,
                borderColor: '#ced4da',
            },
        }
    }),
);

export const FormCapMoiChungNhanDuDieuKienAttp = () => {
    const soBienNhan = useQuerySoBienNhan();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const title = !StringUtil.isNullOrEmty(soBienNhan) ? "CẤP CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM - MÃ HỒ SƠ :  " + soBienNhan : "CẤP CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM";
    const thongTinChuyenNganh = useGetThongTinChuyenNganh(soBienNhan);
    const [indexTab, setIndexTab] = useState(0);
    const classes = useStyles();
    const [openResetFormDlg, setOpenResetFormDlg] = useState(false);
    const { item } = useGetReferItem<ChungNhanAttpThongTinChungDTO>();



    const { attachFileServer, handleSetAttachFile, handleChangeStatus, handleDownloadFile, handleDeleteFileServer } = useContextDropZone();

    const [pageParamsSanPham, setPageParamsSanPham] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);
    const [sanPham, setSanPham] = useState<ChungNhanAttpSanPhamDTO>({ ...ChungNhanAttpSanPhamDTO_INT });

    const initialValues: ChungNhanAttpThongTinChungDTO = { ...ChungNhanAttpThongTinChung_INT, soBienNhan: soBienNhan };
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const validationSchema = Yup.object().shape(
        {
            soGcn: Yup.string().required("Số giấy xác nhận không được bỏ trống"),
            ngayCapGcn: Yup.date()
                .nullable()
                .required("Ngày cấp không được bỏ trống")
                .when("ngayHetHanGcn", (ngayHetHanGcn: any, schema: any): any => {
                    if (ngayHetHanGcn) {
                        return schema.max(ngayHetHanGcn, "Không hợp lệ");
                    }
                    return schema;
                }).max(new Date(), "Ngày cấp không được lớn hơn ngày hiện tại"),
            ngayHetHanGcn: Yup.date()
                .nullable()
                .required("Ngày hết hạn không được bỏ trống")
                .when("ngayCapGcn", (ngayCapGcn: any, schema: any): any => {
                    if (ngayCapGcn) {
                        return schema.min(ngayCapGcn, "Không hợp lệ");
                    }
                    return schema;
                }),
            tenCoSo: Yup.string().required("Tên cơ sở không được bỏ trống"),
            matHangSxkd: Yup.string().required("Mặt hàng sản xuất kinh doanh không được bỏ trống"),
            diaChi: Yup.object().shape({
                diaChiTinhCode: Yup.string().required("Tỉnh/Thành không được bỏ trống"),
                diaChiHuyenCode: Yup.string().trim().required("Quận/Huyện không được bỏ trống"),
                diaChiXaCode: Yup.string().trim().required("Phường/Xã không được bỏ trống"),
                // soDienThoai: Yup.string()
                //     .matches(/^[0-9]{10}$|^[0-9]{11}$/, "Số điện thoại không đúng dịnh dạng"),
                // .matches(/^(0[2-9]{1}[0-9]{8}|\+84[2-9]{1}[0-9]{8})$/, "Số điện thoại không đúng dịnh dạng")
                // .max(10, "Số điện thoại không đúng dịnh dạng")
                // .min(10, "Số điện thoại không đúng dịnh dạng"),
                email: Yup.string().email("Email không đúng dịnh dạng")
            }),
            linhVuc: Yup.string().required("Lĩnh vực không được bỏ trống"),
        },
        [["ngayCapGcn", "ngayHetHanGcn"]]
    );
    //hook
    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });
    const history = useHistory();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [openBuocChuyen, setOpenBuocChuyen] = React.useState(false);
    const [buocChuyens, setBuocChuyens] = React.useState<ThongTinBuocDTO[]>([]);
    const [buocChuyen, setBuocChuyen] = React.useState<ThongTinBuocDTO>({} as ThongTinBuocDTO);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showModal, setShowModal] = React.useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = React.useState(false);
    const [chuyenXuLyRequest, setChuyenXuLyRequest] = React.useState<ChuyenXuLyRequest>({} as ChuyenXuLyRequest);
    let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();
    const handleDongBoGiayPhep = async () => {
        const giayPhep: GiayPhepRequest = {
            maHoSo: formik?.values?.soBienNhan ? formik?.values?.soBienNhan : "",
            loaiGiayPhep: LoaiGiayPhepEnum.CHUNG_NHAN_ATTP,
            giayPhepId: formik?.values.id ? formik?.values.id : 0,
        }
        await layKetQuaSerVice.layKetQuaByMaHoSo(giayPhep).then((res) => {
            if (res != null && res?.data?.error_code == "SUCCESSFUL" && res?.data?.data?.id > 0) {
                initData();
                dispatchNotifiAction(openNotification({ severity: "success", content: 'Yêu cầu thực hiện thành công', open: true }));
            } else {
                dispatchNotifiAction(openNotification({ severity: "error", content: 'Yêu cầu thực hiện thất bại', open: true }));
            }
        }).finally(() => {
        });
    }

    const initData=()=> {
        if (item && item.id) {
            loadThongTinChungById(Number(item.id));
            loadDanhSachSanPhamInit({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: Number(item.id) });
            loadHienTrangCoSoInit(Number(item.id));
        } else if (soBienNhan) {
            // getThongTinChungBySoBienNhan(soBienNhan);

            new ChungNhanDuDieuKienAttpService().getThongTinChungBySoBienNhan(soBienNhan, LoaiChuyenNganh.CAP_MOI).then(resp => {
                if (resp) {
                    resp.attachFiles && resp.attachFiles.length > 0 && handleSetAttachFile([...resp.attachFiles]);
                    formik.setValues(resp);
                    formik.setFieldValue("listSanPham", new Array<ChungNhanAttpSanPhamDTO>());
                    formik.setFieldValue("hienTrangCoSo", { ...ChungNhanAttpHienTrangCoSoDTO_INT });
                } else if (thongTinChuyenNganh.length > 0) {
                    formik.setValues(prev => {
                        const updatedTTC = {
                            ...prev,
                            soBienNhan: soBienNhan,
                            tenCoSo: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.TEN_DOANH_NGHIEP, thongTinChuyenNganh, TypeField.TYPE_STRING),
                            soDkkd: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.GIAY_DKDN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                            ngayCapDkkd: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NGAY_CAP_DKDN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                            noiCapDkkd: ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.NOI_CAP_DKDN, thongTinChuyenNganh, TypeField.TYPE_STRING),
                        }
                        let diaChiEform: DiaChiDTO = { ...DiaChiDTO_INT_CAN_THO };
                        if (updatedTTC.diaChi) {
                            diaChiEform = { ...updatedTTC.diaChi }
                        }
                        diaChiEform.diaChiTinhCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.TINH_THANH, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.diaChiHuyenCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.QUAN_HUYEN, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.diaChiXaCode = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.PHUONG_XA, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.soDienThoai = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.SO_DIEN_THOAI, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.fax = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.FAX, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.email = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.EMAIL, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        diaChiEform.tenDuong = ThongTinChuyenNganhUtil.getValueByKey(ThongTinChuyenNganhEnum.DIA_DIEM, thongTinChuyenNganh, TypeField.TYPE_STRING);
                        // diaChiEform.diaChiTinhCode = "92";
                        // diaChiEform.diaChiHuyenCode = "927";
                        // diaChiEform.diaChiXaCode = "31267";
                        updatedTTC.diaChi = diaChiEform;
                        return updatedTTC;
                    });
                }
            })
        }
    }

    useEffect(() => {
       initData();
    }, [item?.id, soBienNhan, thongTinChuyenNganh]);

    async function getThongTinChungBySoBienNhan(soBienNhan: String) {
        await new ChungNhanDuDieuKienAttpService().getThongTinChungBySoBienNhan(soBienNhan, LoaiChuyenNganh.CAP_MOI).then(resp => {
            if (resp) {
                resp.attachFiles && resp.attachFiles.length > 0 && handleSetAttachFile([...resp.attachFiles]);
                formik.setValues(resp);
                formik.setFieldValue("listSanPham", new Array<ChungNhanAttpSanPhamDTO>());
                formik.setFieldValue("hienTrangCoSo", { ...ChungNhanAttpHienTrangCoSoDTO_INT });
            }
        })
    }

    // useEffect(() => {
    //     if (formik.values.ngayCapGcn != null)
    //         formik.setFieldValue("ngayHetHanGcn", DateUtil.getFiveYear(formik.values.ngayCapGcn));
    // }, [formik.values.ngayCapGcn]);

    async function loadThongTinChungById(id: number) {
        await new ChungNhanDuDieuKienAttpService().getThongTinChungById(id).then(resp => {
            if (resp) {
                resp.attachFiles && resp.attachFiles.length > 0 && handleSetAttachFile([...resp.attachFiles]);
                formik.setValues(resp);
                formik.setFieldValue("listSanPham", new Array<ChungNhanAttpSanPhamDTO>());
                formik.setFieldValue("hienTrangCoSo", { ...ChungNhanAttpHienTrangCoSoDTO_INT });
            }
        })
    }

    async function loadDanhSachSanPhamInit(sanPham: ChungNhanAttpSanPhamDTO) {
        await new ChungNhanDuDieuKienAttpService().getDanhSachSanPham(sanPham, pageParamsSanPham.page, pageParamsSanPham.rowsPerPage).then((response) => {
            if (response) {
                formik.setFieldValue("listSanPham", response.rows);
                setPageParamsSanPham((prev) => ({ ...prev, total: response.total }));
            }
        });
    }

    async function loadHienTrangCoSoInit(chungNhanId: number) {
        if (chungNhanId != null) {
            await new ChungNhanDuDieuKienAttpService().getHienTrangCoSoByChungNhanId(chungNhanId).then(response => {
                if (response) formik.setFieldValue("hienTrangCoSo", response);
            })
        }
    }

    function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
        if (newValue == 1 && formik.values.listSanPham.length == 0 && formik.values.id) {
            loadDanhSachSanPham({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: formik.values.id });
        }
        if (newValue == 2 && formik.values.hienTrangCoSo.id == null && formik.values.id) {
            loadHienTrangCoSo(formik.values.id);
        }
        setIndexTab(newValue);
    }


    // const [locater, setLocater] = React.useState<any>();

    // useEffect(() => {
    //     loadModules(["esri/config", "esri/tasks/Locator"]).then(([esriConfig, Locator]) => {
    //         esriConfig.apiKey = API_KEY_MAP;
    //         const locater = new Locator({
    //             url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    //         });
    //         setLocater(locater);
    //     });
    // }, []);

    // React.useEffect(() => {
    //     loadBanDo();
    // }, [locater, formik.values.diaChi]);


    // const loadBanDo = async () => {
    //     if (locater && formik.values.diaChi?.diaChiTinhCode && formik.values.diaChi.diaChiHuyenCode && formik.values.diaChi.diaChiXaCode) {
    //         const address = {
    //             SingleLine: StringUtil.diaChiToString(formik.values.diaChi),
    //         };
    //         const params = {
    //             address: address,
    //             forStorage: false,
    //         };

    //         await locater.addressToLocations(params).then((response: any) => {
    //             const { location } = response[0];
    //             const { longitude, latitude } = location;
    //             formik.setFieldValue("diaChiKinhDo", longitude);
    //             formik.setFieldValue("diaChiViDo", latitude);
    //         });
    //     }
    // }   

    async function handleSubmit(data: ChungNhanAttpThongTinChungDTO) {
        formik.values.attachFiles = [...attachFileServer.filter(f => !f.isDeleted)];
        await new ChungNhanDuDieuKienAttpService().addOrUpdateThongTinChung(data, LoaiChuyenNganh.CAP_MOI).then((response) => {
            initData();
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
    }

    async function handleGetThongTinDNByMaSoDN(): Promise<void> {
        let soDKDN = formik.values.soDkkd;
        if (soDKDN && !StringUtil.isNullOrEmty(soDKDN)) {
            await new LgspService().layThongTinDNByMaSoDN(soDKDN.toString()).then((res) => {
                if (res.status === 200) {
                    if (res.data.data.data) {
                        const result = res.data.data.data;
                        if (!StringUtil.isNullOrEmty(result.ngayThanhLap)) {
                            formik.setFieldValue("ngayCapDkkd", DateUtil.stringDDMMYYYtoDate(result.ngayThanhLap))
                        }
                        if (!StringUtil.isNullOrEmty(result.tenDoanhNghiep_VN)) {
                            formik.setFieldValue("tenCoSo", result.tenDoanhNghiep_VN)
                        }
                        if (!StringUtil.isNullOrEmty(result.tenDuong)) {
                            formik.setFieldValue("diaChi.tenDuong", result.tenDuong)
                        }
                        if (!StringUtil.isNullOrEmty(result.soNha)) {
                            formik.setFieldValue("diaChi.soNhaDuong", result.soNha)
                        }
                        if (!StringUtil.isNullOrEmty(result.dienThoai)) {
                            formik.setFieldValue("diaChi.soDienThoai", result.dienThoai)
                        }
                        if (!StringUtil.isNullOrEmty(result.fax)) {
                            formik.setFieldValue("diaChi.fax", result.fax)
                        }
                        if (!StringUtil.isNullOrEmty(result.email)) {
                            formik.setFieldValue("diaChi.email", result.email)
                        }
                        dispatchNotifiAction(openNotification({ severity: "success", open: true, content: `Tra cứu số ${soDKDN} thành công` }));
                    } else {
                        dispatchNotifiAction(openNotification({ severity: "error", open: true, content: `Không tìm thấy số ${soDKDN}` }));
                        formik.setFieldValue("ngayCapDkkd", null);
                        formik.setFieldValue("tenCoSo", "");
                        formik.setFieldValue("diaChi.tenDuong", "");
                        formik.setFieldValue("diaChi.soNhaDuong", "");
                        formik.setFieldValue("diaChi.soDienThoai", "");
                        formik.setFieldValue("diaChi.fax", "");
                        formik.setFieldValue("diaChi.email", "");
                    }

                }
            })
        } else {
            dispatchNotifiAction(openNotification({ severity: "error", open: true, content: "Vui lòng nhập số đăng ký doanh nghiệp cần tra cứu" }));
        }
    }

    async function handleSubmitSanPham() {
        let sanPhamNew = { ...sanPham, chungNhanId: formik.values.id };
        setSanPham(sanPhamNew);
        await new ChungNhanDuDieuKienAttpService().addOrUpdateSanPham(sanPhamNew).then((response) => {
            setSanPham({ ...ChungNhanAttpSanPhamDTO_INT });
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
        loadDanhSachSanPham({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: formik.values.id });
    }

    async function loadDanhSachSanPham(sanPham: ChungNhanAttpSanPhamDTO) {
        await new ChungNhanDuDieuKienAttpService().getDanhSachSanPham(sanPham, pageParamsSanPham.page, pageParamsSanPham.rowsPerPage).then((response) => {
            formik.setFieldValue("listSanPham", response.rows);
            setPageParamsSanPham((prev) => ({ ...prev, total: response.total }));
        });
    }

    async function searchDanhSachSanPham() {
        let sanPhamNew = { ...sanPham, chungNhanId: formik.values.id };
        setSanPham(sanPhamNew);
        await new ChungNhanDuDieuKienAttpService().getDanhSachSanPham(sanPhamNew, pageParamsSanPham.page, pageParamsSanPham.rowsPerPage).then((response) => {
            formik.setFieldValue("listSanPham", response.rows);
            setPageParamsSanPham((prev) => ({ ...prev, total: response.total }));
        });
    }

    async function loadHienTrangCoSo(chungNhanId: number) {
        if (chungNhanId != null) {
            await new ChungNhanDuDieuKienAttpService().getHienTrangCoSoByChungNhanId(chungNhanId).then(response => {
                if (response) formik.setFieldValue("hienTrangCoSo", response);
            })
        }
    }

    const handleOpenResetForm = () => {
        setOpenResetFormDlg(true);
    }

    const handleCloseResetForm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetForm();
        }
        setOpenResetFormDlg(false);
    };

    function handleResetForm() {
        formik.setValues({
            ...initialValues,
            id: formik.values.id,
            ngayTao: formik.values.ngayTao,
            nguoiTaoId: formik.values.nguoiTaoId,
            soBienNhan: formik.values.soBienNhan
        });
        handleSetAttachFile(attachFileServer.flatMap((item) => ({ ...item, isDeleted: true })));
    }

    const handleChangeSanPham = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setSanPham({ ...sanPham, [e.target.name]: value })
    }

    function handleResetFormSanPham() {
        setSanPham({ ...ChungNhanAttpSanPhamDTO_INT });
    }

    function handleSaveItemHoaChatSuDung(item: ChungNhanAttpHoaChatDTO, index: number, isEdit: boolean) {
        let data = [...formik.values.hienTrangCoSo.listHoaChat];
        if (isEdit) {
            data[index] = item;
        } else {
            data.splice(0, 0, item);
        }
        formik.setFieldValue("hienTrangCoSo.listHoaChat", data);
    }

    function handleDeleteItemHoaChatSuDung(_item: ChungNhanAttpHoaChatDTO, index: number) {
        let data = [...formik.values.hienTrangCoSo.listHoaChat];
        data.splice(index, 1);
        formik.setFieldValue("hienTrangCoSo.listHoaChat", data);
    }

    function handleSaveItemTrangThietBi(item: ChungNhanAttpTrangThietBiDTO, index: number, isEdit: boolean) {
        let data = [...formik.values.hienTrangCoSo.listTrangThietBi];
        if (isEdit) {
            data[index] = item;
        } else {
            data.splice(0, 0, item);
        }
        formik.setFieldValue("hienTrangCoSo.listTrangThietBi", data);
    }

    function handleDeleteItemTrangThietBi(_item: ChungNhanAttpTrangThietBiDTO, index: number) {
        let data = [...formik.values.hienTrangCoSo.listTrangThietBi];
        data.splice(index, 1);
        formik.setFieldValue("hienTrangCoSo.listTrangThietBi", data);
    }

    async function handleSaveItemSanPham(item: ChungNhanAttpSanPhamDTO, index: number, isEdit: boolean) {
        let itemNew = { ...item, chungNhanId: formik.values.id };
        await new ChungNhanDuDieuKienAttpService().addOrUpdateSanPham(itemNew).then((response) => {
            setSanPham({ ...ChungNhanAttpSanPhamDTO_INT });
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
        loadDanhSachSanPham({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: formik.values.id });
    }

    async function handleDeleteItemSanPham(item: ChungNhanAttpSanPhamDTO, index: number) {
        let itemNew = { ...item, chungNhanId: formik.values.id };
        await new ChungNhanDuDieuKienAttpService().deleteSanPham(itemNew).then((response) => {
            setSanPham({ ...ChungNhanAttpSanPhamDTO_INT });
            dispatchNotifiAction(openNotification({ severity: response.isSuccess ? "success" : "error", content: response.message, open: true }));
        });
        loadDanhSachSanPham({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: formik.values.id });
    }

    async function handleDownloadTemplate(fileId: String, fileName: String) {
        let request = {
            soBienNhan: formik.values.soBienNhan,
            filedId: fileId,
        } as BussinessTemplateDownloadRequest;
        await new ChungNhanDuDieuKienAttpService().handleDownloadTemplateFile(request, LoaiChuyenNganh.CAP_MOI).then((res) => {
            let blob = new Blob([res.data], { type: res.data.type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", String(fileName));
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    const handleChangeNgayCap = async (e: any) => {
        formik.setFieldValue('ngayCapGcn', e);
        if (e != null) {
            formik.setFieldValue("ngayHetHanGcn", DateUtil.getThreeYearRight(e));
        }

    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(String(formik.values.soBienNhan)).then((data) => {
            const buocChuyensNew = [...data];
            setBuocChuyens(buocChuyensNew);
        });
        setOpenBuocChuyen(!openBuocChuyen);
    };
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenBuocChuyen(false);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    };
    const clickBuocChuyen = (buocChuyenData: ThongTinBuocDTO) => {
        setBuocChuyen(buocChuyenData);
        const chuyenXuLyRequestNew: ChuyenXuLyRequest = {
            ...chuyenXuLyRequest,
            maHoSo: String(formik.values.soBienNhan),
            idBuocXuLyTiepTheo: buocChuyenData.idBuocXuLy,
            maNguoiGui: C_USER_DATA_DE.userName,
            nguoiNhanId: 0,
            noiDung: "",
            trichYeuDuThao: "",
            idLoaiVanBan: 0,
            hanXuLy: buocChuyenData.hanXuLyTheoGio,
        };
        setChuyenXuLyRequest(chuyenXuLyRequestNew);
        setShowModal(!showModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{title}</span>
                </div>
                <Paper component="div">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={indexTab} onChange={handleChangeTab}>
                            <CustomTab label="Thông tin chung" />
                            <CustomTab label="Thông tin về sản phẩm" disabled={formik.values.id == null} />
                            <CustomTab label="Hiện trạng cơ sở" disabled={formik.values.id == null} />
                        </CustomTabs>
                    </Box>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Đối tượng</InputLabel>
                                    <NativeSelect input={<CustomInput />} fullWidth name="doiTuong" value={formik.values?.doiTuong} onChange={formik.handleChange} >
                                        <option value={DoiTuong.NONE}>Chọn đối tượng</option>
                                        <option value={DoiTuong.CS_GIET_MO}>{getDoiTuongEnum(DoiTuong.CS_GIET_MO)}</option>
                                        <option value={DoiTuong.SP_NONG_LAM_THUY_SAN}>{getDoiTuongEnum(DoiTuong.SP_NONG_LAM_THUY_SAN)}</option>
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        required
                                        label={"Số giấy chứng nhận"}
                                        name="soGcn"
                                        value={formik.values.soGcn}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "soGcn"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "soGcn")) && formik.errors?.soGcn}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <KeyboardDatePicker
                                        name="ngayCapGcn"
                                        label={"Ngày cấp"}
                                        required
                                        disableFuture
                                        value={(formik.values.ngayCapGcn !== null && formik.values.ngayCapGcn !== undefined) ? formik.values.ngayCapGcn : null}
                                        onChange={handleChangeNgayCap}
                                        autoOk={true}
                                        fullWidth
                                        format="DD/MM/YYYY"
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "ngayCapGcn"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "ngayCapGcn")) && formik.errors.ngayCapGcn}
                                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <KeyboardDatePicker
                                        name="ngayHetHanGcn"
                                        label={"Ngày hết hạn"}
                                        value={(formik.values.ngayHetHanGcn !== null && formik.values.ngayHetHanGcn !== undefined) ? formik.values.ngayHetHanGcn : null}
                                        onChange={(date, _val) => {
                                            formik.setFieldValue("ngayHetHanGcn", date);
                                        }}
                                        autoOk={true}
                                        fullWidth
                                        required
                                        format="DD/MM/YYYY"
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "ngayHetHanGcn"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "ngayHetHanGcn")) && formik.errors.ngayHetHanGcn}
                                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số đăng ký kinh doanh"}
                                        name="soDkkd"
                                        value={formik.values.soDkkd}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles, endAdornment: <EndAdornmentSearch onClick={handleGetThongTinDNByMaSoDN} />, }}
                                        error={Boolean(getIn(formik.errors, "soDkkd"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "soDkkd")) && formik.errors?.soDkkd}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <KeyboardDatePicker
                                        name="ngayCapDkkd"
                                        label={"Ngày cấp"}
                                        value={(formik.values.ngayCapDkkd !== null && formik.values.ngayCapDkkd !== undefined) ? formik.values.ngayCapDkkd : null}
                                        onChange={(date, _val) => {
                                            formik.setFieldValue("ngayCapDkkd", date);
                                        }}
                                        autoOk={true}
                                        fullWidth
                                        format="DD/MM/YYYY"
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "ngayCapDkkd"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "ngayCapDkkd")) && formik.errors.ngayCapDkkd}
                                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapDkkd"
                                        value={formik.values.noiCapDkkd}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "noiCapDkkd"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "noiCapDkkd")) && formik.errors?.noiCapDkkd}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        required
                                        label={"Tên cơ sở"}
                                        name="tenCoSo"
                                        value={formik.values.tenCoSo}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "tenCoSo"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "tenCoSo")) && formik.errors?.tenCoSo}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Mã số"}
                                        name="maSoDkkd"
                                        value={formik.values.maSoDkkd}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "maSoDkkd"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "maSoDkkd")) && formik.errors?.maSoDkkd}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Loại hình sản xuất, kinh doanh</InputLabel>
                                    <NativeSelect input={<CustomInput />} fullWidth name="loaiHinhSxkd" value={formik.values?.loaiHinhSxkd} onChange={formik.handleChange} >
                                        <option value={LoaiHinhSanXuatKinhDoanh.NONE}>--Chọn--</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.NHA_NUOC}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.NHA_NUOC)}</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.LIEN_DOANH_NUOC_NGOAI}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.LIEN_DOANH_NUOC_NGOAI)}</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.TU_NHAN}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.TU_NHAN)}</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.VON_NUOC_NGOAI}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.VON_NUOC_NGOAI)}</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.CO_PHAN}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.CO_PHAN)}</option>
                                        <option value={LoaiHinhSanXuatKinhDoanh.KHAC}>{getLoaiHinhSanXuatKinhDoanhEnum(LoaiHinhSanXuatKinhDoanh.KHAC)}</option>
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Năm bắt đầu hoạt động"}
                                        name="namBatDauHoatDong"
                                        value={formik.values.namBatDauHoatDong}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "namBatDauHoatDong"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "namBatDauHoatDong")) && formik.errors?.namBatDauHoatDong}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Công suất thiết kế"}
                                        name="congSuatThietKe"
                                        value={formik.values.congSuatThietKe}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "congSuatThietKe"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "congSuatThietKe")) && formik.errors?.congSuatThietKe}
                                    />
                                </Grid>
                                <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} disabled={false} />
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Đường"}
                                        name="diaChi.tenDuong"
                                        value={formik.values?.diaChi?.tenDuong}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số nhà"}
                                        name="diaChi.soNha"
                                        value={formik.values?.diaChi?.soNha}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button type="submit" variant="outlined" disableElevation onClick={() => setOpenDialog(true)}>
                                                        Lấy tọa độ
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số điện thoại"}
                                        name="diaChi.soDienThoai"
                                        value={formik.values?.diaChi?.soDienThoai}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "diaChi.soDienThoai"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "diaChi.soDienThoai")) && formik.errors?.diaChi?.soDienThoai}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Fax"}
                                        name="diaChi.fax"
                                        value={formik.values?.diaChi?.fax}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Email"}
                                        name="diaChi.email"
                                        value={formik.values?.diaChi?.email}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "diaChi.email"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "diaChi.email")) && formik.errors?.diaChi?.email}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required classes={labelStyles}>Lĩnh vực</InputLabel>
                                    <NativeSelect input={<CustomInput />} fullWidth name="linhVuc" value={formik.values?.linhVuc} onChange={formik.handleChange} >
                                        <option value={LinhVuc.NONE}>Chọn lĩnh vực</option>
                                        <option value={LinhVuc.NONG_LAM_THUY_SAN}>{getLinhVuc(LinhVuc.NONG_LAM_THUY_SAN)}</option>
                                        <option value={LinhVuc.THUY_SAN}>{getLinhVuc(LinhVuc.THUY_SAN)}</option>
                                        <option value={LinhVuc.TRONG_TROT_CHAN_NUOI}>{getLinhVuc(LinhVuc.TRONG_TROT_CHAN_NUOI)}</option>
                                    </NativeSelect>
                                    {
                                        Boolean(getIn(formik.errors, "linhVuc")) && (StringUtil.isNullOrEmty(formik?.values?.linhVuc)) &&
                                        <FormHelperText className="-error" classes={helperTextStyles}>Lĩnh vực không được bỏ trống </FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chủ cơ sở"}
                                        name="chuCoSo"
                                        value={formik.values.chuCoSo}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Loại mặt hàng đăng ký kinh doanh</InputLabel>
                                    <NativeSelect input={<CustomInput />} fullWidth name="loaiMatHangDkkd" value={formik.values?.loaiMatHangDkkd} onChange={formik.handleChange} >
                                        <option value={LoaiMatHangKinhDoanh.NONE}>--Chọn--</option>
                                        <option value={LoaiMatHangKinhDoanh.NONG_SAN}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.NONG_SAN)}</option>
                                        <option value={LoaiMatHangKinhDoanh.THUY_SAN}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.THUY_SAN)}</option>
                                        <option value={LoaiMatHangKinhDoanh.CHUYEN_DOANH}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.CHUYEN_DOANH)}</option>
                                        <option value={LoaiMatHangKinhDoanh.DONG_VAT}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.DONG_VAT)}</option>
                                        <option value={LoaiMatHangKinhDoanh.TRONG_TROT}>{getLoaiMatHangKinhDoanhEnum(LoaiMatHangKinhDoanh.TRONG_TROT)}</option>
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={9} className="grid-item">
                                    <TextField
                                        fullWidth
                                        required
                                        label={"Mặt hàng sản xuất, kinh doanh"}
                                        name="matHangSxkd"
                                        value={formik.values.matHangSxkd}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        error={Boolean(getIn(formik.errors, "matHangSxkd"))}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(getIn(formik.errors, "matHangSxkd")) && formik.errors?.matHangSxkd}
                                    />
                                </Grid>
                                {formik.values.layKetQua ?
                        
                                    <Grid item xs={3} className="grid-item" >
                                        <Box className="button-group" marginTop="15px" >
                                            <Button type="submit" variant="outlined" disableElevation onClick={
                                                () => handleDongBoGiayPhep()
                                            }>
                                                Lấy kết quả
                                            </Button>
                                        </Box>
                                    </Grid> :
                                    <Grid item xs={3} className="grid-item" >

                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} container className="gridItem">
                                <DropZoneCustom
                                    acceptFiles={`${ACCEPT_FILES}`}
                                    attachFileServer={attachFileServer}
                                    url={`${URL_ATTACHFILES_QLCL_NONG_LAM_THUY_SAN}/${BussinessType.CHUNG_NHAN_DU_DK_ATTP}`}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Box className="button-group" marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={() => formik.handleSubmit()}>
                                        Lưu
                                    </Button>
                                    {formik.values.id && <InBieuMauButton onDownloadTemplate={handleDownloadTemplate} />}
                                    <Button variant="contained" color="secondary" disableElevation
                                        onClick={handleOpenResetForm}>
                                        Xóa
                                    </Button>
                                    <ConfirmationDialogRaw id="confirm-dialog-reset-form" keepMounted open={openResetFormDlg}
                                        title="Xóa thông tin"
                                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                                        onClose={handleCloseResetForm}
                                    />
                                    <Button variant="contained" color="default" disableElevation onClick={() => history.go(-1)}>
                                        Trở về
                                    </Button>
                                    {formik.values.id && formik.values.id > 0 &&
                                        <>
                                            <ChuyenXuLyModal thongTinBuocDTO={buocChuyen} reloadData={() => history.go(-1)} onToggle={handleToggle} hoso={history.location.state as HoSoCuaToiDTO} show={showModal} />
                                            <DanhSachDuThaoModal maHoSo={String(formik.values.soBienNhan)} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
                                            <Button variant="contained" color="primary" disableElevation onClick={(e) => {
                                                showHidePopperThaoTac()
                                            }} ref={anchorRef} aria-haspopup="true"
                                                aria-controls={openBuocChuyen ? "menu-list-grow" : undefined}>
                                                Chuyển
                                            </Button>
                                            <Button variant="contained" color="primary" disableElevation onClick={handleToggleDuThao}>
                                                Dự thảo
                                            </Button>
                                            <Popper open={openBuocChuyen} anchorEl={anchorRef.current} role={undefined} transition>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                                                <MenuList autoFocusItem={openBuocChuyen} id="menu-list-grow">
                                                                    {buocChuyens.map((buocChuyen, k) => {
                                                                        return (
                                                                            <MenuItem key={k}>
                                                                                <CustomButton
                                                                                    startIcon={<ReplyIcon />}
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    disableRipple
                                                                                    onClick={() => clickBuocChuyen(buocChuyen)}
                                                                                >
                                                                                    {buocChuyen.tenBuocXuLy}
                                                                                </CustomButton>
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </TabPanel>
                    )}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên sản phầm, sản xuất kinh doanh"}
                                        name="tenSanPham"
                                        value={sanPham.tenSanPham}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên nguyên liệu/ sản phẩm"}
                                        name="tenNguyenLieu"
                                        value={sanPham.tenNguyenLieu}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nguồn gốc/ xuất xứ"}
                                        name="nguonGoc"
                                        value={sanPham.nguonGoc}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Cách thức đóng gói và thông tin ghi trên bao bì"}
                                        name="cachDongGoi"
                                        value={sanPham.cachDongGoi}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Diện tích (m2/ha)"}
                                        name="dienTich"
                                        value={sanPham.dienTich}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số hộ"}
                                        name="soHo"
                                        value={sanPham.soHo}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Sản lượng (Tấn/ kg)"}
                                        name="sanLuong"
                                        value={sanPham.sanLuong}
                                        onChange={handleChangeSanPham}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={4} className="grid-item"></Grid>
                                <Grid item xs={4} className="grid-item">
                                    <Box className="button-group" marginTop="5px">
                                        {/* <Button variant="contained" color="primary" disableElevation
                                            onClick={searchDanhSachSanPham} >
                                            Tìm kiếm
                                        </Button> */}
                                        <Button variant="contained" color="primary" disableElevation onClick={handleSubmitSanPham} >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation onClick={handleResetFormSanPham} >
                                            Làm mới
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} className="grid-item"></Grid>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <SanPham
                                    dataListItem={formik.values.listSanPham} disableAddButton
                                    onSaveItem={handleSaveItemSanPham} onDeleteItem={handleDeleteItemSanPham} />
                            </Grid>
                            {/* <Grid item xs={12} className="grid-item">
                                <CustomTable
                                    columns={sanPhamColumns}
                                    rows={formik.values.listSanPham}
                                    onChangePage={handleOnChangePageSanPham}
                                    onChangeRowsPerPage={handOnChangeRowsPerPageSanPham}
                                    pageParams={pageParamsSanPham}
                                />
                            </Grid> */}
                        </TabPanel>
                    )}
                    {indexTab === 2 && (
                        <TabPanel value={indexTab} index={2}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 1. Nhà xưởng, trang thiết bị </InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tổng diện tích"}
                                        name="hienTrangCoSo.tongDienTich"
                                        value={formik.values.hienTrangCoSo?.tongDienTich}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"DT khu vực tiếp nhận"}
                                        name="hienTrangCoSo.dtKhuVucTiepNhan"
                                        value={formik.values.hienTrangCoSo?.dtKhuVucTiepNhan}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"DT khu vực sản xuất"}
                                        name="hienTrangCoSo.dtKhuVucSanXuat"
                                        value={formik.values.hienTrangCoSo?.dtKhuVucSanXuat}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"DT khu vực đóng gói"}
                                        name="hienTrangCoSo.dtKhuVucDongGoi"
                                        value={formik.values.hienTrangCoSo?.dtKhuVucDongGoi}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"DT khu vực bảo quản"}
                                        name="hienTrangCoSo.dtKhuVucBaoQuan"
                                        value={formik.values.hienTrangCoSo?.dtKhuVucBaoQuan}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"DT khu vực sản xuất khác"}
                                        name="hienTrangCoSo.dtKhuVucSanXuatKhac"
                                        value={formik.values.hienTrangCoSo?.dtKhuVucSanXuatKhac}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item" > </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TrangThietBi
                                        label={'2. Trang thiết bị chính'}
                                        dataListItem={formik.values.hienTrangCoSo.listTrangThietBi}
                                        onSaveItem={handleSaveItemTrangThietBi} onDeleteItem={handleDeleteItemTrangThietBi} />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 3. Hệ thống phụ trợ </InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > Nguồn nước đang sử dụng </InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Nước máy công cộng</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.nuocMayCongCong}
                                                name="hienTrangCoSo.nuocMayCongCong"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Nước giếng khoan</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.nuocGiengKhoan}
                                                name="hienTrangCoSo.nuocGiengKhoan"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Có hệ thống xử lý</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.coHeThongXuLy}
                                                name="hienTrangCoSo.coHeThongXuLy"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Không có hệ thống xử lý</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.khongCoHeThongXuLy}
                                                name="hienTrangCoSo.khongCoHeThongXuLy"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Nước sông</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.nuocSong}
                                                name="hienTrangCoSo.nuocSong"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > Phương pháp xử lý </InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        name="hienTrangCoSo.phuongPhapXuLy"
                                        value={formik.values.hienTrangCoSo?.phuongPhapXuLy}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > Nguồn nước đá sử dụng </InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Tự sản xuất</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.nuocDaTuSanXuat}
                                                name="hienTrangCoSo.nuocDaTuSanXuat"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Mua ngoài</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.nuocDaMuaNgoai}
                                                name="hienTrangCoSo.nuocDaMuaNgoai"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item" />
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > Phương pháp kiểm soát chất lượng nước đá </InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        name="hienTrangCoSo.phuongPhapKsclNuocDa"
                                        value={formik.values.hienTrangCoSo?.phuongPhapKsclNuocDa}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 4. Hệ thống xử lý chất thải </InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Cách thức thu gom, vận chuyển, xử lý"}
                                        name="hienTrangCoSo.heThongXuLyChatThai"
                                        value={formik.values.hienTrangCoSo?.heThongXuLyChatThai}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 5. Người sản xuất, kinh doanh </InputLabel>
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tổng số"}
                                        name="hienTrangCoSo.tongSoNguoiSxkd"
                                        value={formik.values.hienTrangCoSo?.tongSoNguoiSxkd}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Lao động trực tiếp"}
                                        name="hienTrangCoSo.laoDongTrucTiep"
                                        value={formik.values.hienTrangCoSo?.laoDongTrucTiep}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Lao động gián tiếp"}
                                        name="hienTrangCoSo.laoDongGianTiep"
                                        value={formik.values.hienTrangCoSo?.laoDongGianTiep}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={4} className="grid-item"> </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 6. Vệ sinh nhà xưởng, trang thiết bị </InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tần suất"}
                                        name="hienTrangCoSo.tanSuatVsnx"
                                        value={formik.values.hienTrangCoSo?.tanSuatVsnx}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tổng nhân công làm vệ sinh"}
                                        name="hienTrangCoSo.tongCongNhanVsnx"
                                        value={formik.values.hienTrangCoSo?.tongCongNhanVsnx}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Người của cơ sở"}
                                        name="hienTrangCoSo.congNhanVsnxCuaCoSo"
                                        value={formik.values.hienTrangCoSo?.congNhanVsnxCuaCoSo}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Thuê"}
                                        name="hienTrangCoSo.congNhanVsnxThue"
                                        value={formik.values.hienTrangCoSo?.congNhanVsnxThue}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <HoaChatSuDung
                                        label={'7. Danh mục các loại hóa chất, phụ gia/chất bổ sung, chất tẩy rửa-khử trùng sử dụng'}
                                        dataListItem={formik.values.hienTrangCoSo.listHoaChat}
                                        onSaveItem={handleSaveItemHoaChatSuDung} onDeleteItem={handleDeleteItemHoaChatSuDung} />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 8. Hệ thống quản lý chất lượng đang áp dụng </InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        name="hienTrangCoSo.heThongQuanLyChatLuong"
                                        value={formik.values.hienTrangCoSo?.heThongQuanLyChatLuong}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 9. Phòng kiểm nghiệm </InputLabel>
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Của cơ sở</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.phongKiemNghiemCuaCoSo}
                                                name="hienTrangCoSo.phongKiemNghiemCuaCoSo"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={10} >
                                    <TextField
                                        fullWidth
                                        name="hienTrangCoSo.tenPhongKiemNghiemCuaCoSo"
                                        value={formik.values.hienTrangCoSo?.tenPhongKiemNghiemCuaCoSo}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox">Thuê ngoài</span>}
                                        control={
                                            <Checkbox checked={formik.values.hienTrangCoSo?.phongKiemNghiemThueNgoai}
                                                name="hienTrangCoSo.phongKiemNghiemThueNgoai"
                                                color="default" size="small" onChange={formik.handleChange} />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={10} >
                                    <TextField
                                        fullWidth
                                        name="hienTrangCoSo.tenPhongKiemNghiemThueNgoai"
                                        value={formik.values.hienTrangCoSo?.tenPhongKiemNghiemThueNgoai}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles} > 10. Nội dung khác </InputLabel>
                                    <TextareaAutosize name="hienTrangCoSo.noiDungKhac" rows="5" value={formik.values.hienTrangCoSo?.noiDungKhac} className={classes.textarea} onChange={formik.handleChange} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                </Paper>
            </div>
            {openDialog && (
                <DialogMap
                    diaChiOf="diaChi"
                    formik={formik}
                    id={LopBanDoEnum.QLCL_AN_TOAN_THUC_PHAM}
                    handleCloseDialog={() => setOpenDialog(false)}
                    openDialog={openDialog}
                />
            )}
        </MuiPickersUtilsProvider>
    )
}