export enum LopBanDoEnum {
    THU_Y_BUON_BAN_THUOC = 0,
    THU_Y_VE_SINH = 1,
    THU_Y_AN_TOAN_DICH_BENH = 2,
    THUC_VAT_BUON_BAN_PHAN_BON = 3,
    THUC_VAT_BUON_BAN_THUOC_BVTV = 4,
    QLCL_AN_TOAN_THUC_PHAM = 5,
    THUC_VAT_SAN_XUAT_PHAN_BON = 6,
}

export interface MarkerDTO {
    id: Number | null;
    tenCoSo: String;
    fullDiaChi: String;
    diaChiKinhDo: Number;
    diaChiViDo: Number;
    soGiayPhep: String;
    ngayCap: Date | null;
    soDienThoai: String;
    fax: String;
    diaChiTinhCode: String;
    diaChiHuyenCode: String;
    diaChiXaCode: String;
    tinhTrang: String;
}

export interface ChungNhanATTPMarkerDTO extends MarkerDTO {
    chuCoSo: String;
    matHangSxkd: String;
}

export interface ThucVatMarkerDTO extends MarkerDTO {
    chuCoSo: String;
    donViChuQuan: String;
    soDienThoiDonViChuQuan: String;
    diaDiemNoiChua: String;
}

export interface ThuYMarkerDTO extends MarkerDTO {
    linhVucHoatDong: String;
    lyDoDamBaoVeSinh: String;

    loaiHinhHoatDong: String;
    thiTruongTieuThu: String;
    doiTuong: String;
    dangKyAnToanVoiLoaiBenh: String;
}

export interface BuonBanThuocThuYMarkerDTO extends MarkerDTO {
    chuCoSo: String;
    cacSanPhamKinhDoanh: String;
}
