import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { ChungNhanDuDieuKienAttpService } from "apis/QLCLNongLamThuySan/chungNhanDuDieuKienAttp/chungNhanDuDieuKienAttpService";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { Pageable, TypeCell } from "components/commons/customTable/customTableInterface";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { useGetReferItem } from "hooks/useGetSoGiayPhep";
import { ChungNhanAttpHienTrangCoSoDTO, ChungNhanAttpHienTrangCoSoDTO_INT, ChungNhanAttpSanPhamDTO, ChungNhanAttpSanPhamDTO_INT, ChungNhanAttpThongTinChungDTO, LichSuBienDongDTO } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpDTO";
import { getDoiTuongEnum, getLoaiHinhSanXuatKinhDoanhEnum, getLoaiMatHangKinhDoanhEnum } from "model/QLCLNongLamThuySan/chungNhanDuDieuKien/chungNhanDuDieuKienAttpEnum";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { HoaChatSuDung } from "../hoaChat/hoaChatSuDung";
import { TrangThietBi } from "../trangThietBi/trangThietBi";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

export const ViewGiayChungNhanDuDieuKienAttp = () => {
    const title = "Thông tin chi tiết chứng nhận đủ điều kiện an toàn thực phẩm";
    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = title;
    }, []);

    // Control
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>{title}</span>
            </div>
            <Paper component="div">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={value} onChange={handleChange}>
                        <CustomTab label="Thông tin chung" />
                        <CustomTab label="Thông tin về sản phẩm" />
                        <CustomTab label="Hiện trạng cơ sở" />
                        <CustomTab label="Lịch sử" />
                    </CustomTabs>
                </Box>
                {value === 0 && (
                    <TabPanel value={value} index={0}>
                        <ThongTinChung />
                    </TabPanel>
                )}
                {value === 1 && (
                    <TabPanel value={value} index={1}>
                        <ThongTinSanPham />
                    </TabPanel>
                )}
                {value === 2 && (
                    <TabPanel value={value} index={2}>
                        <HienTrangCoSo />
                    </TabPanel>
                )}
                {value === 3 && (
                    <TabPanel value={value} index={3}>
                        <LichSu />
                    </TabPanel>
                )}
            </Paper>
        </div>
    )
}

const ThongTinChung = () => {
    const history = useHistory();
    const { item } = useGetReferItem<ChungNhanAttpThongTinChungDTO>();
    const [data, setData] = useState<ChungNhanAttpThongTinChungDTO>({} as ChungNhanAttpThongTinChungDTO);
    useEffect(() => {
        function initialData() {
            if (item) {
                new ChungNhanDuDieuKienAttpService().getThongTinChungById(Number(item.id)).then(resp => {
                    setData(resp)
                })
            }
        }
        initialData();
    }, [item])
    return (
        <Grid container>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Đối tượng</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{getDoiTuongEnum(data.doiTuong)}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số giấy chứng nhận cũ</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soGcnCu}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp cũ</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapGcnCu && moment(data.ngayCapGcnCu).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số giấy chứng nhận mới</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soGcn}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp mới</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapGcn && moment(data.ngayCapGcn).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Tên cơ sở</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.tenCoSo}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Mã số</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.maSoDkkd}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số giấy CNĐKKD</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.soDkkd}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.ngayCapDkkd && moment(data.ngayCapDkkd).format("DD/MM/YYYY")}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.noiCapDkkd}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Loại hình sản xuất, KD</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{getLoaiHinhSanXuatKinhDoanhEnum(data.loaiHinhSxkd)}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Năm hoạt động</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.namBatDauHoatDong}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Công suất thiết kế</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.congSuatThietKe}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.fullDiaChi}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Số điện thoại</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.soDienThoai}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Fax</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.diaChi?.fax}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Email</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.diaChi?.email}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Chủ cơ sở</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.chuCoSo}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Loại mặt hàng sản xuất, KD</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{getLoaiMatHangKinhDoanhEnum(data.loaiMatHangDkkd)}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Mặt hàng sản xuất, KD</InputLabel>
                </Grid>
                <Grid item xs={10} className="grid-item">
                    <label className="text-info">{data.matHangSxkd}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Tình trạng hoạt động</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.tinhTrangStr}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Lần cấp lại</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.lanCapLai}</label>
                </Grid>
            </Grid>
            <Grid item xs={12} className="gridItem">
                <Box className="button-group" marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

const sanPhamColumns: ColumnCustomTable<ChungNhanAttpSanPhamDTO>[] = [
    { title: "Tên sản phẩm, sản xuất kinh doanh", field: "tenSanPham" },
    { title: "Tên nguyên liệu/ sản phẩm", field: "tenNguyenLieu" },
    { title: "Nguồn gốc/ xuất xứ", field: "nguonGoc" },
    { title: "Diện tích (m2/ha)", field: "dienTich", width: "10%", align: "left" },
    { title: "Số hộ", field: "soHo", width: "30%", align: "left" },
    { title: "Sản lượng", field: "sanLuong" },
    { title: "Cách thức đóng gói và thông tin ghi trên bao bì", field: "cachDongGoi" },
];

export const ThongTinSanPham = () => {
    const history = useHistory();
    const { item } = useGetReferItem<ChungNhanAttpThongTinChungDTO>();
    const [data, setData] = useState<ChungNhanAttpSanPhamDTO[]>(new Array<ChungNhanAttpSanPhamDTO>());
    const [pageParamsSanPham, setPageParamsSanPham] = useState({ total: 0, page: 0, rowsPerPage: 5, } as Pageable);

    useEffect(() => {
        loadDanhSachSanPham(pageParamsSanPham);
    }, [item])

    async function loadDanhSachSanPham(pageParamsSanPham: Pageable) {
        if (item) {
            await new ChungNhanDuDieuKienAttpService().getDanhSachSanPham({ ...ChungNhanAttpSanPhamDTO_INT, chungNhanId: Number(item.id) }, pageParamsSanPham.page, pageParamsSanPham.rowsPerPage).then((response) => {
                setData(response.rows);
                setPageParamsSanPham((prev) => ({ ...prev, total: response.total }));
            });
        }
    }

    function handleOnChangePageSanPham(value: number) {
        let pageParamsSanPhamNew = { ...pageParamsSanPham, page: value };
        setPageParamsSanPham(pageParamsSanPhamNew);
        loadDanhSachSanPham(pageParamsSanPhamNew);
    }

    function handOnChangeRowsPerPageSanPham(value: number) {
        let pageParamsSanPhamNew = {
            ...pageParamsSanPham,
            page: 0,
            rowsPerPage: value,
        };
        setPageParamsSanPham(pageParamsSanPhamNew);
        loadDanhSachSanPham(pageParamsSanPhamNew);
    }
    return (
        <Grid item xs={12} className="grid-item">
            <CustomTable
                columns={sanPhamColumns}
                rows={data}
                onChangePage={handleOnChangePageSanPham}
                onChangeRowsPerPage={handOnChangeRowsPerPageSanPham}
                pageParams={pageParamsSanPham}
            />
        </Grid>
    )
}


export const HienTrangCoSo = () => {
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const { item } = useGetReferItem<ChungNhanAttpThongTinChungDTO>();
    const [data, setData] = useState<ChungNhanAttpHienTrangCoSoDTO>({ ...ChungNhanAttpHienTrangCoSoDTO_INT });


    async function initialData() {
        if (item) {
            await new ChungNhanDuDieuKienAttpService().getHienTrangCoSoByChungNhanId(Number(item.id)).then(resp => {
                if (resp) {
                    setData(resp);
                }
            })
        }
    }

    useEffect(() => {
        initialData();
    }, [item])

    return (
        <Grid container>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>1. Nhà xưởng, trang thiết bị</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">Tổng diện tích</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.tongDienTich}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">DT khu vực tiếp nhận</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.dtKhuVucTiepNhan}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">DT khu vực sản xuất</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.dtKhuVucSanXuat}</label>
                </Grid>
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">DT khu vực bảo quản</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.dtKhuVucBaoQuan}</label>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <Grid item xs={2} className="grid-item">
                    <InputLabel className="label-info">DT khu vực sản xuất khác</InputLabel>
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <label className="text-info">{data.dtKhuVucSanXuatKhac}</label>
                </Grid>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <TrangThietBi label={'2. Trang thiết bị chính'} viewOnly dataListItem={data.listTrangThietBi ? data.listTrangThietBi : []} disableAddButton />
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>3. Hệ thống phụ trợ</InputLabel>
                <InputLabel classes={labelStyles}>Nguồn nước đang sử dụng</InputLabel>
                <FormGroup row style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControlLabel
                        control={<Checkbox checked={data.nuocMayCongCong} color="default" size="small" />}
                        label="Nước máy công cộng"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={data.nuocGiengKhoan} color="default" size="small" />}
                        label="Nước giếng khoan"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={data.coHeThongXuLy} color="default" size="small" />}
                        label="Có hệ thống xử lý"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={data.khongCoHeThongXuLy} color="default" size="small" />}
                        label="Không có hệ thống xử lý"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={data.nuocSong} color="default" size="small" />}
                        label="Nước sông"
                    />
                </FormGroup>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <InputLabel className="label-info">Phương pháp xử lý</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <label className="text-info">{data.phuongPhapXuLy}</label>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>4. Hệ thống xử lý chất thải</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <InputLabel className="label-info">Cách thức thu gom, vận chuyển, xử lý</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <label className="text-info">{data.heThongXuLyChatThai}</label>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>5. Người sản xuất, kinh doanh</InputLabel>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Tổng số</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.tongSoNguoiSxkd}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Lao động trực tiếp</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.laoDongTrucTiep}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Lao động gián tiếp</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.laoDongGianTiep}</label>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>6. Vệ sinh nhà xưởng, trang thiết bị</InputLabel>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Tần suất</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.tanSuatVsnx}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Tổng nhân công làm vệ sinh</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.tongCongNhanVsnx}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Người của cơ sở</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.congNhanVsnxCuaCoSo}</label>
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel className="label-info">Thuê</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <label className="text-info">{data.congNhanVsnxThue}</label>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <HoaChatSuDung label={'7. Danh mục các loại hóa chất, phụ gia/chất bổ sung, chất tẩy rửa-khử trùng sử dụng'} viewOnly
                    dataListItem={data.listHoaChat ? data.listHoaChat : []} disableAddButton />
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>8. Hệ thống quản lý chất lượng đang áp dụng</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <label className="text-info">{data.heThongQuanLyChatLuong}</label>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <InputLabel classes={labelStyles}>9. Nội dung khác</InputLabel>
            </Grid>
            <Grid container item xs={12} className="grid-item">
                <label className="text-info">{data.noiDungKhac}</label>
            </Grid>
        </Grid>
    );
}

const LichSu = () => {
    // Constant
    const { item } = useGetReferItem<ChungNhanAttpThongTinChungDTO>();
    const columns: ColumnCustomTable<LichSuBienDongDTO>[] = [
        { title: "Loại biến động", field: "loaiBienDong", align: "left" },
        { title: "Ngày biến động", field: "ngayBienDong", type: TypeCell.DATE },
        { title: "Số lần", field: "soLan" },
        { title: "Nội dung", field: "noiDung", width: "40%", align: "left" },
        { title: "Đính kèm", field: "attachFiles", type: TypeCell.ATTACH_FILE_TOOL_TIP },
    ];
    // State
    const [rows, setRows] = useState<LichSuBienDongDTO[]>([]);
    // Hook
    useEffect(() => {
        function initialData() {
            let id = 0;
            if (item?.referenceId  && item?.referenceId > 0) {
                // id = item?.referenceId;
                new ChungNhanDuDieuKienAttpService().getLichSuBienDong(Number(item?.referenceId)).then(resp => {
                    setRows(resp);
                })
            }else if(item?.id  && item?.id > 0){
                // id = item?.id;
                new ChungNhanDuDieuKienAttpService().getLichSuBienDong(Number(item?.id)).then(resp => {
                    setRows(resp);
                })
            }
            
        }
        initialData();
    }, [item?.id,item?.referenceId]);
    return (
        <>
            <CustomTable columns={columns} rows={rows} />
        </>
    );
}