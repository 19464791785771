
import MomentUtils from "@date-io/moment";
import { Box, Button, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { downLoadFile } from "apis/Common/exportUtil";
import { LongBeManagementSevice } from "apis/thuySan/longBe";
import { ColumnCustomTable, CustomTable } from "components/commons/customTable/customTable";
import { ExportGroup } from "components/commons/exportGroup/exportGroup";
import { DiaChiDonViHanhChinh } from "components/commons/fields/dvhc3Cap/donViHanhChinh3Cap";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { getIn, useFormik } from "formik";
import { DiaChiDTO_INT_CAN_THO, DiaChiDTO_INT_CAN_THO_THONG_KE } from "model/QLCLNongLamThuySan/diaChiDTO";
import { LongBeThongKeDiaChiDTO } from "model/thuySan/longBe/longBeThongKeDTO";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { NotificationAction, openNotification } from "redux/actions/notificationAction";
import StringUtil from "utils/stringUtils";
import { TypeCell } from "components/commons/customTable/customTableInterface";
import DialogChiTietThongKe from "components/commons/dialog/dialogChiTietThongKe";
import { DanhSachTraCuuLongBeDTO } from "model/thuySan/longBe/danhSachTraCuuLongBeDTO";
import { DanhSachTraCuuDTO } from "model/thuySan/tauCa/danhSachTraCuuDTO";
import useContextTableFooter from "hooks/useContextTableFooter";
import DialogChiTietXNNuoiTrong from "components/commons/dialog/dialogChiTietGiayXNNuoiTrongLongBe";

export const ThongKeXNNuoiTrongLongBeAoNuoi = () => {
    //const
    const validationSchema = Yup.object().shape(
        {
            ngayCapFrom: Yup.date()
                .nullable()
                .when("ngayCapTo", (ngayCapTo: any, schema: any) => {
                    if (ngayCapTo) {
                        return schema.max(ngayCapTo, "Không hợp lệ");
                    }
                    return schema;
                }),
            ngayCapTo: Yup.date()
                .nullable()
                .when("ngayCapFrom", (ngayCapFrom: any, schema: any) => {
                    if (ngayCapFrom) {
                        return schema.min(ngayCapFrom, "Không hợp lệ");
                    }
                    return schema;
                }),
        },
        [["ngayCapFrom", "ngayCapTo"]]
    );

    //state
    const [mode, setMode] = useState<string>("YEAR");
    const [rows, setRows] = useState<LongBeThongKeDiaChiDTO[]>([]);
    const [rowsChiTiet, setRowsChiTiet] = useState<DanhSachTraCuuLongBeDTO[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogChiTiet, setOpenDialogChiTiet] = useState(false);
    const [itemChiTiet, setItemChiTiet] = useState<any>({});
    const [phuongChiTiet, setPhuongChiTiet] = useState<string>("");
    // hook
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const { pageParams, setPageParams, handOnChangeRowsPerPage, handleOnChangePage } = useContextTableFooter();

    const formik = useFormik({
        initialValues: {
            ngayCapFrom: null,
            ngayCapTo: null,
            year: (new Date()).getFullYear(),
            diaChi: { ...DiaChiDTO_INT_CAN_THO_THONG_KE },

        } as LongBeThongKeDiaChiDTO,
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
        validateOnChange: false,
    });

    useEffect(() => {
        handleSubmit(formik.values);
    }, []);

    const loadChiTietThongKeHandler = async (data: LongBeThongKeDiaChiDTO) => {
        // let request: DanhSachTraCuuDTO = {
        //     soGiayPhep: "",
        //     ngayCap: null,
        //     ngayHetHan: null,
        //     ngayCapFrom: null,
        //     ngayCapTo: null,
        //     diaChi: { ...DiaChiDTO_INT_CAN_THO }
        // }
        // request.diaChi = formik.values.diaChi;
        // if (request.diaChi) {
        //     request.diaChi.diaChiXaCode = data.maPhuong;
        // }
        // if (formik.values.ngayCapFrom) {
        //     request.ngayCapFrom = formik.values.ngayCapFrom;
        // }
        // if (formik.values.ngayCapTo) {
        //     request.ngayCapTo = formik.values.ngayCapTo;
        // }
        if (data.maPhuong) {
            setPhuongChiTiet(data.maPhuong);
        }
        // await new LongBeManagementSevice().chiTietThongKeAoBeLongNuoi(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
        //     setRowsChiTiet(response.rows);
        //     setPageParams((prev) => ({ ...prev, total: response.total }));
        // });
    }

    useEffect(() => {
        async function loadChiTietThongKeHandler() {
            if (openDialog) {
                let request: DanhSachTraCuuDTO = {
                    soGiayPhep: "",
                    ngayCap: null,
                    ngayHetHan: null,
                    ngayCapFrom: null,
                    ngayCapTo: null,
                    diaChi: { ...DiaChiDTO_INT_CAN_THO }
                }
                request.diaChi = formik.values.diaChi;
                if (request.diaChi) {
                    request.diaChi.diaChiXaCode = phuongChiTiet;
                }
                if (formik.values.ngayCapFrom) {
                    request.ngayCapFrom = formik.values.ngayCapFrom;
                }
                if (formik.values.ngayCapTo) {
                    request.ngayCapTo = formik.values.ngayCapTo;
                }

                await new LongBeManagementSevice().chiTietThongKeAoBeLongNuoi(request, pageParams.page, pageParams.rowsPerPage).then((response) => {
                    setRowsChiTiet(response.rows);
                    setPageParams((prev) => ({ ...prev, total: response.total }));
                });
            }
        }

        loadChiTietThongKeHandler();
    }, [pageParams.page, pageParams.rowsPerPage, openDialog, phuongChiTiet]);

    const columnsChiTietThongKe: ColumnCustomTable<DanhSachTraCuuLongBeDTO>[] = [
        {
            title: "Số giấy xác nhận",
            field: "soGiayPhep",
            type: TypeCell.ACTION_LINE_VIEW,
            actions: [{
                func: (data) =>  chiTietGiayXNHandler(data)
            }]
        },
        { title: "Ngày cấp", field: "ngayCap", type: TypeCell.DATE },
        { title: "Họ tên chủ cơ sở", field: "tenCoSo", width: "10%", align: "left" },
        { title: "Địa chỉ cơ sở", field: "fullDiaChi", width: "30%", align: "left" },
        { title: "Hình thức nuôi", field: "hinhThucNuoiStr", align: "left" },
        { title: "Diện tích", field: "dienTich" },
    ];

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleCloseDialogXNNuoiTrong = () => {
        setOpenDialogChiTiet(false);
    }

    const chiTietThongKeHandler = (data: LongBeThongKeDiaChiDTO) => {
        loadChiTietThongKeHandler(data);
        setOpenDialog(true);
    };

    const chiTietGiayXNHandler = (data: DanhSachTraCuuLongBeDTO) => {
        setItemChiTiet(data);
        setOpenDialogChiTiet(true);
    }

    const columns2: ColumnCustomTable<LongBeThongKeDiaChiDTO>[] = [
        { title: "Phường/xã", field: "tenPhuong" },
        {
            title: "Diện tích (m2/m3)",
            field: "dienTich",
            type: TypeCell.ACTION_LINE_VIEW,
            actions: [{
                func: (data) => data.dienTich && data.dienTich>0 && chiTietThongKeHandler(data)
            }]
        },
        {
            title: "Ao/bể/lồng nuôi",
            field: "longNuoi",
            type: TypeCell.ACTION_LINE_VIEW,
            actions: [{
                func: (data) => data.longNuoi && data.longNuoi>0 && chiTietThongKeHandler(data)
            }]
        },
        { title: "Đối tượng nuôi", field: "doiTuongNuoi" },
    ];


    useEffect(() => {
        async function handleChangeMode() {
            if (mode === 'YEAR') {
                formik.setFieldValue('year', (new Date()).getFullYear());
            }
            if (mode === 'MONTH') {
                formik.setFieldValue('thang', (new Date()).getMonth());
            }
        }
        handleChangeMode();
    }, [mode]);

    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();

    // Control
    async function handleSubmit(request: LongBeThongKeDiaChiDTO) {
        if (StringUtil.isNullOrEmty(formik.values?.diaChi?.diaChiHuyenCode)) {
            dispatchNotifiAction(openNotification({ severity: "warning", content: "Vui lòng chọn huyện", open: true }));
            return;
        }
        let data = { ...request, mode: mode };
        // if (mode === 'YEAR') {
        //     data.ngayCapFrom = moment([Number(data.year)]).startOf('year').toDate();
        //     data.ngayCapTo = moment([Number(data.year)]).endOf('year').toDate();
        // } else {
        //     if (mode === 'MONTH') {
        //         let mm = moment().year(Number(data.year)).month(Number(data.thang));
        //         data.ngayCapFrom = mm.startOf('month').toDate();
        //         data.ngayCapTo = mm.endOf('month').toDate();
        //         data.thang = String(Number(data.thang) + 1);
        //     }
        //     data.year = undefined;
        // }
        await new LongBeManagementSevice().thongKeDiaChiAoNuoi(data).then((response) => {
            setRows(response);
        });
    }

    async function handleExportFile(downloadType: "MSWORD" | "EXCEL" | "PDF") {
        if (StringUtil.isNullOrEmty(formik.values?.diaChi?.diaChiHuyenCode)) {
            dispatchNotifiAction(openNotification({ severity: "warning", content: "Vui lòng chọn huyện", open: true }));
            return;
        }
        let data = { ...formik.values, mode: mode, downloadType };
        await new LongBeManagementSevice().thongKeDiaChiAoNuoiExport(data).then((resp) => {
            downLoadFile(resp.data, resp.data.type, `[Tinh-hinh-giay-xac-nhan-dang-ky-nuoi-trong-thuy-san-]${new Date()}`);
        });
    }

    const reduceSum: Number[] = [
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "dienTich"),
        StringUtil.sumNumber(rows.length > 0 ? rows : [], "longNuoi"),
        , "",
    ];

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">{'Thống kê danh sách Ao/bể/lồng nuôi'}</div>
                <Grid container>
                    <DiaChiDonViHanhChinh formik={formik} diaChiOf={"diaChi"} required={false} hideTinhThanh={true} />
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapFrom"
                            label={"Từ ngày"}
                            value={formik.values.ngayCapFrom}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapFrom", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapFrom") && getIn(formik.errors, "ngayCapFrom"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapFrom") && getIn(formik.errors, "ngayCapFrom")) && formik.errors?.ngayCapFrom}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <KeyboardDatePicker
                            name="ngayCapTo"
                            label={"Đến ngày"}
                            value={formik.values.ngayCapTo}
                            onChange={(date, _val) => {
                                formik.setFieldValue("ngayCapTo", date);
                            }}
                            autoOk={true}
                            fullWidth
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            error={Boolean(getIn(formik.touched, "ngayCapTo") && getIn(formik.errors, "ngayCapTo"))}
                            FormHelperTextProps={{
                                className: "-error",
                                classes: helperTextStyles,
                            }}
                            helperText={Boolean(getIn(formik.touched, "ngayCapTo") && getIn(formik.errors, "ngayCapTo")) && formik.errors?.ngayCapTo}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Box className="button-group" marginTop="5px" justifyContent="center" style={{ display: "flex" }}>
                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                Tổng hợp
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <ExportGroup onExportFile={handleExportFile} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CustomTable
                            columns={columns2}
                            rows={rows}
                            reduceSumField={reduceSum}
                        />
                    </Grid>
                </Grid>
            </div>
            {openDialog &&
                <DialogChiTietThongKe
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    rows={rowsChiTiet}
                    columns={columnsChiTietThongKe}
                    pageParams={pageParams}
                    onChangePage={handleOnChangePage}
                    onChangeRowsPerPage={handOnChangeRowsPerPage}
                />}
            {openDialogChiTiet
                && <DialogChiTietXNNuoiTrong
                    openDialog={openDialogChiTiet}
                    handleCloseDialog={handleCloseDialogXNNuoiTrong}
                    itemChiTiet={itemChiTiet}
                />
            }
        </MuiPickersUtilsProvider>
    )
}