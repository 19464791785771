import React from "react";
import StringUtil from "../../../utils/stringUtils";
import moment from "moment";
import { Box, Button, IconButton } from "@material-ui/core";
import { Cell } from "../table/tableCommons";
import { Action, TypeCell } from "./customTableInterface";
import CustomMenu from "./customMenuDrop";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AttachFileService from "apis/attachFileService/attachFileService";
import { AttachFileTooltipCell } from "./attachFileCell";

interface IPropsCell<T> {
    item: T;
    field?: keyof T;
    type?: TypeCell;
    actions?: Action<T>[];
    align?: "center" | "inherit" | "justify" | "left" | "right";
}

async function handleDownloadFile(file: any) {
    await new AttachFileService().downloadFile(file).then((res) => {
        let blob = new Blob([res.data], { type: res.data.type });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file.fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    });
}

const CustomCellTableCustom = <T extends { [key: string]: any }>({ item, field, type, actions, align = "center" }: IPropsCell<T>) => {
    switch (type) {
        case TypeCell.LINK:
            return (
                <Cell scope="row">
                    {field && item[field] && (
                        <Link
                            to={{
                                pathname: `${actions !== undefined && actions[0].title}`,
                                state: { item: item },
                            }}
                            style={{ textDecoration: "none", color: "#367fa9" }}
                            className="url-link"
                        >
                            {item[field]}
                        </Link>
                    )}
                </Cell>
            );
        case TypeCell.BOOLEAN:
            return (
                <Cell scope="row" align={align}>
                    {field && item[field] && <CheckRoundedIcon style={{ color: green[500] }} />}
                </Cell>
            );
        case TypeCell.CURRENCY:
            return (
                <Cell scope="row" align={align}>
                    {field && StringUtil.formatMoney(`${item[field]}`)}
                </Cell>
            );
        case TypeCell.DATE:
            return (
                <Cell scope="row" align={align}>
                    {field && item[field] ? moment(item[field]).format("DD/MM/YYYY") : ""}
                </Cell>
            );
        case TypeCell.DATE_TIME:
            return (
                <Cell scope="row" align={align}>
                    {field && item[field] ? moment(item[field]).format("DD/MM/YYYY hh:mm:ss") : ""}
                </Cell>
            );
        case TypeCell.FILE:
            return (
                <Cell scope="row" align={align}>
                    <Button color="primary" style={{ backgroundColor: "transparent" }} onClick={() => handleDownloadFile(item)}>
                        <AttachFileIcon fontSize="small" />
                    </Button>
                </Cell>
            );
        case TypeCell.ATTACH_FILE_TOOL_TIP:
            return (
                <Cell scope="row" align={align}>
                    <AttachFileTooltipCell attachFiles={item["attachFiles"]} onClickDownload={handleDownloadFile} />
                </Cell>
            );
        case TypeCell.ACTION:
            return (
                <Cell component="th" scope="row" align={align} width="5%">
                    <CustomMenu item={item} actions={actions} />
                </Cell>
            );
        case TypeCell.ACTION_LINE:
            return (
                <Cell align={align}>
                    <Box display="flex" justifyContent="center">
                        {actions !== undefined &&
                            actions.map((action, i) => (
                                <IconButton color="primary" aria-label="edit" size="small" onClick={() => action.func && action.func(item)}>
                                    {action.icon}
                                </IconButton>
                            ))}
                    </Box>
                </Cell>
            );
        case TypeCell.ACTION_LINE_VIEW:
            return (
                <Cell align={align}>
                    <Box display="flex" justifyContent="center" >
                        {actions !== undefined &&
                            actions.map((action, i) => (
                                <Box style={{cursor:"pointer"}} onClick={() => action.func && action.func(item)}>
                                        {field !== undefined && item[field]}
                                </Box>
                            ))}
                    </Box>

                </Cell>
            );
        default:
            return (
                <Cell scope="row" align={align}>
                    {field !== undefined && item[field]}
                </Cell>
            );
    }
};

export default CustomCellTableCustom;
