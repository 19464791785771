import { RoutePropsI } from "routes";
import { lazy } from "react";

const ROOT = "/thuc-vat";

const CapXNNDQuangCaoPhanBon = lazy(() =>
    import("pages/thucVat/quangCaoPhanBon/capXNNDQuangCaoPhanBon").then(({ CapXNNDQuangCaoPhanBon }) => ({
        default: CapXNNDQuangCaoPhanBon,
    }))
);
const DanhSachGiayXacNhanQuangCaoPhanBon = lazy(() =>
    import("pages/thucVat/quangCaoPhanBon/danhSachTraCuu/danhSachQuangCaoPhanBon").then(({ DanhSachGiayXacNhanQuangCaoPhanBon }) => ({
        default: DanhSachGiayXacNhanQuangCaoPhanBon,
    }))
);
const BaoCaoQuangCaoPhanBon = lazy(() => import("pages/thucVat/quangCaoPhanBon/danhSachTraCuu/baoCaoQuangCaoPhanBon"));
const ThongKeQuangCaoPhanBon = lazy(() => import("pages/thucVat/quangCaoPhanBon/danhSachTraCuu/thongKeQuangCaoPhanBon"));
const ViewGiayXacNhanQuangCaoPhanBon = lazy(() => import("pages/thucVat/quangCaoPhanBon/chiTiet/index"));

const BaoCaoVCThuocBVTV = lazy(() =>
    import("pages/thucVat/vanChuyenThuocBVTV/baoCao/baoCaoVCThuocBVTV").then(({ BaoCaoVCThuocBVTV }) => ({
        default: BaoCaoVCThuocBVTV,
    }))
);
const ThongKeVCThuocBVTV = lazy(() =>
    import("pages/thucVat/vanChuyenThuocBVTV/thongKe/thongKeVCThuocBVTV").then(({ ThongKeVCThuocBVTV }) => ({
        default: ThongKeVCThuocBVTV,
    }))
);
const CapMoiVCThuocBVTV = lazy(() =>
    import("pages/thucVat/vanChuyenThuocBVTV/capMoi/capMoiVCThuocBVTV").then(({ CapMoiVCThuocBVTV }) => ({
        default: CapMoiVCThuocBVTV,
    }))
);
const DanhSachGPVanChuyenThuocBVTV = lazy(() =>
    import("pages/thucVat/vanChuyenThuocBVTV/traCuu/danhSachGPVanChuyenThuocBVTV").then(({ DanhSachGPVanChuyenThuocBVTV }) => ({
        default: DanhSachGPVanChuyenThuocBVTV,
    }))
);
const ChiTietGPVanChuyenThuocBVTV = lazy(() =>
    import("pages/thucVat/vanChuyenThuocBVTV/traCuu/chiTietGPVanChuyenThuocBVTV").then(({ ChiTietGPVanChuyenThuocBVTV }) => ({
        default: ChiTietGPVanChuyenThuocBVTV,
    }))
);
const CapMoiGiayXNQCThuocBVTV = lazy(() => import("pages/thucVat/QCThuocBVTV/capMoi"));
const BaoCaoGiayXNQCTBVTC = lazy(() => import("pages/thucVat/QCThuocBVTV/baoCao"));
const DanhSachGiayXacNhanQCTBVTV = lazy(() => import("pages/thucVat/QCThuocBVTV"));
const ThongKeGiayXacNhanTBVTV = lazy(() => import("pages/thucVat/QCThuocBVTV/thongKe"));
const ViewGiayXacNhan = lazy(() => import("pages/thucVat/QCThuocBVTV/view"));

const DanhSachSanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon"));
const NhapDauKySanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon/nhapDauKy"));
const CapMoiGiayXacNhanSanXuatPB = lazy(() => import("pages/thucVat/sanXuatPhanBon/capMoi"));
const CapLaiGiayXacNhanSanXuatPB = lazy(() => import("pages/thucVat/sanXuatPhanBon/capLai"));
const ThuHoiGiayChungNhanSanXuatPB = lazy(() => import("pages/thucVat/sanXuatPhanBon/thuHoi"));
const BaoCaoSanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon/baoCao"));
const ThongKeSanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon/thongKe"));
const BieuDoSanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon/bieuDo"));
const ViewSanXuatPhanBon = lazy(() => import("pages/thucVat/sanXuatPhanBon/view"));

const DanhSachBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon"));
const NhapDauKyBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/nhapDauKy"));
const CapMoiGiayXacNhanBuonBanPB = lazy(() => import("pages/thucVat/buonBanPhanBon/capMoi"));
const CapLaiGiayXacNhanBuonBanPB = lazy(() => import("pages/thucVat/buonBanPhanBon/capLai"));
const ThuHoiGiayChungNhanBuonBanPB = lazy(() => import("pages/thucVat/buonBanPhanBon/thuHoi"));
const BaoCaoBuonPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/baoCao"));
const ThongKeBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/thongKe"));
const BieuDoBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/bieuDo"));
const ViewBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanPhanBon/view"));
// const ChiTietGiayXacNhanQuangCaoPhanBon = lazy(() =>
//   import(
//       "pages/thucVat/quangCaoPhanBon/chiTiet/chiTiet"
//       ).then(({ ChiTietGiayXacNhanQuangCaoPhanBon }) => ({
//       default: ChiTietGiayXacNhanQuangCaoPhanBon,
//     }))
// );

//buôn bán thuốc BVTV
const BaoCaoBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/danhSachTraCuuBBThuocBVTV/baoCaoGiayChungNhan"));
const ThongKeBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/thongKe"));
const BieuDoBuonBanThuocBVTC = lazy(() => import("pages/thucVat/buonBanThuocBVTV/bieuDoBBThuocBVTV"));
const ViewBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/danhSachTraCuuBBThuocBVTV/chiTietBBThuocBVTV"));

const NhapDauKyBBThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/danhSachTraCuuBBThuocBVTV/nhapDauKyBBThuocBVTV/index"));
const CapMoiBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/capMoiBBThuocBVTV"));
const ThuHoiBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/danhSachTraCuuBBThuocBVTV/thuHoiBBThuocBVTV"));
const CapLaiBuonBanPhanBon = lazy(() => import("pages/thucVat/buonBanThuocBVTV/capLaiBBThuocBVTV"));
const DanhSachGiayXacNhanBuonBanThuocBVTV = lazy(() => import("pages/thucVat/buonBanThuocBVTV/danhSachTraCuuBBThuocBVTV/danhSachGiayXacNhan"));

const ROUTE_PAGE_QUANG_CAO_PHAN_BON: RoutePropsI[] = [
    {
        ten: "Danh sách giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT}/danh-sach-giay-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <DanhSachGiayXacNhanQuangCaoPhanBon />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT}/bao-cao-giay-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <BaoCaoQuangCaoPhanBon />,
    },
    {
        ten: "Tình hình cấp giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT}/thong-ke-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <ThongKeQuangCaoPhanBon />,
    },

    {
        ten: "Danh sách giấy xác nhận nội dung thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/danh-sach-giay-chung-nhan-bao-ve-thuc-vat`,
        giaoDien: <DanhSachGiayXacNhanQCTBVTV />,
    },
    {
        ten: "Báo cáo giấy xác nhận nội dung thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/bao-cao-giay-chung-nhan-bao-ve-thuc-vat`,
        giaoDien: <BaoCaoGiayXNQCTBVTC />,
    },
    {
        ten: "Thống kê giấy xác nhận nội dung thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/thong-ke-giay-chung-nhan-bao-ve-thuc-vat`,
        giaoDien: <ThongKeGiayXacNhanTBVTV />,
    },
    {
        ten: "Danh sách giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/danh-sach-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <DanhSachSanXuatPhanBon />,
    },
    {
        ten: "Báo cáo giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/bao-cao-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <BaoCaoSanXuatPhanBon />,
    },
    {
        ten: "Thống kê giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/thong-ke-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <ThongKeSanXuatPhanBon />,
    },
    {
        ten: "Biểu đồ giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/bieu-do-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <BieuDoSanXuatPhanBon />,
    },
    {
        ten: "Danh sách giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/danh-sach-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <DanhSachBuonBanPhanBon />,
    },
    {
        ten: "Báo cáo giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/bao-cao-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <BaoCaoBuonPhanBon />,
    },
    {
        ten: "Thống kê giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/thong-ke-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <ThongKeBuonBanPhanBon />,
    },
    {
        ten: "Biểu đồ giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/bieu-do-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <BieuDoBuonBanPhanBon />,
    },
    {
        ten: "Danh sách giấy phép vận chuyển thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/danh-sach-giay-phep-van-chuyen-thuoc-bvtv`,
        giaoDien: <DanhSachGPVanChuyenThuocBVTV />,
    },
    {
        ten: "Báo cáo tình hình cấp giấy phép vận chuyển thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/bao-cao-giay-phep-van-chuyen-thuoc-bvtv`,
        giaoDien: <BaoCaoVCThuocBVTV />,
    },
    {
        ten: "Thống kê tình hình cấp giấy phép vận chuyển thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/thong-ke-giay-phep-van-chuyen-thuoc-bvtv`,
        giaoDien: <ThongKeVCThuocBVTV />,
    },
];

const ROUTE_CN_QUANG_CAO_PHAN_BON: RoutePropsI[] = [
    {
        ten: "Cấp giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT}/cap-giay-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <CapXNNDQuangCaoPhanBon />,
    },
    {
        ten: "Cấp mới giấy xác nhận nội dung quảng cáo thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/cap-moi-xac-nhan-noi-dung-quang-cao-thuoc-bao-ve-thuc-vat`,
        giaoDien: <CapMoiGiayXNQCThuocBVTV />,
    },
    {
        ten: "Cấp mới giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/cap-moi-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <CapMoiGiayXacNhanSanXuatPB />,
    },
    {
        ten: "Cấp lại giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/cap-lai-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <CapLaiGiayXacNhanSanXuatPB />,
    },


    {
        ten: "Nhập đầu kỳ giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/nhap-dau-ky-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <NhapDauKySanXuatPhanBon />,
    },
    {
        ten: "Thu hồi giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/thu-hoi-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <ThuHoiGiayChungNhanSanXuatPB />,
    },
    {
        ten: "Thông tin chi tiết giấy chứng nhận sản xuất phân bón",
        duongDan: `${ROOT}/thong-tin-chi-tiet-giay-chung-nhan-san-xuat-phan-bon`,
        giaoDien: <ViewSanXuatPhanBon />,
    },

    {
        ten: "Cấp mới giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/cap-moi-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <CapMoiGiayXacNhanBuonBanPB />,
    },
    {
        ten: "Cấp lại giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/cap-lai-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <CapLaiGiayXacNhanBuonBanPB />,
    },
    {
        ten: "Cấp mới giấy phép vận chuyển thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/cap-moi-giay-phep-van-chuyen-thuoc-bao-ve-thuc-vat`,
        giaoDien: <CapMoiVCThuocBVTV />,
    },

    {
        ten: "Thông tin chi tiết giấy xác nhận nội dung quảng cáo phân bón",
        duongDan: `${ROOT}/chi-tiet-giay-xac-nhan-noi-dung-quang-cao-phan-bon`,
        giaoDien: <ViewGiayXacNhanQuangCaoPhanBon />,
    },
    {
        ten: "Thông tin chi tiết giấy xác nhận nội dung thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/thong-tin-chi-tiet-giay-chung-nhan-bao-ve-thuc-vat`,
        giaoDien: <ViewGiayXacNhan />,
    },
    {
        ten: "Nhập đầu kỳ giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/nhap-dau-ky-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <NhapDauKyBuonBanPhanBon />,
    },
    {
        ten: "Thu hồi giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/thu-hoi-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <ThuHoiGiayChungNhanBuonBanPB />,
    },
    {
        ten: "Thông tin chi tiết giấy chứng nhận buôn bán phân bón",
        duongDan: `${ROOT}/thong-tin-chi-tiet-giay-chung-nhan-buon-ban-phan-bon`,
        giaoDien: <ViewBuonBanPhanBon />,
    },
    {
        ten: "Chi tiết giấy phép vận chuyển thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/chi-tiet-giay-phep-van-chuyen-thuoc-bvtv`,
        giaoDien: <ChiTietGPVanChuyenThuocBVTV />,
    },
];

const ROUTE_PAGE_BUON_BAN_THUOC_BVTV: RoutePropsI[] = [
    {
        ten: "Danh sách giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/danh-sach`,
        giaoDien: <DanhSachGiayXacNhanBuonBanThuocBVTV />,
    },
    {
        ten: "Báo cáo giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/bao-cao`,
        giaoDien: <BaoCaoBuonBanThuocBVTV />,
    },
    {
        ten: "Thống kê giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/thong-ke`,
        giaoDien: <ThongKeBuonBanThuocBVTV />,
    },
    {
        ten: "Biểu đồ giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/bieu-do`,
        giaoDien: <BieuDoBuonBanThuocBVTC />,
    },
];

const ROUTE_CN_BUON_BAN_THUOC_BVTV: RoutePropsI[] = [
    {
        ten: "Cấp mới giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/cap-moi`,
        giaoDien: <CapMoiBuonBanThuocBVTV />,
    },
    {
        ten: "Cấp lại giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/cap-lai`,
        giaoDien: <CapLaiBuonBanPhanBon />,
    },
    {
        ten: "Nhập đầu kỳ giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/nhap-dau-ky`,
        giaoDien: <NhapDauKyBBThuocBVTV />,
    },
    {
        ten: "Thu hồi giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/thu-hoi`,
        giaoDien: <ThuHoiBuonBanThuocBVTV />,
    },
    {
        ten: "Chi tiết giấy chứng nhận đủ điều kiện buôn bán thuốc bảo vệ thực vật",
        duongDan: `${ROOT}/buon-ban-thuoc-bvtv/chi-tiet`,
        giaoDien: <ViewBuonBanThuocBVTV />,
    },
];

export const ROUTE_PAGE_THUC_VAT: RoutePropsI[] = [...ROUTE_PAGE_QUANG_CAO_PHAN_BON, ...ROUTE_PAGE_BUON_BAN_THUOC_BVTV];

export const ROUTE_CN_THUC_VAT: RoutePropsI[] = [...ROUTE_CN_QUANG_CAO_PHAN_BON, ...ROUTE_CN_BUON_BAN_THUOC_BVTV];
