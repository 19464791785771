
import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Dialog, Box, Grid, InputLabel, FormHelperText, InputBase, Button, TextareaAutosize, Checkbox, Snackbar, InputAdornment } from '@material-ui/core';
import moment from "moment";
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import { bootstrapTextFieldHook } from '../../components/commons/input/bootstrap';
import { ChuyenXuLyRequest, HoSoCuaToiDTO, HoSoCuaToiMgtService, NguoiNhanDTO, TepTinChuyenXuLy, ThongTinBuocDTO } from 'apis/hoSoCuaToi/hoSoCuaToiMgtService';
import { DialogContent, DialogTitle } from '../../components/commons/dialog/dialogCommons';

import { UserData } from 'apis/userMgt/userManagementService';
interface ChuyenXuLyModalProps {
    hoso: HoSoCuaToiDTO;
    thongTinBuocDTO: ThongTinBuocDTO;
    show: boolean;
    onToggle: () => void;
    reloadData: () => void;
}
const fontFamily = [
    'Arial',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
        textarea: {
            // boxSizing: 'inherit',
            width: '100%',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ced4da',
            fontSize: 14,
            padding: '0.5rem .75rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            fontFamily: fontFamily,
            '&:focus': {
                outline: 'none',
                borderRadius: 4,
                borderColor: '#ced4da',
            },
        }
    }),
);
const ChuyenXuLyModal = (props: ChuyenXuLyModalProps) => {
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const classes = useStyles();
    const INIT_DATA: ChuyenXuLyRequest = {
        maHoSo: '',
        idBuocXuLyTiepTheo: 0,
        maNguoiGui: C_USER_DATA_DE.userName,
        nguoiNhanId: 0,
        tenNguoiNhan: '',
        noiDung: '',
        trichYeuDuThao: '',
        idLoaiVanBan: 0,
        hanXuLy: props.thongTinBuocDTO.hanXuLyTheoGio,
        danhSachFiles: new Array<TepTinChuyenXuLy>()
    }
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');



    const [data, setData] = useState({ ...INIT_DATA });
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const { onToggle, reloadData, thongTinBuocDTO, hoso } = props;
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();

    useEffect(() => {
        setShow(props.show);
        let request = {
            ...data,
            maHoSo: hoso.maSoHoSo,
            idBuocXuLyTiepTheo: props.thongTinBuocDTO.idBuocXuLy,
            hanXuLy: props.thongTinBuocDTO.hanXuLyTheoGio,
            nguoiGuiId: 16807
        };
        setData(request);
    }, [props.show, props.thongTinBuocDTO]);
    const reset = () => {
        setData({ ...INIT_DATA });
    }
    const handleSubmit = () => {
        setSubmit(true);
        if (data.nguoiNhanId <= 0 || !data.nguoiNhanId) {
            return;
        }
        let request = {
            ...data,
            maHoSo: hoso.maSoHoSo,
            idBuocXuLyTiepTheo: thongTinBuocDTO.idBuocXuLy,
            nguoiGuiId: 16807
        };

        hoSoCuaToiMgtService.chuyenXuLy(request).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setMessage('Yêu cầu thực hiện thành công');
                    setSeverity("success");
                    setShowAlert(true);
                    reloadData();
                    onToggle();
                } else {
                    setMessage(res.data.error_message);
                    setSeverity("error");
                    setShowAlert(true);
                }
            } else {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }
        }).catch(error => {
            setMessage('Yêu cầu thực hiện thất bại');
            setSeverity("error");
            setShowAlert(true);
        });
    }
    const handleChangeSelect = (value: NguoiNhanDTO, nameValue: string, nameText: string) => {
        let valueText = value?.id !== 0 ? value?.tenNguoiNhan : "";
        let dataNew = { ...data, [nameValue]: value?.id, [nameText]: valueText };
        setData(dataNew);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        let dataNew = { ...data, [e.target.name]: value };
        setData(dataNew);
    }
    return (
        <>
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
            </Snackbar>
            <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                    {thongTinBuocDTO.tenBuocXuLy}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={6} className="grid-item" >
                                <Box display="flex" >
                                    <Box fontWeight="bold">Mã hồ sơ: </Box>
                                    <Box>&nbsp;{hoso.maSoHoSo}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <Box display="flex" >
                                    <Box fontWeight="bold">Người đứng tên: </Box>
                                    <Box>&nbsp;{hoso.nguoiNop}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <Box display="flex" >
                                    <Box fontWeight="bold">Ngày nhận: </Box>
                                    <Box>&nbsp;{hoso.ngayNhan && hoso.ngayNhan !== undefined && hoso.ngayNhan !== null ? moment(hoso.ngayNhan).format("DD/MM/YYYY HH:mm") : ""}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <Box display="flex" >
                                    <Box fontWeight="bold">Ngày hẹn trả: </Box>
                                    <Box>&nbsp;{hoso.ngayHenTra && hoso.ngayHenTra !== undefined && hoso.ngayHenTra !== null ? moment(hoso.ngayHenTra).format("DD/MM/YYYY") : ""}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel classes={labelStyles} required>Người nhận </InputLabel>
                                <Autocomplete id="nguoiNhanId"
                                    options={thongTinBuocDTO.nguoiNhan}
                                    value={{ id: data.nguoiNhanId ? data.nguoiNhanId : 0, tenNguoiNhan: data.tenNguoiNhan ? data.tenNguoiNhan : "" }}
                                    getOptionLabel={(option) => option.tenNguoiNhan}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'nguoiNhanId', 'tenNguoiNhan')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && (data.nguoiNhanId <= 0 || !data.nguoiNhanId)}
                                                {...params.inputProps}
                                                classes={inputStyles}
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && (data.nguoiNhanId <= 0 || !data.nguoiNhanId) &&
                                    <FormHelperText className="-error" classes={helperTextStyles} >Vui lòng chọn người nhận</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel classes={labelStyles}>Hạn xử lý</InputLabel>
                                <InputBase disabled readOnly fullWidth classes={inputStyles}
                                    endAdornment={<InputAdornment position="end">Giờ</InputAdornment>}
                                    value={data.hanXuLy} name="hanXuLy" />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel classes={labelStyles}>Nội dung</InputLabel>
                                <TextareaAutosize name="noiDung" rows="8" value={data.noiDung} className={classes.textarea} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation className={classes.button}
                                        onClick={handleSubmit}>
                                        {thongTinBuocDTO.tenBuocXuLy}
                                    </Button>
                                    <Button variant="contained" color="secondary" disableElevation className={classes.button}
                                        onClick={reset}>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation className={classes.button}
                                        onClick={onToggle}>
                                        Đóng
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ChuyenXuLyModal;